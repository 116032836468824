import { csrftoken } from "../shared/utils.js";
import { HTTPError } from "../shared/errors.js";
import { fetchError, getJsonOrThrow } from "../shared/api.js";

function listItems(itemType, businessCode) {
  return fetch(`/inventory/${itemType}/${businessCode}/`)
    .catch(fetchError)
    .then(getJsonOrThrow);
}

function createItem(itemType, businessCode, item) {
  return fetch(`/inventory/${itemType}/${businessCode}/`, {
    method: "POST",
    headers: {
      "X-CSRFToken": csrftoken,
    },
    body: item,
  })
    .catch(fetchError)
    .then(getJsonOrThrow);
}

function deleteItem(itemType, businessCode, id) {
  return fetch(`/inventory/${itemType}/${businessCode}/${id}/`, {
    method: "DELETE",
    headers: {
      "X-CSRFToken": csrftoken,
    },
  })
    .catch(fetchError)
    .then((response) => {
      if (response.ok) {
        return Promise.resolve();
      } else {
        return Promise.reject(new HTTPError(response));
      }
    });
}

function patchItem(itemType, businessCode, id, form) {
  return fetch(`/inventory/${itemType}/${businessCode}/${id}/`, {
    method: "PATCH",
    headers: {
      "X-CSRFToken": csrftoken,
    },
    body: form,
  })
    .catch(fetchError)
    .then(getJsonOrThrow);
}

function bookItem(itemType, businessCode, form) {
  return fetch(`/item_booking_enquiry/${businessCode}/${itemType}/`, {
    method: "POST",
    headers: {
      "X-CSRFToken": csrftoken,
    },
    body: form,
  })
    .catch(fetchError)
    .then(getJsonOrThrow);
}

function updateProductsOrder(businessCode, order) {
  return fetch(`/checkout/${businessCode}/products/order/`, {
    method: "PUT",
    headers: {
      "X-CSRFToken": csrftoken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ordered: order }),
  })
    .catch(fetchError)
    .then(getJsonOrThrow);
}

export { listItems, createItem, deleteItem, patchItem, bookItem, updateProductsOrder };
