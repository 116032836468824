export const STOP_WORDS = {
  i: "i",
  me: "me",
  my: "my",
  myself: "myself",
  we: "we",
  our: "our",
  ours: "ours",
  ourselves: "ourselves",
  you: "you",
  your: "your",
  yours: "yours",
  yourself: "yourself",
  yourselves: "yourselves",
  he: "he",
  him: "him",
  his: "his",
  himself: "himself",
  she: "she",
  her: "her",
  hers: "hers",
  herself: "herself",
  it: "it",
  its: "its",
  itself: "itself",
  they: "they",
  them: "them",
  their: "their",
  theirs: "theirs",
  themselves: "themselves",
  what: "what",
  which: "which",
  who: "who",
  whom: "whom",
  this: "this",
  that: "that",
  these: "these",
  those: "those",
  am: "am",
  is: "is",
  are: "are",
  was: "was",
  were: "were",
  be: "be",
  been: "been",
  being: "being",
  have: "have",
  has: "has",
  had: "had",
  having: "having",
  do: "do",
  does: "does",
  did: "did",
  doing: "doing",
  a: "a",
  an: "an",
  the: "the",
  and: "and",
  but: "but",
  if: "if",
  or: "or",
  because: "because",
  as: "as",
  until: "until",
  while: "while",
  of: "of",
  at: "at",
  by: "by",
  for: "for",
  with: "with",
  about: "about",
  against: "against",
  between: "between",
  into: "into",
  through: "through",
  during: "during",
  before: "before",
  after: "after",
  above: "above",
  below: "below",
  to: "to",
  from: "from",
  up: "up",
  down: "down",
  in: "in",
  out: "out",
  on: "on",
  off: "off",
  over: "over",
  under: "under",
  again: "again",
  further: "further",
  then: "then",
  once: "once",
  here: "here",
  there: "there",
  when: "when",
  where: "where",
  why: "why",
  how: "how",
  all: "all",
  any: "any",
  both: "both",
  each: "each",
  few: "few",
  more: "more",
  most: "most",
  other: "other",
  some: "some",
  such: "such",
  no: "no",
  nor: "nor",
  not: "not",
  only: "only",
  own: "own",
  same: "same",
  so: "so",
  than: "than",
  too: "too",
  very: "very",
  s: "s",
  t: "t",
  can: "can",
  will: "will",
  just: "just",
  don: "don",
  should: "should",
  now: "now",
};
