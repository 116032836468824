import EnterEmail from "./panels/EnterEmail.svelte";
import RequestLink from "./panels/RequestLink.svelte";
import Signup from "./panels/Signup.svelte";
import RequestSent from "./panels/RequestSent.svelte";
import EnterPassword from "./panels/EnterPassword.svelte";
import RequestReset from "./panels/RequestReset.svelte";
import PasswordResetSent from "./panels/PasswordResetSent.svelte";

export enum AuthFlows {
  NONE = "none",
  PASSWORDLESS = "passwordless",
  PASSWORD = "password",
}

export enum Transition {
  RIGHT = 200,
  LEFT = -200,
}

export enum State {
  LOGIN,
  SIGNUP,
  SIGNUP_SUBMITTED,
  REQUEST_LINK,
  REQUEST_SENT,
  ENTER_PASSWORD,
  REQUEST_RESET,
  RESET_REQUEST_SENT,
  LOGGED_IN,
}

export const FLY_DURATION = 200;
export const FLY_IN_DELAY = 250;

export type LoginPanels = {
  [state in State]?: any;
};

export let LOGIN_PANELS: LoginPanels = {
  [State.LOGIN]: EnterEmail,
  [State.SIGNUP]: Signup,
  [State.REQUEST_LINK]: RequestLink,
  [State.REQUEST_SENT]: RequestSent,
  [State.REQUEST_RESET]: RequestReset,
  [State.ENTER_PASSWORD]: EnterPassword,
  [State.RESET_REQUEST_SENT]: PasswordResetSent,
};
