import { readable } from "svelte/store";

/* Refers to the traveller in question on a given page. If viewing your own profile, then you.
If viewing someone else's, theirs */
export const traveller = readable(
  {
    code: "",
    name: "",
    following_count: 0,
    selfie: "",
    selfie_avatar_link: "",
    selfie_avatar_uuid: "",
    businesses_visited_count: 0,
    countries_visited_count: 0,
    areas_visited_count: 0,
    followers_count: 0,
    following_count: 0,
  },
  function getTravellerCode(set) {
    if (window.traveller) set(window.traveller);
  }
);

/* Refers to the current user's traveller profile */
export const currentTraveller = readable(
  {
    code: "",
    email: "",
    name: "",
    following_count: 0,
    selfie: "",
    selfie_avatar_link: "",
    selfie_avatar_uuid: "",
    businesses_visited_count: 0,
    countries_visited_count: 0,
    areas_visited_count: 0,
    followers_count: 0,
    following_count: 0,
  },
  function getCurrentTraveller(set) {
    if (window.currentTraveller) set(window.currentTraveller);
  }
);
