<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { fade } from "svelte/transition";
  import SearchInput from "./SearchInput.svelte";
  import SearchResults from "./SearchResults.svelte";

  const dispatch = createEventDispatcher();

  let query = "";
  let focus = false;

  const transitionOptions = { duration: 100 };
</script>

<style>
  .bg-dark {
    opacity: 0.7;
  }
  .focus {
    z-index: 1050;
  }
  .search-box {
    border-radius: 2rem;
  }
</style>

{#if focus}
  <div
    transition:fade={transitionOptions}
    class="bg-dark fixed-top w-100 h-100"
    on:click={() => (focus = false)} />
{/if}

<div class="w-100 row position-absolute text-dark">
  <div
    class="col-12 col-md-8 offset-md-2 search-box p-4"
    class:focus
    class:bg-white={focus}>
    <SearchInput
      on:focus={() => (focus = true)}
      on:query={(ev) => (query = ev.detail)}
      autofocus={false} />

    {#if focus}
      <SearchResults {query} />
    {/if}
  </div>
</div>
