<script lang="ts" context="module">
  declare global {
    interface Window {
      context: {
        creatorsUrl: string;
        marketingUrl: string;
      };
    }
  }
</script>

<script lang="ts">
  import Navbar from "/guidebook/components/navbar/Navbar.svelte";
  import MobileNavbar from "/guidebook/components/navbar/MobileNavbar.svelte";

  export let showSearch = true;
  const context = window.context;
</script>

<Navbar
  {showSearch}
  contentWhite={true}
  transparent={true}
  creatorsUrl={context["creatorsUrl"]}
  marketingUrl={context["marketingUrl"]} />

<slot />

<MobileNavbar
  creatorsUrl={context["creatorsUrl"]}
  marketingUrl={context["marketingUrl"]} />
