/* Image quality levels for uploaded images. */
export const ImageQuality = {
  high: "2560x1560 95%",
  standard: "1600x1600 95%",
  avatar: "500x500 80%",
};

export enum BusinessType {
  RESTAURANT = "restaurant",
  CAFE = "cafe",
  BAR = "bar",
  ACTIVITY = "activity",
  ACCOMMODATION = "accommodation",
}

export enum BusinessTypeGroup {
  STAY_DO = "stay_do",
  EAT_DRINK = "eat_drink",
}

export enum BusinessCategory {
  ACCOMMODATION = "accommodation",
  ACTIVITY = "activity",
  EAT_DRINK = "eat_drink",
}
