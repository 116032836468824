<script lang="ts">
  import { fade } from "svelte/transition";
  import FeaturedBusinessCard from "./FeaturedBusinessCard.svelte";

  export let businesses = [];
  export let size = 12;
</script>

<div class="row">
  {#each businesses.slice(0, size) as business, i (i)}
    <div class="col-md-3" transition:fade>
      <FeaturedBusinessCard {...business} on:click />
    </div>
  {/each}
</div>
