// This file is *automatically generated* by the directory.utils.transpile_enums module,
// which gets called as part of the webpack build process.

// transpile_enums converts Pythonic enum.Enum subclasses into JavaScript objects. This
// ensures that frontend JS files have an up-to-date and canonical record of enum values,
// rather than working with arbitrarily-defined values.

// To register additional enum.Enum subclasses for transpiling, add the module name to
// the top of directory.utils.transpile_enums

export enum ActivitySubtype {
  airport_transfers = "Airport Transfers",
  archery_shooting = "Archery & Shooting",
  art = "Art",
  atv_tours = "ATV Tours",
  axe_throwing = "Axe Throwing",
  bar_crawl = "Bar Crawl",
  birdwatching = "Birdwatching",
  boats = "Boat Tours",
  breweries_distilleries = "Breweries & Distilleries",
  bungee_jumping = "Bungee Jumping",
  chocolate_experiences = "Chocolate Experiences",
  climbing = "Climbing",
  coffee_experiences = "Coffee Experiences",
  cooking_classes = "Cooking Classes",
  crafts = "Crafts",
  cultural = "Cultural Experiences",
  cycling = "Cycling",
  dance_classes = "Dance classes",
  day_tours = "Day Tours",
  diving = "Diving",
  dogsledding = "Dog sledding",
  escape_rooms = "Escape Rooms",
  farm_experiences = "Farm Experiences",
  fishing = "Fishing",
  food_and_drink = "Food & Drink Tours",
  fun_games = "Fun & Games",
  golf = "Golf",
  guided_tours = "Guided Tours",
  hang_gliding = "Hang-gliding",
  health_fitness = "Health & Fitness",
  hiking = "Hiking",
  horses = "Horse Riding",
  hot_air_balloon = "Hot Air Ballon Rides",
  karting = "Karting",
  kayaking = "Kayaking",
  kitesurfing = "Kitesurfing",
  language_lessons = "Language Lessons",
  live_music = "Live Music",
  martial_arts = "Martial Arts",
  meditation = "Meditation",
  motor_sports = "Motor Sports",
  motorcycles = "Motorcycle Tours",
  mountain_climbing = "Mountain climbing",
  multiday_tours = "Multi-Day Tours",
  museums = "Museums",
  off_road_tours = "Off-Road Tours",
  open_top_bus_tours = "Open Top Bus Tours",
  other = "other",
  paddle_boarding = "Paddle Boarding",
  paintballing = "Paintballing",
  painting = "Painting",
  paragliding = "Paragliding",
  photography_tours = "Photography Tours",
  pilates = "Pilates",
  pottery = "Pottery",
  rafting = "Rafting",
  safari = "Safari",
  sailing = "Sailing",
  sandboarding = "Sandboarding",
  shore_excursions = "Shore Excursions",
  shows_theatre = "Shows & Theatre",
  sightseeing = "Sightseeing",
  skiing = "Skiing",
  sky_tours = "Sky tours",
  skydiving = "Skydiving",
  snorkelling = "Snorkelling",
  snowboarding = "Snowboarding",
  spa = "Spa",
  stargazing = "Stargazing",
  surfing = "Surfing",
  taxi = "Taxi",
  theme_parks = "Theme Parks",
  travel_agency = "Travel Agency",
  tuktuk = "Tuktuk",
  walking = "Walking",
  wellness = "Wellness & Wellbeing",
  wine_tasting = "Wine Tasting",
  winetours = "Wine Tours",
  yoga = "Yoga",
  zipline = "Zipline",
}

export enum BarFavouriteType {
  bar = "Bar",
  beer = "Pub/Beer",
  cocktail = "Cocktail Bar",
  nightclub = "Nightclub",
  wine = "Wine Bar",
}

export enum BookDirectOption {
  checkout = "Open the Checkout Widget",
  enquiry = "Send an enquiry email",
  hide = "Hide the Book Direct button",
  redirect = "Redirect to my website",
}

export enum BusinessType {
  accommodation = "Places to Stay",
  activity = "Activities & Tours",
  bar = "Bars",
  cafe = "Cafes",
  restaurant = "Restaurants",
  sight = "Local Sights",
}

export enum Category {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
}

export enum ChannelManager {
  axis_rooms = "AxisRooms",
  beds24 = "Beds24",
  bokun = "Bokun",
  book_logic = "BookLogic",
  booking_expert = "Booking Expert",
  bookingkit = "Bookingkit",
  busy_rooms = "Busy Rooms",
  cubilis = "Cubilis Channel Manager",
  d_edge = "D-EDGE",
  e_rev_max = "eRevMax",
  ezee = "eZee",
  freetobook = "Freetobook",
  globres = "GlobRes",
  hotel_net_solutions = "HotelNetSolutions",
  hotel_rez = "HotelRez",
  hotel_spider = "Hotel-Spider",
  hoteliers = "Hoteliers.com",
  little_hotellier = "Little Hotelier",
  lodgable = "Lodgable",
  myallocator = "MyAllocator (Cloudbeds)",
  no = "I don't use a channel manager",
  other = "Other",
  percolate = "Percolate",
  profitroom = "Profitroom",
  rentals_united = "Rentals United",
  roomcloud = "RoomCloud",
  sirvoy = "Sirvoy",
  siteminder = "SiteMinder",
  smart_hotel = "SmartHOTEL",
  staah = "STAAH",
  synxis = "SynXis",
  travel_click = "TravelClick",
  travel_line = "TravelLine",
  trekksoft = "Trekksoft",
  web_hotelier = "WebHotelier",
  windsurfer = "Windsurfer CRS",
  wix = "Wix",
  yieldplanet = "YieldPlanet",
}

export enum DietaryType {
  halal = "Halal",
  kosher = "Kosher",
  nothing = "Nothing",
  vegan_friendly = "Vegan Friendly",
  vegan_only = "Vegan Only",
  vegetarian_friendly = "Vegetarian Friendly",
  vegetarian_only = "Vegetarian Only",
}

export enum EatDrinkItemType {
  dish = "Dish",
  product = "Product",
  supplier = "Supplier",
}

export enum InventoryType {
  activity = "activity",
  dish = "dish",
  product = "product",
  room = "room",
  supplier = "supplier",
}

export enum LgbtqType {
  lgbtq_focus = "LGBTQ+ Focus",
  lgbtq_friendly = "LGBTQ+ Friendly",
  nothing = "Nothing",
}

export enum PriceRangeType {
  expensive = "$$$$",
  expensive_to_extravagant = "$$$$ - $$$$$",
  extravagant = "$$$$$",
  high = "$$$",
  high_to_expensive = "$$$ - $$$$",
  low = "$",
  low_to_medium = "$ - $$",
  medium = "$$",
  medium_to_high = "$$ - $$$",
}

export enum ProfileSource {
  csv = "csv",
  google = "google",
  lem = "lem",
  manual = "manual",
  tab = "tab",
}

export enum ReferrerRefereeRelationshipType {
  dont_know = "I don't know them",
  know_of = "I know them",
  know_personally = "I know them personally",
}

export enum RestaurantRecommendationPriceType {
  average = "Average",
  cheap = "Cheap",
  expensive = "Expensive",
}

export enum RoomType {
  private = "Private Room",
  shared = "Shared Room",
}

export enum PrivacyType {
  private = "private",
  public = "public",
}

export enum RelationshipStatus {
  following = "following",
  requested = "requested",
}

export enum TravellerProfileSource {
  public = "public",
  tab = "tab",
}

export enum TravellerTier {
  basic = "basic",
  influencer = "influencer",
  media_organisation = "media organisation",
}
