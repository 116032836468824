<script lang="ts">
  import Login from "/traveller/login/Login.svelte";
  import { createEventDispatcher, onMount, onDestroy } from "svelte";
  import { fly, fade } from "svelte/transition";
  import { quintInOut } from "svelte/easing";

  const dispatch = createEventDispatcher();

  let scrollY;
  let height;
  let width;

  // screen widths
  $: isMobile = width < 768; // px
  $: isXL = width >= 1200; // px
  $: isStandard = !isMobile && !isXL;

  // disable/enable scrolling in body when modal active
  onMount(() => document.body.classList.add("modal-open"));
  onDestroy(() => document.body.classList.remove("modal-open"));

  const close = () => dispatch("close");
</script>

<style lang="css">
  .top-right {
    /* Custom class as we don't place the button in the header */
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem 1rem;
    z-index: 1004;
  }

  .card {
    position: fixed;
    bottom: 4rem;
    /* Ensure modal is centered on desktop */
    left: 25%;
    z-index: 1100;
    max-height: calc(100vh - 6rem);
    /* Allow the content to expand vertically  */
    height: auto;
    width: -moz-calc(100% - 2rem);
    width: -webkit-calc(100% - 2rem);
    width: -o-calc(100% - 2rem);
    width: calc(100% - 2rem);
  }

  @media only screen and (max-width: 768px) {
    .card {
      /* Ensure modal is centered on mobile */
      left: 0;
    }
  }

  @media only screen and (min-width: 1200px) {
    .card {
      left: 36.9%;
    }
  }

  :global(.slide-modal .h2) {
    font-size: x-large;
  }
  :global(.slide-modal .form-group) {
    margin-bottom: 1rem;
  }

  .modal-background {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1090;
  }
</style>

<svelte:window bind:scrollY bind:outerHeight={height} bind:innerWidth={width} />

<div
  class="w-100 h-100 modal-background bg-dark opacity-5"
  transition:fade
  on:click={close} />

<div
  class="slide-modal card rounded-lg mx-3 mb-3"
  class:w-50={isStandard}
  class:w-25={isXL}
  transition:fly={{
    delay: 0,
    duration: 500,
    x: 0,
    y: 300,
    opacity: 0,
    easing: quintInOut,
  }}>
  <div class="card-body overflow-x-hidden overflow-y-scroll">
    <button type="button" class="close top-right" aria-label="Close" on:click={close}>
      <span aria-hidden="true">
        <i class="far far-times text-black" />
      </span>
    </button>
    <Login nextURL={window.location.href} />
  </div>
</div>
