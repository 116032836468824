import { csrftoken } from "../../shared/utils.js";
import { fetchError, getJsonOrThrow } from "../../shared/api.js";

export function signup(data) {}
export function login(email: string, password: string) {
  return fetch("/users/login/", {
    method: "POST",
    headers: {
      "X-CSRFToken": csrftoken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  })
    .catch(fetchError)
    .then(getJsonOrThrow);
}

export function checkEmail(email: string) {
  return fetch("/users/auth-flows/", {
    method: "POST",
    headers: {
      "X-CSRFToken": csrftoken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email }),
  })
    .catch(fetchError)
    .then(getJsonOrThrow);
}

export function requestMagicLink(email: string, url?: string) {
  return fetch("/users/magic-link/", {
    method: "POST",
    headers: {
      "X-CSRFToken": csrftoken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      url: url,
    }),
  })
    .catch(fetchError)
    .then(getJsonOrThrow);
}

export function requestPasswordReset(email: string) {
  let form = new FormData();
  form.append("email", email);
  return fetch("/accounts/password/reset/", {
    method: "POST",
    headers: {
      "X-CSRFToken": csrftoken,
    },
    body: form,
  })
    .catch(fetchError)
    .then((response) => response.ok);
}
