<script lang="ts">
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();
  export let query = "";
  export let autofocus = true;

  $: dispatch("query", query);
</script>

<div class="search-bar py-2 px-4 mb-4">
  <div class="d-flex flex-row">
    <div class="d-flex align-items-center">
      <i class="fa fa-search mr-2 text-primary" />
    </div>
    <div class="mx-5 flex-grow-1 align-items-center">
      <!-- need to autofocus to new modal input -->
      <!-- svelte-ignore a11y-autofocus -->
      <input
        on:focus
        bind:value={query}
        type="text"
        name="search"
        placeholder="Where do you want to go?"
        class="form-control border-0 shadow-0"
        {autofocus} />
    </div>
  </div>
</div>
