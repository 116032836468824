<script lang="ts">
  import { ActivitySubtype, PriceRangeType, BusinessType } from "/shared/enums";
  import { BusinessSerialiser as Business } from "/apiFactory/api";

  export let business: Business;

  const businessToBadgeContent = (business: Business) => {
    let content = "";
    switch (BusinessType[business.business_type]) {
      case BusinessType.accommodation:
        content = "<i class='far fa-bed mr-1'></i>";
        if (business.price_range) {
          content = content + " " + PriceRangeType[business.price_range];
        }
        break;
      case BusinessType.activity:
        content = ActivitySubtype[business.activity_subtype];
        if (!content || content.toLowerCase() == "other") {
          // set empty or "other" to a better name
          content = "ACTIVITY";
        } else {
          content = content.toUpperCase();
        }
        break;
      case BusinessType.cafe:
        content = "<i class='fa fa-coffee mr-1'></i> CAFE";
        break;
      case BusinessType.bar:
        content = "<i class='fa fa-glass-martini-alt mr-1'></i> BAR";
        break;
      case BusinessType.restaurant:
        content = "<i class='fa fa-utensils mr-1'></i>";
        if (business.cuisine) {
          // commented out -- due to issues with cuisine historical field
          // content = content + " " + business.cuisine.toUpperCase();
        }
        break;
      default:
        content = "";
        break;
    }
    return content;
  };

  const badgeContent = businessToBadgeContent(business);
</script>

{#if badgeContent}
  <span class="badge badge-light px-2 py-2 rounded-lg text-xs font-weight-bolder">
    {@html badgeContent}
  </span>
{/if}
