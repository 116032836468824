<script lang="ts">
  import Page from "./explore/__layout.svelte";
  import MetaTags from "/shared/components/MetaTags.svelte";
  import ActivityProductGallery from "/guidebook/components/ActivityProduct/ResponsiveGallery.svelte";
  import AreaButtonsGroupSwiper from "/guidebook/components/AreaButtonsGroupSwiper.svelte";
  import BusinessGallerySelector from "/guidebook/components/BusinessGallerySelector.svelte";
  import FeaturedBusinessGallerySelector from "/guidebook/components/FeaturedBusinessGallerySelector.svelte";
  import PureActivitySwiper from "/guidebook/components/PureActivitySwiper.svelte";
  import CountryCardSwiper from "/guidebook/components/country/PureCardSwiper.svelte";
  import { countryView } from "/guidebook/shared/urls";
  import Hero from "/guidebook/components/Hero.svelte";

  export let heroBusinesses;
  export let featuredBusinesses;
  export let popularActivities;
  export let popularAreas;
  export let popularCountries;
  export let accommodationBusinesses;
  export let activityProducts;
  export let activityBusinesses;
  export let regions: Array<{
    code: string;
    name: string;
    countries: Array<{ code: string; name: string }>;
  }>;
</script>

<style global>
  .display-5 {
    font-size: 3rem;
    line-height: 1.3;
  }
  /* Small devices (landscape phones, 544px and up) */
  @media (max-width: 768px) {
    .display-5 {
      font-size: 2.5rem;
    }
    .h3 {
      font-size: 1.125rem;
    }
  }
</style>

<Page showSearch={false}>
  <MetaTags
    description={"Get personalised travel recommendations from The Guide and book direct. You'll get the best price, and help local businesses avoid unnecessary fees."} />
  <Hero heroItems={heroBusinesses} />
  <div class="container px-md-5 px-4">
    <h3 class="font-weight-bold mt-5 mb-2">Discover amazing local businesses</h3>
    <FeaturedBusinessGallerySelector businesses={featuredBusinesses} />

    <h3 id="popularActivitiesHeading" class="font-weight-bold mt-5">
      Popular activities around the world
    </h3>
    <PureActivitySwiper activities={popularActivities} />

    <h3 class="mt-5 font-weight-bold" id="popularDestinationsHeader">
      Explore popular destinations
    </h3>
    <AreaButtonsGroupSwiper areas={popularAreas} />

    <h3 class="mt-5 font-weight-bold mb-2">Featured places to stay</h3>
    <BusinessGallerySelector businesses={accommodationBusinesses} />

    <h3 class="mt-5 font-weight-bold mb-2">Featured things to do</h3>
    <ActivityProductGallery {activityProducts} />

    <h3 class="mt-5 font-weight-bold mb-2">Activities & tours run by local experts</h3>
    <BusinessGallerySelector businesses={activityBusinesses} />
  </div>

  <div class="container px-md-5 px-4 mt-5 mb-5">
    <h2 style="font-size: 30px;" class="font-weight-bold">Popular places to go</h2>
    <CountryCardSwiper countries={popularCountries} />
  </div>

  <div class="container px-md-5 px-4 mt-5 mb-5">
    <div id="regionsHeading" class="h3 font-weight-bold">Explore all countries</div>
    <div class="accordion" id="regionsAccordion">
      {#each regions as region}
        <button
          class="btn btn-link btn-block text-left text-reset pl-0"
          type="button"
          data-toggle="collapse"
          data-target="#collapse{region.code}"
          aria-expanded="true"
          aria-controls="collapse{region.code}">
          <span class="h4">
            {region.name}
            <i class="fa fa-sm far-arrow-right" />
          </span>
        </button>
        <ul
          id="collapse{region.code}"
          class="collapse list-unstyled text-md"
          aria-labelledby="regionsHeading"
          data-parent="#regionsAccordion">
          {#each region.countries as country}
            <li class="ml-4">
              <a href={countryView(country.code)}>
                <span>{country.name}</span>
              </a>
            </li>
          {/each}
        </ul>
      {/each}
    </div>
  </div>
</Page>
