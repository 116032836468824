<script lang="ts">
  import ResultSet from "../grayhound/ResultSet.svelte";
  import ResultItem from "../grayhound/ResultItem.svelte";
  import ResultList from "../grayhound/ResultList.svelte";
  import { Hound, relevancy } from "../grayhound/grayhound";
  import { COUNTRIES } from "/shared/country";
  import { countryView } from "/guidebook/shared/urls";
  export let query: string;

  let search: Hound = {
    name: "Countries",
    search: false,
    local: (words) => {
      let indexes = {
        country: Object.entries(COUNTRIES),
      };
      return relevancy(words, indexes, "country");
    },
  };
</script>

<style lang="scss">
  @use "../sass/user/colours";

  .text-reset {
    text-decoration: none;
  }

  .icon-reset {
    color: #{colours.$green};
  }
</style>

<ResultSet hound={search} {query} let:results on:complete>
  <ResultList {results} let:items>
    {#each items as result (result.key)}
      <ResultItem let:hovering>
        <a class="text-reset" href={countryView(result.country.key)}>
          <div>
            <i class:icon-reset={!hovering} class="fas fa-passport mr-2" />
            {result.country.value}
          </div>
        </a>
      </ResultItem>
    {/each}
  </ResultList>
</ResultSet>
