<script lang="ts">
  export let active: boolean;
  export let label: string;
  export let icon: string;
</script>

<div
  class:text-gray-900={active}
  class:text-gray-500={!active}
  class="btn px-3 text-center text-gray-500"
  on:click>
  <i class:text-primary={active} class:text-gray-500={!active} class="fa fa-lg {icon}" />
  <div class="font-weight-bold text-xs">{label}</div>
</div>
