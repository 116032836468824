<script lang="ts">
  import Select from "svelte-select";
  import { Recommender } from "/business/types";

  export let recommenders: Array<Recommender>;
  export let selected: { value: Recommender; label: string };

  let choices = [];

  // reshape data for Select component
  recommenders.forEach((recommender) => {
    // format label with avatar
    let label = `
    <span><img
      alt="Traveller avatar"
      data-blink-uuid=${recommender.selfie_avatar_uuid}
      data-blink-scale-mode="scale-crop"
      data-blink-quality="smart"
      class="avatar avatar-sm bg-light mx-2 mb-1" />${recommender.name}</span>
    `;
    choices.push({
      value: recommender,
      label: label,
    });
  });
  selected = choices[0];
</script>

<style lang="scss">
  // Import theme colours
  @use "sass/user/colours";

  .custom-select-theme {
    /* increase input height to accommodate avatar */
    --height: 3.5rem;
    --borderFocusColor: #{colours.$primary};
  }
  // force the selected item to be centered
  :global(.selectedItem) {
    width: 100%;
  }
</style>

<!--
  @component
  Display a Select input of recommenders to choose from - with avatars

  When only one recommender availble, preselect and fix choice (for UX).

-->
<div class="select mb-4 custom-select-theme">
  <Select
    items={choices}
    showChevron={choices.length > 1}
    isClearable={false}
    isSearchable={false}
    bind:value={selected} />
</div>
