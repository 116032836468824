import "bootstrap/js/dist/util";
import "bootstrap/js/dist/button";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/modal";

import "/../sass/project.scss";
import { ActivitySubtype } from "/shared/enums";
import { countryView } from "/guidebook/shared/urls";
import { getActivityImageURL } from "/traveller/shared/urls";
import { initComponentById } from "/shared/svelte";
import Home from "./index.svelte";

import("/messages.js");
let data = $("#data").data();

data.popularCountries = ["MX", "ZA", "TH", "AR", "ID", "CO", "PE", "NP"].map((code) => ({
  code: code,
  selected: true,
  cardLink: countryView(code),
}));

data.popularActivities = data.popularActivities.map((activity) => {
  return {
    name: ActivitySubtype[activity],
    imageLink: getActivityImageURL(activity),
  };
});

data.activityProducts = data.activityProducts.map((product) => ({
  product: product,
}));
data.featuredBusinesses = Object.values(data.featuredBusinesses).map((business) => ({
  business: business,
}));
data.popularAreas = data.popularAreas.map((area) => ({ area: area }));
initComponentById("page", Home, data);
