<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import BusinessTypeBadge from "./BusinessTypeBadge.svelte";
  import { BusinessWithHashtagsAndLanguagesSerialiser as Business } from "/apiFactory/api";

  const dispatch = createEventDispatcher();
  export let business: Business;
</script>

<div
  on:click={() => dispatch("click", { business: business })}
  style="cursor: pointer;"
  class="card h-100 border-0 shadow hover-animate rounded-lg">
  <div
    data-blink-uuid={business.cover_image_uuid}
    style="min-height: 200px; max-height: 300px;"
    class="card-img-top overflow-hidden bg-cover rounded-lg-top" />
  <div class="mx-2 mt-2">
    <div class="float-right">
      <BusinessTypeBadge {business} />
    </div>
    <h5 class="mb-0 font-weight-bold text-base text-truncate">
      {business.name}
    </h5>
    <p class="m-0 font-weight-light text-xs">
      {business.city}, {business.country.name}
    </p>
    <p class="mt-1 text-muted text-xs font-weight-bolder">
      {#if business.hashtags}
        {#each business.hashtags.slice(0, 5) as hashtag}
          <span class="mr-1">#{hashtag}</span>
        {/each}
      {/if}
    </p>
  </div>
</div>
