<script lang="ts">
  import AreaSearch from "./searchers/Area.svelte";
  import { fade } from "svelte/transition";
  import BusinessSearch from "./searchers/Business.svelte";
  import CountrySearch from "./searchers/Country.svelte";
  import Activities from "./searchers/Activities.svelte";

  export let query = "";

  let resultFound = false;
  let searchesComplete = 0;

  $: searchComplete = searchesComplete === 4;

  // react to changes in query
  $: {
    query;
    searchesComplete = 0;
    resultFound = false;
  }

  const handleComplete = (_resultFound) => {
    // only update if the query has not changed since
    if (_resultFound.query != query) return;

    // any result will do
    resultFound ||= _resultFound.found;
    searchesComplete++;
  };
</script>

<style>
  .result-box {
    overflow-y: scroll;
    max-height: 70vh;
  }
</style>

<div in:fade class="px-3 result-box">
  {#if !query}
    <div class="text-muted" in:fade>
      Try searching for a destination, activity or business
    </div>
  {:else if query.length && searchComplete && !resultFound}
    <div class="text-muted" in:fade>No results found</div>
  {/if}

  <CountrySearch {query} on:complete={(ev) => handleComplete(ev.detail)} />
  <AreaSearch {query} on:complete={(ev) => handleComplete(ev.detail)} />
  <BusinessSearch {query} on:complete={(ev) => handleComplete(ev.detail)} />
  <Activities {query} on:complete={(ev) => handleComplete(ev.detail)} />
</div>
