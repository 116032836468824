<script lang="ts">
  /**
   * This is a crib of a svelte-forms-lib component:
   * https://github.com/tjinauyeung/svelte-forms-lib/blob/master/lib/components/ErrorMessage.svelte
   * We adapt it here to display errors returned from an API.
   *
   * An errors store must be provided in the context.
   */
  import { getContext } from "svelte";
  const errors: SvelteStore<any> = getContext("errors");

  export let name;
</script>

<style>
  .invalid-feedback {
    display: block !important;
  }
</style>

{#if $errors && $errors[name]}
  <span id="error_{name}" class="invalid-feedback">
    <strong>{$errors[name]}</strong>
  </span>
{/if}
