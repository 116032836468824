export default [
  {
    code: "AO",
    flag_colours: ["#ff0000", "#000000"],
  },
  {
    code: "BF",
    flag_colours: ["#ff0000", "#009900"],
  },
  {
    code: "BI",
    flag_colours: ["#ff0000", "#00cc00", "#ffffff"],
  },
  {
    code: "BJ",
    flag_colours: ["#ffff00", "#ff0000", "#009900"],
  },
  {
    code: "BW",
    flag_colours: ["#35cdff", "#000000"],
  },
  {
    code: "CD",
    flag_colours: ["#00a0e0", "#e00010"],
  },
  {
    code: "CF",
    flag_colours: ["#ffff00", "#ffffff", "#ff0000", "#0000ff", "#009900"],
  },
  {
    code: "CG",
    flag_colours: ["#ffff00", "#ff0000", "#00cc00"],
  },
  {
    code: "CI",
    flag_colours: ["#ff9900", "#00cc00", "#ffffff"],
  },
  {
    code: "CM",
    flag_colours: ["#ffff00", "#ff0000", "#009900"],
  },
  {
    code: "CV",
    flag_colours: ["#000099", "#ffffff"],
  },
  {
    code: "DJ",
    flag_colours: ["#00cc00", "#6699ff", "#ffffff"],
  },
  {
    code: "DZ",
    flag_colours: ["#006633", "#ffffff"],
  },
  {
    code: "EG",
    flag_colours: ["#ff0000", "#000000", "#ffffff"],
  },
  {
    code: "ER",
    flag_colours: ["#ff0000", "#0000cc", "#009900"],
  },
  {
    code: "ET",
    flag_colours: ["#ffff00", "#006633", "#ff0000"],
  },
  {
    code: "GA",
    flag_colours: ["#ffff00", "#009900", "#3366cc"],
  },
  {
    code: "GH",
    flag_colours: ["#ffff00", "#ff0000", "#009900"],
  },
  {
    code: "GM",
    flag_colours: ["#ff0000", "#000099", "#ffffff", "#009900"],
  },
  {
    code: "GN",
    flag_colours: ["#ffff00", "#ff0000", "#009900"],
  },
  {
    code: "GQ",
    flag_colours: ["#009900", "#fe0000", "#ffffff", "#3399ff"],
  },
  {
    code: "GW",
    flag_colours: ["#ffff00", "#ff0000", "#009900"],
  },
  {
    code: "KE",
    flag_colours: ["#000000", "#cc0000", "#009900"],
  },
  {
    code: "KM",
    flag_colours: ["#ffff00", "#ffffff", "#008400", "#ff0000", "#0000ff"],
  },
  {
    code: "LR",
    flag_colours: ["#cc0000", "#ffffff"],
  },
  {
    code: "LS",
    flag_colours: ["#009933", "#000099", "#ffffff"],
  },
  {
    code: "LY",
    flag_colours: ["#009900", "#ffffff"],
  },
  {
    code: "MA",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "MG",
    flag_colours: ["#ff0000", "#ffffff", "#009900"],
  },
  {
    code: "ML",
    flag_colours: ["#ffff00", "#ff0000", "#009900"],
  },
  {
    code: "MR",
    flag_colours: ["#009900", "#ffffff"],
  },
  {
    code: "MU",
    flag_colours: ["#ffff00", "#009900", "#cc0000", "#000066"],
  },
  {
    code: "MW",
    flag_colours: ["#ff0000", "#000000", "#009900"],
  },
  {
    code: "MZ",
    flag_colours: ["#ff0000", "#000000", "#ffcc00", "#009900"],
  },
  {
    code: "NA",
    flag_colours: ["#ff0000", "#000099", "#009900"],
  },
  {
    code: "NE",
    flag_colours: ["#ff6600", "#ffffff", "#009900"],
  },
  {
    code: "NG",
    flag_colours: ["#ffffff", "#009900"],
  },
  {
    code: "RW",
    flag_colours: ["#ffff00", "#009900", "#3399ff"],
  },
  {
    code: "SC",
    flag_colours: ["#ffff00", "#ffffff", "#ff0000", "#000099", "#009900"],
  },
  {
    code: "SD",
    flag_colours: ["#ff0000", "#000000", "#ffffff", "#009900"],
  },
  {
    code: "SL",
    flag_colours: ["#00cc00", "#ffffff", "#0000cc"],
  },
  {
    code: "SN",
    flag_colours: ["#ffff00", "#ff0000", "#009900"],
  },
  {
    code: "SO",
    flag_colours: ["#3399ff", "#ffffff"],
  },
  {
    code: "ST",
    flag_colours: ["#ffff00", "#ff0000", "#009900"],
  },
  {
    code: "SZ",
    flag_colours: ["#990000", "#6699cc", "#ffcc00"],
  },
  {
    code: "TD",
    flag_colours: ["#ffff00", "#ff0000", "#000066"],
  },
  {
    code: "TG",
    flag_colours: ["#ff0000", "#ffcc00", "#009900"],
  },
  {
    code: "TN",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "TZ",
    flag_colours: ["#ffff00", "#000000", "#009900", "#0099ff"],
  },
  {
    code: "UG",
    flag_colours: ["#ffff00", "#ff0000", "#000000"],
  },
  {
    code: "ZA",
    flag_colours: ["#009933", "#ff0000", "#0000cc", "#ffffff"],
  },
  {
    code: "ZM",
    flag_colours: ["#339933", "#ffffff"],
  },
  {
    code: "ZW",
    flag_colours: ["#ffcc00", "#ffffff", "#ff0000", "#000000", "#009900"],
  },
  {
    code: "AF",
    flag_colours: ["#006647", "#000000", "#cf142b"],
  },
  {
    code: "AM",
    flag_colours: ["#ff0000", "#ff9900", "#000099"],
  },
  {
    code: "AZ",
    flag_colours: ["#009933", "#0099cc", "#cc3333"],
  },
  {
    code: "BD",
    flag_colours: ["#ff0000", "#006600"],
  },
  {
    code: "BN",
    flag_colours: ["#f7db00", "#000000", "#ffffff"],
  },
  {
    code: "BT",
    flag_colours: ["#ff6600", "#ffcc00"],
  },
  {
    code: "CN",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "GE",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "ID",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "IN",
    flag_colours: ["#ffcc00", "#339933", "#fdfdfd"],
  },
  {
    code: "JP",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "KG",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "KH",
    flag_colours: ["#000099", "#cc0000"],
  },
  {
    code: "KP",
    flag_colours: ["#ff0000", "#0000ff"],
  },
  {
    code: "KR",
    flag_colours: ["#ffffff", "#ffffff"],
  },
  {
    code: "KZ",
    flag_colours: ["#2dacad", "#ffffff"],
  },
  {
    code: "LA",
    flag_colours: ["#ff0000", "#000099"],
  },
  {
    code: "LK",
    flag_colours: ["#ff6600", "#991b33", "#ffcc00", "#006600"],
  },
  {
    code: "MM",
    flag_colours: ["#ff0000", "#0000cc"],
  },
  {
    code: "MN",
    flag_colours: ["#ff0000", "#0000ff"],
  },
  {
    code: "MV",
    flag_colours: ["#ff0000", "#009900"],
  },
  {
    code: "MY",
    flag_colours: ["#cc0001", "#ffffff", "#010066"],
  },
  {
    code: "NP",
    flag_colours: ["#000063", "#ce0000", "#c0c0c0"],
  },
  {
    code: "PH",
    flag_colours: ["#ff0000", "#000099", "#ffffff"],
  },
  {
    code: "PK",
    flag_colours: ["#006600", "#ffffff"],
  },
  {
    code: "SG",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "TH",
    flag_colours: ["#ff0000", "#000099", "#ffffff"],
  },
  {
    code: "TJ",
    flag_colours: ["#006600", "#cc0000", "#ffffff"],
  },
  {
    code: "TL",
    flag_colours: ["#ff0000", "#000000"],
  },
  {
    code: "TM",
    flag_colours: ["#006600", "#ffffff"],
  },
  {
    code: "UZ",
    flag_colours: ["#ffffff", "#0099cc", "#00cc33"],
  },
  {
    code: "VN",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "AD",
    flag_colours: ["#ffff00", "#ff0000", "#0000cc"],
  },
  {
    code: "AL",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "AT",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "BA",
    flag_colours: ["#ffcc00", "#000099"],
  },
  {
    code: "BE",
    flag_colours: ["#ffff00", "#ff0000", "#000000"],
  },
  {
    code: "BG",
    flag_colours: ["#ff0000", "#ffffff", "#009900"],
  },
  {
    code: "BY",
    flag_colours: ["#ff0000", "#009900"],
  },
  {
    code: "CH",
    flag_colours: ["#cc0000", "#ffffff"],
  },
  {
    code: "CY",
    flag_colours: ["#ffffff", "#ffffff"],
  },
  {
    code: "CZ",
    flag_colours: ["#ff0000", "#0000cc", "#ffffff"],
  },
  {
    code: "DE",
    flag_colours: ["#ff0000", "#000000", "#ffcc00"],
  },
  {
    code: "DK",
    flag_colours: ["#d81e05", "#ffffff"],
  },
  {
    code: "EE",
    flag_colours: ["#000000", "#ffffff", "#0066cc"],
  },
  {
    code: "ES",
    flag_colours: ["#ffff00", "#ff0000"],
  },
  {
    code: "FI",
    flag_colours: ["#003399", "#ffffff"],
  },
  {
    code: "FR",
    flag_colours: ["#ff0000", "#ffffff", "#000066"],
  },
  {
    code: "GB",
    flag_colours: ["#cc0000", "#ffffff", "#000066"],
  },
  {
    code: "GR",
    flag_colours: ["#000099", "#ffffff"],
  },
  {
    code: "HR",
    flag_colours: ["#ff0000", "#0000cc", "#ffffff"],
  },
  {
    code: "HU",
    flag_colours: ["#008400", "#ff0000", "#ffffff"],
  },
  {
    code: "IE",
    flag_colours: ["#ff6600", "#ffffff", "#009900"],
  },
  {
    code: "IS",
    flag_colours: ["#ff0000", "#0000cc", "#ffffff"],
  },
  {
    code: "IT",
    flag_colours: ["#ff0000", "#ffffff", "#009900"],
  },
  {
    code: "LI",
    flag_colours: ["#ff0000", "#000099"],
  },
  {
    code: "LT",
    flag_colours: ["#ffff00", "#ff0000", "#009900"],
  },
  {
    code: "LU",
    flag_colours: ["#ff0000", "#ffffff", "#0099ff"],
  },
  {
    code: "LV",
    flag_colours: ["#990000", "#ffffff"],
  },
  {
    code: "MC",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "MD",
    flag_colours: ["#0000ad", "#ffde00", "#de2110"],
  },
  {
    code: "ME",
    flag_colours: ["#d6ad42", "#c60810"],
  },
  {
    code: "MK",
    flag_colours: ["#ffff00", "#ff0000"],
  },
  {
    code: "MT",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "NL",
    flag_colours: ["#003399", "#ff0033", "#ffffff"],
  },
  {
    code: "NO",
    flag_colours: ["#ff0000", "#ffffff", "#003366"],
  },
  {
    code: "PL",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "PT",
    flag_colours: ["#ff0000", "#006600"],
  },
  {
    code: "RO",
    flag_colours: ["#ffff00", "#ff0000", "#0000cc"],
  },
  {
    code: "RS",
    flag_colours: ["#ffffff", "#0066cc", "#cc3333"],
  },
  {
    code: "RU",
    flag_colours: ["#ff0000", "#0000ff", "#ffffff"],
  },
  {
    code: "SE",
    flag_colours: ["#ffcc00", "#006699"],
  },
  {
    code: "SI",
    flag_colours: ["#ff0000", "#000099", "#ffffff"],
  },
  {
    code: "SK",
    flag_colours: ["#ff0000", "#0000cc", "#ffffff"],
  },
  {
    code: "SM",
    flag_colours: ["#ffffff", "#3399ff"],
  },
  {
    code: "TR",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "UA",
    flag_colours: ["#ffff00", "#0099ff"],
  },
  {
    code: "VA",
    flag_colours: ["#ffff00", "#ffffff"],
  },
  {
    code: "AG",
    flag_colours: ["#000000", "#cc0000", "#0066cc"],
  },
  {
    code: "AR",
    flag_colours: ["#ffffff", "#66ccff"],
  },
  {
    code: "BB",
    flag_colours: ["#ffcc00", "#003399"],
  },
  {
    code: "BO",
    flag_colours: ["#ffff00", "#ff0000", "#009900"],
  },
  {
    code: "BR",
    flag_colours: ["#ffff00", "#006600", "#0000cc"],
  },
  {
    code: "BS",
    flag_colours: ["#00cccc", "#000000", "#ffcc00"],
  },
  {
    code: "BZ",
    flag_colours: ["#ff0000", "#0000cc", "#ffffff"],
  },
  {
    code: "CL",
    flag_colours: ["#ff0000", "#000099", "#ffffff"],
  },
  {
    code: "CO",
    flag_colours: ["#ff0000", "#ffcc00", "#0000cc"],
  },
  {
    code: "CR",
    flag_colours: ["#ffffff", "#ff0000", "#003399"],
  },
  {
    code: "CU",
    flag_colours: ["#ffffff", "#cc0000", "#003399"],
  },
  {
    code: "DM",
    flag_colours: ["#ffff00", "#ffffff", "#ff0000", "#000000", "#009900"],
  },
  {
    code: "DO",
    flag_colours: ["#ff0000", "#000099", "#ffffff"],
  },
  {
    code: "EC",
    flag_colours: ["#ffff00", "#ff0000", "#0000cc"],
  },
  {
    code: "GD",
    flag_colours: ["#ff0000", "#ffcc00", "#009900"],
  },
  {
    code: "GT",
    flag_colours: ["#0066ff", "#ffffff"],
  },
  {
    code: "GY",
    flag_colours: ["#ffff00", "#ff0000", "#009900"],
  },
  {
    code: "HN",
    flag_colours: ["#000099", "#ffffff"],
  },
  {
    code: "HT",
    flag_colours: ["#ff0000", "#000099"],
  },
  {
    code: "JM",
    flag_colours: ["#000000", "#ffcc00", "#009900"],
  },
  {
    code: "KN",
    flag_colours: ["#ff0000", "#000000", "#009900"],
  },
  {
    code: "LC",
    flag_colours: ["#66ccff", "#ffffff"],
  },
  {
    code: "MX",
    flag_colours: ["#006644", "#ffffff", "#d20f34"],
  },
  {
    code: "NI",
    flag_colours: ["#ffffff", "#0066cc"],
  },
  {
    code: "PA",
    flag_colours: ["#ff0000", "#0000ff", "#ffffff"],
  },
  {
    code: "PE",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "PY",
    flag_colours: ["#ff0000", "#0000cc", "#ffffff"],
  },
  {
    code: "SR",
    flag_colours: ["#ff0000", "#ffffff", "#009900"],
  },
  {
    code: "SV",
    flag_colours: ["#0000ff", "#ffffff"],
  },
  {
    code: "TT",
    flag_colours: ["#ff0000", "#000000"],
  },
  {
    code: "UY",
    flag_colours: ["#ffffff", "#0066cc"],
  },
  {
    code: "VC",
    flag_colours: ["#ffff00", "#0000cc", "#009900"],
  },
  {
    code: "VE",
    flag_colours: ["#ffff00", "#ff0000", "#0000f7"],
  },
  {
    code: "AE",
    flag_colours: ["#ff0000", "#000000", "#ffffff", "#009900"],
  },
  {
    code: "BH",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "IL",
    flag_colours: ["#0000cc", "#ffffff"],
  },
  {
    code: "IQ",
    flag_colours: ["#000000", "#cc0000", "#ffffff"],
  },
  {
    code: "IR",
    flag_colours: ["#009933", "#ff0000", "#ffffff"],
  },
  {
    code: "JO",
    flag_colours: ["#ff0000", "#000000", "#ffffff", "#009900"],
  },
  {
    code: "KW",
    flag_colours: ["#ff0000", "#000000", "#ffffff", "#009900"],
  },
  {
    code: "LB",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "OM",
    flag_colours: ["#ff0000", "#ffffff", "#009900"],
  },
  {
    code: "PS",
    flag_colours: ["#ff0000", "#000000", "#ffffff", "#009900"],
  },
  {
    code: "QA",
    flag_colours: ["#581007", "#ffffff"],
  },
  {
    code: "SA",
    flag_colours: ["#006600", "#ffffff"],
  },
  {
    code: "SY",
    flag_colours: ["#ff0000", "#000000", "#ffffff"],
  },
  {
    code: "YE",
    flag_colours: ["#ff0000", "#000000", "#ffffff"],
  },
  {
    code: "CA",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "US",
    flag_colours: ["#cc0000", "#ffffff", "#000066"],
  },
  {
    code: "AU",
    flag_colours: ["#cc0000", "#000066"],
  },
  {
    code: "FJ",
    flag_colours: ["#cc0000", "#3399ff"],
  },
  {
    code: "FM",
    flag_colours: ["#6699cc", "#ffffff"],
  },
  {
    code: "KI",
    flag_colours: ["#193070", "#c81010", "#ffffff"],
  },
  {
    code: "MH",
    flag_colours: ["#ff6600", "#ffffff", "#000066"],
  },
  {
    code: "NR",
    flag_colours: ["#003366", "#ffffff"],
  },
  {
    code: "NZ",
    flag_colours: ["#cc0000", "#000066"],
  },
  {
    code: "PG",
    flag_colours: ["#ff0000", "#000000"],
  },
  {
    code: "PW",
    flag_colours: ["#ffff00", "#0099ff"],
  },
  {
    code: "SB",
    flag_colours: ["#0000cc", "#006600"],
  },
  {
    code: "TO",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "TV",
    flag_colours: ["#cc0000", "#0099cc"],
  },
  {
    code: "VU",
    flag_colours: ["#ff0000", "#000000", "#006600"],
  },
  {
    code: "WS",
    flag_colours: ["#ff0000", "#000066"],
  },
  {
    code: "RE",
    flag_colours: ["#f70000", "#3267f7", "#f7f700"],
  },
  {
    code: "SS",
    flag_colours: ["#da121a", "#000000", "#078930"],
  },
  {
    code: "GF",
    flag_colours: ["#f4d609", "#07852e"],
  },
  {
    code: "YT",
    flag_colours: ["#ff0000", "#ffffff", "#000066"],
  },
  {
    code: "SH",
    flag_colours: ["#cc0000", "#ffffff", "#000066"],
  },
  {
    code: "EH",
    flag_colours: ["#009900", "#ffffff"],
  },
  {
    code: "HK",
    flag_colours: ["#ff0000", "#ffffff"],
  },
  {
    code: "TB",
    flag_colours: ["#009cce", "#ce0821"],
  },
  {
    code: "TW",
    flag_colours: ["#000095", "#fe0000"],
  },
  {
    code: "IO",
    flag_colours: ["#cc0000", "#ffffff", "#000066"],
  },
  {
    code: "MO",
    flag_colours: ["#33775f", "#ffffff", "#f5d349"],
  },
  {
    code: "XK",
    flag_colours: ["#244aa5", "#ffffff", "#d0a650"],
  },
  {
    code: "AX",
    flag_colours: ["#2864aa", "#f9d548", "#ca2c23"],
  },
  {
    code: "FO",
    flag_colours: ["#ffffff", "#db3c3f", "#2865b8"],
  },
  {
    code: "GI",
    flag_colours: ["#ffffff", "#df0000"],
  },
  {
    code: "GG",
    flag_colours: ["#ffffff", "#d63034"],
  },
  {
    code: "IM",
    flag_colours: ["#ffffff", "#cf142b"],
  },
  {
    code: "JE",
    flag_colours: ["#ffffff", "#cf142b"],
  },
  {
    code: "AI",
    flag_colours: ["#cc0000", "#ffffff", "#000066"],
  },
  {
    code: "AW",
    flag_colours: ["#418fde", "#ffd100", "#ef3340"],
  },
  {
    code: "BQ",
    flag_colours: ["#ff0000", "#ffffff", "#000066"],
  },
  {
    code: "KY",
    flag_colours: ["#cc0000", "#ffffff", "#000066"],
  },
  {
    code: "CW",
    flag_colours: ["#183381", "#ffffff", "#f9e814"],
  },
  {
    code: "FK",
    flag_colours: ["#cc0000", "#ffffff", "#000066"],
  },
  {
    code: "GP",
    flag_colours: ["#ff0000", "#ffffff", "#000066"],
  },
  {
    code: "MQ",
    flag_colours: ["#ff0000", "#ffffff", "#000066"],
  },
  {
    code: "TC",
    flag_colours: ["#cc0000", "#ffffff", "#000066"],
  },
  {
    code: "VG",
    flag_colours: ["#cc0000", "#ffffff", "#000066"],
  },
  {
    code: "VI",
    flag_colours: ["#cc0000", "#ffffff", "#000066"],
  },
  {
    code: "MF",
    flag_colours: ["#ff0000", "#ffffff", "#000066"],
  },
  {
    code: "SX",
    flag_colours: ["#ff0000", "#ffffff", "#000066"],
  },
  {
    code: "PR",
    flag_colours: ["#0050f0", "#ffffff", "#ed0000"],
  },
  {
    code: "BL",
    flag_colours: ["#ff0000", "#ffffff", "#000066"],
  },
  {
    code: "MS",
    flag_colours: ["#cc0000", "#ffffff", "#000066"],
  },
  {
    code: "BM",
    flag_colours: ["#cc0000", "#ffffff", "#000066"],
  },
  {
    code: "GL",
    flag_colours: ["#ffffff", "#d00c33"],
  },
  {
    code: "PM",
    flag_colours: ["#ff0000", "#ffffff", "#000066"],
  },
  {
    code: "AS",
    flag_colours: ["#cc0000", "#ffffff", "#000066"],
  },
  {
    code: "CX",
    flag_colours: ["#1c8a42", "#ffc639", "#0021ad"],
  },
  {
    code: "CC",
    flag_colours: ["#008000", "#ffe000"],
  },
  {
    code: "CK",
    flag_colours: ["#cc0000", "#ffffff", "#000066"],
  },
  {
    code: "PF",
    flag_colours: ["#ff0000", "#ffffff", "#000066"],
  },
  {
    code: "GU",
    flag_colours: ["#18307c", "#c62139"],
  },
  {
    code: "NC",
    flag_colours: ["#ff0000", "#ffffff", "#000066"],
  },
  {
    code: "NU",
    flag_colours: ["#cc0000", "#ffffff", "#000066"],
  },
  {
    code: "NF",
    flag_colours: ["#198200", "#ffffff"],
  },
  {
    code: "MP",
    flag_colours: ["#0071bc", "#ffffff"],
  },
  {
    code: "PN",
    flag_colours: ["#cc0000", "#ffffff", "#000066"],
  },
  {
    code: "TK",
    flag_colours: ["#00247d", "#fed100"],
  },
  {
    code: "WF",
    flag_colours: ["#ff0000", "#ffffff", "#000066"],
  },
];
