<script lang="ts">
  import Gallery from "./Gallery.svelte";
  import Swiper from "./Swiper.svelte";
  import DetailModal from "./DetailModal.svelte";

  export let activityProducts = [];

  /**
   * isSmallDevice will be set once, on component initialization
   * (it is not reactive).
   */
  const isSmallDevice = window.innerWidth <= 576;
  let modalOpen = false;
  let product = null;

  const openModal = (event) => {
    product = event.detail.product;
    toggle();
  };
  const toggle = () => (modalOpen = !modalOpen);
</script>

<svelte:component
  this={isSmallDevice ? Swiper : Gallery}
  {activityProducts}
  on:click={openModal} />

{#if product}
  <DetailModal
    activity={product}
    business={product.business}
    isOpen={modalOpen}
    {toggle} />
{/if}
