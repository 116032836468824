<script lang="ts">
  import SearchButton from "./SearchButton.svelte";
  import SearchModal from "./SearchModal.svelte";
  let showModal = false;
</script>

<SearchButton on:click={() => (showModal = true)} />

<!-- prevent navbar styles from turning everything linky green -->
<div class="text-body">
  <SearchModal bind:showModal />
</div>
