<script lang="ts">
  import ResultSet from "../grayhound/ResultSet.svelte";
  import ResultList from "../grayhound/ResultList.svelte";
  import ResultItem from "../grayhound/ResultItem.svelte";
  import { Hound } from "../grayhound/grayhound";
  import { formatLocation } from "/shared/utils";
  import { BusinessType } from "/shared/constants";

  export let query: string;

  let search: Hound = {
    name: "Businesses",
    remote: (query) => `/search/public/tab/?search=${query}`,
    transform: (response) => response.results,
    identify: (business) => business.name,
    render: (business) => `${business.name} (${business.country.name})`,
  };

  const getIcon = (business_type) => {
    switch (business_type) {
      case BusinessType.ACCOMMODATION:
        return "far fa-bed";
      case BusinessType.ACTIVITY:
        return "far fa-hiking";
      case BusinessType.BAR:
        return "fas fa-glass-martini-alt";
      case BusinessType.CAFE:
        return "fas fa-coffee";
      case BusinessType.RESTAURANT:
        return "fas fa-utensils";
    }
  };
</script>

<style lang="scss">
  @use "../sass/user/colours";

  .text-reset {
    text-decoration: none;
  }

  .icon-reset {
    color: #{colours.$green};
  }
</style>

<ResultSet hound={search} {query} let:results on:complete>
  <ResultList {results} let:items>
    {#each items as business (business.business_code)}
      <ResultItem let:hovering>
        <a class="text-reset" href={business.profile_url}>
          <div>
            <i
              class:icon-reset={!hovering}
              class={getIcon(business.business_type) + " mr-2"} />
            {business.name}
          </div>
          <div class="font-weight-light text-sm">
            {formatLocation(business)}
          </div>
        </a>
      </ResultItem>
    {/each}
  </ResultList>
</ResultSet>
