<script lang="ts">
  import { notification } from "/shared/utils.js";
  import Controller from "./Controller.svelte";
  import { AuthFlows, State, LOGIN_PANELS } from "./constants";
  import { errors, authFlow } from "./stores";
  import { login, checkEmail, requestMagicLink, requestPasswordReset } from "./api";
  import api from "/traveller/api";

  export let nextURL = "";
  export let isSignup = false;
  export let panels = LOGIN_PANELS;

  let url = new URL(window.location.href);
  if (!nextURL) {
    // attempt to get next param from url
    let next = url.searchParams.get("next");
    // make FQDN for creating magic links
    if (next) {
      url.pathname = url.searchParams.get("next");
      url.searchParams.delete("next");
      nextURL = url.href;
    }
  }

  let email = "";
  let name = "";
  let initials = "";

  async function _checkEmail(emailAddress) {
    try {
      let response = await checkEmail(emailAddress);
      $authFlow = response.auth_flow;
      name = response.first_name;
      initials = response.initials;
      email = emailAddress;
    } catch (e) {
      notification.reportError(e);
      return false;
    }
    return $authFlow !== AuthFlows.NONE;
  }

  async function createAccount(form) {
    // add referrer code from url
    form["referrer"] = url.searchParams.get("referrer");
    try {
      let response = await api.travellerApiTravellerCreate(form);
      name = response.data.name;
      return true;
    } catch (error) {
      if (error.response?.status == 400) {
        $errors = error.response.data;
        return false;
      }
      notification.reportError(error);
      return false;
    }
  }

  async function requestLink(email: string, url: string, notify: boolean) {
    try {
      let response = await requestMagicLink(email, url);
      if (notify) {
        notification.success(`We sent a magic link email to you at ${email}.`);
      }
      return response.success;
    } catch (e) {
      notification.reportError(e);
      return false;
    }
  }

  async function _requestPasswordReset(email) {
    try {
      let response = await requestPasswordReset(email);
      return response;
    } catch (e) {
      notification.reportError(e);
      return false;
    }
  }

  async function _login(password) {
    let success = false;
    try {
      let response = await login(email, password);
      success = response.success;
    } catch (e) {
      notification.reportError(e);
      return false;
    }
    if (!success) {
      $errors["passwordInput"] = "Email/Password invalid";
    }
    return success;
  }
</script>

<Controller
  initialState={isSignup ? State.SIGNUP : undefined}
  {panels}
  {nextURL}
  {email}
  {name}
  {initials}
  checkEmail={_checkEmail}
  {createAccount}
  {requestLink}
  login={_login}
  requestPasswordReset={_requestPasswordReset} />
