<script lang="ts">
  import Avatar, { Size } from "/traveller/components/Avatar.svelte";
  import { TravellerCoreSerialiser } from "/apiFactory/api";

  export let traveller: TravellerCoreSerialiser;
  export let comment = "";
</script>

<div class="mb-5 mx-2">
  <div class="row mb-3">
    <div class="col-12">
      <div class="media">
        {#if traveller.selfie_avatar_link}
          <div class="mr-3">
            <Avatar {traveller} size={Size.LARGE} />
          </div>
        {:else}
          <i
            class="fa fa-lg fa-user-friends text-lg text-muted avatar avatar-lg
            bg-light mr-3 d-flex align-items-center justify-content-center" />
        {/if}
        <div class="media-body">
          <span class="h5 font-weight-bold">{traveller.name}</span>
          <div class="text-muted">{comment}</div>
        </div>
      </div>
    </div>
  </div>
</div>
