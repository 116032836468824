<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import ResultItem from "./ResultItem.svelte";

  export let results: Array<any>;
  export let suggestions: Array<any> = [];
  export let render = (value: string) => value;
  export let limit = 5;

  let dispatch = createEventDispatcher();
  $: items = (results.length ? results : suggestions).slice(0, limit);
</script>

<div class="d-flex flex-column">
  <slot {items} {render}>
    {#each items as result}
      <ResultItem result={render(result)} on:click={() => dispatch("select", result)} />
    {/each}
  </slot>
</div>
