<script lang="ts">
  import { fly, fade, slide } from "svelte/transition";
  import {
    accountView,
    logoutView,
    vouchersView,
    wishlistView,
    makeAbsoluteURL,
  } from "/traveller/shared/urls";
  import { allActivitiesView, allCountriesView } from "/guidebook/shared/urls";
  import { currentTraveller } from "/traveller/stores";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { loginView as businessLoginView } from "/business/shared/urls";

  export let marketingUrl = null;
  export let creatorsUrl = null;

  let showMiniMenu = false;
  let dispatch = createEventDispatcher();
  // disable/enable scrolling in body when modal active
  onMount(() => document.body.classList.add("modal-open"));
  onDestroy(() => document.body.classList.remove("modal-open"));
</script>

<style>
  .submenu {
    position: fixed;
    z-index: 1100;
    bottom: 4rem;
    right: 0%;
    background-color: white;
    box-sizing: border-box;
    /* dynamic but limited height  */
    height: fit-content;
    --navbar-inset: 6rem;
    max-height: calc(100% - var(--navbar-inset));
    list-style-type: none;
    width: -moz-calc(100% - 2rem);
    width: -webkit-calc(100% - 2rem);
    width: -o-calc(100% - 2rem);
    width: calc(100% - 2rem);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .modal-background {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1090;
  }
  .mini-menu {
    /* hide content when collapsing/sliding */
    overflow-y: hidden;
  }
</style>

<ul
  class="mx-3 px-2 py-3 submenu submenu-height rounded-lg my-3 shadow-lg
  overflow-y-scroll"
  transition:fly={{ x: 50, y: 100, duration: 200 }}>
  <li>
    <button
      class="btn dropdown-item dropdown-toggle font-weight-bold text-muted"
      id="dropdownMenuLink"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      on:click={() => (showMiniMenu = !showMiniMenu)}>
      {#if $currentTraveller.code}
        <i class="fa fa-fw fa-globe-americas text-muted mr-2" />
      {/if}
      Explore The Guide
    </button>

    {#if showMiniMenu}
      <div transition:slide class="mini-menu px-4">
        <!-- Megamenu list-->
        <ul class="megamenu-list list-unstyled">
          <li class="megamenu-list-item">
            <a href={allCountriesView()} class="megamenu-list-link text-muted">
              See all countries
            </a>
          </li>
          <li class="megamenu-list-item">
            <a href={allActivitiesView()} class="megamenu-list-link text-muted">
              See more activities
            </a>
          </li>
        </ul>
      </div>
    {/if}
  </li>

  {#if $currentTraveller.code}
    <a href={wishlistView()} class="dropdown-item font-weight-bold text-muted">
      <i class="fa fa-bookmark text-muted mr-2 fa-fw" />
      My Wishlist
    </a>
    <a href={vouchersView()} class="dropdown-item font-weight-bold text-muted">
      <i class="fa fa-ticket-alt text-muted mr-2 fa-fw" />
      Saved Vouchers
    </a>
    <a href={accountView()} class="dropdown-item font-weight-bold text-muted">
      <i class="fa fa-sliders-v text-muted mr-2 fa-fw" />
      Profile & Settings
    </a>
    <a href={logoutView()} class="dropdown-item font-weight-bold text-muted">
      <i class="fa fa-sign-out-alt text-muted mr-2 fa-fw" />
      Log Out
    </a>
  {:else}
    <li>
      <a href={marketingUrl} class="dropdown-item font-weight-bold text-muted">
        Info for Businesses
      </a>
    </li>
    <li>
      <a
        href={makeAbsoluteURL("listing", businessLoginView())}
        class="dropdown-item font-weight-bold text-muted">
        Business login
      </a>
    </li>

    <li>
      <a href={creatorsUrl} class="dropdown-item font-weight-bold text-muted">
        Info for Creators
      </a>
    </li>
    <li>
      <button
        on:click={() => dispatch("login")}
        class="dropdown-item font-weight-bold text-muted btn">
        Traveller login
      </button>
    </li>
    <li class="text-center">
      <button on:click={() => dispatch("login")} class="btn btn-primary mt-2">
        Sign up for free
      </button>
    </li>
  {/if}
</ul>
<!-- /SUBMENU -->

<div
  class="w-100 h-100 modal-background bg-dark opacity-5"
  transition:fade
  on:click={() => dispatch("close")} />
