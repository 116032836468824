<script lang="ts">
  import NavLink from "./NavLink.svelte";
  import NavButton from "./NavButton.svelte";
  import { fly } from "svelte/transition";
  import { discoverView, recommendationsView } from "/traveller/shared/urls";
  import { homeView } from "/guidebook/shared/urls";
  import { currentTraveller } from "/traveller/stores";
  import SignupSlide from "/traveller/modals/SignupSlide.svelte";
  import { onMount } from "svelte";
  import SearchModal from "/shared/components/search/SearchModal.svelte";
  import SubMenu from "./SubMenu.svelte";

  export let marketingUrl = null;
  export let creatorsUrl = null;

  /* This is a mobile-only component */
  const isMobile = window.innerWidth <= 576;
  const location = window.location.pathname;

  let showSubmenu = false;
  let showSignup = false;
  let showSearch = false;
  $: buttonActive = showSubmenu || showSignup || showSearch;

  // force intro animation
  let init = false;
  onMount(() => (init = true));

  const toggleSearch = () => {
    showSubmenu = false;
    showSignup = false;
    showSearch = !showSearch;
  };

  const toggleSignup = () => {
    showSubmenu = false;
    showSearch = false;
    showSignup = !showSignup;
  };

  const toggleSubmenu = () => {
    showSignup = false;
    showSearch = false;
    showSubmenu = !showSubmenu;
  };
</script>

<style>
  .mobile-nav {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: auto;
    z-index: 1100;
    cursor: pointer;
  }

  /* sit above iOS bottom chrome */
  @supports (bottom: env(safe-area-inset-bottom)) and
    (height: env(safe-area-inset-bottom)) {
    .mobile-nav {
      padding-bottom: env(safe-area-inset-bottom);
    }
  }
</style>

{#if init && isMobile}
  <nav
    class="mobile-nav w-100 border-top pt-1 bg-white"
    transition:fly={{ delay: 0, duration: 500, x: 0, y: 650, opacity: 0 }}>
    <!-- NAVLINKS -->
    <div class="d-flex flex-row justify-content-around">
      <!-- EXPLORE -->
      <NavLink
        label={"Explore"}
        link={homeView()}
        icon={"fa-globe-americas"}
        active={location.includes("explore") && !buttonActive} />

      <!-- SEARCH -->
      <NavButton
        on:click={toggleSearch}
        label={"Search"}
        icon={"fa-search"}
        active={showSearch} />

      {#if $currentTraveller.code}
        <!-- Authed content-->

        <!-- FOR YOU -->
        <NavLink
          label={"For You"}
          link={discoverView()}
          icon={"fa-user-circle"}
          active={location == discoverView() && !buttonActive} />

        <!-- MY RECS -->
        <NavLink
          label={"My Recs"}
          link={recommendationsView($currentTraveller.code)}
          icon={"fa-heart"}
          active={location == recommendationsView($currentTraveller.code) &&
            !buttonActive} />
      {:else}
        <!-- Unauthed content - login/signup CTAs-->

        <!-- FOR YOU -->
        <NavButton
          on:click={toggleSignup}
          label={"For You"}
          icon={"fa-user-circle"}
          active={false} />

        <!-- LOG IN -->
        <NavButton
          on:click={toggleSignup}
          label={"Log In"}
          icon={"fal-user-circle"}
          active={showSignup} />
      {/if}

      <!-- MORE -->
      <NavButton
        on:click={toggleSubmenu}
        label={"More"}
        icon={"fa-ellipsis-h"}
        active={showSubmenu} />
    </div>
    <!-- /NAVLINKs -->
  </nav>

  {#if showSubmenu}
    <SubMenu
      {creatorsUrl}
      {marketingUrl}
      on:close={toggleSubmenu}
      on:login={toggleSignup} />
  {/if}

  {#if showSignup}
    <SignupSlide on:close={toggleSignup} />
  {/if}

  <SearchModal bind:showModal={showSearch} />
{/if}
