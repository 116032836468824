<script lang="ts" context="module">
  declare global {
    interface Window {
      hCaptchaKey: string;
      handleCaptchaOnLoad: Function;
      handleSuccess: Function;
    }
  }

  declare var hcaptcha: any;
</script>

<script lang="ts">
  import { onDestroy, createEventDispatcher, onMount } from "svelte";

  const dispatch = createEventDispatcher();

  export const reset = () => {
    if (mounted && loaded && widgetID) hcaptcha.reset(widgetID);
  };

  const hCaptchaKey = window.hCaptchaKey;
  // ensure that all captcha divs on a page are uniquely identifiable
  const id = Math.floor(Math.random() * 100);

  let mounted = false;
  let loaded = false;
  let widgetID;

  window.handleCaptchaOnLoad = () => {
    loaded = true;
  };

  window.handleSuccess = (token) => {
    dispatch("success", {
      token: token,
    });
  };

  onMount(() => {
    mounted = true;
  });

  onDestroy(() => {
    window.handleCaptchaOnLoad = null;
    window.handleSuccess = null;
    // guard against script loading race conditions
    // i.e. if component is destroyed before hcaptcha reference is loaded
    if (loaded) hcaptcha = null;
  });

  $: if (mounted && loaded) {
    widgetID = hcaptcha.render(`h-captcha-${id}`, {
      sitekey: hCaptchaKey,
      callback: "handleSuccess",
    });
  }
</script>

<svelte:head>
  <script
    src="https://hcaptcha.com/1/api.js?render=explicit&onload=handleCaptchaOnLoad"
    async
    defer>
  </script>
</svelte:head>

<div id="h-captcha-{id}" />
