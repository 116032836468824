<script lang="ts">
  import { Modal, ModalBody } from "sveltestrap";
  import SearchInput from "./SearchInput.svelte";
  import SearchResults from "./SearchResults.svelte";

  export let showModal = false;

  let query = "";
  const toggle = () => (showModal = !showModal);
</script>

<style global>
  .search-rounded-modal {
    /* Give modal breathing room and tappable escape space for mobile */
    border-radius: 2rem;
    top: 5vh;
    max-height: 90vh;
    overflow-y: hidden;
  }
</style>

<Modal
  contentClassName={"search-rounded-modal"}
  isOpen={showModal}
  {toggle}
  size="lg"
  transitionOptions={{ duration: 50 }}>
  <ModalBody>
    <SearchInput on:query={(ev) => (query = ev.detail)} />

    <SearchResults {query} />
  </ModalBody>
</Modal>
