<script lang="ts">
  import { createEventDispatcher } from "svelte";
  export let email: string;

  const dispatch = createEventDispatcher();
</script>

<div class="h2 text-center font-weight-bold">Check your email</div>
<hr />
<div class="mt-4 text-center">
  <i class="fa fa-lock-open-alt fa-3x text-primary" />
  <div class="text-muted mt-2">
    We sent an email to you at {email}.
    <br />
    It has a magic link that’ll sign you in!
  </div>

  <button
    class="btn btn-outline-primary mt-4"
    on:click={() => dispatch("requestLink", email)}>
    <i class="fa far-redo pr-2" />
    Resend Link
  </button>
</div>
