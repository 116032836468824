<script lang="ts">
  import ResultItem from "../grayhound/ResultItem.svelte";
  import ResultList from "../grayhound/ResultList.svelte";
  import ResultSet from "../grayhound/ResultSet.svelte";
  import { Hound } from "../grayhound/grayhound";
  import { areaView } from "/guidebook/shared/urls";

  export let query;

  let search: Hound = {
    name: "Areas",
    remote: (query) => `/search/areas-countries/?search=${query}`,
    transform: (response) => response.results,
    identify: (area) => area.name,
    render: (area) => `${area.name} (${area.country.name})`,
    search: false,
  };
</script>

<style lang="scss">
  @use "../sass/user/colours";

  .text-reset {
    text-decoration: none;
  }

  .icon-reset {
    color: #{colours.$green};
  }
</style>

<ResultSet hound={search} {query} let:results on:complete>
  <ResultList {results} let:items>
    {#each items as area (area.slug)}
      <ResultItem let:hovering>
        <a class="text-reset" href={areaView(area.country.code, area.slug)}>
          <div>
            <i class:icon-reset={!hovering} class="fas fa-map-marker-alt mr-2" />
            {area.name}
          </div>
          <div class="font-weight-light text-sm">{area.country.name}</div>
        </a>
      </ResultItem>
    {/each}
  </ResultList>
</ResultSet>
