<script lang="ts">
  export let result: string = "";

  let selected = false;
  let hovering = false;
</script>

<style lang="scss">
  @use "sass/user/colours";
  .item {
    cursor: pointer;
  }
  .item:hover {
    background-color: #{colours.$primary};
    color: white;
  }
</style>

<div
  on:mouseenter={() => (hovering = true)}
  on:mouseleave={() => (hovering = false)}
  class="item px-3 bg-gray rounded cursor-pointer py-2"
  on:click>
  <slot {hovering}>{result}</slot>
</div>
