<script>
  import { areaView } from "../shared/urls";

  export let area;
</script>

<style>
  a {
    text-decoration: none;
  }
</style>

<!-- use text-reset to stop anchor text colouring -->
<a class="text-reset" href={areaView(area.country.code, area.slug)}>
  <div
    class="card d-inline-flex text-center px-3 py-3 my-2 mx-2 hover-scale
    border-1 rounded-lg font-space-wide"
    style="cursor: pointer;">
    <div class="text-sm text-uppercase text-no-wrap">{area.name}</div>
  </div>
</a>
