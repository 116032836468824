<script lang="ts">
  import SpotlightModal from "../../traveller/modals/Spotlight.svelte";
  import PureBusinessGallery from "./PureBusinessGallery.svelte";
  import PureBusinessSwiper from "./PureBusinessSwiper.svelte";

  export let businesses = [];

  /**
   * isSmallDevice will be set once, on component initialization
   * (it is not reactive).
   */
  const isSmallDevice = window.innerWidth <= 576;

  let modalOpen = false;
  let business = null;

  const openModal = (event) => {
    business = event.detail.business;
    toggle();
  };
  const toggle = () => (modalOpen = !modalOpen);
</script>

<svelte:component
  this={isSmallDevice ? PureBusinessSwiper : PureBusinessGallery}
  {businesses}
  on:click={openModal} />

<SpotlightModal {business} open={modalOpen} {toggle} />
