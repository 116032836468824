<script>
  import AreaButton from "./AreaButton.svelte";
  import PureSwiper from "./PureSwiper.svelte";

  export let areas = [];
  let swiperProps = {
    slidesPerView: "auto",
    spaceBetween: 0,
    freeMode: true,
    navigation: {
      nextEl: ".button-next",
      prevEl: ".button-prev",
    },
  };
</script>

<style global lang="css">
  #areaButtonsGroupSwiper .swiper-slide {
    /* let the button determine the width so multiple fit in a single view */
    width: unset !important;
  }
</style>

<div id="areaButtonsGroupSwiper" class="row">
  <PureSwiper items={areas} component={AreaButton} {swiperProps} />
</div>
