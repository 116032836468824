/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @enum {string}
 */

export enum ActivityEnum {
  AirportTransfers = "airport_transfers",
  ArcheryShooting = "archery_shooting",
  Art = "art",
  AtvTours = "atv_tours",
  AxeThrowing = "axe_throwing",
  BarCrawl = "bar_crawl",
  Birdwatching = "birdwatching",
  Boats = "boats",
  BreweriesDistilleries = "breweries_distilleries",
  BungeeJumping = "bungee_jumping",
  ChocolateExperiences = "chocolate_experiences",
  Climbing = "climbing",
  CoffeeExperiences = "coffee_experiences",
  CookingClasses = "cooking_classes",
  Crafts = "crafts",
  Cultural = "cultural",
  Cycling = "cycling",
  DanceClasses = "dance_classes",
  DayTours = "day_tours",
  Diving = "diving",
  Dogsledding = "dogsledding",
  EscapeRooms = "escape_rooms",
  FarmExperiences = "farm_experiences",
  Fishing = "fishing",
  FoodAndDrink = "food_and_drink",
  FunGames = "fun_games",
  Golf = "golf",
  GuidedTours = "guided_tours",
  HangGliding = "hang_gliding",
  HealthFitness = "health_fitness",
  Hiking = "hiking",
  Horses = "horses",
  HotAirBalloon = "hot_air_balloon",
  Karting = "karting",
  Kayaking = "kayaking",
  Kitesurfing = "kitesurfing",
  LanguageLessons = "language_lessons",
  LiveMusic = "live_music",
  MartialArts = "martial_arts",
  Meditation = "meditation",
  MotorSports = "motor_sports",
  Motorcycles = "motorcycles",
  MountainClimbing = "mountain_climbing",
  MultidayTours = "multiday_tours",
  Museums = "museums",
  OffRoadTours = "off_road_tours",
  OpenTopBusTours = "open_top_bus_tours",
  Other = "other",
  PaddleBoarding = "paddle_boarding",
  Paintballing = "paintballing",
  Painting = "painting",
  Paragliding = "paragliding",
  PhotographyTours = "photography_tours",
  Pilates = "pilates",
  Pottery = "pottery",
  Rafting = "rafting",
  Safari = "safari",
  Sailing = "sailing",
  Sandboarding = "sandboarding",
  ShoreExcursions = "shore_excursions",
  ShowsTheatre = "shows_theatre",
  Sightseeing = "sightseeing",
  Skiing = "skiing",
  SkyTours = "sky_tours",
  Skydiving = "skydiving",
  Snorkelling = "snorkelling",
  Snowboarding = "snowboarding",
  Spa = "spa",
  Stargazing = "stargazing",
  Surfing = "surfing",
  Taxi = "taxi",
  ThemeParks = "theme_parks",
  TravelAgency = "travel_agency",
  Tuktuk = "tuktuk",
  Walking = "walking",
  Wellness = "wellness",
  WineTasting = "wine_tasting",
  Winetours = "winetours",
  Yoga = "yoga",
  Zipline = "zipline",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum ActivitySubtypeEnum {
  AirportTransfers = "airport_transfers",
  ArcheryShooting = "archery_shooting",
  Art = "art",
  AtvTours = "atv_tours",
  AxeThrowing = "axe_throwing",
  BarCrawl = "bar_crawl",
  Birdwatching = "birdwatching",
  Boats = "boats",
  BreweriesDistilleries = "breweries_distilleries",
  BungeeJumping = "bungee_jumping",
  ChocolateExperiences = "chocolate_experiences",
  Climbing = "climbing",
  CoffeeExperiences = "coffee_experiences",
  CookingClasses = "cooking_classes",
  Crafts = "crafts",
  Cultural = "cultural",
  Cycling = "cycling",
  DanceClasses = "dance_classes",
  DayTours = "day_tours",
  Diving = "diving",
  Dogsledding = "dogsledding",
  EscapeRooms = "escape_rooms",
  FarmExperiences = "farm_experiences",
  Fishing = "fishing",
  FoodAndDrink = "food_and_drink",
  FunGames = "fun_games",
  Golf = "golf",
  GuidedTours = "guided_tours",
  HangGliding = "hang_gliding",
  HealthFitness = "health_fitness",
  Hiking = "hiking",
  Horses = "horses",
  HotAirBalloon = "hot_air_balloon",
  Karting = "karting",
  Kayaking = "kayaking",
  Kitesurfing = "kitesurfing",
  LanguageLessons = "language_lessons",
  LiveMusic = "live_music",
  MartialArts = "martial_arts",
  Meditation = "meditation",
  MotorSports = "motor_sports",
  Motorcycles = "motorcycles",
  MountainClimbing = "mountain_climbing",
  MultidayTours = "multiday_tours",
  Museums = "museums",
  OffRoadTours = "off_road_tours",
  OpenTopBusTours = "open_top_bus_tours",
  Other = "other",
  PaddleBoarding = "paddle_boarding",
  Paintballing = "paintballing",
  Painting = "painting",
  Paragliding = "paragliding",
  PhotographyTours = "photography_tours",
  Pilates = "pilates",
  Pottery = "pottery",
  Rafting = "rafting",
  Safari = "safari",
  Sailing = "sailing",
  Sandboarding = "sandboarding",
  ShoreExcursions = "shore_excursions",
  ShowsTheatre = "shows_theatre",
  Sightseeing = "sightseeing",
  Skiing = "skiing",
  SkyTours = "sky_tours",
  Skydiving = "skydiving",
  Snorkelling = "snorkelling",
  Snowboarding = "snowboarding",
  Spa = "spa",
  Stargazing = "stargazing",
  Surfing = "surfing",
  Taxi = "taxi",
  ThemeParks = "theme_parks",
  TravelAgency = "travel_agency",
  Tuktuk = "tuktuk",
  Walking = "walking",
  Wellness = "wellness",
  WineTasting = "wine_tasting",
  Winetours = "winetours",
  Yoga = "yoga",
  Zipline = "zipline",
}

/**
 *
 * @export
 * @interface AggregateCountrySerialiser
 */
export interface AggregateCountrySerialiser {
  /**
   *
   * @type {string}
   * @memberof AggregateCountrySerialiser
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AggregateCountrySerialiser
   */
  code: string;
  /**
   *
   * @type {Array<TravellerCoreSerialiser>}
   * @memberof AggregateCountrySerialiser
   */
  travellers: Array<TravellerCoreSerialiser>;
  /**
   *
   * @type {number}
   * @memberof AggregateCountrySerialiser
   */
  visits: number;
  /**
   *
   * @type {string}
   * @memberof AggregateCountrySerialiser
   */
  beta: string;
}
/**
 *
 * @export
 * @interface AreaSerialiser
 */
export interface AreaSerialiser {
  /**
   *
   * @type {string}
   * @memberof AreaSerialiser
   */
  name: string;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof AreaSerialiser
   */
  country: { [key: string]: any };
  /**
   *
   * @type {string}
   * @memberof AreaSerialiser
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof AreaSerialiser
   */
  image_link?: string;
  /**
   *
   * @type {string}
   * @memberof AreaSerialiser
   */
  image_link_uuid: string;
  /**
   *
   * @type {CategoryEnum | BlankEnum | NullEnum}
   * @memberof AreaSerialiser
   */
  category?: CategoryEnum | BlankEnum | NullEnum | null;
}
/**
 *
 * @export
 * @interface AreaSerialiserRequest
 */
export interface AreaSerialiserRequest {
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof AreaSerialiserRequest
   */
  country: { [key: string]: any };
  /**
   *
   * @type {string}
   * @memberof AreaSerialiserRequest
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof AreaSerialiserRequest
   */
  image_link?: string;
  /**
   *
   * @type {CategoryEnum | BlankEnum | NullEnum}
   * @memberof AreaSerialiserRequest
   */
  category?: CategoryEnum | BlankEnum | NullEnum | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum BarEstablishmentTypeEnum {
  Beer = "beer",
  Wine = "wine",
  Cocktail = "cocktail",
  Bar = "bar",
  Nightclub = "nightclub",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum BlankEnum {
  Empty = "",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum BonusCreditEnum {
  NUMBER_5 = 5,
  NUMBER_10 = 10,
  NUMBER_15 = 15,
  NUMBER_20 = 20,
  NUMBER_25 = 25,
  NUMBER_50 = 50,
  NUMBER_75 = 75,
  NUMBER_100 = 100,
}

/**
 * Serialise an anonymous user\'s or traveller\'s booking request.   Travellers can optionally attach an existing coupon, or have one created  by passing a recommender.  Note: This serialiser expects a traveller to be set by the caller for coupon       creation.
 * @export
 * @interface BookingEnquirySerialiser
 */
export interface BookingEnquirySerialiser {
  /**
   *
   * @type {string}
   * @memberof BookingEnquirySerialiser
   */
  business_profile: string;
  /**
   *
   * @type {string}
   * @memberof BookingEnquirySerialiser
   */
  guest_name: string;
  /**
   *
   * @type {string}
   * @memberof BookingEnquirySerialiser
   */
  guest_email: string;
  /**
   *
   * @type {string}
   * @memberof BookingEnquirySerialiser
   */
  from_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof BookingEnquirySerialiser
   */
  to_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof BookingEnquirySerialiser
   */
  guest_message?: string;
  /**
   *
   * @type {string}
   * @memberof BookingEnquirySerialiser
   */
  coupon?: string | null;
  /**
   *
   * @type {string}
   * @memberof BookingEnquirySerialiser
   */
  recommender?: string | null;
}
/**
 * Serialise an anonymous user\'s or traveller\'s booking request.   Travellers can optionally attach an existing coupon, or have one created  by passing a recommender.  Note: This serialiser expects a traveller to be set by the caller for coupon       creation.
 * @export
 * @interface BookingEnquirySerialiserRequest
 */
export interface BookingEnquirySerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof BookingEnquirySerialiserRequest
   */
  business_profile: string;
  /**
   *
   * @type {string}
   * @memberof BookingEnquirySerialiserRequest
   */
  guest_name: string;
  /**
   *
   * @type {string}
   * @memberof BookingEnquirySerialiserRequest
   */
  guest_email: string;
  /**
   *
   * @type {string}
   * @memberof BookingEnquirySerialiserRequest
   */
  from_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof BookingEnquirySerialiserRequest
   */
  to_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof BookingEnquirySerialiserRequest
   */
  guest_message?: string;
  /**
   *
   * @type {string}
   * @memberof BookingEnquirySerialiserRequest
   */
  coupon?: string | null;
  /**
   *
   * @type {string}
   * @memberof BookingEnquirySerialiserRequest
   */
  recommender?: string | null;
  /**
   *
   * @type {string}
   * @memberof BookingEnquirySerialiserRequest
   */
  token: string;
}
/**
 *
 * @export
 * @interface BusinessCouponReadSerialiser
 */
export interface BusinessCouponReadSerialiser {
  /**
   *
   * @type {TravellerSerialiser}
   * @memberof BusinessCouponReadSerialiser
   */
  traveller: TravellerSerialiser;
  /**
   *
   * @type {TravellerSerialiser}
   * @memberof BusinessCouponReadSerialiser
   */
  recommender: TravellerSerialiser;
  /**
   *
   * @type {BusinessSerialiser}
   * @memberof BusinessCouponReadSerialiser
   */
  business: BusinessSerialiser;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponReadSerialiser
   */
  business_email: string;
  /**
   *
   * @type {BookingEnquirySerialiser}
   * @memberof BusinessCouponReadSerialiser
   */
  booking_enquiry: BookingEnquirySerialiser;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponReadSerialiser
   */
  code: string;
  /**
   *
   * @type {number}
   * @memberof BusinessCouponReadSerialiser
   */
  discount: number;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponReadSerialiser
   */
  bonus_credit?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponReadSerialiser
   */
  bonus_credit_currency: string;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponReadSerialiser
   */
  expires_at: string;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponReadSerialiser
   */
  cancelled_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponReadSerialiser
   */
  redeemed_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponReadSerialiser
   */
  bonus_credited_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponReadSerialiser
   */
  redemption_start_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponReadSerialiser
   */
  redemption_end_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponReadSerialiser
   */
  traveller_confirmed_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponReadSerialiser
   */
  is_expired: string;
}
/**
 *
 * @export
 * @interface BusinessCouponSerialiser
 */
export interface BusinessCouponSerialiser {
  /**
   *
   * @type {string}
   * @memberof BusinessCouponSerialiser
   */
  traveller: string;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponSerialiser
   */
  recommender: string;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponSerialiser
   */
  business: string;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponSerialiser
   */
  code?: string;
}
/**
 *
 * @export
 * @interface BusinessCouponSerialiserRequest
 */
export interface BusinessCouponSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof BusinessCouponSerialiserRequest
   */
  traveller: string;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponSerialiserRequest
   */
  recommender: string;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponSerialiserRequest
   */
  business: string;
  /**
   *
   * @type {string}
   * @memberof BusinessCouponSerialiserRequest
   */
  code?: string;
}
/**
 *
 * @export
 * @interface BusinessProfileNearbySerialiser
 */
export interface BusinessProfileNearbySerialiser {
  /**
   *
   * @type {Array<FullBusinessSerialiser>}
   * @memberof BusinessProfileNearbySerialiser
   */
  activity: Array<FullBusinessSerialiser>;
  /**
   *
   * @type {Array<FullBusinessSerialiser>}
   * @memberof BusinessProfileNearbySerialiser
   */
  accommodation: Array<FullBusinessSerialiser>;
  /**
   *
   * @type {Array<FullBusinessSerialiser>}
   * @memberof BusinessProfileNearbySerialiser
   */
  eat_drink: Array<FullBusinessSerialiser>;
}
/**
 *
 * @export
 * @interface BusinessSearchResultSerialiser
 */
export interface BusinessSearchResultSerialiser {
  /**
   *
   * @type {string}
   * @memberof BusinessSearchResultSerialiser
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSearchResultSerialiser
   */
  business_code?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSearchResultSerialiser
   */
  profile_url: string;
  /**
   *
   * @type {BusinessTypeEnum}
   * @memberof BusinessSearchResultSerialiser
   */
  business_type: BusinessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof BusinessSearchResultSerialiser
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSearchResultSerialiser
   */
  area: string;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof BusinessSearchResultSerialiser
   */
  country: { [key: string]: any };
  /**
   *
   * @type {boolean}
   * @memberof BusinessSearchResultSerialiser
   */
  referrable: boolean;
  /**
   *
   * @type {BonusCreditEnum | NullEnum}
   * @memberof BusinessSearchResultSerialiser
   */
  bonus_credit: BonusCreditEnum | NullEnum | null;
  /**
   *
   * @type {DiscountEnum | NullEnum}
   * @memberof BusinessSearchResultSerialiser
   */
  discount: DiscountEnum | NullEnum | null;
}
/**
 *
 * @export
 * @interface BusinessSerialiser
 */
export interface BusinessSerialiser {
  /**
   *
   * @type {string}
   * @memberof BusinessSerialiser
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSerialiser
   */
  business_code?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSerialiser
   */
  profile_url: string;
  /**
   *
   * @type {BusinessTypeEnum}
   * @memberof BusinessSerialiser
   */
  business_type: BusinessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof BusinessSerialiser
   */
  formatted_address: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSerialiser
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSerialiser
   */
  short_description?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSerialiser
   */
  city_and_country: string;
  /**
   *
   * @type {number}
   * @memberof BusinessSerialiser
   */
  staff_discount?: number | null;
  /**
   *
   * @type {AreaSerialiser}
   * @memberof BusinessSerialiser
   */
  area: AreaSerialiser;
  /**
   *
   * @type {string}
   * @memberof BusinessSerialiser
   */
  cover_image_link?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSerialiser
   */
  cover_image_uuid: string;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof BusinessSerialiser
   */
  country: { [key: string]: any };
  /**
   *
   * @type {PriceRangeEnum}
   * @memberof BusinessSerialiser
   */
  price_range: PriceRangeEnum;
  /**
   *
   * @type {ActivitySubtypeEnum}
   * @memberof BusinessSerialiser
   */
  activity_subtype: ActivitySubtypeEnum;
  /**
   *
   * @type {number}
   * @memberof BusinessSerialiser
   */
  cuisine?: number | null;
  /**
   *
   * @type {string}
   * @memberof BusinessSerialiser
   */
  is_premium: string;
  /**
   *
   * @type {BonusCreditEnum | NullEnum}
   * @memberof BusinessSerialiser
   */
  bonus_credit: BonusCreditEnum | NullEnum | null;
  /**
   *
   * @type {DiscountEnum | NullEnum}
   * @memberof BusinessSerialiser
   */
  discount: DiscountEnum | NullEnum | null;
  /**
   *
   * @type {string}
   * @memberof BusinessSerialiser
   */
  third_party_discount_code: string;
  /**
   *
   * @type {boolean}
   * @memberof BusinessSerialiser
   */
  prebooking_required: boolean;
}
/**
 *
 * @export
 * @interface BusinessSerialiserRequest
 */
export interface BusinessSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof BusinessSerialiserRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSerialiserRequest
   */
  business_code?: string;
  /**
   *
   * @type {BusinessTypeEnum}
   * @memberof BusinessSerialiserRequest
   */
  business_type: BusinessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof BusinessSerialiserRequest
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSerialiserRequest
   */
  short_description?: string;
  /**
   *
   * @type {number}
   * @memberof BusinessSerialiserRequest
   */
  staff_discount?: number | null;
  /**
   *
   * @type {AreaSerialiserRequest}
   * @memberof BusinessSerialiserRequest
   */
  area: AreaSerialiserRequest;
  /**
   *
   * @type {string}
   * @memberof BusinessSerialiserRequest
   */
  cover_image_link?: string;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof BusinessSerialiserRequest
   */
  country: { [key: string]: any };
  /**
   *
   * @type {PriceRangeEnum}
   * @memberof BusinessSerialiserRequest
   */
  price_range: PriceRangeEnum;
  /**
   *
   * @type {ActivitySubtypeEnum}
   * @memberof BusinessSerialiserRequest
   */
  activity_subtype: ActivitySubtypeEnum;
  /**
   *
   * @type {number}
   * @memberof BusinessSerialiserRequest
   */
  cuisine?: number | null;
}
/**
 * Full Business Profile info with recommendations based on owners/traveller in the context.
 * @export
 * @interface BusinessSpotlightSerialiser
 */
export interface BusinessSpotlightSerialiser {
  /**
   *
   * @type {string}
   * @memberof BusinessSpotlightSerialiser
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSpotlightSerialiser
   */
  business_code?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSpotlightSerialiser
   */
  profile_url: string;
  /**
   *
   * @type {BusinessTypeEnum}
   * @memberof BusinessSpotlightSerialiser
   */
  business_type: BusinessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof BusinessSpotlightSerialiser
   */
  formatted_address: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSpotlightSerialiser
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSpotlightSerialiser
   */
  short_description?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSpotlightSerialiser
   */
  city_and_country: string;
  /**
   *
   * @type {number}
   * @memberof BusinessSpotlightSerialiser
   */
  staff_discount?: number | null;
  /**
   *
   * @type {AreaSerialiser}
   * @memberof BusinessSpotlightSerialiser
   */
  area: AreaSerialiser;
  /**
   *
   * @type {string}
   * @memberof BusinessSpotlightSerialiser
   */
  cover_image_link?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSpotlightSerialiser
   */
  cover_image_uuid: string;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof BusinessSpotlightSerialiser
   */
  country: { [key: string]: any };
  /**
   *
   * @type {PriceRangeEnum}
   * @memberof BusinessSpotlightSerialiser
   */
  price_range: PriceRangeEnum;
  /**
   *
   * @type {ActivitySubtypeEnum}
   * @memberof BusinessSpotlightSerialiser
   */
  activity_subtype: ActivitySubtypeEnum;
  /**
   *
   * @type {string}
   * @memberof BusinessSpotlightSerialiser
   */
  cuisine: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSpotlightSerialiser
   */
  is_premium: string;
  /**
   *
   * @type {BonusCreditEnum | NullEnum}
   * @memberof BusinessSpotlightSerialiser
   */
  bonus_credit: BonusCreditEnum | NullEnum | null;
  /**
   *
   * @type {DiscountEnum | NullEnum}
   * @memberof BusinessSpotlightSerialiser
   */
  discount: DiscountEnum | NullEnum | null;
  /**
   *
   * @type {string}
   * @memberof BusinessSpotlightSerialiser
   */
  third_party_discount_code: string;
  /**
   *
   * @type {boolean}
   * @memberof BusinessSpotlightSerialiser
   */
  prebooking_required: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof BusinessSpotlightSerialiser
   */
  hashtags: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BusinessSpotlightSerialiser
   */
  languages?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BusinessSpotlightSerialiser
   */
  additional_services: Array<string>;
  /**
   *
   * @type {string}
   * @memberof BusinessSpotlightSerialiser
   */
  opening_time?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessSpotlightSerialiser
   */
  closing_time?: string | null;
  /**
   *
   * @type {number}
   * @memberof BusinessSpotlightSerialiser
   */
  number_of_rooms?: number | null;
  /**
   *
   * @type {LgbtqTypeEnum | BlankEnum | NullEnum}
   * @memberof BusinessSpotlightSerialiser
   */
  lgbtq_type?: LgbtqTypeEnum | BlankEnum | NullEnum | null;
  /**
   *
   * @type {boolean}
   * @memberof BusinessSpotlightSerialiser
   */
  is_shell: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BusinessSpotlightSerialiser
   */
  area_expert?: boolean;
  /**
   *
   * @type {DietaryCategoryEnum | BlankEnum | NullEnum}
   * @memberof BusinessSpotlightSerialiser
   */
  dietary_category?: DietaryCategoryEnum | BlankEnum | NullEnum | null;
  /**
   *
   * @type {string}
   * @memberof BusinessSpotlightSerialiser
   */
  restaurant_style: string;
  /**
   *
   * @type {string}
   * @memberof BusinessSpotlightSerialiser
   */
  book_now_url?: string;
  /**
   *
   * @type {Array<VisitedBusinessSerialiser>}
   * @memberof BusinessSpotlightSerialiser
   */
  visits: Array<VisitedBusinessSerialiser>;
  /**
   *
   * @type {string}
   * @memberof BusinessSpotlightSerialiser
   */
  wishlist_uuid: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum BusinessTypeEnum {
  Accommodation = "accommodation",
  Activity = "activity",
  Sight = "sight",
  Restaurant = "restaurant",
  Bar = "bar",
  Cafe = "cafe",
}

/**
 *
 * @export
 * @interface BusinessUpdateSerialiser
 */
export interface BusinessUpdateSerialiser {
  /**
   *
   * @type {number}
   * @memberof BusinessUpdateSerialiser
   */
  pk: number;
  /**
   *
   * @type {string}
   * @memberof BusinessUpdateSerialiser
   */
  created: string;
  /**
   *
   * @type {string}
   * @memberof BusinessUpdateSerialiser
   */
  body?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessUpdateSerialiser
   */
  image_uuid: string;
  /**
   *
   * @type {string}
   * @memberof BusinessUpdateSerialiser
   */
  image_link?: string | null;
}
/**
 * Serialises hashtags and languages in addition to the other fields.  Note: users of this serialiser must either call this classes .setup_eager_loading()       method or, if using this serialiser as a mixin, define their own .setup_eager_loading()       method to lazy-load hashtags (otherwise, using this serialiser will result in expensive       queries!)
 * @export
 * @interface BusinessWithHashtagsAndLanguagesSerialiser
 */
export interface BusinessWithHashtagsAndLanguagesSerialiser {
  /**
   *
   * @type {string}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  business_code?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  profile_url: string;
  /**
   *
   * @type {BusinessTypeEnum}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  business_type: BusinessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  formatted_address: string;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  short_description?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  city_and_country: string;
  /**
   *
   * @type {number}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  staff_discount?: number | null;
  /**
   *
   * @type {AreaSerialiser}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  area: AreaSerialiser;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  cover_image_link?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  cover_image_uuid: string;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  country: { [key: string]: any };
  /**
   *
   * @type {PriceRangeEnum}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  price_range: PriceRangeEnum;
  /**
   *
   * @type {ActivitySubtypeEnum}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  activity_subtype: ActivitySubtypeEnum;
  /**
   *
   * @type {number}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  cuisine?: number | null;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  is_premium: string;
  /**
   *
   * @type {BonusCreditEnum | NullEnum}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  bonus_credit: BonusCreditEnum | NullEnum | null;
  /**
   *
   * @type {DiscountEnum | NullEnum}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  discount: DiscountEnum | NullEnum | null;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  third_party_discount_code: string;
  /**
   *
   * @type {boolean}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  prebooking_required: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  hashtags: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiser
   */
  languages?: Array<string>;
}
/**
 * Serialises hashtags and languages in addition to the other fields.  Note: users of this serialiser must either call this classes .setup_eager_loading()       method or, if using this serialiser as a mixin, define their own .setup_eager_loading()       method to lazy-load hashtags (otherwise, using this serialiser will result in expensive       queries!)
 * @export
 * @interface BusinessWithHashtagsAndLanguagesSerialiserRequest
 */
export interface BusinessWithHashtagsAndLanguagesSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiserRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiserRequest
   */
  business_code?: string;
  /**
   *
   * @type {BusinessTypeEnum}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiserRequest
   */
  business_type: BusinessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiserRequest
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiserRequest
   */
  short_description?: string;
  /**
   *
   * @type {number}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiserRequest
   */
  staff_discount?: number | null;
  /**
   *
   * @type {AreaSerialiserRequest}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiserRequest
   */
  area: AreaSerialiserRequest;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiserRequest
   */
  cover_image_link?: string;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiserRequest
   */
  country: { [key: string]: any };
  /**
   *
   * @type {PriceRangeEnum}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiserRequest
   */
  price_range: PriceRangeEnum;
  /**
   *
   * @type {ActivitySubtypeEnum}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiserRequest
   */
  activity_subtype: ActivitySubtypeEnum;
  /**
   *
   * @type {number}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiserRequest
   */
  cuisine?: number | null;
  /**
   *
   * @type {Array<string>}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiserRequest
   */
  hashtags: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BusinessWithHashtagsAndLanguagesSerialiserRequest
   */
  languages?: Array<string>;
}
/**
 * Serialises hashtags and languages in addition to the other fields.  Note: users of this serialiser must either call this classes .setup_eager_loading()       method or, if using this serialiser as a mixin, define their own .setup_eager_loading()       method to lazy-load hashtags (otherwise, using this serialiser will result in expensive       queries!)
 * @export
 * @interface BusinessWithHighlightsSerialiser
 */
export interface BusinessWithHighlightsSerialiser {
  /**
   *
   * @type {string}
   * @memberof BusinessWithHighlightsSerialiser
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHighlightsSerialiser
   */
  business_code?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHighlightsSerialiser
   */
  profile_url: string;
  /**
   *
   * @type {BusinessTypeEnum}
   * @memberof BusinessWithHighlightsSerialiser
   */
  business_type: BusinessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHighlightsSerialiser
   */
  formatted_address: string;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHighlightsSerialiser
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHighlightsSerialiser
   */
  short_description?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHighlightsSerialiser
   */
  city_and_country: string;
  /**
   *
   * @type {number}
   * @memberof BusinessWithHighlightsSerialiser
   */
  staff_discount?: number | null;
  /**
   *
   * @type {AreaSerialiser}
   * @memberof BusinessWithHighlightsSerialiser
   */
  area: AreaSerialiser;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHighlightsSerialiser
   */
  cover_image_link?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHighlightsSerialiser
   */
  cover_image_uuid: string;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof BusinessWithHighlightsSerialiser
   */
  country: { [key: string]: any };
  /**
   *
   * @type {PriceRangeEnum}
   * @memberof BusinessWithHighlightsSerialiser
   */
  price_range: PriceRangeEnum;
  /**
   *
   * @type {ActivitySubtypeEnum}
   * @memberof BusinessWithHighlightsSerialiser
   */
  activity_subtype: ActivitySubtypeEnum;
  /**
   *
   * @type {number}
   * @memberof BusinessWithHighlightsSerialiser
   */
  cuisine?: number | null;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHighlightsSerialiser
   */
  is_premium: string;
  /**
   *
   * @type {BonusCreditEnum | NullEnum}
   * @memberof BusinessWithHighlightsSerialiser
   */
  bonus_credit: BonusCreditEnum | NullEnum | null;
  /**
   *
   * @type {DiscountEnum | NullEnum}
   * @memberof BusinessWithHighlightsSerialiser
   */
  discount: DiscountEnum | NullEnum | null;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHighlightsSerialiser
   */
  third_party_discount_code: string;
  /**
   *
   * @type {boolean}
   * @memberof BusinessWithHighlightsSerialiser
   */
  prebooking_required: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof BusinessWithHighlightsSerialiser
   */
  hashtags: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BusinessWithHighlightsSerialiser
   */
  languages?: Array<string>;
  /**
   *
   * @type {Array<VisitedBusinessSerialiser>}
   * @memberof BusinessWithHighlightsSerialiser
   */
  visits: Array<VisitedBusinessSerialiser>;
  /**
   *
   * @type {string}
   * @memberof BusinessWithHighlightsSerialiser
   */
  wishlist_uuid: string;
  /**
   *
   * @type {boolean}
   * @memberof BusinessWithHighlightsSerialiser
   */
  has_recommendation: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum CategoryEnum {
  Large = "LARGE",
  Medium = "MEDIUM",
  Small = "SMALL",
}

/**
 *
 * @export
 * @interface ClaimedBusinessSerialiser
 */
export interface ClaimedBusinessSerialiser {
  /**
   *
   * @type {string}
   * @memberof ClaimedBusinessSerialiser
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ClaimedBusinessSerialiser
   */
  business_code?: string;
  /**
   *
   * @type {UserSerialiser}
   * @memberof ClaimedBusinessSerialiser
   */
  owner: UserSerialiser;
}
/**
 *
 * @export
 * @interface ClaimedBusinessSerialiserRequest
 */
export interface ClaimedBusinessSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof ClaimedBusinessSerialiserRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ClaimedBusinessSerialiserRequest
   */
  business_code?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum CountryEnum {
  Af = "AF",
  Ax = "AX",
  Al = "AL",
  Dz = "DZ",
  As = "AS",
  Ad = "AD",
  Ao = "AO",
  Ai = "AI",
  Aq = "AQ",
  Ag = "AG",
  Ar = "AR",
  Am = "AM",
  Aw = "AW",
  Au = "AU",
  At = "AT",
  Az = "AZ",
  Bh = "BH",
  Bd = "BD",
  Bb = "BB",
  By = "BY",
  Be = "BE",
  Bz = "BZ",
  Bj = "BJ",
  Bm = "BM",
  Bt = "BT",
  Bo = "BO",
  Bq = "BQ",
  Ba = "BA",
  Bw = "BW",
  Bv = "BV",
  Br = "BR",
  Io = "IO",
  Vg = "VG",
  Bn = "BN",
  Bg = "BG",
  Bf = "BF",
  Bi = "BI",
  Kh = "KH",
  Cm = "CM",
  Ca = "CA",
  Cv = "CV",
  Ky = "KY",
  Cf = "CF",
  Td = "TD",
  Cl = "CL",
  Cn = "CN",
  Cx = "CX",
  Cc = "CC",
  Co = "CO",
  Km = "KM",
  Cg = "CG",
  Ck = "CK",
  Cr = "CR",
  Hr = "HR",
  Cu = "CU",
  Cw = "CW",
  Cy = "CY",
  Cz = "CZ",
  Cd = "CD",
  Dk = "DK",
  Dj = "DJ",
  Dm = "DM",
  Do = "DO",
  Tl = "TL",
  Ec = "EC",
  Eg = "EG",
  Sv = "SV",
  Gq = "GQ",
  Er = "ER",
  Ee = "EE",
  Sz = "SZ",
  Et = "ET",
  Fk = "FK",
  Fo = "FO",
  Fj = "FJ",
  Fi = "FI",
  Fr = "FR",
  Gf = "GF",
  Pf = "PF",
  Tf = "TF",
  Ga = "GA",
  Ge = "GE",
  De = "DE",
  Gh = "GH",
  Gi = "GI",
  Gr = "GR",
  Gl = "GL",
  Gd = "GD",
  Gp = "GP",
  Gu = "GU",
  Gt = "GT",
  Gg = "GG",
  Gn = "GN",
  Gw = "GW",
  Gy = "GY",
  Ht = "HT",
  Hm = "HM",
  Hn = "HN",
  Hk = "HK",
  Hu = "HU",
  Is = "IS",
  In = "IN",
  Id = "ID",
  Ir = "IR",
  Iq = "IQ",
  Ie = "IE",
  Im = "IM",
  Il = "IL",
  It = "IT",
  Ci = "CI",
  Jm = "JM",
  Jp = "JP",
  Je = "JE",
  Jo = "JO",
  Kz = "KZ",
  Ke = "KE",
  Ki = "KI",
  Xk = "XK",
  Kw = "KW",
  Kg = "KG",
  La = "LA",
  Lv = "LV",
  Lb = "LB",
  Ls = "LS",
  Lr = "LR",
  Ly = "LY",
  Li = "LI",
  Lt = "LT",
  Lu = "LU",
  Mo = "MO",
  Mg = "MG",
  Mw = "MW",
  My = "MY",
  Mv = "MV",
  Ml = "ML",
  Mt = "MT",
  Mh = "MH",
  Mq = "MQ",
  Mr = "MR",
  Mu = "MU",
  Yt = "YT",
  Mx = "MX",
  Fm = "FM",
  Md = "MD",
  Mc = "MC",
  Mn = "MN",
  Me = "ME",
  Ms = "MS",
  Ma = "MA",
  Mz = "MZ",
  Mm = "MM",
  Na = "NA",
  Nr = "NR",
  Np = "NP",
  Nl = "NL",
  Nc = "NC",
  Nz = "NZ",
  Ni = "NI",
  Ne = "NE",
  Ng = "NG",
  Nu = "NU",
  Nf = "NF",
  Kp = "KP",
  Mk = "MK",
  Mp = "MP",
  No = "NO",
  Om = "OM",
  Pk = "PK",
  Pw = "PW",
  Ps = "PS",
  Pa = "PA",
  Pg = "PG",
  Py = "PY",
  Pe = "PE",
  Ph = "PH",
  Pn = "PN",
  Pl = "PL",
  Pt = "PT",
  Pr = "PR",
  Qa = "QA",
  Re = "RE",
  Ro = "RO",
  Ru = "RU",
  Rw = "RW",
  Bl = "BL",
  Sh = "SH",
  Mf = "MF",
  Pm = "PM",
  Ws = "WS",
  Sm = "SM",
  St = "ST",
  Sa = "SA",
  Sn = "SN",
  Rs = "RS",
  Sc = "SC",
  Sl = "SL",
  Sg = "SG",
  Sx = "SX",
  Sk = "SK",
  Si = "SI",
  Sb = "SB",
  So = "SO",
  Za = "ZA",
  Gs = "GS",
  Kr = "KR",
  Ss = "SS",
  Es = "ES",
  Lk = "LK",
  Kn = "KN",
  Lc = "LC",
  Vc = "VC",
  Sd = "SD",
  Sr = "SR",
  Sj = "SJ",
  Se = "SE",
  Ch = "CH",
  Sy = "SY",
  Tw = "TW",
  Tj = "TJ",
  Tz = "TZ",
  Th = "TH",
  Bs = "BS",
  Gm = "GM",
  Tb = "TB",
  Tg = "TG",
  Tk = "TK",
  To = "TO",
  Tt = "TT",
  Tn = "TN",
  Tr = "TR",
  Tm = "TM",
  Tc = "TC",
  Tv = "TV",
  Vi = "VI",
  Ug = "UG",
  Ua = "UA",
  Ae = "AE",
  Gb = "GB",
  Us = "US",
  Um = "UM",
  Uy = "UY",
  Uz = "UZ",
  Vu = "VU",
  Va = "VA",
  Ve = "VE",
  Vn = "VN",
  Wf = "WF",
  Eh = "EH",
  Ye = "YE",
  Zm = "ZM",
  Zw = "ZW",
}

/**
 *
 * @export
 * @interface CreateRecommendationSerialiser
 */
export interface CreateRecommendationSerialiser {
  /**
   *
   * @type {number}
   * @memberof CreateRecommendationSerialiser
   */
  pk: number;
  /**
   *
   * @type {string}
   * @memberof CreateRecommendationSerialiser
   */
  business?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRecommendationSerialiser
   */
  gplace_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRecommendationSerialiser
   */
  name?: string;
  /**
   *
   * @type {RecommendedBusinessSerialiser}
   * @memberof CreateRecommendationSerialiser
   */
  recommendee: RecommendedBusinessSerialiser;
  /**
   *
   * @type {RecommendationTypeEnum}
   * @memberof CreateRecommendationSerialiser
   */
  recommendation_type: RecommendationTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof CreateRecommendationSerialiser
   */
  is_favourite?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateRecommendationSerialiser
   */
  is_calibration?: boolean;
  /**
   *
   * @type {BarEstablishmentTypeEnum}
   * @memberof CreateRecommendationSerialiser
   */
  bar_establishment_type?: BarEstablishmentTypeEnum;
  /**
   *
   * @type {PriceEnum}
   * @memberof CreateRecommendationSerialiser
   */
  price?: PriceEnum;
  /**
   *
   * @type {string}
   * @memberof CreateRecommendationSerialiser
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRecommendationSerialiser
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRecommendationSerialiser
   */
  referrer_message?: string;
  /**
   *
   * @type {RelationshipEnum}
   * @memberof CreateRecommendationSerialiser
   */
  relationship?: RelationshipEnum;
}
/**
 *
 * @export
 * @interface CreateRecommendationSerialiserRequest
 */
export interface CreateRecommendationSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof CreateRecommendationSerialiserRequest
   */
  recommender: string;
  /**
   *
   * @type {string}
   * @memberof CreateRecommendationSerialiserRequest
   */
  business?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRecommendationSerialiserRequest
   */
  gplace_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRecommendationSerialiserRequest
   */
  name?: string;
  /**
   *
   * @type {RecommendationTypeEnum}
   * @memberof CreateRecommendationSerialiserRequest
   */
  recommendation_type: RecommendationTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof CreateRecommendationSerialiserRequest
   */
  is_favourite?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateRecommendationSerialiserRequest
   */
  is_calibration?: boolean;
  /**
   *
   * @type {BarEstablishmentTypeEnum}
   * @memberof CreateRecommendationSerialiserRequest
   */
  bar_establishment_type?: BarEstablishmentTypeEnum;
  /**
   *
   * @type {PriceEnum}
   * @memberof CreateRecommendationSerialiserRequest
   */
  price?: PriceEnum;
  /**
   *
   * @type {string}
   * @memberof CreateRecommendationSerialiserRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRecommendationSerialiserRequest
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRecommendationSerialiserRequest
   */
  referrer_message?: string;
  /**
   *
   * @type {RelationshipEnum}
   * @memberof CreateRecommendationSerialiserRequest
   */
  relationship?: RelationshipEnum;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum DietaryCategoryEnum {
  Nothing = "nothing",
  VegetarianFriendly = "vegetarian_friendly",
  VegetarianOnly = "vegetarian_only",
  VeganFriendly = "vegan_friendly",
  VeganOnly = "vegan_only",
  Halal = "halal",
  Kosher = "kosher",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum DiscountEnum {
  NUMBER_0 = 0,
  NUMBER_5 = 5,
  NUMBER_10 = 10,
  NUMBER_15 = 15,
  NUMBER_20 = 20,
  NUMBER_25 = 25,
}

/**
 *
 * @export
 * @interface FollowerRelationshipReadSerialiser
 */
export interface FollowerRelationshipReadSerialiser {
  /**
   *
   * @type {StatusEnum}
   * @memberof FollowerRelationshipReadSerialiser
   */
  status?: StatusEnum;
  /**
   *
   * @type {TravellerSerialiser}
   * @memberof FollowerRelationshipReadSerialiser
   */
  from_traveller: TravellerSerialiser;
  /**
   *
   * @type {TravellerSerialiser}
   * @memberof FollowerRelationshipReadSerialiser
   */
  to_traveller: TravellerSerialiser;
  /**
   *
   * @type {string}
   * @memberof FollowerRelationshipReadSerialiser
   */
  uuid: string;
}
/**
 *
 * @export
 * @interface FollowerRelationshipWriteSerialiser
 */
export interface FollowerRelationshipWriteSerialiser {
  /**
   *
   * @type {StatusEnum}
   * @memberof FollowerRelationshipWriteSerialiser
   */
  status?: StatusEnum;
  /**
   *
   * @type {string}
   * @memberof FollowerRelationshipWriteSerialiser
   */
  from_traveller: string;
  /**
   *
   * @type {string}
   * @memberof FollowerRelationshipWriteSerialiser
   */
  to_traveller: string;
  /**
   *
   * @type {string}
   * @memberof FollowerRelationshipWriteSerialiser
   */
  uuid: string;
}
/**
 *
 * @export
 * @interface FollowerRelationshipWriteSerialiserRequest
 */
export interface FollowerRelationshipWriteSerialiserRequest {
  /**
   *
   * @type {StatusEnum}
   * @memberof FollowerRelationshipWriteSerialiserRequest
   */
  status?: StatusEnum;
  /**
   *
   * @type {string}
   * @memberof FollowerRelationshipWriteSerialiserRequest
   */
  from_traveller: string;
  /**
   *
   * @type {string}
   * @memberof FollowerRelationshipWriteSerialiserRequest
   */
  to_traveller: string;
}
/**
 * Includes the information found on business profile pages (including hashtags).
 * @export
 * @interface FullBusinessSerialiser
 */
export interface FullBusinessSerialiser {
  /**
   *
   * @type {string}
   * @memberof FullBusinessSerialiser
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FullBusinessSerialiser
   */
  business_code?: string;
  /**
   *
   * @type {string}
   * @memberof FullBusinessSerialiser
   */
  profile_url: string;
  /**
   *
   * @type {BusinessTypeEnum}
   * @memberof FullBusinessSerialiser
   */
  business_type: BusinessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof FullBusinessSerialiser
   */
  formatted_address: string;
  /**
   *
   * @type {string}
   * @memberof FullBusinessSerialiser
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof FullBusinessSerialiser
   */
  short_description?: string;
  /**
   *
   * @type {string}
   * @memberof FullBusinessSerialiser
   */
  city_and_country: string;
  /**
   *
   * @type {number}
   * @memberof FullBusinessSerialiser
   */
  staff_discount?: number | null;
  /**
   *
   * @type {AreaSerialiser}
   * @memberof FullBusinessSerialiser
   */
  area: AreaSerialiser;
  /**
   *
   * @type {string}
   * @memberof FullBusinessSerialiser
   */
  cover_image_link?: string;
  /**
   *
   * @type {string}
   * @memberof FullBusinessSerialiser
   */
  cover_image_uuid: string;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof FullBusinessSerialiser
   */
  country: { [key: string]: any };
  /**
   *
   * @type {PriceRangeEnum}
   * @memberof FullBusinessSerialiser
   */
  price_range: PriceRangeEnum;
  /**
   *
   * @type {ActivitySubtypeEnum}
   * @memberof FullBusinessSerialiser
   */
  activity_subtype: ActivitySubtypeEnum;
  /**
   *
   * @type {string}
   * @memberof FullBusinessSerialiser
   */
  cuisine: string;
  /**
   *
   * @type {string}
   * @memberof FullBusinessSerialiser
   */
  is_premium: string;
  /**
   *
   * @type {BonusCreditEnum | NullEnum}
   * @memberof FullBusinessSerialiser
   */
  bonus_credit: BonusCreditEnum | NullEnum | null;
  /**
   *
   * @type {DiscountEnum | NullEnum}
   * @memberof FullBusinessSerialiser
   */
  discount: DiscountEnum | NullEnum | null;
  /**
   *
   * @type {string}
   * @memberof FullBusinessSerialiser
   */
  third_party_discount_code: string;
  /**
   *
   * @type {boolean}
   * @memberof FullBusinessSerialiser
   */
  prebooking_required: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof FullBusinessSerialiser
   */
  hashtags: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof FullBusinessSerialiser
   */
  languages?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof FullBusinessSerialiser
   */
  additional_services: Array<string>;
  /**
   *
   * @type {string}
   * @memberof FullBusinessSerialiser
   */
  opening_time?: string | null;
  /**
   *
   * @type {string}
   * @memberof FullBusinessSerialiser
   */
  closing_time?: string | null;
  /**
   *
   * @type {number}
   * @memberof FullBusinessSerialiser
   */
  number_of_rooms?: number | null;
  /**
   *
   * @type {LgbtqTypeEnum | BlankEnum | NullEnum}
   * @memberof FullBusinessSerialiser
   */
  lgbtq_type?: LgbtqTypeEnum | BlankEnum | NullEnum | null;
  /**
   *
   * @type {boolean}
   * @memberof FullBusinessSerialiser
   */
  is_shell: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FullBusinessSerialiser
   */
  area_expert?: boolean;
  /**
   *
   * @type {DietaryCategoryEnum | BlankEnum | NullEnum}
   * @memberof FullBusinessSerialiser
   */
  dietary_category?: DietaryCategoryEnum | BlankEnum | NullEnum | null;
  /**
   *
   * @type {string}
   * @memberof FullBusinessSerialiser
   */
  restaurant_style: string;
  /**
   *
   * @type {string}
   * @memberof FullBusinessSerialiser
   */
  book_now_url?: string;
}
/**
 *
 * @export
 * @interface GPlaceSerialiser
 */
export interface GPlaceSerialiser {
  /**
   *
   * @type {string}
   * @memberof GPlaceSerialiser
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GPlaceSerialiser
   */
  formatted_address: string;
  /**
   *
   * @type {string}
   * @memberof GPlaceSerialiser
   */
  place_id: string;
}
/**
 * A subclass of ModelSerializer that outputs geojson-ready data as features and feature collections
 * @export
 * @interface GeoJsonLocalServiceSerialiser
 */
export interface GeoJsonLocalServiceSerialiser {
  /**
   *
   * @type {number}
   * @memberof GeoJsonLocalServiceSerialiser
   */
  pk: number;
  /**
   *
   * @type {string}
   * @memberof GeoJsonLocalServiceSerialiser
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof GeoJsonLocalServiceSerialiser
   */
  service_type?: string;
  /**
   *
   * @type {string}
   * @memberof GeoJsonLocalServiceSerialiser
   */
  travel_description?: string;
  /**
   *
   * @type {string}
   * @memberof GeoJsonLocalServiceSerialiser
   */
  long_lat?: string | null;
}
/**
 * A subclass of ModelSerializer that outputs geojson-ready data as features and feature collections
 * @export
 * @interface GeoJsonRecommendationSerialiser
 */
export interface GeoJsonRecommendationSerialiser {
  /**
   *
   * @type {number}
   * @memberof GeoJsonRecommendationSerialiser
   */
  recommendee: number;
  /**
   *
   * @type {string}
   * @memberof GeoJsonRecommendationSerialiser
   */
  text?: string;
  /**
   *
   * @type {RecommendationTypeEnum}
   * @memberof GeoJsonRecommendationSerialiser
   */
  recommendation_type: RecommendationTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof GeoJsonRecommendationSerialiser
   */
  is_favourite?: boolean;
  /**
   *
   * @type {PriceEnum | BlankEnum | NullEnum}
   * @memberof GeoJsonRecommendationSerialiser
   */
  price?: PriceEnum | BlankEnum | NullEnum | null;
  /**
   *
   * @type {BarEstablishmentTypeEnum | BlankEnum | NullEnum}
   * @memberof GeoJsonRecommendationSerialiser
   */
  bar_establishment_type?: BarEstablishmentTypeEnum | BlankEnum | NullEnum | null;
  /**
   *
   * @type {string}
   * @memberof GeoJsonRecommendationSerialiser
   */
  recommendee_lon_lat: string;
}
/**
 * Verify a captcha token.  If used with a ModelSerializer \'token\' must be specified in the list of fields.  Note that we do captcha validation in the class-level .validate() method, as we want to ensure that this method runs *after* the field-level validation methods have been run: if a field-level validation method fails, we should return early and allow the user to retry with the same (unverified) captcha.
 * @export
 * @interface GuestQuestionSerialiser
 */
export interface GuestQuestionSerialiser {
  /**
   *
   * @type {string}
   * @memberof GuestQuestionSerialiser
   */
  business_profile: string;
  /**
   *
   * @type {string}
   * @memberof GuestQuestionSerialiser
   */
  guest_name: string;
  /**
   *
   * @type {string}
   * @memberof GuestQuestionSerialiser
   */
  guest_email: string;
  /**
   *
   * @type {string}
   * @memberof GuestQuestionSerialiser
   */
  guest_question: string;
}
/**
 * Verify a captcha token.  If used with a ModelSerializer \'token\' must be specified in the list of fields.  Note that we do captcha validation in the class-level .validate() method, as we want to ensure that this method runs *after* the field-level validation methods have been run: if a field-level validation method fails, we should return early and allow the user to retry with the same (unverified) captcha.
 * @export
 * @interface GuestQuestionSerialiserRequest
 */
export interface GuestQuestionSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof GuestQuestionSerialiserRequest
   */
  business_profile: string;
  /**
   *
   * @type {string}
   * @memberof GuestQuestionSerialiserRequest
   */
  guest_name: string;
  /**
   *
   * @type {string}
   * @memberof GuestQuestionSerialiserRequest
   */
  guest_email: string;
  /**
   *
   * @type {string}
   * @memberof GuestQuestionSerialiserRequest
   */
  guest_question: string;
  /**
   *
   * @type {string}
   * @memberof GuestQuestionSerialiserRequest
   */
  token: string;
}
/**
 *
 * @export
 * @interface HashtagSerialiser
 */
export interface HashtagSerialiser {
  /**
   *
   * @type {string}
   * @memberof HashtagSerialiser
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof HashtagSerialiser
   */
  emoji: string;
  /**
   *
   * @type {boolean}
   * @memberof HashtagSerialiser
   */
  selected: boolean;
}
/**
 *
 * @export
 * @interface HashtagSerialiserRequest
 */
export interface HashtagSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof HashtagSerialiserRequest
   */
  name: string;
}
/**
 *
 * @export
 * @interface ItemBookingSerialiser
 */
export interface ItemBookingSerialiser {
  /**
   *
   * @type {number}
   * @memberof ItemBookingSerialiser
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ItemBookingSerialiser
   */
  business_profile: string;
  /**
   *
   * @type {ItemTypeEnum}
   * @memberof ItemBookingSerialiser
   */
  item_type: ItemTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ItemBookingSerialiser
   */
  created: string;
  /**
   *
   * @type {string}
   * @memberof ItemBookingSerialiser
   */
  modified: string;
  /**
   *
   * @type {string}
   * @memberof ItemBookingSerialiser
   */
  bookable_name?: string;
  /**
   *
   * @type {string}
   * @memberof ItemBookingSerialiser
   */
  guest_name: string;
  /**
   *
   * @type {string}
   * @memberof ItemBookingSerialiser
   */
  guest_email: string;
  /**
   *
   * @type {string}
   * @memberof ItemBookingSerialiser
   */
  from_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof ItemBookingSerialiser
   */
  to_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof ItemBookingSerialiser
   */
  guest_message?: string;
  /**
   *
   * @type {number}
   * @memberof ItemBookingSerialiser
   */
  activity?: number | null;
  /**
   *
   * @type {number}
   * @memberof ItemBookingSerialiser
   */
  room?: number | null;
  /**
   *
   * @type {number}
   * @memberof ItemBookingSerialiser
   */
  coupon?: number | null;
}
/**
 *
 * @export
 * @interface ItemBookingSerialiserRequest
 */
export interface ItemBookingSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof ItemBookingSerialiserRequest
   */
  business_profile: string;
  /**
   *
   * @type {ItemTypeEnum}
   * @memberof ItemBookingSerialiserRequest
   */
  item_type: ItemTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ItemBookingSerialiserRequest
   */
  bookable_name?: string;
  /**
   *
   * @type {string}
   * @memberof ItemBookingSerialiserRequest
   */
  guest_name: string;
  /**
   *
   * @type {string}
   * @memberof ItemBookingSerialiserRequest
   */
  guest_email: string;
  /**
   *
   * @type {string}
   * @memberof ItemBookingSerialiserRequest
   */
  from_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof ItemBookingSerialiserRequest
   */
  to_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof ItemBookingSerialiserRequest
   */
  guest_message?: string;
  /**
   *
   * @type {number}
   * @memberof ItemBookingSerialiserRequest
   */
  activity?: number | null;
  /**
   *
   * @type {number}
   * @memberof ItemBookingSerialiserRequest
   */
  room?: number | null;
  /**
   *
   * @type {number}
   * @memberof ItemBookingSerialiserRequest
   */
  coupon?: number | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum ItemTypeEnum {
  Activity = "activity",
  Room = "room",
  Dish = "dish",
  Product = "product",
  Supplier = "supplier",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum LgbtqTypeEnum {
  Nothing = "nothing",
  LgbtqFriendly = "lgbtq_friendly",
  LgbtqFocus = "lgbtq_focus",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum NullEnum {
  Null = "null",
}

/**
 *
 * @export
 * @interface PaginatedAreaSerialiserList
 */
export interface PaginatedAreaSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedAreaSerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedAreaSerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedAreaSerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<AreaSerialiser>}
   * @memberof PaginatedAreaSerialiserList
   */
  results?: Array<AreaSerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedBusinessCouponReadSerialiserList
 */
export interface PaginatedBusinessCouponReadSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedBusinessCouponReadSerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedBusinessCouponReadSerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedBusinessCouponReadSerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<BusinessCouponReadSerialiser>}
   * @memberof PaginatedBusinessCouponReadSerialiserList
   */
  results?: Array<BusinessCouponReadSerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedBusinessSearchResultSerialiserList
 */
export interface PaginatedBusinessSearchResultSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedBusinessSearchResultSerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedBusinessSearchResultSerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedBusinessSearchResultSerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<BusinessSearchResultSerialiser>}
   * @memberof PaginatedBusinessSearchResultSerialiserList
   */
  results?: Array<BusinessSearchResultSerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedBusinessUpdateSerialiserList
 */
export interface PaginatedBusinessUpdateSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedBusinessUpdateSerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedBusinessUpdateSerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedBusinessUpdateSerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<BusinessUpdateSerialiser>}
   * @memberof PaginatedBusinessUpdateSerialiserList
   */
  results?: Array<BusinessUpdateSerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedBusinessWithHighlightsSerialiserList
 */
export interface PaginatedBusinessWithHighlightsSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedBusinessWithHighlightsSerialiserList
   */
  count?: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedBusinessWithHighlightsSerialiserList
   */
  next?: number | null;
  /**
   *
   * @type {number}
   * @memberof PaginatedBusinessWithHighlightsSerialiserList
   */
  previous?: number | null;
  /**
   *
   * @type {Array<BusinessWithHighlightsSerialiser>}
   * @memberof PaginatedBusinessWithHighlightsSerialiserList
   */
  results?: Array<BusinessWithHighlightsSerialiser>;
  /**
   *
   * @type {Array<PaginatedBusinessWithHighlightsSerialiserListHashtags>}
   * @memberof PaginatedBusinessWithHighlightsSerialiserList
   */
  hashtags?: Array<PaginatedBusinessWithHighlightsSerialiserListHashtags>;
  /**
   *
   * @type {Array<PaginatedBusinessWithHighlightsSerialiserListHashtags>}
   * @memberof PaginatedBusinessWithHighlightsSerialiserList
   */
  cuisines?: Array<PaginatedBusinessWithHighlightsSerialiserListHashtags>;
  /**
   *
   * @type {Array<PaginatedBusinessWithHighlightsSerialiserListHashtags>}
   * @memberof PaginatedBusinessWithHighlightsSerialiserList
   */
  activities?: Array<PaginatedBusinessWithHighlightsSerialiserListHashtags>;
}
/**
 *
 * @export
 * @interface PaginatedBusinessWithHighlightsSerialiserListHashtags
 */
export interface PaginatedBusinessWithHighlightsSerialiserListHashtags {
  /**
   *
   * @type {string}
   * @memberof PaginatedBusinessWithHighlightsSerialiserListHashtags
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof PaginatedBusinessWithHighlightsSerialiserListHashtags
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedBusinessWithHighlightsSerialiserListHashtags
   */
  slug?: string;
}
/**
 *
 * @export
 * @interface PaginatedFollowerRelationshipReadSerialiserList
 */
export interface PaginatedFollowerRelationshipReadSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedFollowerRelationshipReadSerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedFollowerRelationshipReadSerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedFollowerRelationshipReadSerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<FollowerRelationshipReadSerialiser>}
   * @memberof PaginatedFollowerRelationshipReadSerialiserList
   */
  results?: Array<FollowerRelationshipReadSerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedGPlaceSerialiserList
 */
export interface PaginatedGPlaceSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedGPlaceSerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedGPlaceSerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedGPlaceSerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<GPlaceSerialiser>}
   * @memberof PaginatedGPlaceSerialiserList
   */
  results?: Array<GPlaceSerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedGeoJsonLocalServiceSerialiserList
 */
export interface PaginatedGeoJsonLocalServiceSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedGeoJsonLocalServiceSerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedGeoJsonLocalServiceSerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedGeoJsonLocalServiceSerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<GeoJsonLocalServiceSerialiser>}
   * @memberof PaginatedGeoJsonLocalServiceSerialiserList
   */
  results?: Array<GeoJsonLocalServiceSerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedGeoJsonRecommendationSerialiserList
 */
export interface PaginatedGeoJsonRecommendationSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedGeoJsonRecommendationSerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedGeoJsonRecommendationSerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedGeoJsonRecommendationSerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<GeoJsonRecommendationSerialiser>}
   * @memberof PaginatedGeoJsonRecommendationSerialiserList
   */
  results?: Array<GeoJsonRecommendationSerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedHashtagSerialiserList
 */
export interface PaginatedHashtagSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedHashtagSerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedHashtagSerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedHashtagSerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<HashtagSerialiser>}
   * @memberof PaginatedHashtagSerialiserList
   */
  results?: Array<HashtagSerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedRecommendationSerialiserList
 */
export interface PaginatedRecommendationSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedRecommendationSerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedRecommendationSerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedRecommendationSerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<RecommendationSerialiser>}
   * @memberof PaginatedRecommendationSerialiserList
   */
  results?: Array<RecommendationSerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedReferralSerialiserList
 */
export interface PaginatedReferralSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedReferralSerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedReferralSerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedReferralSerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<ReferralSerialiser>}
   * @memberof PaginatedReferralSerialiserList
   */
  results?: Array<ReferralSerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedTeamMemberSerialiserList
 */
export interface PaginatedTeamMemberSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedTeamMemberSerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedTeamMemberSerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedTeamMemberSerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<TeamMemberSerialiser>}
   * @memberof PaginatedTeamMemberSerialiserList
   */
  results?: Array<TeamMemberSerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedTravellerActivitySerialiserList
 */
export interface PaginatedTravellerActivitySerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedTravellerActivitySerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedTravellerActivitySerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedTravellerActivitySerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<TravellerActivitySerialiser>}
   * @memberof PaginatedTravellerActivitySerialiserList
   */
  results?: Array<TravellerActivitySerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedTravellerAreaSerialiserList
 */
export interface PaginatedTravellerAreaSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedTravellerAreaSerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedTravellerAreaSerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedTravellerAreaSerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<TravellerAreaSerialiser>}
   * @memberof PaginatedTravellerAreaSerialiserList
   */
  results?: Array<TravellerAreaSerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedTravellerSerialiserList
 */
export interface PaginatedTravellerSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedTravellerSerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedTravellerSerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedTravellerSerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<TravellerSerialiser>}
   * @memberof PaginatedTravellerSerialiserList
   */
  results?: Array<TravellerSerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedVisitedActivitySerialiserList
 */
export interface PaginatedVisitedActivitySerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedVisitedActivitySerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedVisitedActivitySerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedVisitedActivitySerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<VisitedActivitySerialiser>}
   * @memberof PaginatedVisitedActivitySerialiserList
   */
  results?: Array<VisitedActivitySerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedVisitedAreaSerialiserList
 */
export interface PaginatedVisitedAreaSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedVisitedAreaSerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedVisitedAreaSerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedVisitedAreaSerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<VisitedAreaSerialiser>}
   * @memberof PaginatedVisitedAreaSerialiserList
   */
  results?: Array<VisitedAreaSerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedVisitedBusinessWithProfileSerialiserList
 */
export interface PaginatedVisitedBusinessWithProfileSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedVisitedBusinessWithProfileSerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedVisitedBusinessWithProfileSerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedVisitedBusinessWithProfileSerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<VisitedBusinessWithProfileSerialiser>}
   * @memberof PaginatedVisitedBusinessWithProfileSerialiserList
   */
  results?: Array<VisitedBusinessWithProfileSerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedVisitedCountrySerialiserList
 */
export interface PaginatedVisitedCountrySerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedVisitedCountrySerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedVisitedCountrySerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedVisitedCountrySerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<VisitedCountrySerialiser>}
   * @memberof PaginatedVisitedCountrySerialiserList
   */
  results?: Array<VisitedCountrySerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedWishlistBusinessReadSerialiserList
 */
export interface PaginatedWishlistBusinessReadSerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedWishlistBusinessReadSerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedWishlistBusinessReadSerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedWishlistBusinessReadSerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<WishlistBusinessReadSerialiser>}
   * @memberof PaginatedWishlistBusinessReadSerialiserList
   */
  results?: Array<WishlistBusinessReadSerialiser>;
}
/**
 *
 * @export
 * @interface PaginatedWishlistCountrySerialiserList
 */
export interface PaginatedWishlistCountrySerialiserList {
  /**
   *
   * @type {number}
   * @memberof PaginatedWishlistCountrySerialiserList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedWishlistCountrySerialiserList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedWishlistCountrySerialiserList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<WishlistCountrySerialiser>}
   * @memberof PaginatedWishlistCountrySerialiserList
   */
  results?: Array<WishlistCountrySerialiser>;
}
/**
 *
 * @export
 * @interface PatchedBusinessCouponReadSerialiserRequest
 */
export interface PatchedBusinessCouponReadSerialiserRequest {
  /**
   *
   * @type {TravellerSerialiserRequest}
   * @memberof PatchedBusinessCouponReadSerialiserRequest
   */
  traveller?: TravellerSerialiserRequest;
  /**
   *
   * @type {TravellerSerialiserRequest}
   * @memberof PatchedBusinessCouponReadSerialiserRequest
   */
  recommender?: TravellerSerialiserRequest;
  /**
   *
   * @type {BusinessSerialiserRequest}
   * @memberof PatchedBusinessCouponReadSerialiserRequest
   */
  business?: BusinessSerialiserRequest;
  /**
   *
   * @type {string}
   * @memberof PatchedBusinessCouponReadSerialiserRequest
   */
  business_email?: string;
  /**
   *
   * @type {BookingEnquirySerialiserRequest}
   * @memberof PatchedBusinessCouponReadSerialiserRequest
   */
  booking_enquiry?: BookingEnquirySerialiserRequest;
  /**
   *
   * @type {string}
   * @memberof PatchedBusinessCouponReadSerialiserRequest
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof PatchedBusinessCouponReadSerialiserRequest
   */
  discount?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedBusinessCouponReadSerialiserRequest
   */
  bonus_credit?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedBusinessCouponReadSerialiserRequest
   */
  expires_at?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedBusinessCouponReadSerialiserRequest
   */
  cancelled_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedBusinessCouponReadSerialiserRequest
   */
  redeemed_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedBusinessCouponReadSerialiserRequest
   */
  bonus_credited_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedBusinessCouponReadSerialiserRequest
   */
  redemption_start_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedBusinessCouponReadSerialiserRequest
   */
  redemption_end_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedBusinessCouponReadSerialiserRequest
   */
  traveller_confirmed_at?: string | null;
}
/**
 *
 * @export
 * @interface PatchedCreateRecommendationSerialiserRequest
 */
export interface PatchedCreateRecommendationSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof PatchedCreateRecommendationSerialiserRequest
   */
  recommender?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedCreateRecommendationSerialiserRequest
   */
  business?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedCreateRecommendationSerialiserRequest
   */
  gplace_id?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedCreateRecommendationSerialiserRequest
   */
  name?: string;
  /**
   *
   * @type {RecommendationTypeEnum}
   * @memberof PatchedCreateRecommendationSerialiserRequest
   */
  recommendation_type?: RecommendationTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof PatchedCreateRecommendationSerialiserRequest
   */
  is_favourite?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedCreateRecommendationSerialiserRequest
   */
  is_calibration?: boolean;
  /**
   *
   * @type {BarEstablishmentTypeEnum}
   * @memberof PatchedCreateRecommendationSerialiserRequest
   */
  bar_establishment_type?: BarEstablishmentTypeEnum;
  /**
   *
   * @type {PriceEnum}
   * @memberof PatchedCreateRecommendationSerialiserRequest
   */
  price?: PriceEnum;
  /**
   *
   * @type {string}
   * @memberof PatchedCreateRecommendationSerialiserRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedCreateRecommendationSerialiserRequest
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedCreateRecommendationSerialiserRequest
   */
  referrer_message?: string;
  /**
   *
   * @type {RelationshipEnum}
   * @memberof PatchedCreateRecommendationSerialiserRequest
   */
  relationship?: RelationshipEnum;
}
/**
 *
 * @export
 * @interface PatchedFollowerRelationshipWriteSerialiserRequest
 */
export interface PatchedFollowerRelationshipWriteSerialiserRequest {
  /**
   *
   * @type {StatusEnum}
   * @memberof PatchedFollowerRelationshipWriteSerialiserRequest
   */
  status?: StatusEnum;
  /**
   *
   * @type {string}
   * @memberof PatchedFollowerRelationshipWriteSerialiserRequest
   */
  from_traveller?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedFollowerRelationshipWriteSerialiserRequest
   */
  to_traveller?: string;
}
/**
 *
 * @export
 * @interface PatchedTeamMemberSerialiserRequest
 */
export interface PatchedTeamMemberSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof PatchedTeamMemberSerialiserRequest
   */
  business?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedTeamMemberSerialiserRequest
   */
  email_address?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedTeamMemberSerialiserRequest
   */
  full_name?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedTeamMemberSerialiserRequest
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedTeamMemberSerialiserRequest
   */
  image_link?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedTeamMemberSerialiserRequest
   */
  position?: string;
}
/**
 *
 * @export
 * @interface PatchedTravellerUpdateSerialiserRequest
 */
export interface PatchedTravellerUpdateSerialiserRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof PatchedTravellerUpdateSerialiserRequest
   */
  hashtags?: Array<string>;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof PatchedTravellerUpdateSerialiserRequest
   */
  home_country?: { [key: string]: any };
}
/**
 *
 * @export
 * @interface PatchedVisitedActivitySerialiserRequest
 */
export interface PatchedVisitedActivitySerialiserRequest {
  /**
   *
   * @type {ActivityEnum}
   * @memberof PatchedVisitedActivitySerialiserRequest
   */
  activity?: ActivityEnum;
  /**
   *
   * @type {AreaSerialiserRequest}
   * @memberof PatchedVisitedActivitySerialiserRequest
   */
  area?: AreaSerialiserRequest;
}
/**
 *
 * @export
 * @interface PatchedVisitedAreaSerialiserRequest
 */
export interface PatchedVisitedAreaSerialiserRequest {
  /**
   *
   * @type {AreaSerialiserRequest}
   * @memberof PatchedVisitedAreaSerialiserRequest
   */
  area?: AreaSerialiserRequest;
  /**
   *
   * @type {boolean}
   * @memberof PatchedVisitedAreaSerialiserRequest
   */
  lived?: boolean;
}
/**
 *
 * @export
 * @interface PatchedVisitedBusinessWithProfileSerialiserRequest
 */
export interface PatchedVisitedBusinessWithProfileSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof PatchedVisitedBusinessWithProfileSerialiserRequest
   */
  comment?: string;
  /**
   *
   * @type {BusinessTypeEnum}
   * @memberof PatchedVisitedBusinessWithProfileSerialiserRequest
   */
  business_type?: BusinessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PatchedVisitedBusinessWithProfileSerialiserRequest
   */
  business_code?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedVisitedBusinessWithProfileSerialiserRequest
   */
  gplace_id?: string;
}
/**
 *
 * @export
 * @interface PatchedVisitedCountrySerialiserRequest
 */
export interface PatchedVisitedCountrySerialiserRequest {
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof PatchedVisitedCountrySerialiserRequest
   */
  country?: { [key: string]: any };
}
/**
 *
 * @export
 * @interface PatchedWishlistBusinessWriteSerialiserRequest
 */
export interface PatchedWishlistBusinessWriteSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof PatchedWishlistBusinessWriteSerialiserRequest
   */
  business?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedWishlistBusinessWriteSerialiserRequest
   */
  uuid?: string;
}
/**
 *
 * @export
 * @interface PatchedWishlistCountrySerialiserRequest
 */
export interface PatchedWishlistCountrySerialiserRequest {
  /**
   *
   * @type {CountryEnum | BlankEnum}
   * @memberof PatchedWishlistCountrySerialiserRequest
   */
  country?: CountryEnum | BlankEnum;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PriceEnum {
  Cheap = "cheap",
  Average = "average",
  Expensive = "expensive",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum PriceRangeEnum {
  Low = "low",
  Medium = "medium",
  High = "high",
  Expensive = "expensive",
  Extravagant = "extravagant",
  LowToMedium = "low_to_medium",
  MediumToHigh = "medium_to_high",
  HighToExpensive = "high_to_expensive",
  ExpensiveToExtravagant = "expensive_to_extravagant",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum PrivacyEnum {
  Private = "private",
  Public = "public",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum RatingEnum {
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3,
}

/**
 *
 * @export
 * @interface RecommendationSerialiser
 */
export interface RecommendationSerialiser {
  /**
   *
   * @type {number}
   * @memberof RecommendationSerialiser
   */
  pk: number;
  /**
   *
   * @type {RecommendedBusinessSerialiser}
   * @memberof RecommendationSerialiser
   */
  recommendee: RecommendedBusinessSerialiser;
  /**
   *
   * @type {string}
   * @memberof RecommendationSerialiser
   */
  text?: string;
  /**
   *
   * @type {RecommendationTypeEnum}
   * @memberof RecommendationSerialiser
   */
  recommendation_type: RecommendationTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof RecommendationSerialiser
   */
  is_favourite?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RecommendationSerialiser
   */
  is_calibration?: boolean;
  /**
   *
   * @type {PriceEnum | BlankEnum | NullEnum}
   * @memberof RecommendationSerialiser
   */
  price?: PriceEnum | BlankEnum | NullEnum | null;
  /**
   *
   * @type {BarEstablishmentTypeEnum | BlankEnum | NullEnum}
   * @memberof RecommendationSerialiser
   */
  bar_establishment_type?: BarEstablishmentTypeEnum | BlankEnum | NullEnum | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum RecommendationTypeEnum {
  Accommodation = "accommodation",
  Activity = "activity",
  Sight = "sight",
  Restaurant = "restaurant",
  Bar = "bar",
  Cafe = "cafe",
}

/**
 *
 * @export
 * @interface RecommendedBusinessSerialiser
 */
export interface RecommendedBusinessSerialiser {
  /**
   *
   * @type {string}
   * @memberof RecommendedBusinessSerialiser
   */
  business_code?: string;
  /**
   *
   * @type {string}
   * @memberof RecommendedBusinessSerialiser
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof RecommendedBusinessSerialiser
   */
  hashtags: Array<string>;
  /**
   *
   * @type {string}
   * @memberof RecommendedBusinessSerialiser
   */
  cover_image_link?: string;
  /**
   *
   * @type {string}
   * @memberof RecommendedBusinessSerialiser
   */
  city_and_country: string;
  /**
   *
   * @type {string}
   * @memberof RecommendedBusinessSerialiser
   */
  gplace_id?: string;
}
/**
 *
 * @export
 * @interface RecommendedBusinessSerialiserRequest
 */
export interface RecommendedBusinessSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof RecommendedBusinessSerialiserRequest
   */
  business_code?: string;
  /**
   *
   * @type {string}
   * @memberof RecommendedBusinessSerialiserRequest
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof RecommendedBusinessSerialiserRequest
   */
  hashtags: Array<string>;
  /**
   *
   * @type {string}
   * @memberof RecommendedBusinessSerialiserRequest
   */
  cover_image_link?: string;
  /**
   *
   * @type {string}
   * @memberof RecommendedBusinessSerialiserRequest
   */
  gplace_id?: string;
}
/**
 *
 * @export
 * @interface ReferralSerialiser
 */
export interface ReferralSerialiser {
  /**
   *
   * @type {BusinessSerialiser}
   * @memberof ReferralSerialiser
   */
  referee: BusinessSerialiser;
  /**
   *
   * @type {ClaimedBusinessSerialiser}
   * @memberof ReferralSerialiser
   */
  referrer: ClaimedBusinessSerialiser;
  /**
   *
   * @type {string}
   * @memberof ReferralSerialiser
   */
  referral_url: string;
  /**
   *
   * @type {string}
   * @memberof ReferralSerialiser
   */
  referral_absolute_url: string;
  /**
   *
   * @type {string}
   * @memberof ReferralSerialiser
   */
  redeemed_at: string;
  /**
   *
   * @type {RelationshipEnum | BlankEnum | NullEnum}
   * @memberof ReferralSerialiser
   */
  relationship?: RelationshipEnum | BlankEnum | NullEnum | null;
  /**
   *
   * @type {string}
   * @memberof ReferralSerialiser
   */
  referrer_message?: string;
}
/**
 *
 * @export
 * @interface ReferralSerialiserRequest
 */
export interface ReferralSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof ReferralSerialiserRequest
   */
  referrer_code: string;
  /**
   *
   * @type {string}
   * @memberof ReferralSerialiserRequest
   */
  business_code?: string;
  /**
   *
   * @type {BusinessTypeEnum}
   * @memberof ReferralSerialiserRequest
   */
  business_type?: BusinessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ReferralSerialiserRequest
   */
  gplace_id?: string;
  /**
   *
   * @type {string}
   * @memberof ReferralSerialiserRequest
   */
  name?: string;
  /**
   *
   * @type {RelationshipEnum | BlankEnum | NullEnum}
   * @memberof ReferralSerialiserRequest
   */
  relationship?: RelationshipEnum | BlankEnum | NullEnum | null;
  /**
   *
   * @type {string}
   * @memberof ReferralSerialiserRequest
   */
  referrer_message?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum RelationshipEnum {
  DontKnow = "dont_know",
  KnowOf = "know_of",
  KnowPersonally = "know_personally",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum StatusEnum {
  Requested = "requested",
  Following = "following",
}

/**
 *
 * @export
 * @interface TeamMemberSerialiser
 */
export interface TeamMemberSerialiser {
  /**
   *
   * @type {number}
   * @memberof TeamMemberSerialiser
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TeamMemberSerialiser
   */
  business: string;
  /**
   *
   * @type {string}
   * @memberof TeamMemberSerialiser
   */
  email_address?: string;
  /**
   *
   * @type {string}
   * @memberof TeamMemberSerialiser
   */
  full_name: string;
  /**
   *
   * @type {string}
   * @memberof TeamMemberSerialiser
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof TeamMemberSerialiser
   */
  image_link?: string;
  /**
   *
   * @type {string}
   * @memberof TeamMemberSerialiser
   */
  image_link_uuid: string;
  /**
   *
   * @type {string}
   * @memberof TeamMemberSerialiser
   */
  position?: string;
  /**
   *
   * @type {string}
   * @memberof TeamMemberSerialiser
   */
  edit_form_markup: string;
  /**
   *
   * @type {string}
   * @memberof TeamMemberSerialiser
   */
  code: string;
}
/**
 *
 * @export
 * @interface TeamMemberSerialiserRequest
 */
export interface TeamMemberSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof TeamMemberSerialiserRequest
   */
  business: string;
  /**
   *
   * @type {string}
   * @memberof TeamMemberSerialiserRequest
   */
  email_address?: string;
  /**
   *
   * @type {string}
   * @memberof TeamMemberSerialiserRequest
   */
  full_name: string;
  /**
   *
   * @type {string}
   * @memberof TeamMemberSerialiserRequest
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof TeamMemberSerialiserRequest
   */
  image_link?: string;
  /**
   *
   * @type {string}
   * @memberof TeamMemberSerialiserRequest
   */
  position?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum TierEnum {
  Basic = "basic",
  Influencer = "influencer",
  MediaOrganisation = "media organisation",
}

/**
 *
 * @export
 * @interface TravellerActivitySerialiser
 */
export interface TravellerActivitySerialiser {
  /**
   *
   * @type {string}
   * @memberof TravellerActivitySerialiser
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TravellerActivitySerialiser
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof TravellerActivitySerialiser
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof TravellerActivitySerialiser
   */
  visited: number;
  /**
   *
   * @type {string}
   * @memberof TravellerActivitySerialiser
   */
  visited_uuid: string;
}
/**
 *
 * @export
 * @interface TravellerAreaSerialiser
 */
export interface TravellerAreaSerialiser {
  /**
   *
   * @type {string}
   * @memberof TravellerAreaSerialiser
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TravellerAreaSerialiser
   */
  slug: string;
  /**
   *
   * @type {CountryEnum}
   * @memberof TravellerAreaSerialiser
   */
  country: CountryEnum;
  /**
   *
   * @type {string}
   * @memberof TravellerAreaSerialiser
   */
  visited: string;
  /**
   *
   * @type {string}
   * @memberof TravellerAreaSerialiser
   */
  visited_uuid: string;
}
/**
 *
 * @export
 * @interface TravellerChecklistSerialiser
 */
export interface TravellerChecklistSerialiser {
  /**
   *
   * @type {boolean}
   * @memberof TravellerChecklistSerialiser
   */
  hashtags_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TravellerChecklistSerialiser
   */
  wishlistcountry_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TravellerChecklistSerialiser
   */
  visitedbusiness_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TravellerChecklistSerialiser
   */
  visitedcountry_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TravellerChecklistSerialiser
   */
  personal_details_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TravellerChecklistSerialiser
   */
  invite_friend_completed: boolean;
  /**
   *
   * @type {number}
   * @memberof TravellerChecklistSerialiser
   */
  recommendations_completed: number;
}
/**
 *
 * @export
 * @interface TravellerCoreSerialiser
 */
export interface TravellerCoreSerialiser {
  /**
   *
   * @type {string}
   * @memberof TravellerCoreSerialiser
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof TravellerCoreSerialiser
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TravellerCoreSerialiser
   */
  selfie: string;
  /**
   *
   * @type {string}
   * @memberof TravellerCoreSerialiser
   */
  selfie_avatar_link: string;
  /**
   *
   * @type {string}
   * @memberof TravellerCoreSerialiser
   */
  selfie_avatar_uuid: string;
}
/**
 * Create a traveller with extra user details.
 * @export
 * @interface TravellerCreateSerialiser
 */
export interface TravellerCreateSerialiser {
  /**
   *
   * @type {string}
   * @memberof TravellerCreateSerialiser
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof TravellerCreateSerialiser
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TravellerCreateSerialiser
   */
  selfie: string;
  /**
   *
   * @type {string}
   * @memberof TravellerCreateSerialiser
   */
  selfie_avatar_link: string;
  /**
   *
   * @type {string}
   * @memberof TravellerCreateSerialiser
   */
  selfie_avatar_uuid: string;
}
/**
 * Create a traveller with extra user details.
 * @export
 * @interface TravellerCreateSerialiserRequest
 */
export interface TravellerCreateSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof TravellerCreateSerialiserRequest
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof TravellerCreateSerialiserRequest
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof TravellerCreateSerialiserRequest
   */
  email: string;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof TravellerCreateSerialiserRequest
   */
  home_country?: { [key: string]: any };
  /**
   *
   * @type {string}
   * @memberof TravellerCreateSerialiserRequest
   */
  referrer?: string | null;
  /**
   *
   * @type {string}
   * @memberof TravellerCreateSerialiserRequest
   */
  referrer_business?: string | null;
}
/**
 *
 * @export
 * @interface TravellerSerialiser
 */
export interface TravellerSerialiser {
  /**
   *
   * @type {string}
   * @memberof TravellerSerialiser
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof TravellerSerialiser
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TravellerSerialiser
   */
  selfie: string;
  /**
   *
   * @type {string}
   * @memberof TravellerSerialiser
   */
  selfie_avatar_link: string;
  /**
   *
   * @type {string}
   * @memberof TravellerSerialiser
   */
  selfie_avatar_uuid: string;
  /**
   *
   * @type {UserSerialiser}
   * @memberof TravellerSerialiser
   */
  owner: UserSerialiser;
  /**
   *
   * @type {string}
   * @memberof TravellerSerialiser
   */
  countries_visited: string;
  /**
   *
   * @type {string}
   * @memberof TravellerSerialiser
   */
  areas_visited: string;
  /**
   *
   * @type {string}
   * @memberof TravellerSerialiser
   */
  recommendations: string;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof TravellerSerialiser
   */
  home_country: { [key: string]: any };
  /**
   *
   * @type {string}
   * @memberof TravellerSerialiser
   */
  followed_by_current_user: string;
  /**
   *
   * @type {string}
   * @memberof TravellerSerialiser
   */
  follows_current_user: string;
  /**
   *
   * @type {string}
   * @memberof TravellerSerialiser
   */
  requested_to_follow_by_current_user: string;
  /**
   *
   * @type {PrivacyEnum}
   * @memberof TravellerSerialiser
   */
  privacy?: PrivacyEnum;
  /**
   *
   * @type {TierEnum}
   * @memberof TravellerSerialiser
   */
  tier?: TierEnum;
  /**
   *
   * @type {Array<HashtagSerialiser>}
   * @memberof TravellerSerialiser
   */
  hashtags: Array<HashtagSerialiser>;
}
/**
 *
 * @export
 * @interface TravellerSerialiserRequest
 */
export interface TravellerSerialiserRequest {
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof TravellerSerialiserRequest
   */
  home_country: { [key: string]: any };
  /**
   *
   * @type {PrivacyEnum}
   * @memberof TravellerSerialiserRequest
   */
  privacy?: PrivacyEnum;
  /**
   *
   * @type {TierEnum}
   * @memberof TravellerSerialiserRequest
   */
  tier?: TierEnum;
  /**
   *
   * @type {Array<HashtagSerialiserRequest>}
   * @memberof TravellerSerialiserRequest
   */
  hashtags: Array<HashtagSerialiserRequest>;
}
/**
 *
 * @export
 * @interface TravellerUpdateSerialiser
 */
export interface TravellerUpdateSerialiser {
  /**
   *
   * @type {string}
   * @memberof TravellerUpdateSerialiser
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof TravellerUpdateSerialiser
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TravellerUpdateSerialiser
   */
  selfie: string;
  /**
   *
   * @type {string}
   * @memberof TravellerUpdateSerialiser
   */
  selfie_avatar_link: string;
  /**
   *
   * @type {string}
   * @memberof TravellerUpdateSerialiser
   */
  selfie_avatar_uuid: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TravellerUpdateSerialiser
   */
  hashtags?: Array<string>;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof TravellerUpdateSerialiser
   */
  home_country?: { [key: string]: any };
}
/**
 *
 * @export
 * @interface UserSerialiser
 */
export interface UserSerialiser {
  /**
   *
   * @type {string}
   * @memberof UserSerialiser
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserSerialiser
   */
  last_name?: string;
}
/**
 *
 * @export
 * @interface UserSerialiserRequest
 */
export interface UserSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof UserSerialiserRequest
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserSerialiserRequest
   */
  last_name?: string;
}
/**
 *
 * @export
 * @interface VisitedActivitySerialiser
 */
export interface VisitedActivitySerialiser {
  /**
   *
   * @type {string}
   * @memberof VisitedActivitySerialiser
   */
  uuid: string;
  /**
   *
   * @type {TravellerCoreSerialiser}
   * @memberof VisitedActivitySerialiser
   */
  traveller: TravellerCoreSerialiser;
  /**
   *
   * @type {ActivityEnum}
   * @memberof VisitedActivitySerialiser
   */
  activity: ActivityEnum;
  /**
   *
   * @type {AreaSerialiser}
   * @memberof VisitedActivitySerialiser
   */
  area: AreaSerialiser;
  /**
   *
   * @type {string}
   * @memberof VisitedActivitySerialiser
   */
  name: string;
}
/**
 *
 * @export
 * @interface VisitedActivitySerialiserRequest
 */
export interface VisitedActivitySerialiserRequest {
  /**
   *
   * @type {ActivityEnum}
   * @memberof VisitedActivitySerialiserRequest
   */
  activity: ActivityEnum;
  /**
   *
   * @type {AreaSerialiserRequest}
   * @memberof VisitedActivitySerialiserRequest
   */
  area: AreaSerialiserRequest;
}
/**
 *
 * @export
 * @interface VisitedAreaSerialiser
 */
export interface VisitedAreaSerialiser {
  /**
   *
   * @type {string}
   * @memberof VisitedAreaSerialiser
   */
  uuid: string;
  /**
   *
   * @type {AreaSerialiser}
   * @memberof VisitedAreaSerialiser
   */
  area: AreaSerialiser;
  /**
   *
   * @type {string}
   * @memberof VisitedAreaSerialiser
   */
  traveller: string;
  /**
   *
   * @type {boolean}
   * @memberof VisitedAreaSerialiser
   */
  lived?: boolean;
}
/**
 *
 * @export
 * @interface VisitedAreaSerialiserRequest
 */
export interface VisitedAreaSerialiserRequest {
  /**
   *
   * @type {AreaSerialiserRequest}
   * @memberof VisitedAreaSerialiserRequest
   */
  area: AreaSerialiserRequest;
  /**
   *
   * @type {boolean}
   * @memberof VisitedAreaSerialiserRequest
   */
  lived?: boolean;
}
/**
 *
 * @export
 * @interface VisitedBusinessSerialiser
 */
export interface VisitedBusinessSerialiser {
  /**
   *
   * @type {string}
   * @memberof VisitedBusinessSerialiser
   */
  comment?: string;
  /**
   *
   * @type {RatingEnum}
   * @memberof VisitedBusinessSerialiser
   */
  rating: RatingEnum;
  /**
   *
   * @type {TravellerCoreSerialiser}
   * @memberof VisitedBusinessSerialiser
   */
  traveller: TravellerCoreSerialiser;
  /**
   *
   * @type {string}
   * @memberof VisitedBusinessSerialiser
   */
  created: string;
}
/**
 *
 * @export
 * @interface VisitedBusinessWithProfileSerialiser
 */
export interface VisitedBusinessWithProfileSerialiser {
  /**
   *
   * @type {BusinessWithHashtagsAndLanguagesSerialiser}
   * @memberof VisitedBusinessWithProfileSerialiser
   */
  business: BusinessWithHashtagsAndLanguagesSerialiser;
  /**
   *
   * @type {string}
   * @memberof VisitedBusinessWithProfileSerialiser
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof VisitedBusinessWithProfileSerialiser
   */
  wishlist_uuid: string;
  /**
   *
   * @type {string}
   * @memberof VisitedBusinessWithProfileSerialiser
   */
  uuid: string;
  /**
   *
   * @type {TravellerCoreSerialiser}
   * @memberof VisitedBusinessWithProfileSerialiser
   */
  traveller: TravellerCoreSerialiser;
  /**
   *
   * @type {string}
   * @memberof VisitedBusinessWithProfileSerialiser
   */
  created: string;
}
/**
 *
 * @export
 * @interface VisitedBusinessWithProfileSerialiserRequest
 */
export interface VisitedBusinessWithProfileSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof VisitedBusinessWithProfileSerialiserRequest
   */
  comment?: string;
  /**
   *
   * @type {BusinessTypeEnum}
   * @memberof VisitedBusinessWithProfileSerialiserRequest
   */
  business_type?: BusinessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof VisitedBusinessWithProfileSerialiserRequest
   */
  business_code?: string;
  /**
   *
   * @type {string}
   * @memberof VisitedBusinessWithProfileSerialiserRequest
   */
  gplace_id?: string;
}
/**
 *
 * @export
 * @interface VisitedCountrySerialiser
 */
export interface VisitedCountrySerialiser {
  /**
   *
   * @type {string}
   * @memberof VisitedCountrySerialiser
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof VisitedCountrySerialiser
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof VisitedCountrySerialiser
   */
  beta: string;
  /**
   *
   * @type {string}
   * @memberof VisitedCountrySerialiser
   */
  visits: string;
  /**
   *
   * @type {string}
   * @memberof VisitedCountrySerialiser
   */
  areas: string;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof VisitedCountrySerialiser
   */
  country: { [key: string]: any };
  /**
   *
   * @type {string}
   * @memberof VisitedCountrySerialiser
   */
  uuid: string;
}
/**
 *
 * @export
 * @interface VisitedCountrySerialiserRequest
 */
export interface VisitedCountrySerialiserRequest {
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof VisitedCountrySerialiserRequest
   */
  country: { [key: string]: any };
}
/**
 *
 * @export
 * @interface WishlistBusinessReadSerialiser
 */
export interface WishlistBusinessReadSerialiser {
  /**
   *
   * @type {number}
   * @memberof WishlistBusinessReadSerialiser
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof WishlistBusinessReadSerialiser
   */
  traveller: string;
  /**
   *
   * @type {BusinessSerialiser}
   * @memberof WishlistBusinessReadSerialiser
   */
  business: BusinessSerialiser;
  /**
   *
   * @type {string}
   * @memberof WishlistBusinessReadSerialiser
   */
  created: string;
  /**
   *
   * @type {string}
   * @memberof WishlistBusinessReadSerialiser
   */
  modified: string;
  /**
   *
   * @type {string}
   * @memberof WishlistBusinessReadSerialiser
   */
  uuid: string;
}
/**
 *
 * @export
 * @interface WishlistBusinessWriteSerialiser
 */
export interface WishlistBusinessWriteSerialiser {
  /**
   *
   * @type {string}
   * @memberof WishlistBusinessWriteSerialiser
   */
  traveller: string;
  /**
   *
   * @type {string}
   * @memberof WishlistBusinessWriteSerialiser
   */
  business: string;
  /**
   *
   * @type {string}
   * @memberof WishlistBusinessWriteSerialiser
   */
  uuid?: string;
}
/**
 *
 * @export
 * @interface WishlistBusinessWriteSerialiserRequest
 */
export interface WishlistBusinessWriteSerialiserRequest {
  /**
   *
   * @type {string}
   * @memberof WishlistBusinessWriteSerialiserRequest
   */
  business: string;
  /**
   *
   * @type {string}
   * @memberof WishlistBusinessWriteSerialiserRequest
   */
  uuid?: string;
}
/**
 *
 * @export
 * @interface WishlistCountrySerialiser
 */
export interface WishlistCountrySerialiser {
  /**
   *
   * @type {string}
   * @memberof WishlistCountrySerialiser
   */
  uuid: string;
  /**
   *
   * @type {CountryEnum | BlankEnum}
   * @memberof WishlistCountrySerialiser
   */
  country?: CountryEnum | BlankEnum;
  /**
   *
   * @type {string}
   * @memberof WishlistCountrySerialiser
   */
  traveller: string;
}
/**
 *
 * @export
 * @interface WishlistCountrySerialiserRequest
 */
export interface WishlistCountrySerialiserRequest {
  /**
   *
   * @type {CountryEnum | BlankEnum}
   * @memberof WishlistCountrySerialiserRequest
   */
  country?: CountryEnum | BlankEnum;
}

/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns a list of areas ordered by distance given a specified area_slug
     * @param {string} areaSlug
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAreaNearbyList: async (
      areaSlug: string,
      page?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'areaSlug' is not null or undefined
      assertParamExists("apiAreaNearbyList", "areaSlug", areaSlug);
      const localVarPath = `/api/area/nearby/{area_slug}/`.replace(
        `{${"area_slug"}}`,
        encodeURIComponent(String(areaSlug))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {BookingEnquirySerialiserRequest} bookingEnquirySerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBookingEnquiryCreate: async (
      bookingEnquirySerialiserRequest: BookingEnquirySerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bookingEnquirySerialiserRequest' is not null or undefined
      assertParamExists(
        "apiBookingEnquiryCreate",
        "bookingEnquirySerialiserRequest",
        bookingEnquirySerialiserRequest
      );
      const localVarPath = `/api/booking-enquiry/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bookingEnquirySerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBusinessNearbyAreaList: async (
      businessCode: string,
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("apiBusinessNearbyAreaList", "businessCode", businessCode);
      const localVarPath = `/api/business/{business_code}/nearby/area/`.replace(
        `{${"business_code"}}`,
        encodeURIComponent(String(businessCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} businessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBusinessNearbyRetrieve: async (
      businessCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("apiBusinessNearbyRetrieve", "businessCode", businessCode);
      const localVarPath = `/api/business/{business_code}/nearby/`.replace(
        `{${"business_code"}}`,
        encodeURIComponent(String(businessCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return the details for a specific business specified by it\'s business_code.  Note: We specifically allow retrieving hidden businesses as the user needs to know the code.
     * @param {string} businessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBusinessRetrieve: async (
      businessCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("apiBusinessRetrieve", "businessCode", businessCode);
      const localVarPath = `/api/business/{business_code}/`.replace(
        `{${"business_code"}}`,
        encodeURIComponent(String(businessCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GuestQuestionSerialiserRequest} guestQuestionSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiGuestQuestionCreate: async (
      guestQuestionSerialiserRequest: GuestQuestionSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'guestQuestionSerialiserRequest' is not null or undefined
      assertParamExists(
        "apiGuestQuestionCreate",
        "guestQuestionSerialiserRequest",
        guestQuestionSerialiserRequest
      );
      const localVarPath = `/api/guest_question/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        guestQuestionSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {VisitedAreaSerialiserRequest} visitedAreaSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiVisitedAreaCreate: async (
      visitedAreaSerialiserRequest: VisitedAreaSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'visitedAreaSerialiserRequest' is not null or undefined
      assertParamExists(
        "apiVisitedAreaCreate",
        "visitedAreaSerialiserRequest",
        visitedAreaSerialiserRequest
      );
      const localVarPath = `/api/visited/area/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        visitedAreaSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiVisitedAreaDestroy: async (
      uuid: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("apiVisitedAreaDestroy", "uuid", uuid);
      const localVarPath = `/api/visited/area/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiVisitedAreaList: async (
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/visited/area/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {string} uuid
     * @param {PatchedVisitedAreaSerialiserRequest} [patchedVisitedAreaSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiVisitedAreaPartialUpdate: async (
      uuid: string,
      patchedVisitedAreaSerialiserRequest?: PatchedVisitedAreaSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("apiVisitedAreaPartialUpdate", "uuid", uuid);
      const localVarPath = `/api/visited/area/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedVisitedAreaSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiVisitedAreaRetrieve: async (
      uuid: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("apiVisitedAreaRetrieve", "uuid", uuid);
      const localVarPath = `/api/visited/area/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {string} uuid
     * @param {VisitedAreaSerialiserRequest} visitedAreaSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiVisitedAreaUpdate: async (
      uuid: string,
      visitedAreaSerialiserRequest: VisitedAreaSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("apiVisitedAreaUpdate", "uuid", uuid);
      // verify required parameter 'visitedAreaSerialiserRequest' is not null or undefined
      assertParamExists(
        "apiVisitedAreaUpdate",
        "visitedAreaSerialiserRequest",
        visitedAreaSerialiserRequest
      );
      const localVarPath = `/api/visited/area/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        visitedAreaSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns a list of areas ordered by distance given a specified area_slug
     * @param {string} areaSlug
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAreaNearbyList(
      areaSlug: string,
      page?: number,
      pageSize?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedAreaSerialiserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAreaNearbyList(
        areaSlug,
        page,
        pageSize,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {BookingEnquirySerialiserRequest} bookingEnquirySerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiBookingEnquiryCreate(
      bookingEnquirySerialiserRequest: BookingEnquirySerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingEnquirySerialiser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiBookingEnquiryCreate(
        bookingEnquirySerialiserRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiBusinessNearbyAreaList(
      businessCode: string,
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedAreaSerialiserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessNearbyAreaList(
        businessCode,
        page,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} businessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiBusinessNearbyRetrieve(
      businessCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<BusinessProfileNearbySerialiser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessNearbyRetrieve(
        businessCode,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Return the details for a specific business specified by it\'s business_code.  Note: We specifically allow retrieving hidden businesses as the user needs to know the code.
     * @param {string} businessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiBusinessRetrieve(
      businessCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessSerialiser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessRetrieve(
        businessCode,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {GuestQuestionSerialiserRequest} guestQuestionSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiGuestQuestionCreate(
      guestQuestionSerialiserRequest: GuestQuestionSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuestQuestionSerialiser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiGuestQuestionCreate(
        guestQuestionSerialiserRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {VisitedAreaSerialiserRequest} visitedAreaSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiVisitedAreaCreate(
      visitedAreaSerialiserRequest: VisitedAreaSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitedAreaSerialiser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiVisitedAreaCreate(
        visitedAreaSerialiserRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiVisitedAreaDestroy(
      uuid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiVisitedAreaDestroy(
        uuid,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiVisitedAreaList(
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedVisitedAreaSerialiserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiVisitedAreaList(
        page,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {string} uuid
     * @param {PatchedVisitedAreaSerialiserRequest} [patchedVisitedAreaSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiVisitedAreaPartialUpdate(
      uuid: string,
      patchedVisitedAreaSerialiserRequest?: PatchedVisitedAreaSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitedAreaSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiVisitedAreaPartialUpdate(
          uuid,
          patchedVisitedAreaSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiVisitedAreaRetrieve(
      uuid: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitedAreaSerialiser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiVisitedAreaRetrieve(
        uuid,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {string} uuid
     * @param {VisitedAreaSerialiserRequest} visitedAreaSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiVisitedAreaUpdate(
      uuid: string,
      visitedAreaSerialiserRequest: VisitedAreaSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitedAreaSerialiser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiVisitedAreaUpdate(
        uuid,
        visitedAreaSerialiserRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ApiApiFp(configuration);
  return {
    /**
     * Returns a list of areas ordered by distance given a specified area_slug
     * @param {string} areaSlug
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAreaNearbyList(
      areaSlug: string,
      page?: number,
      pageSize?: number,
      options?: any
    ): AxiosPromise<PaginatedAreaSerialiserList> {
      return localVarFp
        .apiAreaNearbyList(areaSlug, page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {BookingEnquirySerialiserRequest} bookingEnquirySerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBookingEnquiryCreate(
      bookingEnquirySerialiserRequest: BookingEnquirySerialiserRequest,
      options?: any
    ): AxiosPromise<BookingEnquirySerialiser> {
      return localVarFp
        .apiBookingEnquiryCreate(bookingEnquirySerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBusinessNearbyAreaList(
      businessCode: string,
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedAreaSerialiserList> {
      return localVarFp
        .apiBusinessNearbyAreaList(businessCode, page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} businessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBusinessNearbyRetrieve(
      businessCode: string,
      options?: any
    ): AxiosPromise<BusinessProfileNearbySerialiser> {
      return localVarFp
        .apiBusinessNearbyRetrieve(businessCode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return the details for a specific business specified by it\'s business_code.  Note: We specifically allow retrieving hidden businesses as the user needs to know the code.
     * @param {string} businessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBusinessRetrieve(
      businessCode: string,
      options?: any
    ): AxiosPromise<BusinessSerialiser> {
      return localVarFp
        .apiBusinessRetrieve(businessCode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {GuestQuestionSerialiserRequest} guestQuestionSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiGuestQuestionCreate(
      guestQuestionSerialiserRequest: GuestQuestionSerialiserRequest,
      options?: any
    ): AxiosPromise<GuestQuestionSerialiser> {
      return localVarFp
        .apiGuestQuestionCreate(guestQuestionSerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {VisitedAreaSerialiserRequest} visitedAreaSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiVisitedAreaCreate(
      visitedAreaSerialiserRequest: VisitedAreaSerialiserRequest,
      options?: any
    ): AxiosPromise<VisitedAreaSerialiser> {
      return localVarFp
        .apiVisitedAreaCreate(visitedAreaSerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiVisitedAreaDestroy(uuid: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .apiVisitedAreaDestroy(uuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiVisitedAreaList(
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedVisitedAreaSerialiserList> {
      return localVarFp
        .apiVisitedAreaList(page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {string} uuid
     * @param {PatchedVisitedAreaSerialiserRequest} [patchedVisitedAreaSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiVisitedAreaPartialUpdate(
      uuid: string,
      patchedVisitedAreaSerialiserRequest?: PatchedVisitedAreaSerialiserRequest,
      options?: any
    ): AxiosPromise<VisitedAreaSerialiser> {
      return localVarFp
        .apiVisitedAreaPartialUpdate(uuid, patchedVisitedAreaSerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiVisitedAreaRetrieve(
      uuid: string,
      options?: any
    ): AxiosPromise<VisitedAreaSerialiser> {
      return localVarFp
        .apiVisitedAreaRetrieve(uuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedArea records filtered by traveller code.
     * @param {string} uuid
     * @param {VisitedAreaSerialiserRequest} visitedAreaSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiVisitedAreaUpdate(
      uuid: string,
      visitedAreaSerialiserRequest: VisitedAreaSerialiserRequest,
      options?: any
    ): AxiosPromise<VisitedAreaSerialiser> {
      return localVarFp
        .apiVisitedAreaUpdate(uuid, visitedAreaSerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI {
  /**
   * Returns a list of areas ordered by distance given a specified area_slug
   * @param {string} areaSlug
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [pageSize] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiAreaNearbyList(
    areaSlug: string,
    page?: number,
    pageSize?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .apiAreaNearbyList(areaSlug, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BookingEnquirySerialiserRequest} bookingEnquirySerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiBookingEnquiryCreate(
    bookingEnquirySerialiserRequest: BookingEnquirySerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .apiBookingEnquiryCreate(bookingEnquirySerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} businessCode
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiBusinessNearbyAreaList(
    businessCode: string,
    page?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .apiBusinessNearbyAreaList(businessCode, page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} businessCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiBusinessNearbyRetrieve(businessCode: string, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .apiBusinessNearbyRetrieve(businessCode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return the details for a specific business specified by it\'s business_code.  Note: We specifically allow retrieving hidden businesses as the user needs to know the code.
   * @param {string} businessCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiBusinessRetrieve(businessCode: string, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .apiBusinessRetrieve(businessCode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GuestQuestionSerialiserRequest} guestQuestionSerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiGuestQuestionCreate(
    guestQuestionSerialiserRequest: GuestQuestionSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .apiGuestQuestionCreate(guestQuestionSerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedArea records filtered by traveller code.
   * @param {VisitedAreaSerialiserRequest} visitedAreaSerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiVisitedAreaCreate(
    visitedAreaSerialiserRequest: VisitedAreaSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .apiVisitedAreaCreate(visitedAreaSerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedArea records filtered by traveller code.
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiVisitedAreaDestroy(uuid: string, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .apiVisitedAreaDestroy(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedArea records filtered by traveller code.
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiVisitedAreaList(page?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .apiVisitedAreaList(page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedArea records filtered by traveller code.
   * @param {string} uuid
   * @param {PatchedVisitedAreaSerialiserRequest} [patchedVisitedAreaSerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiVisitedAreaPartialUpdate(
    uuid: string,
    patchedVisitedAreaSerialiserRequest?: PatchedVisitedAreaSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .apiVisitedAreaPartialUpdate(uuid, patchedVisitedAreaSerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedArea records filtered by traveller code.
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiVisitedAreaRetrieve(uuid: string, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .apiVisitedAreaRetrieve(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedArea records filtered by traveller code.
   * @param {string} uuid
   * @param {VisitedAreaSerialiserRequest} visitedAreaSerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiVisitedAreaUpdate(
    uuid: string,
    visitedAreaSerialiserRequest: VisitedAreaSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .apiVisitedAreaUpdate(uuid, visitedAreaSerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AreaApi - axios parameter creator
 * @export
 */
export const AreaApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    areaFavouritesList: async (
      businessCode: string,
      page?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("areaFavouritesList", "businessCode", businessCode);
      const localVarPath = `/area/favourites/{business_code}/`.replace(
        `{${"business_code"}}`,
        encodeURIComponent(String(businessCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API endpoint for retrieving shell businesses in the same area as a given business.
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    areaShellsList: async (
      businessCode: string,
      page?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("areaShellsList", "businessCode", businessCode);
      const localVarPath = `/area/shells/{business_code}`.replace(
        `{${"business_code"}}`,
        encodeURIComponent(String(businessCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AreaApi - functional programming interface
 * @export
 */
export const AreaApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AreaApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async areaFavouritesList(
      businessCode: string,
      page?: number,
      pageSize?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedRecommendationSerialiserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.areaFavouritesList(
        businessCode,
        page,
        pageSize,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * API endpoint for retrieving shell businesses in the same area as a given business.
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async areaShellsList(
      businessCode: string,
      page?: number,
      pageSize?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedBusinessSearchResultSerialiserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.areaShellsList(
        businessCode,
        page,
        pageSize,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AreaApi - factory interface
 * @export
 */
export const AreaApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AreaApiFp(configuration);
  return {
    /**
     *
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    areaFavouritesList(
      businessCode: string,
      page?: number,
      pageSize?: number,
      options?: any
    ): AxiosPromise<PaginatedRecommendationSerialiserList> {
      return localVarFp
        .areaFavouritesList(businessCode, page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API endpoint for retrieving shell businesses in the same area as a given business.
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    areaShellsList(
      businessCode: string,
      page?: number,
      pageSize?: number,
      options?: any
    ): AxiosPromise<PaginatedBusinessSearchResultSerialiserList> {
      return localVarFp
        .areaShellsList(businessCode, page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AreaApi - object-oriented interface
 * @export
 * @class AreaApi
 * @extends {BaseAPI}
 */
export class AreaApi extends BaseAPI {
  /**
   *
   * @param {string} businessCode
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [pageSize] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AreaApi
   */
  public areaFavouritesList(
    businessCode: string,
    page?: number,
    pageSize?: number,
    options?: AxiosRequestConfig
  ) {
    return AreaApiFp(this.configuration)
      .areaFavouritesList(businessCode, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API endpoint for retrieving shell businesses in the same area as a given business.
   * @param {string} businessCode
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [pageSize] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AreaApi
   */
  public areaShellsList(
    businessCode: string,
    page?: number,
    pageSize?: number,
    options?: AxiosRequestConfig
  ) {
    return AreaApiFp(this.configuration)
      .areaShellsList(businessCode, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CreditApi - axios parameter creator
 * @export
 */
export const CreditApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {BusinessCouponSerialiserRequest} businessCouponSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponCreate: async (
      businessCouponSerialiserRequest: BusinessCouponSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCouponSerialiserRequest' is not null or undefined
      assertParamExists(
        "creditApiCouponCreate",
        "businessCouponSerialiserRequest",
        businessCouponSerialiserRequest
      );
      const localVarPath = `/credit/api/coupon/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessCouponSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponDestroy: async (
      code: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("creditApiCouponDestroy", "code", code);
      const localVarPath = `/credit/api/coupon/{code}/`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [attached] Is attached to a booking
     * @param {string} [businessBusinessCode]
     * @param {number} [page] A page number within the paginated result set.
     * @param {string} [travellerCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponList: async (
      attached?: string,
      businessBusinessCode?: string,
      page?: number,
      travellerCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/credit/api/coupon/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (attached !== undefined) {
        localVarQueryParameter["attached"] = attached;
      }

      if (businessBusinessCode !== undefined) {
        localVarQueryParameter["business__business_code"] = businessBusinessCode;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (travellerCode !== undefined) {
        localVarQueryParameter["traveller__code"] = travellerCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} code
     * @param {PatchedBusinessCouponReadSerialiserRequest} [patchedBusinessCouponReadSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponPartialUpdate: async (
      code: string,
      patchedBusinessCouponReadSerialiserRequest?: PatchedBusinessCouponReadSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("creditApiCouponPartialUpdate", "code", code);
      const localVarPath = `/credit/api/coupon/{code}/`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedBusinessCouponReadSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sets Coupon redeemed_at attribute.  Note this endpoint can only be called by business owners.
     * @param {string} code
     * @param {PatchedBusinessCouponReadSerialiserRequest} [patchedBusinessCouponReadSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponRedeemPartialUpdate: async (
      code: string,
      patchedBusinessCouponReadSerialiserRequest?: PatchedBusinessCouponReadSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("creditApiCouponRedeemPartialUpdate", "code", code);
      const localVarPath = `/credit/api/coupon/{code}/redeem/`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedBusinessCouponReadSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponRetrieve: async (
      code: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("creditApiCouponRetrieve", "code", code);
      const localVarPath = `/credit/api/coupon/{code}/`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sets Coupon confirmed_at and redemption dates attributes.  Note this endpoint can be called by both business and traveller owners.
     * @param {string} code
     * @param {PatchedBusinessCouponReadSerialiserRequest} [patchedBusinessCouponReadSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponTravellerConfirmPartialUpdate: async (
      code: string,
      patchedBusinessCouponReadSerialiserRequest?: PatchedBusinessCouponReadSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("creditApiCouponTravellerConfirmPartialUpdate", "code", code);
      const localVarPath = `/credit/api/coupon/{code}/traveller_confirm/`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedBusinessCouponReadSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Blanks Coupon confirmed_at and redemption dates attributes.
     * @param {string} code
     * @param {PatchedBusinessCouponReadSerialiserRequest} [patchedBusinessCouponReadSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponTravellerUnconfirmPartialUpdate: async (
      code: string,
      patchedBusinessCouponReadSerialiserRequest?: PatchedBusinessCouponReadSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("creditApiCouponTravellerUnconfirmPartialUpdate", "code", code);
      const localVarPath = `/credit/api/coupon/{code}/traveller_unconfirm/`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedBusinessCouponReadSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} code
     * @param {BusinessCouponSerialiserRequest} businessCouponSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponUpdate: async (
      code: string,
      businessCouponSerialiserRequest: BusinessCouponSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("creditApiCouponUpdate", "code", code);
      // verify required parameter 'businessCouponSerialiserRequest' is not null or undefined
      assertParamExists(
        "creditApiCouponUpdate",
        "businessCouponSerialiserRequest",
        businessCouponSerialiserRequest
      );
      const localVarPath = `/credit/api/coupon/{code}/`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessCouponSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CreditApi - functional programming interface
 * @export
 */
export const CreditApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CreditApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {BusinessCouponSerialiserRequest} businessCouponSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creditApiCouponCreate(
      businessCouponSerialiserRequest: BusinessCouponSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessCouponSerialiser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creditApiCouponCreate(
        businessCouponSerialiserRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creditApiCouponDestroy(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creditApiCouponDestroy(
        code,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [attached] Is attached to a booking
     * @param {string} [businessBusinessCode]
     * @param {number} [page] A page number within the paginated result set.
     * @param {string} [travellerCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creditApiCouponList(
      attached?: string,
      businessBusinessCode?: string,
      page?: number,
      travellerCode?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedBusinessCouponReadSerialiserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creditApiCouponList(
        attached,
        businessBusinessCode,
        page,
        travellerCode,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} code
     * @param {PatchedBusinessCouponReadSerialiserRequest} [patchedBusinessCouponReadSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creditApiCouponPartialUpdate(
      code: string,
      patchedBusinessCouponReadSerialiserRequest?: PatchedBusinessCouponReadSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<BusinessCouponReadSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.creditApiCouponPartialUpdate(
          code,
          patchedBusinessCouponReadSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Sets Coupon redeemed_at attribute.  Note this endpoint can only be called by business owners.
     * @param {string} code
     * @param {PatchedBusinessCouponReadSerialiserRequest} [patchedBusinessCouponReadSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creditApiCouponRedeemPartialUpdate(
      code: string,
      patchedBusinessCouponReadSerialiserRequest?: PatchedBusinessCouponReadSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<BusinessCouponReadSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.creditApiCouponRedeemPartialUpdate(
          code,
          patchedBusinessCouponReadSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creditApiCouponRetrieve(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<BusinessCouponReadSerialiser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creditApiCouponRetrieve(
        code,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Sets Coupon confirmed_at and redemption dates attributes.  Note this endpoint can be called by both business and traveller owners.
     * @param {string} code
     * @param {PatchedBusinessCouponReadSerialiserRequest} [patchedBusinessCouponReadSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creditApiCouponTravellerConfirmPartialUpdate(
      code: string,
      patchedBusinessCouponReadSerialiserRequest?: PatchedBusinessCouponReadSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<BusinessCouponReadSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.creditApiCouponTravellerConfirmPartialUpdate(
          code,
          patchedBusinessCouponReadSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Blanks Coupon confirmed_at and redemption dates attributes.
     * @param {string} code
     * @param {PatchedBusinessCouponReadSerialiserRequest} [patchedBusinessCouponReadSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creditApiCouponTravellerUnconfirmPartialUpdate(
      code: string,
      patchedBusinessCouponReadSerialiserRequest?: PatchedBusinessCouponReadSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<BusinessCouponReadSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.creditApiCouponTravellerUnconfirmPartialUpdate(
          code,
          patchedBusinessCouponReadSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} code
     * @param {BusinessCouponSerialiserRequest} businessCouponSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creditApiCouponUpdate(
      code: string,
      businessCouponSerialiserRequest: BusinessCouponSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessCouponSerialiser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creditApiCouponUpdate(
        code,
        businessCouponSerialiserRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * CreditApi - factory interface
 * @export
 */
export const CreditApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CreditApiFp(configuration);
  return {
    /**
     *
     * @param {BusinessCouponSerialiserRequest} businessCouponSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponCreate(
      businessCouponSerialiserRequest: BusinessCouponSerialiserRequest,
      options?: any
    ): AxiosPromise<BusinessCouponSerialiser> {
      return localVarFp
        .creditApiCouponCreate(businessCouponSerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponDestroy(code: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .creditApiCouponDestroy(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [attached] Is attached to a booking
     * @param {string} [businessBusinessCode]
     * @param {number} [page] A page number within the paginated result set.
     * @param {string} [travellerCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponList(
      attached?: string,
      businessBusinessCode?: string,
      page?: number,
      travellerCode?: string,
      options?: any
    ): AxiosPromise<PaginatedBusinessCouponReadSerialiserList> {
      return localVarFp
        .creditApiCouponList(attached, businessBusinessCode, page, travellerCode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} code
     * @param {PatchedBusinessCouponReadSerialiserRequest} [patchedBusinessCouponReadSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponPartialUpdate(
      code: string,
      patchedBusinessCouponReadSerialiserRequest?: PatchedBusinessCouponReadSerialiserRequest,
      options?: any
    ): AxiosPromise<BusinessCouponReadSerialiser> {
      return localVarFp
        .creditApiCouponPartialUpdate(
          code,
          patchedBusinessCouponReadSerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Sets Coupon redeemed_at attribute.  Note this endpoint can only be called by business owners.
     * @param {string} code
     * @param {PatchedBusinessCouponReadSerialiserRequest} [patchedBusinessCouponReadSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponRedeemPartialUpdate(
      code: string,
      patchedBusinessCouponReadSerialiserRequest?: PatchedBusinessCouponReadSerialiserRequest,
      options?: any
    ): AxiosPromise<BusinessCouponReadSerialiser> {
      return localVarFp
        .creditApiCouponRedeemPartialUpdate(
          code,
          patchedBusinessCouponReadSerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponRetrieve(
      code: string,
      options?: any
    ): AxiosPromise<BusinessCouponReadSerialiser> {
      return localVarFp
        .creditApiCouponRetrieve(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Sets Coupon confirmed_at and redemption dates attributes.  Note this endpoint can be called by both business and traveller owners.
     * @param {string} code
     * @param {PatchedBusinessCouponReadSerialiserRequest} [patchedBusinessCouponReadSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponTravellerConfirmPartialUpdate(
      code: string,
      patchedBusinessCouponReadSerialiserRequest?: PatchedBusinessCouponReadSerialiserRequest,
      options?: any
    ): AxiosPromise<BusinessCouponReadSerialiser> {
      return localVarFp
        .creditApiCouponTravellerConfirmPartialUpdate(
          code,
          patchedBusinessCouponReadSerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Blanks Coupon confirmed_at and redemption dates attributes.
     * @param {string} code
     * @param {PatchedBusinessCouponReadSerialiserRequest} [patchedBusinessCouponReadSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponTravellerUnconfirmPartialUpdate(
      code: string,
      patchedBusinessCouponReadSerialiserRequest?: PatchedBusinessCouponReadSerialiserRequest,
      options?: any
    ): AxiosPromise<BusinessCouponReadSerialiser> {
      return localVarFp
        .creditApiCouponTravellerUnconfirmPartialUpdate(
          code,
          patchedBusinessCouponReadSerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} code
     * @param {BusinessCouponSerialiserRequest} businessCouponSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditApiCouponUpdate(
      code: string,
      businessCouponSerialiserRequest: BusinessCouponSerialiserRequest,
      options?: any
    ): AxiosPromise<BusinessCouponSerialiser> {
      return localVarFp
        .creditApiCouponUpdate(code, businessCouponSerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CreditApi - object-oriented interface
 * @export
 * @class CreditApi
 * @extends {BaseAPI}
 */
export class CreditApi extends BaseAPI {
  /**
   *
   * @param {BusinessCouponSerialiserRequest} businessCouponSerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditApi
   */
  public creditApiCouponCreate(
    businessCouponSerialiserRequest: BusinessCouponSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return CreditApiFp(this.configuration)
      .creditApiCouponCreate(businessCouponSerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditApi
   */
  public creditApiCouponDestroy(code: string, options?: AxiosRequestConfig) {
    return CreditApiFp(this.configuration)
      .creditApiCouponDestroy(code, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [attached] Is attached to a booking
   * @param {string} [businessBusinessCode]
   * @param {number} [page] A page number within the paginated result set.
   * @param {string} [travellerCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditApi
   */
  public creditApiCouponList(
    attached?: string,
    businessBusinessCode?: string,
    page?: number,
    travellerCode?: string,
    options?: AxiosRequestConfig
  ) {
    return CreditApiFp(this.configuration)
      .creditApiCouponList(attached, businessBusinessCode, page, travellerCode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} code
   * @param {PatchedBusinessCouponReadSerialiserRequest} [patchedBusinessCouponReadSerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditApi
   */
  public creditApiCouponPartialUpdate(
    code: string,
    patchedBusinessCouponReadSerialiserRequest?: PatchedBusinessCouponReadSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return CreditApiFp(this.configuration)
      .creditApiCouponPartialUpdate(
        code,
        patchedBusinessCouponReadSerialiserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sets Coupon redeemed_at attribute.  Note this endpoint can only be called by business owners.
   * @param {string} code
   * @param {PatchedBusinessCouponReadSerialiserRequest} [patchedBusinessCouponReadSerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditApi
   */
  public creditApiCouponRedeemPartialUpdate(
    code: string,
    patchedBusinessCouponReadSerialiserRequest?: PatchedBusinessCouponReadSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return CreditApiFp(this.configuration)
      .creditApiCouponRedeemPartialUpdate(
        code,
        patchedBusinessCouponReadSerialiserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditApi
   */
  public creditApiCouponRetrieve(code: string, options?: AxiosRequestConfig) {
    return CreditApiFp(this.configuration)
      .creditApiCouponRetrieve(code, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sets Coupon confirmed_at and redemption dates attributes.  Note this endpoint can be called by both business and traveller owners.
   * @param {string} code
   * @param {PatchedBusinessCouponReadSerialiserRequest} [patchedBusinessCouponReadSerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditApi
   */
  public creditApiCouponTravellerConfirmPartialUpdate(
    code: string,
    patchedBusinessCouponReadSerialiserRequest?: PatchedBusinessCouponReadSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return CreditApiFp(this.configuration)
      .creditApiCouponTravellerConfirmPartialUpdate(
        code,
        patchedBusinessCouponReadSerialiserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Blanks Coupon confirmed_at and redemption dates attributes.
   * @param {string} code
   * @param {PatchedBusinessCouponReadSerialiserRequest} [patchedBusinessCouponReadSerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditApi
   */
  public creditApiCouponTravellerUnconfirmPartialUpdate(
    code: string,
    patchedBusinessCouponReadSerialiserRequest?: PatchedBusinessCouponReadSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return CreditApiFp(this.configuration)
      .creditApiCouponTravellerUnconfirmPartialUpdate(
        code,
        patchedBusinessCouponReadSerialiserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} code
   * @param {BusinessCouponSerialiserRequest} businessCouponSerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditApi
   */
  public creditApiCouponUpdate(
    code: string,
    businessCouponSerialiserRequest: BusinessCouponSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return CreditApiFp(this.configuration)
      .creditApiCouponUpdate(code, businessCouponSerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * GuideApi - axios parameter creator
 * @export
 */
export const GuideApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns a list of popular activities in a given country.
     * @param {string} countryCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guideApiActivitiesPopularRetrieve: async (
      countryCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'countryCode' is not null or undefined
      assertParamExists("guideApiActivitiesPopularRetrieve", "countryCode", countryCode);
      const localVarPath = `/guide/api/activities/popular/{country_code}/`.replace(
        `{${"country_code"}}`,
        encodeURIComponent(String(countryCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get/List businesses filterable by top-level attributes, with additional with filter metadata.  Note:     Businesses are ordered by descending `score` first, then `business_code` where scores are equal.     This prevents duplication across pages where scores are equal across a large     number of businesses.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guideApiBusinessList: async (
      ordering?: string,
      page?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/guide/api/business/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (ordering !== undefined) {
        localVarQueryParameter["ordering"] = ordering;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get/List businesses filterable by top-level attributes, with additional with filter metadata.  Note:     Businesses are ordered by descending `score` first, then `business_code` where scores are equal.     This prevents duplication across pages where scores are equal across a large     number of businesses.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guideApiBusinessRetrieve: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("guideApiBusinessRetrieve", "id", id);
      const localVarPath = `/guide/api/business/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GuideApi - functional programming interface
 * @export
 */
export const GuideApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GuideApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns a list of popular activities in a given country.
     * @param {string} countryCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async guideApiActivitiesPopularRetrieve(
      countryCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.guideApiActivitiesPopularRetrieve(
          countryCode,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Get/List businesses filterable by top-level attributes, with additional with filter metadata.  Note:     Businesses are ordered by descending `score` first, then `business_code` where scores are equal.     This prevents duplication across pages where scores are equal across a large     number of businesses.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async guideApiBusinessList(
      ordering?: string,
      page?: number,
      pageSize?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedBusinessWithHighlightsSerialiserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.guideApiBusinessList(
        ordering,
        page,
        pageSize,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Get/List businesses filterable by top-level attributes, with additional with filter metadata.  Note:     Businesses are ordered by descending `score` first, then `business_code` where scores are equal.     This prevents duplication across pages where scores are equal across a large     number of businesses.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async guideApiBusinessRetrieve(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<BusinessWithHighlightsSerialiser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.guideApiBusinessRetrieve(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * GuideApi - factory interface
 * @export
 */
export const GuideApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = GuideApiFp(configuration);
  return {
    /**
     * Returns a list of popular activities in a given country.
     * @param {string} countryCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guideApiActivitiesPopularRetrieve(
      countryCode: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .guideApiActivitiesPopularRetrieve(countryCode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get/List businesses filterable by top-level attributes, with additional with filter metadata.  Note:     Businesses are ordered by descending `score` first, then `business_code` where scores are equal.     This prevents duplication across pages where scores are equal across a large     number of businesses.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guideApiBusinessList(
      ordering?: string,
      page?: number,
      pageSize?: number,
      options?: any
    ): AxiosPromise<PaginatedBusinessWithHighlightsSerialiserList> {
      return localVarFp
        .guideApiBusinessList(ordering, page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get/List businesses filterable by top-level attributes, with additional with filter metadata.  Note:     Businesses are ordered by descending `score` first, then `business_code` where scores are equal.     This prevents duplication across pages where scores are equal across a large     number of businesses.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guideApiBusinessRetrieve(
      id: string,
      options?: any
    ): AxiosPromise<BusinessWithHighlightsSerialiser> {
      return localVarFp
        .guideApiBusinessRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GuideApi - object-oriented interface
 * @export
 * @class GuideApi
 * @extends {BaseAPI}
 */
export class GuideApi extends BaseAPI {
  /**
   * Returns a list of popular activities in a given country.
   * @param {string} countryCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuideApi
   */
  public guideApiActivitiesPopularRetrieve(
    countryCode: string,
    options?: AxiosRequestConfig
  ) {
    return GuideApiFp(this.configuration)
      .guideApiActivitiesPopularRetrieve(countryCode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get/List businesses filterable by top-level attributes, with additional with filter metadata.  Note:     Businesses are ordered by descending `score` first, then `business_code` where scores are equal.     This prevents duplication across pages where scores are equal across a large     number of businesses.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [pageSize] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuideApi
   */
  public guideApiBusinessList(
    ordering?: string,
    page?: number,
    pageSize?: number,
    options?: AxiosRequestConfig
  ) {
    return GuideApiFp(this.configuration)
      .guideApiBusinessList(ordering, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get/List businesses filterable by top-level attributes, with additional with filter metadata.  Note:     Businesses are ordered by descending `score` first, then `business_code` where scores are equal.     This prevents duplication across pages where scores are equal across a large     number of businesses.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuideApi
   */
  public guideApiBusinessRetrieve(id: string, options?: AxiosRequestConfig) {
    return GuideApiFp(this.configuration)
      .guideApiBusinessRetrieve(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * InventoryApi - axios parameter creator
 * @export
 */
export const InventoryApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} businessCode
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryCreate: async (
      businessCode: string,
      type: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("inventoryCreate", "businessCode", businessCode);
      // verify required parameter 'type' is not null or undefined
      assertParamExists("inventoryCreate", "type", type);
      const localVarPath = `/inventory/{type}/{business_code}/`
        .replace(`{${"business_code"}}`, encodeURIComponent(String(businessCode)))
        .replace(`{${"type"}}`, encodeURIComponent(String(type)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} id
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryDestroy: async (
      businessCode: string,
      id: number,
      type: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("inventoryDestroy", "businessCode", businessCode);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("inventoryDestroy", "id", id);
      // verify required parameter 'type' is not null or undefined
      assertParamExists("inventoryDestroy", "type", type);
      const localVarPath = `/inventory/{type}/{business_code}/{id}/`
        .replace(`{${"business_code"}}`, encodeURIComponent(String(businessCode)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"type"}}`, encodeURIComponent(String(type)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} id
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryPartialUpdate: async (
      businessCode: string,
      id: number,
      type: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("inventoryPartialUpdate", "businessCode", businessCode);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("inventoryPartialUpdate", "id", id);
      // verify required parameter 'type' is not null or undefined
      assertParamExists("inventoryPartialUpdate", "type", type);
      const localVarPath = `/inventory/{type}/{business_code}/{id}/`
        .replace(`{${"business_code"}}`, encodeURIComponent(String(businessCode)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"type"}}`, encodeURIComponent(String(type)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} businessCode
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryRetrieve: async (
      businessCode: string,
      type: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("inventoryRetrieve", "businessCode", businessCode);
      // verify required parameter 'type' is not null or undefined
      assertParamExists("inventoryRetrieve", "type", type);
      const localVarPath = `/inventory/{type}/{business_code}/`
        .replace(`{${"business_code"}}`, encodeURIComponent(String(businessCode)))
        .replace(`{${"type"}}`, encodeURIComponent(String(type)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} id
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryRetrieve2: async (
      businessCode: string,
      id: number,
      type: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("inventoryRetrieve2", "businessCode", businessCode);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("inventoryRetrieve2", "id", id);
      // verify required parameter 'type' is not null or undefined
      assertParamExists("inventoryRetrieve2", "type", type);
      const localVarPath = `/inventory/{type}/{business_code}/{id}/`
        .replace(`{${"business_code"}}`, encodeURIComponent(String(businessCode)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"type"}}`, encodeURIComponent(String(type)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} id
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryUpdate: async (
      businessCode: string,
      id: number,
      type: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("inventoryUpdate", "businessCode", businessCode);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("inventoryUpdate", "id", id);
      // verify required parameter 'type' is not null or undefined
      assertParamExists("inventoryUpdate", "type", type);
      const localVarPath = `/inventory/{type}/{business_code}/{id}/`
        .replace(`{${"business_code"}}`, encodeURIComponent(String(businessCode)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"type"}}`, encodeURIComponent(String(type)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InventoryApi - functional programming interface
 * @export
 */
export const InventoryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InventoryApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} businessCode
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inventoryCreate(
      businessCode: string,
      type: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryCreate(
        businessCode,
        type,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} id
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inventoryDestroy(
      businessCode: string,
      id: number,
      type: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryDestroy(
        businessCode,
        id,
        type,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} id
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inventoryPartialUpdate(
      businessCode: string,
      id: number,
      type: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryPartialUpdate(
        businessCode,
        id,
        type,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} businessCode
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inventoryRetrieve(
      businessCode: string,
      type: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryRetrieve(
        businessCode,
        type,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} id
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inventoryRetrieve2(
      businessCode: string,
      id: number,
      type: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryRetrieve2(
        businessCode,
        id,
        type,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} id
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inventoryUpdate(
      businessCode: string,
      id: number,
      type: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryUpdate(
        businessCode,
        id,
        type,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * InventoryApi - factory interface
 * @export
 */
export const InventoryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = InventoryApiFp(configuration);
  return {
    /**
     *
     * @param {string} businessCode
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryCreate(
      businessCode: string,
      type: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .inventoryCreate(businessCode, type, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} id
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryDestroy(
      businessCode: string,
      id: number,
      type: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .inventoryDestroy(businessCode, id, type, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} id
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryPartialUpdate(
      businessCode: string,
      id: number,
      type: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .inventoryPartialUpdate(businessCode, id, type, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} businessCode
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryRetrieve(
      businessCode: string,
      type: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .inventoryRetrieve(businessCode, type, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} id
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryRetrieve2(
      businessCode: string,
      id: number,
      type: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .inventoryRetrieve2(businessCode, id, type, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} id
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryUpdate(
      businessCode: string,
      id: number,
      type: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .inventoryUpdate(businessCode, id, type, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InventoryApi - object-oriented interface
 * @export
 * @class InventoryApi
 * @extends {BaseAPI}
 */
export class InventoryApi extends BaseAPI {
  /**
   *
   * @param {string} businessCode
   * @param {string} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public inventoryCreate(
    businessCode: string,
    type: string,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .inventoryCreate(businessCode, type, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} businessCode
   * @param {number} id
   * @param {string} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public inventoryDestroy(
    businessCode: string,
    id: number,
    type: string,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .inventoryDestroy(businessCode, id, type, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} businessCode
   * @param {number} id
   * @param {string} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public inventoryPartialUpdate(
    businessCode: string,
    id: number,
    type: string,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .inventoryPartialUpdate(businessCode, id, type, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} businessCode
   * @param {string} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public inventoryRetrieve(
    businessCode: string,
    type: string,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .inventoryRetrieve(businessCode, type, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} businessCode
   * @param {number} id
   * @param {string} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public inventoryRetrieve2(
    businessCode: string,
    id: number,
    type: string,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .inventoryRetrieve2(businessCode, id, type, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} businessCode
   * @param {number} id
   * @param {string} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public inventoryUpdate(
    businessCode: string,
    id: number,
    type: string,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .inventoryUpdate(businessCode, id, type, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ItemBookingEnquiryApi - axios parameter creator
 * @export
 */
export const ItemBookingEnquiryApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} businessCode
     * @param {string} itemType
     * @param {ItemBookingSerialiserRequest} itemBookingSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    itemBookingEnquiryCreate: async (
      businessCode: string,
      itemType: string,
      itemBookingSerialiserRequest: ItemBookingSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("itemBookingEnquiryCreate", "businessCode", businessCode);
      // verify required parameter 'itemType' is not null or undefined
      assertParamExists("itemBookingEnquiryCreate", "itemType", itemType);
      // verify required parameter 'itemBookingSerialiserRequest' is not null or undefined
      assertParamExists(
        "itemBookingEnquiryCreate",
        "itemBookingSerialiserRequest",
        itemBookingSerialiserRequest
      );
      const localVarPath = `/item_booking_enquiry/{business_code}/{item_type}/`
        .replace(`{${"business_code"}}`, encodeURIComponent(String(businessCode)))
        .replace(`{${"item_type"}}`, encodeURIComponent(String(itemType)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        itemBookingSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ItemBookingEnquiryApi - functional programming interface
 * @export
 */
export const ItemBookingEnquiryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ItemBookingEnquiryApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} businessCode
     * @param {string} itemType
     * @param {ItemBookingSerialiserRequest} itemBookingSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async itemBookingEnquiryCreate(
      businessCode: string,
      itemType: string,
      itemBookingSerialiserRequest: ItemBookingSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemBookingSerialiser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.itemBookingEnquiryCreate(
        businessCode,
        itemType,
        itemBookingSerialiserRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * ItemBookingEnquiryApi - factory interface
 * @export
 */
export const ItemBookingEnquiryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ItemBookingEnquiryApiFp(configuration);
  return {
    /**
     *
     * @param {string} businessCode
     * @param {string} itemType
     * @param {ItemBookingSerialiserRequest} itemBookingSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    itemBookingEnquiryCreate(
      businessCode: string,
      itemType: string,
      itemBookingSerialiserRequest: ItemBookingSerialiserRequest,
      options?: any
    ): AxiosPromise<ItemBookingSerialiser> {
      return localVarFp
        .itemBookingEnquiryCreate(
          businessCode,
          itemType,
          itemBookingSerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ItemBookingEnquiryApi - object-oriented interface
 * @export
 * @class ItemBookingEnquiryApi
 * @extends {BaseAPI}
 */
export class ItemBookingEnquiryApi extends BaseAPI {
  /**
   *
   * @param {string} businessCode
   * @param {string} itemType
   * @param {ItemBookingSerialiserRequest} itemBookingSerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ItemBookingEnquiryApi
   */
  public itemBookingEnquiryCreate(
    businessCode: string,
    itemType: string,
    itemBookingSerialiserRequest: ItemBookingSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return ItemBookingEnquiryApiFp(this.configuration)
      .itemBookingEnquiryCreate(
        businessCode,
        itemType,
        itemBookingSerialiserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PostsApi - axios parameter creator
 * @export
 */
export const PostsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postsDestroy: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("postsDestroy", "id", id);
      const localVarPath = `/posts/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [businessProfileBusinessCode]
     * @param {'AD' | 'AE' | 'AF' | 'AG' | 'AI' | 'AL' | 'AM' | 'AO' | 'AQ' | 'AR' | 'AS' | 'AT' | 'AU' | 'AW' | 'AX' | 'AZ' | 'BA' | 'BB' | 'BD' | 'BE' | 'BF' | 'BG' | 'BH' | 'BI' | 'BJ' | 'BL' | 'BM' | 'BN' | 'BO' | 'BQ' | 'BR' | 'BS' | 'BT' | 'BV' | 'BW' | 'BY' | 'BZ' | 'CA' | 'CC' | 'CD' | 'CF' | 'CG' | 'CH' | 'CI' | 'CK' | 'CL' | 'CM' | 'CN' | 'CO' | 'CR' | 'CU' | 'CV' | 'CW' | 'CX' | 'CY' | 'CZ' | 'DE' | 'DJ' | 'DK' | 'DM' | 'DO' | 'DZ' | 'EC' | 'EE' | 'EG' | 'EH' | 'ER' | 'ES' | 'ET' | 'FI' | 'FJ' | 'FK' | 'FM' | 'FO' | 'FR' | 'GA' | 'GB' | 'GD' | 'GE' | 'GF' | 'GG' | 'GH' | 'GI' | 'GL' | 'GM' | 'GN' | 'GP' | 'GQ' | 'GR' | 'GS' | 'GT' | 'GU' | 'GW' | 'GY' | 'HK' | 'HM' | 'HN' | 'HR' | 'HT' | 'HU' | 'ID' | 'IE' | 'IL' | 'IM' | 'IN' | 'IO' | 'IQ' | 'IR' | 'IS' | 'IT' | 'JE' | 'JM' | 'JO' | 'JP' | 'KE' | 'KG' | 'KH' | 'KI' | 'KM' | 'KN' | 'KP' | 'KR' | 'KW' | 'KY' | 'KZ' | 'LA' | 'LB' | 'LC' | 'LI' | 'LK' | 'LR' | 'LS' | 'LT' | 'LU' | 'LV' | 'LY' | 'MA' | 'MC' | 'MD' | 'ME' | 'MF' | 'MG' | 'MH' | 'MK' | 'ML' | 'MM' | 'MN' | 'MO' | 'MP' | 'MQ' | 'MR' | 'MS' | 'MT' | 'MU' | 'MV' | 'MW' | 'MX' | 'MY' | 'MZ' | 'NA' | 'NC' | 'NE' | 'NF' | 'NG' | 'NI' | 'NL' | 'NO' | 'NP' | 'NR' | 'NU' | 'NZ' | 'OM' | 'PA' | 'PE' | 'PF' | 'PG' | 'PH' | 'PK' | 'PL' | 'PM' | 'PN' | 'PR' | 'PS' | 'PT' | 'PW' | 'PY' | 'QA' | 'RE' | 'RO' | 'RS' | 'RU' | 'RW' | 'SA' | 'SB' | 'SC' | 'SD' | 'SE' | 'SG' | 'SH' | 'SI' | 'SJ' | 'SK' | 'SL' | 'SM' | 'SN' | 'SO' | 'SR' | 'SS' | 'ST' | 'SV' | 'SX' | 'SY' | 'SZ' | 'TB' | 'TC' | 'TD' | 'TF' | 'TG' | 'TH' | 'TJ' | 'TK' | 'TL' | 'TM' | 'TN' | 'TO' | 'TR' | 'TT' | 'TV' | 'TW' | 'TZ' | 'UA' | 'UG' | 'UM' | 'US' | 'UY' | 'UZ' | 'VA' | 'VC' | 'VE' | 'VG' | 'VI' | 'VN' | 'VU' | 'WF' | 'WS' | 'XK' | 'YE' | 'YT' | 'ZA' | 'ZM' | 'ZW'} [businessProfileCountry]
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {boolean} [_public]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postsList: async (
      businessProfileBusinessCode?: string,
      businessProfileCountry?:
        | "AD"
        | "AE"
        | "AF"
        | "AG"
        | "AI"
        | "AL"
        | "AM"
        | "AO"
        | "AQ"
        | "AR"
        | "AS"
        | "AT"
        | "AU"
        | "AW"
        | "AX"
        | "AZ"
        | "BA"
        | "BB"
        | "BD"
        | "BE"
        | "BF"
        | "BG"
        | "BH"
        | "BI"
        | "BJ"
        | "BL"
        | "BM"
        | "BN"
        | "BO"
        | "BQ"
        | "BR"
        | "BS"
        | "BT"
        | "BV"
        | "BW"
        | "BY"
        | "BZ"
        | "CA"
        | "CC"
        | "CD"
        | "CF"
        | "CG"
        | "CH"
        | "CI"
        | "CK"
        | "CL"
        | "CM"
        | "CN"
        | "CO"
        | "CR"
        | "CU"
        | "CV"
        | "CW"
        | "CX"
        | "CY"
        | "CZ"
        | "DE"
        | "DJ"
        | "DK"
        | "DM"
        | "DO"
        | "DZ"
        | "EC"
        | "EE"
        | "EG"
        | "EH"
        | "ER"
        | "ES"
        | "ET"
        | "FI"
        | "FJ"
        | "FK"
        | "FM"
        | "FO"
        | "FR"
        | "GA"
        | "GB"
        | "GD"
        | "GE"
        | "GF"
        | "GG"
        | "GH"
        | "GI"
        | "GL"
        | "GM"
        | "GN"
        | "GP"
        | "GQ"
        | "GR"
        | "GS"
        | "GT"
        | "GU"
        | "GW"
        | "GY"
        | "HK"
        | "HM"
        | "HN"
        | "HR"
        | "HT"
        | "HU"
        | "ID"
        | "IE"
        | "IL"
        | "IM"
        | "IN"
        | "IO"
        | "IQ"
        | "IR"
        | "IS"
        | "IT"
        | "JE"
        | "JM"
        | "JO"
        | "JP"
        | "KE"
        | "KG"
        | "KH"
        | "KI"
        | "KM"
        | "KN"
        | "KP"
        | "KR"
        | "KW"
        | "KY"
        | "KZ"
        | "LA"
        | "LB"
        | "LC"
        | "LI"
        | "LK"
        | "LR"
        | "LS"
        | "LT"
        | "LU"
        | "LV"
        | "LY"
        | "MA"
        | "MC"
        | "MD"
        | "ME"
        | "MF"
        | "MG"
        | "MH"
        | "MK"
        | "ML"
        | "MM"
        | "MN"
        | "MO"
        | "MP"
        | "MQ"
        | "MR"
        | "MS"
        | "MT"
        | "MU"
        | "MV"
        | "MW"
        | "MX"
        | "MY"
        | "MZ"
        | "NA"
        | "NC"
        | "NE"
        | "NF"
        | "NG"
        | "NI"
        | "NL"
        | "NO"
        | "NP"
        | "NR"
        | "NU"
        | "NZ"
        | "OM"
        | "PA"
        | "PE"
        | "PF"
        | "PG"
        | "PH"
        | "PK"
        | "PL"
        | "PM"
        | "PN"
        | "PR"
        | "PS"
        | "PT"
        | "PW"
        | "PY"
        | "QA"
        | "RE"
        | "RO"
        | "RS"
        | "RU"
        | "RW"
        | "SA"
        | "SB"
        | "SC"
        | "SD"
        | "SE"
        | "SG"
        | "SH"
        | "SI"
        | "SJ"
        | "SK"
        | "SL"
        | "SM"
        | "SN"
        | "SO"
        | "SR"
        | "SS"
        | "ST"
        | "SV"
        | "SX"
        | "SY"
        | "SZ"
        | "TB"
        | "TC"
        | "TD"
        | "TF"
        | "TG"
        | "TH"
        | "TJ"
        | "TK"
        | "TL"
        | "TM"
        | "TN"
        | "TO"
        | "TR"
        | "TT"
        | "TV"
        | "TW"
        | "TZ"
        | "UA"
        | "UG"
        | "UM"
        | "US"
        | "UY"
        | "UZ"
        | "VA"
        | "VC"
        | "VE"
        | "VG"
        | "VI"
        | "VN"
        | "VU"
        | "WF"
        | "WS"
        | "XK"
        | "YE"
        | "YT"
        | "ZA"
        | "ZM"
        | "ZW",
      ordering?: string,
      page?: number,
      _public?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/posts/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (businessProfileBusinessCode !== undefined) {
        localVarQueryParameter["business_profile__business_code"] =
          businessProfileBusinessCode;
      }

      if (businessProfileCountry !== undefined) {
        localVarQueryParameter["business_profile__country"] = businessProfileCountry;
      }

      if (ordering !== undefined) {
        localVarQueryParameter["ordering"] = ordering;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (_public !== undefined) {
        localVarQueryParameter["public"] = _public;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PostsApi - functional programming interface
 * @export
 */
export const PostsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PostsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postsDestroy(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postsDestroy(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [businessProfileBusinessCode]
     * @param {'AD' | 'AE' | 'AF' | 'AG' | 'AI' | 'AL' | 'AM' | 'AO' | 'AQ' | 'AR' | 'AS' | 'AT' | 'AU' | 'AW' | 'AX' | 'AZ' | 'BA' | 'BB' | 'BD' | 'BE' | 'BF' | 'BG' | 'BH' | 'BI' | 'BJ' | 'BL' | 'BM' | 'BN' | 'BO' | 'BQ' | 'BR' | 'BS' | 'BT' | 'BV' | 'BW' | 'BY' | 'BZ' | 'CA' | 'CC' | 'CD' | 'CF' | 'CG' | 'CH' | 'CI' | 'CK' | 'CL' | 'CM' | 'CN' | 'CO' | 'CR' | 'CU' | 'CV' | 'CW' | 'CX' | 'CY' | 'CZ' | 'DE' | 'DJ' | 'DK' | 'DM' | 'DO' | 'DZ' | 'EC' | 'EE' | 'EG' | 'EH' | 'ER' | 'ES' | 'ET' | 'FI' | 'FJ' | 'FK' | 'FM' | 'FO' | 'FR' | 'GA' | 'GB' | 'GD' | 'GE' | 'GF' | 'GG' | 'GH' | 'GI' | 'GL' | 'GM' | 'GN' | 'GP' | 'GQ' | 'GR' | 'GS' | 'GT' | 'GU' | 'GW' | 'GY' | 'HK' | 'HM' | 'HN' | 'HR' | 'HT' | 'HU' | 'ID' | 'IE' | 'IL' | 'IM' | 'IN' | 'IO' | 'IQ' | 'IR' | 'IS' | 'IT' | 'JE' | 'JM' | 'JO' | 'JP' | 'KE' | 'KG' | 'KH' | 'KI' | 'KM' | 'KN' | 'KP' | 'KR' | 'KW' | 'KY' | 'KZ' | 'LA' | 'LB' | 'LC' | 'LI' | 'LK' | 'LR' | 'LS' | 'LT' | 'LU' | 'LV' | 'LY' | 'MA' | 'MC' | 'MD' | 'ME' | 'MF' | 'MG' | 'MH' | 'MK' | 'ML' | 'MM' | 'MN' | 'MO' | 'MP' | 'MQ' | 'MR' | 'MS' | 'MT' | 'MU' | 'MV' | 'MW' | 'MX' | 'MY' | 'MZ' | 'NA' | 'NC' | 'NE' | 'NF' | 'NG' | 'NI' | 'NL' | 'NO' | 'NP' | 'NR' | 'NU' | 'NZ' | 'OM' | 'PA' | 'PE' | 'PF' | 'PG' | 'PH' | 'PK' | 'PL' | 'PM' | 'PN' | 'PR' | 'PS' | 'PT' | 'PW' | 'PY' | 'QA' | 'RE' | 'RO' | 'RS' | 'RU' | 'RW' | 'SA' | 'SB' | 'SC' | 'SD' | 'SE' | 'SG' | 'SH' | 'SI' | 'SJ' | 'SK' | 'SL' | 'SM' | 'SN' | 'SO' | 'SR' | 'SS' | 'ST' | 'SV' | 'SX' | 'SY' | 'SZ' | 'TB' | 'TC' | 'TD' | 'TF' | 'TG' | 'TH' | 'TJ' | 'TK' | 'TL' | 'TM' | 'TN' | 'TO' | 'TR' | 'TT' | 'TV' | 'TW' | 'TZ' | 'UA' | 'UG' | 'UM' | 'US' | 'UY' | 'UZ' | 'VA' | 'VC' | 'VE' | 'VG' | 'VI' | 'VN' | 'VU' | 'WF' | 'WS' | 'XK' | 'YE' | 'YT' | 'ZA' | 'ZM' | 'ZW'} [businessProfileCountry]
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {boolean} [_public]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postsList(
      businessProfileBusinessCode?: string,
      businessProfileCountry?:
        | "AD"
        | "AE"
        | "AF"
        | "AG"
        | "AI"
        | "AL"
        | "AM"
        | "AO"
        | "AQ"
        | "AR"
        | "AS"
        | "AT"
        | "AU"
        | "AW"
        | "AX"
        | "AZ"
        | "BA"
        | "BB"
        | "BD"
        | "BE"
        | "BF"
        | "BG"
        | "BH"
        | "BI"
        | "BJ"
        | "BL"
        | "BM"
        | "BN"
        | "BO"
        | "BQ"
        | "BR"
        | "BS"
        | "BT"
        | "BV"
        | "BW"
        | "BY"
        | "BZ"
        | "CA"
        | "CC"
        | "CD"
        | "CF"
        | "CG"
        | "CH"
        | "CI"
        | "CK"
        | "CL"
        | "CM"
        | "CN"
        | "CO"
        | "CR"
        | "CU"
        | "CV"
        | "CW"
        | "CX"
        | "CY"
        | "CZ"
        | "DE"
        | "DJ"
        | "DK"
        | "DM"
        | "DO"
        | "DZ"
        | "EC"
        | "EE"
        | "EG"
        | "EH"
        | "ER"
        | "ES"
        | "ET"
        | "FI"
        | "FJ"
        | "FK"
        | "FM"
        | "FO"
        | "FR"
        | "GA"
        | "GB"
        | "GD"
        | "GE"
        | "GF"
        | "GG"
        | "GH"
        | "GI"
        | "GL"
        | "GM"
        | "GN"
        | "GP"
        | "GQ"
        | "GR"
        | "GS"
        | "GT"
        | "GU"
        | "GW"
        | "GY"
        | "HK"
        | "HM"
        | "HN"
        | "HR"
        | "HT"
        | "HU"
        | "ID"
        | "IE"
        | "IL"
        | "IM"
        | "IN"
        | "IO"
        | "IQ"
        | "IR"
        | "IS"
        | "IT"
        | "JE"
        | "JM"
        | "JO"
        | "JP"
        | "KE"
        | "KG"
        | "KH"
        | "KI"
        | "KM"
        | "KN"
        | "KP"
        | "KR"
        | "KW"
        | "KY"
        | "KZ"
        | "LA"
        | "LB"
        | "LC"
        | "LI"
        | "LK"
        | "LR"
        | "LS"
        | "LT"
        | "LU"
        | "LV"
        | "LY"
        | "MA"
        | "MC"
        | "MD"
        | "ME"
        | "MF"
        | "MG"
        | "MH"
        | "MK"
        | "ML"
        | "MM"
        | "MN"
        | "MO"
        | "MP"
        | "MQ"
        | "MR"
        | "MS"
        | "MT"
        | "MU"
        | "MV"
        | "MW"
        | "MX"
        | "MY"
        | "MZ"
        | "NA"
        | "NC"
        | "NE"
        | "NF"
        | "NG"
        | "NI"
        | "NL"
        | "NO"
        | "NP"
        | "NR"
        | "NU"
        | "NZ"
        | "OM"
        | "PA"
        | "PE"
        | "PF"
        | "PG"
        | "PH"
        | "PK"
        | "PL"
        | "PM"
        | "PN"
        | "PR"
        | "PS"
        | "PT"
        | "PW"
        | "PY"
        | "QA"
        | "RE"
        | "RO"
        | "RS"
        | "RU"
        | "RW"
        | "SA"
        | "SB"
        | "SC"
        | "SD"
        | "SE"
        | "SG"
        | "SH"
        | "SI"
        | "SJ"
        | "SK"
        | "SL"
        | "SM"
        | "SN"
        | "SO"
        | "SR"
        | "SS"
        | "ST"
        | "SV"
        | "SX"
        | "SY"
        | "SZ"
        | "TB"
        | "TC"
        | "TD"
        | "TF"
        | "TG"
        | "TH"
        | "TJ"
        | "TK"
        | "TL"
        | "TM"
        | "TN"
        | "TO"
        | "TR"
        | "TT"
        | "TV"
        | "TW"
        | "TZ"
        | "UA"
        | "UG"
        | "UM"
        | "US"
        | "UY"
        | "UZ"
        | "VA"
        | "VC"
        | "VE"
        | "VG"
        | "VI"
        | "VN"
        | "VU"
        | "WF"
        | "WS"
        | "XK"
        | "YE"
        | "YT"
        | "ZA"
        | "ZM"
        | "ZW",
      ordering?: string,
      page?: number,
      _public?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedBusinessUpdateSerialiserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postsList(
        businessProfileBusinessCode,
        businessProfileCountry,
        ordering,
        page,
        _public,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * PostsApi - factory interface
 * @export
 */
export const PostsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PostsApiFp(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postsDestroy(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .postsDestroy(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [businessProfileBusinessCode]
     * @param {'AD' | 'AE' | 'AF' | 'AG' | 'AI' | 'AL' | 'AM' | 'AO' | 'AQ' | 'AR' | 'AS' | 'AT' | 'AU' | 'AW' | 'AX' | 'AZ' | 'BA' | 'BB' | 'BD' | 'BE' | 'BF' | 'BG' | 'BH' | 'BI' | 'BJ' | 'BL' | 'BM' | 'BN' | 'BO' | 'BQ' | 'BR' | 'BS' | 'BT' | 'BV' | 'BW' | 'BY' | 'BZ' | 'CA' | 'CC' | 'CD' | 'CF' | 'CG' | 'CH' | 'CI' | 'CK' | 'CL' | 'CM' | 'CN' | 'CO' | 'CR' | 'CU' | 'CV' | 'CW' | 'CX' | 'CY' | 'CZ' | 'DE' | 'DJ' | 'DK' | 'DM' | 'DO' | 'DZ' | 'EC' | 'EE' | 'EG' | 'EH' | 'ER' | 'ES' | 'ET' | 'FI' | 'FJ' | 'FK' | 'FM' | 'FO' | 'FR' | 'GA' | 'GB' | 'GD' | 'GE' | 'GF' | 'GG' | 'GH' | 'GI' | 'GL' | 'GM' | 'GN' | 'GP' | 'GQ' | 'GR' | 'GS' | 'GT' | 'GU' | 'GW' | 'GY' | 'HK' | 'HM' | 'HN' | 'HR' | 'HT' | 'HU' | 'ID' | 'IE' | 'IL' | 'IM' | 'IN' | 'IO' | 'IQ' | 'IR' | 'IS' | 'IT' | 'JE' | 'JM' | 'JO' | 'JP' | 'KE' | 'KG' | 'KH' | 'KI' | 'KM' | 'KN' | 'KP' | 'KR' | 'KW' | 'KY' | 'KZ' | 'LA' | 'LB' | 'LC' | 'LI' | 'LK' | 'LR' | 'LS' | 'LT' | 'LU' | 'LV' | 'LY' | 'MA' | 'MC' | 'MD' | 'ME' | 'MF' | 'MG' | 'MH' | 'MK' | 'ML' | 'MM' | 'MN' | 'MO' | 'MP' | 'MQ' | 'MR' | 'MS' | 'MT' | 'MU' | 'MV' | 'MW' | 'MX' | 'MY' | 'MZ' | 'NA' | 'NC' | 'NE' | 'NF' | 'NG' | 'NI' | 'NL' | 'NO' | 'NP' | 'NR' | 'NU' | 'NZ' | 'OM' | 'PA' | 'PE' | 'PF' | 'PG' | 'PH' | 'PK' | 'PL' | 'PM' | 'PN' | 'PR' | 'PS' | 'PT' | 'PW' | 'PY' | 'QA' | 'RE' | 'RO' | 'RS' | 'RU' | 'RW' | 'SA' | 'SB' | 'SC' | 'SD' | 'SE' | 'SG' | 'SH' | 'SI' | 'SJ' | 'SK' | 'SL' | 'SM' | 'SN' | 'SO' | 'SR' | 'SS' | 'ST' | 'SV' | 'SX' | 'SY' | 'SZ' | 'TB' | 'TC' | 'TD' | 'TF' | 'TG' | 'TH' | 'TJ' | 'TK' | 'TL' | 'TM' | 'TN' | 'TO' | 'TR' | 'TT' | 'TV' | 'TW' | 'TZ' | 'UA' | 'UG' | 'UM' | 'US' | 'UY' | 'UZ' | 'VA' | 'VC' | 'VE' | 'VG' | 'VI' | 'VN' | 'VU' | 'WF' | 'WS' | 'XK' | 'YE' | 'YT' | 'ZA' | 'ZM' | 'ZW'} [businessProfileCountry]
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {boolean} [_public]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postsList(
      businessProfileBusinessCode?: string,
      businessProfileCountry?:
        | "AD"
        | "AE"
        | "AF"
        | "AG"
        | "AI"
        | "AL"
        | "AM"
        | "AO"
        | "AQ"
        | "AR"
        | "AS"
        | "AT"
        | "AU"
        | "AW"
        | "AX"
        | "AZ"
        | "BA"
        | "BB"
        | "BD"
        | "BE"
        | "BF"
        | "BG"
        | "BH"
        | "BI"
        | "BJ"
        | "BL"
        | "BM"
        | "BN"
        | "BO"
        | "BQ"
        | "BR"
        | "BS"
        | "BT"
        | "BV"
        | "BW"
        | "BY"
        | "BZ"
        | "CA"
        | "CC"
        | "CD"
        | "CF"
        | "CG"
        | "CH"
        | "CI"
        | "CK"
        | "CL"
        | "CM"
        | "CN"
        | "CO"
        | "CR"
        | "CU"
        | "CV"
        | "CW"
        | "CX"
        | "CY"
        | "CZ"
        | "DE"
        | "DJ"
        | "DK"
        | "DM"
        | "DO"
        | "DZ"
        | "EC"
        | "EE"
        | "EG"
        | "EH"
        | "ER"
        | "ES"
        | "ET"
        | "FI"
        | "FJ"
        | "FK"
        | "FM"
        | "FO"
        | "FR"
        | "GA"
        | "GB"
        | "GD"
        | "GE"
        | "GF"
        | "GG"
        | "GH"
        | "GI"
        | "GL"
        | "GM"
        | "GN"
        | "GP"
        | "GQ"
        | "GR"
        | "GS"
        | "GT"
        | "GU"
        | "GW"
        | "GY"
        | "HK"
        | "HM"
        | "HN"
        | "HR"
        | "HT"
        | "HU"
        | "ID"
        | "IE"
        | "IL"
        | "IM"
        | "IN"
        | "IO"
        | "IQ"
        | "IR"
        | "IS"
        | "IT"
        | "JE"
        | "JM"
        | "JO"
        | "JP"
        | "KE"
        | "KG"
        | "KH"
        | "KI"
        | "KM"
        | "KN"
        | "KP"
        | "KR"
        | "KW"
        | "KY"
        | "KZ"
        | "LA"
        | "LB"
        | "LC"
        | "LI"
        | "LK"
        | "LR"
        | "LS"
        | "LT"
        | "LU"
        | "LV"
        | "LY"
        | "MA"
        | "MC"
        | "MD"
        | "ME"
        | "MF"
        | "MG"
        | "MH"
        | "MK"
        | "ML"
        | "MM"
        | "MN"
        | "MO"
        | "MP"
        | "MQ"
        | "MR"
        | "MS"
        | "MT"
        | "MU"
        | "MV"
        | "MW"
        | "MX"
        | "MY"
        | "MZ"
        | "NA"
        | "NC"
        | "NE"
        | "NF"
        | "NG"
        | "NI"
        | "NL"
        | "NO"
        | "NP"
        | "NR"
        | "NU"
        | "NZ"
        | "OM"
        | "PA"
        | "PE"
        | "PF"
        | "PG"
        | "PH"
        | "PK"
        | "PL"
        | "PM"
        | "PN"
        | "PR"
        | "PS"
        | "PT"
        | "PW"
        | "PY"
        | "QA"
        | "RE"
        | "RO"
        | "RS"
        | "RU"
        | "RW"
        | "SA"
        | "SB"
        | "SC"
        | "SD"
        | "SE"
        | "SG"
        | "SH"
        | "SI"
        | "SJ"
        | "SK"
        | "SL"
        | "SM"
        | "SN"
        | "SO"
        | "SR"
        | "SS"
        | "ST"
        | "SV"
        | "SX"
        | "SY"
        | "SZ"
        | "TB"
        | "TC"
        | "TD"
        | "TF"
        | "TG"
        | "TH"
        | "TJ"
        | "TK"
        | "TL"
        | "TM"
        | "TN"
        | "TO"
        | "TR"
        | "TT"
        | "TV"
        | "TW"
        | "TZ"
        | "UA"
        | "UG"
        | "UM"
        | "US"
        | "UY"
        | "UZ"
        | "VA"
        | "VC"
        | "VE"
        | "VG"
        | "VI"
        | "VN"
        | "VU"
        | "WF"
        | "WS"
        | "XK"
        | "YE"
        | "YT"
        | "ZA"
        | "ZM"
        | "ZW",
      ordering?: string,
      page?: number,
      _public?: boolean,
      options?: any
    ): AxiosPromise<PaginatedBusinessUpdateSerialiserList> {
      return localVarFp
        .postsList(
          businessProfileBusinessCode,
          businessProfileCountry,
          ordering,
          page,
          _public,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PostsApi - object-oriented interface
 * @export
 * @class PostsApi
 * @extends {BaseAPI}
 */
export class PostsApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostsApi
   */
  public postsDestroy(id: string, options?: AxiosRequestConfig) {
    return PostsApiFp(this.configuration)
      .postsDestroy(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [businessProfileBusinessCode]
   * @param {'AD' | 'AE' | 'AF' | 'AG' | 'AI' | 'AL' | 'AM' | 'AO' | 'AQ' | 'AR' | 'AS' | 'AT' | 'AU' | 'AW' | 'AX' | 'AZ' | 'BA' | 'BB' | 'BD' | 'BE' | 'BF' | 'BG' | 'BH' | 'BI' | 'BJ' | 'BL' | 'BM' | 'BN' | 'BO' | 'BQ' | 'BR' | 'BS' | 'BT' | 'BV' | 'BW' | 'BY' | 'BZ' | 'CA' | 'CC' | 'CD' | 'CF' | 'CG' | 'CH' | 'CI' | 'CK' | 'CL' | 'CM' | 'CN' | 'CO' | 'CR' | 'CU' | 'CV' | 'CW' | 'CX' | 'CY' | 'CZ' | 'DE' | 'DJ' | 'DK' | 'DM' | 'DO' | 'DZ' | 'EC' | 'EE' | 'EG' | 'EH' | 'ER' | 'ES' | 'ET' | 'FI' | 'FJ' | 'FK' | 'FM' | 'FO' | 'FR' | 'GA' | 'GB' | 'GD' | 'GE' | 'GF' | 'GG' | 'GH' | 'GI' | 'GL' | 'GM' | 'GN' | 'GP' | 'GQ' | 'GR' | 'GS' | 'GT' | 'GU' | 'GW' | 'GY' | 'HK' | 'HM' | 'HN' | 'HR' | 'HT' | 'HU' | 'ID' | 'IE' | 'IL' | 'IM' | 'IN' | 'IO' | 'IQ' | 'IR' | 'IS' | 'IT' | 'JE' | 'JM' | 'JO' | 'JP' | 'KE' | 'KG' | 'KH' | 'KI' | 'KM' | 'KN' | 'KP' | 'KR' | 'KW' | 'KY' | 'KZ' | 'LA' | 'LB' | 'LC' | 'LI' | 'LK' | 'LR' | 'LS' | 'LT' | 'LU' | 'LV' | 'LY' | 'MA' | 'MC' | 'MD' | 'ME' | 'MF' | 'MG' | 'MH' | 'MK' | 'ML' | 'MM' | 'MN' | 'MO' | 'MP' | 'MQ' | 'MR' | 'MS' | 'MT' | 'MU' | 'MV' | 'MW' | 'MX' | 'MY' | 'MZ' | 'NA' | 'NC' | 'NE' | 'NF' | 'NG' | 'NI' | 'NL' | 'NO' | 'NP' | 'NR' | 'NU' | 'NZ' | 'OM' | 'PA' | 'PE' | 'PF' | 'PG' | 'PH' | 'PK' | 'PL' | 'PM' | 'PN' | 'PR' | 'PS' | 'PT' | 'PW' | 'PY' | 'QA' | 'RE' | 'RO' | 'RS' | 'RU' | 'RW' | 'SA' | 'SB' | 'SC' | 'SD' | 'SE' | 'SG' | 'SH' | 'SI' | 'SJ' | 'SK' | 'SL' | 'SM' | 'SN' | 'SO' | 'SR' | 'SS' | 'ST' | 'SV' | 'SX' | 'SY' | 'SZ' | 'TB' | 'TC' | 'TD' | 'TF' | 'TG' | 'TH' | 'TJ' | 'TK' | 'TL' | 'TM' | 'TN' | 'TO' | 'TR' | 'TT' | 'TV' | 'TW' | 'TZ' | 'UA' | 'UG' | 'UM' | 'US' | 'UY' | 'UZ' | 'VA' | 'VC' | 'VE' | 'VG' | 'VI' | 'VN' | 'VU' | 'WF' | 'WS' | 'XK' | 'YE' | 'YT' | 'ZA' | 'ZM' | 'ZW'} [businessProfileCountry]
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {number} [page] A page number within the paginated result set.
   * @param {boolean} [_public]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostsApi
   */
  public postsList(
    businessProfileBusinessCode?: string,
    businessProfileCountry?:
      | "AD"
      | "AE"
      | "AF"
      | "AG"
      | "AI"
      | "AL"
      | "AM"
      | "AO"
      | "AQ"
      | "AR"
      | "AS"
      | "AT"
      | "AU"
      | "AW"
      | "AX"
      | "AZ"
      | "BA"
      | "BB"
      | "BD"
      | "BE"
      | "BF"
      | "BG"
      | "BH"
      | "BI"
      | "BJ"
      | "BL"
      | "BM"
      | "BN"
      | "BO"
      | "BQ"
      | "BR"
      | "BS"
      | "BT"
      | "BV"
      | "BW"
      | "BY"
      | "BZ"
      | "CA"
      | "CC"
      | "CD"
      | "CF"
      | "CG"
      | "CH"
      | "CI"
      | "CK"
      | "CL"
      | "CM"
      | "CN"
      | "CO"
      | "CR"
      | "CU"
      | "CV"
      | "CW"
      | "CX"
      | "CY"
      | "CZ"
      | "DE"
      | "DJ"
      | "DK"
      | "DM"
      | "DO"
      | "DZ"
      | "EC"
      | "EE"
      | "EG"
      | "EH"
      | "ER"
      | "ES"
      | "ET"
      | "FI"
      | "FJ"
      | "FK"
      | "FM"
      | "FO"
      | "FR"
      | "GA"
      | "GB"
      | "GD"
      | "GE"
      | "GF"
      | "GG"
      | "GH"
      | "GI"
      | "GL"
      | "GM"
      | "GN"
      | "GP"
      | "GQ"
      | "GR"
      | "GS"
      | "GT"
      | "GU"
      | "GW"
      | "GY"
      | "HK"
      | "HM"
      | "HN"
      | "HR"
      | "HT"
      | "HU"
      | "ID"
      | "IE"
      | "IL"
      | "IM"
      | "IN"
      | "IO"
      | "IQ"
      | "IR"
      | "IS"
      | "IT"
      | "JE"
      | "JM"
      | "JO"
      | "JP"
      | "KE"
      | "KG"
      | "KH"
      | "KI"
      | "KM"
      | "KN"
      | "KP"
      | "KR"
      | "KW"
      | "KY"
      | "KZ"
      | "LA"
      | "LB"
      | "LC"
      | "LI"
      | "LK"
      | "LR"
      | "LS"
      | "LT"
      | "LU"
      | "LV"
      | "LY"
      | "MA"
      | "MC"
      | "MD"
      | "ME"
      | "MF"
      | "MG"
      | "MH"
      | "MK"
      | "ML"
      | "MM"
      | "MN"
      | "MO"
      | "MP"
      | "MQ"
      | "MR"
      | "MS"
      | "MT"
      | "MU"
      | "MV"
      | "MW"
      | "MX"
      | "MY"
      | "MZ"
      | "NA"
      | "NC"
      | "NE"
      | "NF"
      | "NG"
      | "NI"
      | "NL"
      | "NO"
      | "NP"
      | "NR"
      | "NU"
      | "NZ"
      | "OM"
      | "PA"
      | "PE"
      | "PF"
      | "PG"
      | "PH"
      | "PK"
      | "PL"
      | "PM"
      | "PN"
      | "PR"
      | "PS"
      | "PT"
      | "PW"
      | "PY"
      | "QA"
      | "RE"
      | "RO"
      | "RS"
      | "RU"
      | "RW"
      | "SA"
      | "SB"
      | "SC"
      | "SD"
      | "SE"
      | "SG"
      | "SH"
      | "SI"
      | "SJ"
      | "SK"
      | "SL"
      | "SM"
      | "SN"
      | "SO"
      | "SR"
      | "SS"
      | "ST"
      | "SV"
      | "SX"
      | "SY"
      | "SZ"
      | "TB"
      | "TC"
      | "TD"
      | "TF"
      | "TG"
      | "TH"
      | "TJ"
      | "TK"
      | "TL"
      | "TM"
      | "TN"
      | "TO"
      | "TR"
      | "TT"
      | "TV"
      | "TW"
      | "TZ"
      | "UA"
      | "UG"
      | "UM"
      | "US"
      | "UY"
      | "UZ"
      | "VA"
      | "VC"
      | "VE"
      | "VG"
      | "VI"
      | "VN"
      | "VU"
      | "WF"
      | "WS"
      | "XK"
      | "YE"
      | "YT"
      | "ZA"
      | "ZM"
      | "ZW",
    ordering?: string,
    page?: number,
    _public?: boolean,
    options?: AxiosRequestConfig
  ) {
    return PostsApiFp(this.configuration)
      .postsList(
        businessProfileBusinessCode,
        businessProfileCountry,
        ordering,
        page,
        _public,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * RecommendationApi - axios parameter creator
 * @export
 */
export const RecommendationApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateRecommendationSerialiserRequest} createRecommendationSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recommendationCreateCreate: async (
      createRecommendationSerialiserRequest: CreateRecommendationSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createRecommendationSerialiserRequest' is not null or undefined
      assertParamExists(
        "recommendationCreateCreate",
        "createRecommendationSerialiserRequest",
        createRecommendationSerialiserRequest
      );
      const localVarPath = `/recommendation/create/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRecommendationSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recommendationDeleteDestroy: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("recommendationDeleteDestroy", "id", id);
      const localVarPath = `/recommendation/delete/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {PatchedCreateRecommendationSerialiserRequest} [patchedCreateRecommendationSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recommendationEditPartialUpdate: async (
      id: number,
      patchedCreateRecommendationSerialiserRequest?: PatchedCreateRecommendationSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("recommendationEditPartialUpdate", "id", id);
      const localVarPath = `/recommendation/edit/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedCreateRecommendationSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {CreateRecommendationSerialiserRequest} createRecommendationSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recommendationEditUpdate: async (
      id: number,
      createRecommendationSerialiserRequest: CreateRecommendationSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("recommendationEditUpdate", "id", id);
      // verify required parameter 'createRecommendationSerialiserRequest' is not null or undefined
      assertParamExists(
        "recommendationEditUpdate",
        "createRecommendationSerialiserRequest",
        createRecommendationSerialiserRequest
      );
      const localVarPath = `/recommendation/edit/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRecommendationSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RecommendationApi - functional programming interface
 * @export
 */
export const RecommendationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RecommendationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateRecommendationSerialiserRequest} createRecommendationSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recommendationCreateCreate(
      createRecommendationSerialiserRequest: CreateRecommendationSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateRecommendationSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.recommendationCreateCreate(
          createRecommendationSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recommendationDeleteDestroy(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.recommendationDeleteDestroy(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {PatchedCreateRecommendationSerialiserRequest} [patchedCreateRecommendationSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recommendationEditPartialUpdate(
      id: number,
      patchedCreateRecommendationSerialiserRequest?: PatchedCreateRecommendationSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateRecommendationSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.recommendationEditPartialUpdate(
          id,
          patchedCreateRecommendationSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {CreateRecommendationSerialiserRequest} createRecommendationSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recommendationEditUpdate(
      id: number,
      createRecommendationSerialiserRequest: CreateRecommendationSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateRecommendationSerialiser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recommendationEditUpdate(
        id,
        createRecommendationSerialiserRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * RecommendationApi - factory interface
 * @export
 */
export const RecommendationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = RecommendationApiFp(configuration);
  return {
    /**
     *
     * @param {CreateRecommendationSerialiserRequest} createRecommendationSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recommendationCreateCreate(
      createRecommendationSerialiserRequest: CreateRecommendationSerialiserRequest,
      options?: any
    ): AxiosPromise<CreateRecommendationSerialiser> {
      return localVarFp
        .recommendationCreateCreate(createRecommendationSerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recommendationDeleteDestroy(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .recommendationDeleteDestroy(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {PatchedCreateRecommendationSerialiserRequest} [patchedCreateRecommendationSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recommendationEditPartialUpdate(
      id: number,
      patchedCreateRecommendationSerialiserRequest?: PatchedCreateRecommendationSerialiserRequest,
      options?: any
    ): AxiosPromise<CreateRecommendationSerialiser> {
      return localVarFp
        .recommendationEditPartialUpdate(
          id,
          patchedCreateRecommendationSerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {CreateRecommendationSerialiserRequest} createRecommendationSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recommendationEditUpdate(
      id: number,
      createRecommendationSerialiserRequest: CreateRecommendationSerialiserRequest,
      options?: any
    ): AxiosPromise<CreateRecommendationSerialiser> {
      return localVarFp
        .recommendationEditUpdate(id, createRecommendationSerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * RecommendationApi - object-oriented interface
 * @export
 * @class RecommendationApi
 * @extends {BaseAPI}
 */
export class RecommendationApi extends BaseAPI {
  /**
   *
   * @param {CreateRecommendationSerialiserRequest} createRecommendationSerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecommendationApi
   */
  public recommendationCreateCreate(
    createRecommendationSerialiserRequest: CreateRecommendationSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return RecommendationApiFp(this.configuration)
      .recommendationCreateCreate(createRecommendationSerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecommendationApi
   */
  public recommendationDeleteDestroy(id: number, options?: AxiosRequestConfig) {
    return RecommendationApiFp(this.configuration)
      .recommendationDeleteDestroy(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {PatchedCreateRecommendationSerialiserRequest} [patchedCreateRecommendationSerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecommendationApi
   */
  public recommendationEditPartialUpdate(
    id: number,
    patchedCreateRecommendationSerialiserRequest?: PatchedCreateRecommendationSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return RecommendationApiFp(this.configuration)
      .recommendationEditPartialUpdate(
        id,
        patchedCreateRecommendationSerialiserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {CreateRecommendationSerialiserRequest} createRecommendationSerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecommendationApi
   */
  public recommendationEditUpdate(
    id: number,
    createRecommendationSerialiserRequest: CreateRecommendationSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return RecommendationApiFp(this.configuration)
      .recommendationEditUpdate(id, createRecommendationSerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * RecommendationsApi - axios parameter creator
 * @export
 */
export const RecommendationsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recommendationsGeoJsonList: async (
      businessCode: string,
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("recommendationsGeoJsonList", "businessCode", businessCode);
      const localVarPath = `/recommendations/geo_json/{business_code}/`.replace(
        `{${"business_code"}}`,
        encodeURIComponent(String(businessCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} businessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recommendationsList: async (
      businessCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("recommendationsList", "businessCode", businessCode);
      const localVarPath = `/recommendations/{business_code}/`.replace(
        `{${"business_code"}}`,
        encodeURIComponent(String(businessCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RecommendationsApi - functional programming interface
 * @export
 */
export const RecommendationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RecommendationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recommendationsGeoJsonList(
      businessCode: string,
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedGeoJsonRecommendationSerialiserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.recommendationsGeoJsonList(
          businessCode,
          page,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} businessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recommendationsList(
      businessCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<RecommendationSerialiser>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recommendationsList(
        businessCode,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * RecommendationsApi - factory interface
 * @export
 */
export const RecommendationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = RecommendationsApiFp(configuration);
  return {
    /**
     *
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recommendationsGeoJsonList(
      businessCode: string,
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedGeoJsonRecommendationSerialiserList> {
      return localVarFp
        .recommendationsGeoJsonList(businessCode, page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} businessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recommendationsList(
      businessCode: string,
      options?: any
    ): AxiosPromise<Array<RecommendationSerialiser>> {
      return localVarFp
        .recommendationsList(businessCode, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * RecommendationsApi - object-oriented interface
 * @export
 * @class RecommendationsApi
 * @extends {BaseAPI}
 */
export class RecommendationsApi extends BaseAPI {
  /**
   *
   * @param {string} businessCode
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecommendationsApi
   */
  public recommendationsGeoJsonList(
    businessCode: string,
    page?: number,
    options?: AxiosRequestConfig
  ) {
    return RecommendationsApiFp(this.configuration)
      .recommendationsGeoJsonList(businessCode, page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} businessCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecommendationsApi
   */
  public recommendationsList(businessCode: string, options?: AxiosRequestConfig) {
    return RecommendationsApiFp(this.configuration)
      .recommendationsList(businessCode, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ReferralsApi - axios parameter creator
 * @export
 */
export const ReferralsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} businessCode
     * @param {ReferralSerialiserRequest} referralSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    referralsCreate: async (
      businessCode: string,
      referralSerialiserRequest: ReferralSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("referralsCreate", "businessCode", businessCode);
      // verify required parameter 'referralSerialiserRequest' is not null or undefined
      assertParamExists(
        "referralsCreate",
        "referralSerialiserRequest",
        referralSerialiserRequest
      );
      const localVarPath = `/referrals/{business_code}/`.replace(
        `{${"business_code"}}`,
        encodeURIComponent(String(businessCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        referralSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    referralsList: async (
      businessCode: string,
      page?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("referralsList", "businessCode", businessCode);
      const localVarPath = `/referrals/{business_code}/`.replace(
        `{${"business_code"}}`,
        encodeURIComponent(String(businessCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReferralsApi - functional programming interface
 * @export
 */
export const ReferralsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReferralsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} businessCode
     * @param {ReferralSerialiserRequest} referralSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async referralsCreate(
      businessCode: string,
      referralSerialiserRequest: ReferralSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralSerialiser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.referralsCreate(
        businessCode,
        referralSerialiserRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async referralsList(
      businessCode: string,
      page?: number,
      pageSize?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedReferralSerialiserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.referralsList(
        businessCode,
        page,
        pageSize,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * ReferralsApi - factory interface
 * @export
 */
export const ReferralsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ReferralsApiFp(configuration);
  return {
    /**
     *
     * @param {string} businessCode
     * @param {ReferralSerialiserRequest} referralSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    referralsCreate(
      businessCode: string,
      referralSerialiserRequest: ReferralSerialiserRequest,
      options?: any
    ): AxiosPromise<ReferralSerialiser> {
      return localVarFp
        .referralsCreate(businessCode, referralSerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    referralsList(
      businessCode: string,
      page?: number,
      pageSize?: number,
      options?: any
    ): AxiosPromise<PaginatedReferralSerialiserList> {
      return localVarFp
        .referralsList(businessCode, page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ReferralsApi - object-oriented interface
 * @export
 * @class ReferralsApi
 * @extends {BaseAPI}
 */
export class ReferralsApi extends BaseAPI {
  /**
   *
   * @param {string} businessCode
   * @param {ReferralSerialiserRequest} referralSerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferralsApi
   */
  public referralsCreate(
    businessCode: string,
    referralSerialiserRequest: ReferralSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return ReferralsApiFp(this.configuration)
      .referralsCreate(businessCode, referralSerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} businessCode
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [pageSize] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferralsApi
   */
  public referralsList(
    businessCode: string,
    page?: number,
    pageSize?: number,
    options?: AxiosRequestConfig
  ) {
    return ReferralsApiFp(this.configuration)
      .referralsList(businessCode, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Public API endpoint for querying Areas & searching for Areas and Countries by name.  We can use ListAPIView methods for retrieving matching Areas and augment results with matched country names.  Ordered by population descending.
     * @param {'AD' | 'AE' | 'AF' | 'AG' | 'AI' | 'AL' | 'AM' | 'AO' | 'AQ' | 'AR' | 'AS' | 'AT' | 'AU' | 'AW' | 'AX' | 'AZ' | 'BA' | 'BB' | 'BD' | 'BE' | 'BF' | 'BG' | 'BH' | 'BI' | 'BJ' | 'BL' | 'BM' | 'BN' | 'BO' | 'BQ' | 'BR' | 'BS' | 'BT' | 'BV' | 'BW' | 'BY' | 'BZ' | 'CA' | 'CC' | 'CD' | 'CF' | 'CG' | 'CH' | 'CI' | 'CK' | 'CL' | 'CM' | 'CN' | 'CO' | 'CR' | 'CU' | 'CV' | 'CW' | 'CX' | 'CY' | 'CZ' | 'DE' | 'DJ' | 'DK' | 'DM' | 'DO' | 'DZ' | 'EC' | 'EE' | 'EG' | 'EH' | 'ER' | 'ES' | 'ET' | 'FI' | 'FJ' | 'FK' | 'FM' | 'FO' | 'FR' | 'GA' | 'GB' | 'GD' | 'GE' | 'GF' | 'GG' | 'GH' | 'GI' | 'GL' | 'GM' | 'GN' | 'GP' | 'GQ' | 'GR' | 'GS' | 'GT' | 'GU' | 'GW' | 'GY' | 'HK' | 'HM' | 'HN' | 'HR' | 'HT' | 'HU' | 'ID' | 'IE' | 'IL' | 'IM' | 'IN' | 'IO' | 'IQ' | 'IR' | 'IS' | 'IT' | 'JE' | 'JM' | 'JO' | 'JP' | 'KE' | 'KG' | 'KH' | 'KI' | 'KM' | 'KN' | 'KP' | 'KR' | 'KW' | 'KY' | 'KZ' | 'LA' | 'LB' | 'LC' | 'LI' | 'LK' | 'LR' | 'LS' | 'LT' | 'LU' | 'LV' | 'LY' | 'MA' | 'MC' | 'MD' | 'ME' | 'MF' | 'MG' | 'MH' | 'MK' | 'ML' | 'MM' | 'MN' | 'MO' | 'MP' | 'MQ' | 'MR' | 'MS' | 'MT' | 'MU' | 'MV' | 'MW' | 'MX' | 'MY' | 'MZ' | 'NA' | 'NC' | 'NE' | 'NF' | 'NG' | 'NI' | 'NL' | 'NO' | 'NP' | 'NR' | 'NU' | 'NZ' | 'OM' | 'PA' | 'PE' | 'PF' | 'PG' | 'PH' | 'PK' | 'PL' | 'PM' | 'PN' | 'PR' | 'PS' | 'PT' | 'PW' | 'PY' | 'QA' | 'RE' | 'RO' | 'RS' | 'RU' | 'RW' | 'SA' | 'SB' | 'SC' | 'SD' | 'SE' | 'SG' | 'SH' | 'SI' | 'SJ' | 'SK' | 'SL' | 'SM' | 'SN' | 'SO' | 'SR' | 'SS' | 'ST' | 'SV' | 'SX' | 'SY' | 'SZ' | 'TB' | 'TC' | 'TD' | 'TF' | 'TG' | 'TH' | 'TJ' | 'TK' | 'TL' | 'TM' | 'TN' | 'TO' | 'TR' | 'TT' | 'TV' | 'TW' | 'TZ' | 'UA' | 'UG' | 'UM' | 'US' | 'UY' | 'UZ' | 'VA' | 'VC' | 'VE' | 'VG' | 'VI' | 'VN' | 'VU' | 'WF' | 'WS' | 'XK' | 'YE' | 'YT' | 'ZA' | 'ZM' | 'ZW'} [country]
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAreasCountriesList: async (
      country?:
        | "AD"
        | "AE"
        | "AF"
        | "AG"
        | "AI"
        | "AL"
        | "AM"
        | "AO"
        | "AQ"
        | "AR"
        | "AS"
        | "AT"
        | "AU"
        | "AW"
        | "AX"
        | "AZ"
        | "BA"
        | "BB"
        | "BD"
        | "BE"
        | "BF"
        | "BG"
        | "BH"
        | "BI"
        | "BJ"
        | "BL"
        | "BM"
        | "BN"
        | "BO"
        | "BQ"
        | "BR"
        | "BS"
        | "BT"
        | "BV"
        | "BW"
        | "BY"
        | "BZ"
        | "CA"
        | "CC"
        | "CD"
        | "CF"
        | "CG"
        | "CH"
        | "CI"
        | "CK"
        | "CL"
        | "CM"
        | "CN"
        | "CO"
        | "CR"
        | "CU"
        | "CV"
        | "CW"
        | "CX"
        | "CY"
        | "CZ"
        | "DE"
        | "DJ"
        | "DK"
        | "DM"
        | "DO"
        | "DZ"
        | "EC"
        | "EE"
        | "EG"
        | "EH"
        | "ER"
        | "ES"
        | "ET"
        | "FI"
        | "FJ"
        | "FK"
        | "FM"
        | "FO"
        | "FR"
        | "GA"
        | "GB"
        | "GD"
        | "GE"
        | "GF"
        | "GG"
        | "GH"
        | "GI"
        | "GL"
        | "GM"
        | "GN"
        | "GP"
        | "GQ"
        | "GR"
        | "GS"
        | "GT"
        | "GU"
        | "GW"
        | "GY"
        | "HK"
        | "HM"
        | "HN"
        | "HR"
        | "HT"
        | "HU"
        | "ID"
        | "IE"
        | "IL"
        | "IM"
        | "IN"
        | "IO"
        | "IQ"
        | "IR"
        | "IS"
        | "IT"
        | "JE"
        | "JM"
        | "JO"
        | "JP"
        | "KE"
        | "KG"
        | "KH"
        | "KI"
        | "KM"
        | "KN"
        | "KP"
        | "KR"
        | "KW"
        | "KY"
        | "KZ"
        | "LA"
        | "LB"
        | "LC"
        | "LI"
        | "LK"
        | "LR"
        | "LS"
        | "LT"
        | "LU"
        | "LV"
        | "LY"
        | "MA"
        | "MC"
        | "MD"
        | "ME"
        | "MF"
        | "MG"
        | "MH"
        | "MK"
        | "ML"
        | "MM"
        | "MN"
        | "MO"
        | "MP"
        | "MQ"
        | "MR"
        | "MS"
        | "MT"
        | "MU"
        | "MV"
        | "MW"
        | "MX"
        | "MY"
        | "MZ"
        | "NA"
        | "NC"
        | "NE"
        | "NF"
        | "NG"
        | "NI"
        | "NL"
        | "NO"
        | "NP"
        | "NR"
        | "NU"
        | "NZ"
        | "OM"
        | "PA"
        | "PE"
        | "PF"
        | "PG"
        | "PH"
        | "PK"
        | "PL"
        | "PM"
        | "PN"
        | "PR"
        | "PS"
        | "PT"
        | "PW"
        | "PY"
        | "QA"
        | "RE"
        | "RO"
        | "RS"
        | "RU"
        | "RW"
        | "SA"
        | "SB"
        | "SC"
        | "SD"
        | "SE"
        | "SG"
        | "SH"
        | "SI"
        | "SJ"
        | "SK"
        | "SL"
        | "SM"
        | "SN"
        | "SO"
        | "SR"
        | "SS"
        | "ST"
        | "SV"
        | "SX"
        | "SY"
        | "SZ"
        | "TB"
        | "TC"
        | "TD"
        | "TF"
        | "TG"
        | "TH"
        | "TJ"
        | "TK"
        | "TL"
        | "TM"
        | "TN"
        | "TO"
        | "TR"
        | "TT"
        | "TV"
        | "TW"
        | "TZ"
        | "UA"
        | "UG"
        | "UM"
        | "US"
        | "UY"
        | "UZ"
        | "VA"
        | "VC"
        | "VE"
        | "VG"
        | "VI"
        | "VN"
        | "VU"
        | "WF"
        | "WS"
        | "XK"
        | "YE"
        | "YT"
        | "ZA"
        | "ZM"
        | "ZW",
      ordering?: string,
      page?: number,
      search?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/search/areas-countries/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (ordering !== undefined) {
        localVarQueryParameter["ordering"] = ordering;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API endpoint for searching for businesses by name  This endpoint encapsulates two distinct use cases.   1. A nearby business code and name are supplied. In this case, we search for      a list of establishment-type places near to the business, which contain      `name`.   2. A country code and name are supplied. In this case, we search for a list      of establishment-type places within `country`, which contain `name`.  Query Parameters:      name(str): name of business to search for     nearby(str): nearby business_code to search from     country(str): country code to restrict search to
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGoogleList: async (
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/search/google/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Public API endpoint for searching for tab businesses by name
     * @param {number} [page] A page number within the paginated result set.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPublicTabList: async (
      page?: number,
      search?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/search/public/tab/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API endpoint for searching for businesses by name  This endpoint encapsulates two distinct use cases.   1. A nearby business code and name are supplied. In this case, we search for      a list of establishment-type places near to the business, which contain      `name`.   2. A country code and name are supplied. In this case, we search for a list      of establishment-type places within `country`, which contain `name`.  Query Parameters:      name(str): name of business to search for     nearby(str): nearby business_code to search from     country(str): country code to restrict search to
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchTabList: async (
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/search/tab/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration);
  return {
    /**
     * Public API endpoint for querying Areas & searching for Areas and Countries by name.  We can use ListAPIView methods for retrieving matching Areas and augment results with matched country names.  Ordered by population descending.
     * @param {'AD' | 'AE' | 'AF' | 'AG' | 'AI' | 'AL' | 'AM' | 'AO' | 'AQ' | 'AR' | 'AS' | 'AT' | 'AU' | 'AW' | 'AX' | 'AZ' | 'BA' | 'BB' | 'BD' | 'BE' | 'BF' | 'BG' | 'BH' | 'BI' | 'BJ' | 'BL' | 'BM' | 'BN' | 'BO' | 'BQ' | 'BR' | 'BS' | 'BT' | 'BV' | 'BW' | 'BY' | 'BZ' | 'CA' | 'CC' | 'CD' | 'CF' | 'CG' | 'CH' | 'CI' | 'CK' | 'CL' | 'CM' | 'CN' | 'CO' | 'CR' | 'CU' | 'CV' | 'CW' | 'CX' | 'CY' | 'CZ' | 'DE' | 'DJ' | 'DK' | 'DM' | 'DO' | 'DZ' | 'EC' | 'EE' | 'EG' | 'EH' | 'ER' | 'ES' | 'ET' | 'FI' | 'FJ' | 'FK' | 'FM' | 'FO' | 'FR' | 'GA' | 'GB' | 'GD' | 'GE' | 'GF' | 'GG' | 'GH' | 'GI' | 'GL' | 'GM' | 'GN' | 'GP' | 'GQ' | 'GR' | 'GS' | 'GT' | 'GU' | 'GW' | 'GY' | 'HK' | 'HM' | 'HN' | 'HR' | 'HT' | 'HU' | 'ID' | 'IE' | 'IL' | 'IM' | 'IN' | 'IO' | 'IQ' | 'IR' | 'IS' | 'IT' | 'JE' | 'JM' | 'JO' | 'JP' | 'KE' | 'KG' | 'KH' | 'KI' | 'KM' | 'KN' | 'KP' | 'KR' | 'KW' | 'KY' | 'KZ' | 'LA' | 'LB' | 'LC' | 'LI' | 'LK' | 'LR' | 'LS' | 'LT' | 'LU' | 'LV' | 'LY' | 'MA' | 'MC' | 'MD' | 'ME' | 'MF' | 'MG' | 'MH' | 'MK' | 'ML' | 'MM' | 'MN' | 'MO' | 'MP' | 'MQ' | 'MR' | 'MS' | 'MT' | 'MU' | 'MV' | 'MW' | 'MX' | 'MY' | 'MZ' | 'NA' | 'NC' | 'NE' | 'NF' | 'NG' | 'NI' | 'NL' | 'NO' | 'NP' | 'NR' | 'NU' | 'NZ' | 'OM' | 'PA' | 'PE' | 'PF' | 'PG' | 'PH' | 'PK' | 'PL' | 'PM' | 'PN' | 'PR' | 'PS' | 'PT' | 'PW' | 'PY' | 'QA' | 'RE' | 'RO' | 'RS' | 'RU' | 'RW' | 'SA' | 'SB' | 'SC' | 'SD' | 'SE' | 'SG' | 'SH' | 'SI' | 'SJ' | 'SK' | 'SL' | 'SM' | 'SN' | 'SO' | 'SR' | 'SS' | 'ST' | 'SV' | 'SX' | 'SY' | 'SZ' | 'TB' | 'TC' | 'TD' | 'TF' | 'TG' | 'TH' | 'TJ' | 'TK' | 'TL' | 'TM' | 'TN' | 'TO' | 'TR' | 'TT' | 'TV' | 'TW' | 'TZ' | 'UA' | 'UG' | 'UM' | 'US' | 'UY' | 'UZ' | 'VA' | 'VC' | 'VE' | 'VG' | 'VI' | 'VN' | 'VU' | 'WF' | 'WS' | 'XK' | 'YE' | 'YT' | 'ZA' | 'ZM' | 'ZW'} [country]
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchAreasCountriesList(
      country?:
        | "AD"
        | "AE"
        | "AF"
        | "AG"
        | "AI"
        | "AL"
        | "AM"
        | "AO"
        | "AQ"
        | "AR"
        | "AS"
        | "AT"
        | "AU"
        | "AW"
        | "AX"
        | "AZ"
        | "BA"
        | "BB"
        | "BD"
        | "BE"
        | "BF"
        | "BG"
        | "BH"
        | "BI"
        | "BJ"
        | "BL"
        | "BM"
        | "BN"
        | "BO"
        | "BQ"
        | "BR"
        | "BS"
        | "BT"
        | "BV"
        | "BW"
        | "BY"
        | "BZ"
        | "CA"
        | "CC"
        | "CD"
        | "CF"
        | "CG"
        | "CH"
        | "CI"
        | "CK"
        | "CL"
        | "CM"
        | "CN"
        | "CO"
        | "CR"
        | "CU"
        | "CV"
        | "CW"
        | "CX"
        | "CY"
        | "CZ"
        | "DE"
        | "DJ"
        | "DK"
        | "DM"
        | "DO"
        | "DZ"
        | "EC"
        | "EE"
        | "EG"
        | "EH"
        | "ER"
        | "ES"
        | "ET"
        | "FI"
        | "FJ"
        | "FK"
        | "FM"
        | "FO"
        | "FR"
        | "GA"
        | "GB"
        | "GD"
        | "GE"
        | "GF"
        | "GG"
        | "GH"
        | "GI"
        | "GL"
        | "GM"
        | "GN"
        | "GP"
        | "GQ"
        | "GR"
        | "GS"
        | "GT"
        | "GU"
        | "GW"
        | "GY"
        | "HK"
        | "HM"
        | "HN"
        | "HR"
        | "HT"
        | "HU"
        | "ID"
        | "IE"
        | "IL"
        | "IM"
        | "IN"
        | "IO"
        | "IQ"
        | "IR"
        | "IS"
        | "IT"
        | "JE"
        | "JM"
        | "JO"
        | "JP"
        | "KE"
        | "KG"
        | "KH"
        | "KI"
        | "KM"
        | "KN"
        | "KP"
        | "KR"
        | "KW"
        | "KY"
        | "KZ"
        | "LA"
        | "LB"
        | "LC"
        | "LI"
        | "LK"
        | "LR"
        | "LS"
        | "LT"
        | "LU"
        | "LV"
        | "LY"
        | "MA"
        | "MC"
        | "MD"
        | "ME"
        | "MF"
        | "MG"
        | "MH"
        | "MK"
        | "ML"
        | "MM"
        | "MN"
        | "MO"
        | "MP"
        | "MQ"
        | "MR"
        | "MS"
        | "MT"
        | "MU"
        | "MV"
        | "MW"
        | "MX"
        | "MY"
        | "MZ"
        | "NA"
        | "NC"
        | "NE"
        | "NF"
        | "NG"
        | "NI"
        | "NL"
        | "NO"
        | "NP"
        | "NR"
        | "NU"
        | "NZ"
        | "OM"
        | "PA"
        | "PE"
        | "PF"
        | "PG"
        | "PH"
        | "PK"
        | "PL"
        | "PM"
        | "PN"
        | "PR"
        | "PS"
        | "PT"
        | "PW"
        | "PY"
        | "QA"
        | "RE"
        | "RO"
        | "RS"
        | "RU"
        | "RW"
        | "SA"
        | "SB"
        | "SC"
        | "SD"
        | "SE"
        | "SG"
        | "SH"
        | "SI"
        | "SJ"
        | "SK"
        | "SL"
        | "SM"
        | "SN"
        | "SO"
        | "SR"
        | "SS"
        | "ST"
        | "SV"
        | "SX"
        | "SY"
        | "SZ"
        | "TB"
        | "TC"
        | "TD"
        | "TF"
        | "TG"
        | "TH"
        | "TJ"
        | "TK"
        | "TL"
        | "TM"
        | "TN"
        | "TO"
        | "TR"
        | "TT"
        | "TV"
        | "TW"
        | "TZ"
        | "UA"
        | "UG"
        | "UM"
        | "US"
        | "UY"
        | "UZ"
        | "VA"
        | "VC"
        | "VE"
        | "VG"
        | "VI"
        | "VN"
        | "VU"
        | "WF"
        | "WS"
        | "XK"
        | "YE"
        | "YT"
        | "ZA"
        | "ZM"
        | "ZW",
      ordering?: string,
      page?: number,
      search?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedAreaSerialiserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchAreasCountriesList(
        country,
        ordering,
        page,
        search,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * API endpoint for searching for businesses by name  This endpoint encapsulates two distinct use cases.   1. A nearby business code and name are supplied. In this case, we search for      a list of establishment-type places near to the business, which contain      `name`.   2. A country code and name are supplied. In this case, we search for a list      of establishment-type places within `country`, which contain `name`.  Query Parameters:      name(str): name of business to search for     nearby(str): nearby business_code to search from     country(str): country code to restrict search to
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchGoogleList(
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedGPlaceSerialiserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchGoogleList(
        page,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Public API endpoint for searching for tab businesses by name
     * @param {number} [page] A page number within the paginated result set.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchPublicTabList(
      page?: number,
      search?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedBusinessSearchResultSerialiserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchPublicTabList(
        page,
        search,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * API endpoint for searching for businesses by name  This endpoint encapsulates two distinct use cases.   1. A nearby business code and name are supplied. In this case, we search for      a list of establishment-type places near to the business, which contain      `name`.   2. A country code and name are supplied. In this case, we search for a list      of establishment-type places within `country`, which contain `name`.  Query Parameters:      name(str): name of business to search for     nearby(str): nearby business_code to search from     country(str): country code to restrict search to
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchTabList(
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedBusinessSearchResultSerialiserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchTabList(
        page,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SearchApiFp(configuration);
  return {
    /**
     * Public API endpoint for querying Areas & searching for Areas and Countries by name.  We can use ListAPIView methods for retrieving matching Areas and augment results with matched country names.  Ordered by population descending.
     * @param {'AD' | 'AE' | 'AF' | 'AG' | 'AI' | 'AL' | 'AM' | 'AO' | 'AQ' | 'AR' | 'AS' | 'AT' | 'AU' | 'AW' | 'AX' | 'AZ' | 'BA' | 'BB' | 'BD' | 'BE' | 'BF' | 'BG' | 'BH' | 'BI' | 'BJ' | 'BL' | 'BM' | 'BN' | 'BO' | 'BQ' | 'BR' | 'BS' | 'BT' | 'BV' | 'BW' | 'BY' | 'BZ' | 'CA' | 'CC' | 'CD' | 'CF' | 'CG' | 'CH' | 'CI' | 'CK' | 'CL' | 'CM' | 'CN' | 'CO' | 'CR' | 'CU' | 'CV' | 'CW' | 'CX' | 'CY' | 'CZ' | 'DE' | 'DJ' | 'DK' | 'DM' | 'DO' | 'DZ' | 'EC' | 'EE' | 'EG' | 'EH' | 'ER' | 'ES' | 'ET' | 'FI' | 'FJ' | 'FK' | 'FM' | 'FO' | 'FR' | 'GA' | 'GB' | 'GD' | 'GE' | 'GF' | 'GG' | 'GH' | 'GI' | 'GL' | 'GM' | 'GN' | 'GP' | 'GQ' | 'GR' | 'GS' | 'GT' | 'GU' | 'GW' | 'GY' | 'HK' | 'HM' | 'HN' | 'HR' | 'HT' | 'HU' | 'ID' | 'IE' | 'IL' | 'IM' | 'IN' | 'IO' | 'IQ' | 'IR' | 'IS' | 'IT' | 'JE' | 'JM' | 'JO' | 'JP' | 'KE' | 'KG' | 'KH' | 'KI' | 'KM' | 'KN' | 'KP' | 'KR' | 'KW' | 'KY' | 'KZ' | 'LA' | 'LB' | 'LC' | 'LI' | 'LK' | 'LR' | 'LS' | 'LT' | 'LU' | 'LV' | 'LY' | 'MA' | 'MC' | 'MD' | 'ME' | 'MF' | 'MG' | 'MH' | 'MK' | 'ML' | 'MM' | 'MN' | 'MO' | 'MP' | 'MQ' | 'MR' | 'MS' | 'MT' | 'MU' | 'MV' | 'MW' | 'MX' | 'MY' | 'MZ' | 'NA' | 'NC' | 'NE' | 'NF' | 'NG' | 'NI' | 'NL' | 'NO' | 'NP' | 'NR' | 'NU' | 'NZ' | 'OM' | 'PA' | 'PE' | 'PF' | 'PG' | 'PH' | 'PK' | 'PL' | 'PM' | 'PN' | 'PR' | 'PS' | 'PT' | 'PW' | 'PY' | 'QA' | 'RE' | 'RO' | 'RS' | 'RU' | 'RW' | 'SA' | 'SB' | 'SC' | 'SD' | 'SE' | 'SG' | 'SH' | 'SI' | 'SJ' | 'SK' | 'SL' | 'SM' | 'SN' | 'SO' | 'SR' | 'SS' | 'ST' | 'SV' | 'SX' | 'SY' | 'SZ' | 'TB' | 'TC' | 'TD' | 'TF' | 'TG' | 'TH' | 'TJ' | 'TK' | 'TL' | 'TM' | 'TN' | 'TO' | 'TR' | 'TT' | 'TV' | 'TW' | 'TZ' | 'UA' | 'UG' | 'UM' | 'US' | 'UY' | 'UZ' | 'VA' | 'VC' | 'VE' | 'VG' | 'VI' | 'VN' | 'VU' | 'WF' | 'WS' | 'XK' | 'YE' | 'YT' | 'ZA' | 'ZM' | 'ZW'} [country]
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAreasCountriesList(
      country?:
        | "AD"
        | "AE"
        | "AF"
        | "AG"
        | "AI"
        | "AL"
        | "AM"
        | "AO"
        | "AQ"
        | "AR"
        | "AS"
        | "AT"
        | "AU"
        | "AW"
        | "AX"
        | "AZ"
        | "BA"
        | "BB"
        | "BD"
        | "BE"
        | "BF"
        | "BG"
        | "BH"
        | "BI"
        | "BJ"
        | "BL"
        | "BM"
        | "BN"
        | "BO"
        | "BQ"
        | "BR"
        | "BS"
        | "BT"
        | "BV"
        | "BW"
        | "BY"
        | "BZ"
        | "CA"
        | "CC"
        | "CD"
        | "CF"
        | "CG"
        | "CH"
        | "CI"
        | "CK"
        | "CL"
        | "CM"
        | "CN"
        | "CO"
        | "CR"
        | "CU"
        | "CV"
        | "CW"
        | "CX"
        | "CY"
        | "CZ"
        | "DE"
        | "DJ"
        | "DK"
        | "DM"
        | "DO"
        | "DZ"
        | "EC"
        | "EE"
        | "EG"
        | "EH"
        | "ER"
        | "ES"
        | "ET"
        | "FI"
        | "FJ"
        | "FK"
        | "FM"
        | "FO"
        | "FR"
        | "GA"
        | "GB"
        | "GD"
        | "GE"
        | "GF"
        | "GG"
        | "GH"
        | "GI"
        | "GL"
        | "GM"
        | "GN"
        | "GP"
        | "GQ"
        | "GR"
        | "GS"
        | "GT"
        | "GU"
        | "GW"
        | "GY"
        | "HK"
        | "HM"
        | "HN"
        | "HR"
        | "HT"
        | "HU"
        | "ID"
        | "IE"
        | "IL"
        | "IM"
        | "IN"
        | "IO"
        | "IQ"
        | "IR"
        | "IS"
        | "IT"
        | "JE"
        | "JM"
        | "JO"
        | "JP"
        | "KE"
        | "KG"
        | "KH"
        | "KI"
        | "KM"
        | "KN"
        | "KP"
        | "KR"
        | "KW"
        | "KY"
        | "KZ"
        | "LA"
        | "LB"
        | "LC"
        | "LI"
        | "LK"
        | "LR"
        | "LS"
        | "LT"
        | "LU"
        | "LV"
        | "LY"
        | "MA"
        | "MC"
        | "MD"
        | "ME"
        | "MF"
        | "MG"
        | "MH"
        | "MK"
        | "ML"
        | "MM"
        | "MN"
        | "MO"
        | "MP"
        | "MQ"
        | "MR"
        | "MS"
        | "MT"
        | "MU"
        | "MV"
        | "MW"
        | "MX"
        | "MY"
        | "MZ"
        | "NA"
        | "NC"
        | "NE"
        | "NF"
        | "NG"
        | "NI"
        | "NL"
        | "NO"
        | "NP"
        | "NR"
        | "NU"
        | "NZ"
        | "OM"
        | "PA"
        | "PE"
        | "PF"
        | "PG"
        | "PH"
        | "PK"
        | "PL"
        | "PM"
        | "PN"
        | "PR"
        | "PS"
        | "PT"
        | "PW"
        | "PY"
        | "QA"
        | "RE"
        | "RO"
        | "RS"
        | "RU"
        | "RW"
        | "SA"
        | "SB"
        | "SC"
        | "SD"
        | "SE"
        | "SG"
        | "SH"
        | "SI"
        | "SJ"
        | "SK"
        | "SL"
        | "SM"
        | "SN"
        | "SO"
        | "SR"
        | "SS"
        | "ST"
        | "SV"
        | "SX"
        | "SY"
        | "SZ"
        | "TB"
        | "TC"
        | "TD"
        | "TF"
        | "TG"
        | "TH"
        | "TJ"
        | "TK"
        | "TL"
        | "TM"
        | "TN"
        | "TO"
        | "TR"
        | "TT"
        | "TV"
        | "TW"
        | "TZ"
        | "UA"
        | "UG"
        | "UM"
        | "US"
        | "UY"
        | "UZ"
        | "VA"
        | "VC"
        | "VE"
        | "VG"
        | "VI"
        | "VN"
        | "VU"
        | "WF"
        | "WS"
        | "XK"
        | "YE"
        | "YT"
        | "ZA"
        | "ZM"
        | "ZW",
      ordering?: string,
      page?: number,
      search?: string,
      options?: any
    ): AxiosPromise<PaginatedAreaSerialiserList> {
      return localVarFp
        .searchAreasCountriesList(country, ordering, page, search, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API endpoint for searching for businesses by name  This endpoint encapsulates two distinct use cases.   1. A nearby business code and name are supplied. In this case, we search for      a list of establishment-type places near to the business, which contain      `name`.   2. A country code and name are supplied. In this case, we search for a list      of establishment-type places within `country`, which contain `name`.  Query Parameters:      name(str): name of business to search for     nearby(str): nearby business_code to search from     country(str): country code to restrict search to
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGoogleList(
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedGPlaceSerialiserList> {
      return localVarFp
        .searchGoogleList(page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Public API endpoint for searching for tab businesses by name
     * @param {number} [page] A page number within the paginated result set.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPublicTabList(
      page?: number,
      search?: string,
      options?: any
    ): AxiosPromise<PaginatedBusinessSearchResultSerialiserList> {
      return localVarFp
        .searchPublicTabList(page, search, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API endpoint for searching for businesses by name  This endpoint encapsulates two distinct use cases.   1. A nearby business code and name are supplied. In this case, we search for      a list of establishment-type places near to the business, which contain      `name`.   2. A country code and name are supplied. In this case, we search for a list      of establishment-type places within `country`, which contain `name`.  Query Parameters:      name(str): name of business to search for     nearby(str): nearby business_code to search from     country(str): country code to restrict search to
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchTabList(
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedBusinessSearchResultSerialiserList> {
      return localVarFp
        .searchTabList(page, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
  /**
   * Public API endpoint for querying Areas & searching for Areas and Countries by name.  We can use ListAPIView methods for retrieving matching Areas and augment results with matched country names.  Ordered by population descending.
   * @param {'AD' | 'AE' | 'AF' | 'AG' | 'AI' | 'AL' | 'AM' | 'AO' | 'AQ' | 'AR' | 'AS' | 'AT' | 'AU' | 'AW' | 'AX' | 'AZ' | 'BA' | 'BB' | 'BD' | 'BE' | 'BF' | 'BG' | 'BH' | 'BI' | 'BJ' | 'BL' | 'BM' | 'BN' | 'BO' | 'BQ' | 'BR' | 'BS' | 'BT' | 'BV' | 'BW' | 'BY' | 'BZ' | 'CA' | 'CC' | 'CD' | 'CF' | 'CG' | 'CH' | 'CI' | 'CK' | 'CL' | 'CM' | 'CN' | 'CO' | 'CR' | 'CU' | 'CV' | 'CW' | 'CX' | 'CY' | 'CZ' | 'DE' | 'DJ' | 'DK' | 'DM' | 'DO' | 'DZ' | 'EC' | 'EE' | 'EG' | 'EH' | 'ER' | 'ES' | 'ET' | 'FI' | 'FJ' | 'FK' | 'FM' | 'FO' | 'FR' | 'GA' | 'GB' | 'GD' | 'GE' | 'GF' | 'GG' | 'GH' | 'GI' | 'GL' | 'GM' | 'GN' | 'GP' | 'GQ' | 'GR' | 'GS' | 'GT' | 'GU' | 'GW' | 'GY' | 'HK' | 'HM' | 'HN' | 'HR' | 'HT' | 'HU' | 'ID' | 'IE' | 'IL' | 'IM' | 'IN' | 'IO' | 'IQ' | 'IR' | 'IS' | 'IT' | 'JE' | 'JM' | 'JO' | 'JP' | 'KE' | 'KG' | 'KH' | 'KI' | 'KM' | 'KN' | 'KP' | 'KR' | 'KW' | 'KY' | 'KZ' | 'LA' | 'LB' | 'LC' | 'LI' | 'LK' | 'LR' | 'LS' | 'LT' | 'LU' | 'LV' | 'LY' | 'MA' | 'MC' | 'MD' | 'ME' | 'MF' | 'MG' | 'MH' | 'MK' | 'ML' | 'MM' | 'MN' | 'MO' | 'MP' | 'MQ' | 'MR' | 'MS' | 'MT' | 'MU' | 'MV' | 'MW' | 'MX' | 'MY' | 'MZ' | 'NA' | 'NC' | 'NE' | 'NF' | 'NG' | 'NI' | 'NL' | 'NO' | 'NP' | 'NR' | 'NU' | 'NZ' | 'OM' | 'PA' | 'PE' | 'PF' | 'PG' | 'PH' | 'PK' | 'PL' | 'PM' | 'PN' | 'PR' | 'PS' | 'PT' | 'PW' | 'PY' | 'QA' | 'RE' | 'RO' | 'RS' | 'RU' | 'RW' | 'SA' | 'SB' | 'SC' | 'SD' | 'SE' | 'SG' | 'SH' | 'SI' | 'SJ' | 'SK' | 'SL' | 'SM' | 'SN' | 'SO' | 'SR' | 'SS' | 'ST' | 'SV' | 'SX' | 'SY' | 'SZ' | 'TB' | 'TC' | 'TD' | 'TF' | 'TG' | 'TH' | 'TJ' | 'TK' | 'TL' | 'TM' | 'TN' | 'TO' | 'TR' | 'TT' | 'TV' | 'TW' | 'TZ' | 'UA' | 'UG' | 'UM' | 'US' | 'UY' | 'UZ' | 'VA' | 'VC' | 'VE' | 'VG' | 'VI' | 'VN' | 'VU' | 'WF' | 'WS' | 'XK' | 'YE' | 'YT' | 'ZA' | 'ZM' | 'ZW'} [country]
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {number} [page] A page number within the paginated result set.
   * @param {string} [search] A search term.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public searchAreasCountriesList(
    country?:
      | "AD"
      | "AE"
      | "AF"
      | "AG"
      | "AI"
      | "AL"
      | "AM"
      | "AO"
      | "AQ"
      | "AR"
      | "AS"
      | "AT"
      | "AU"
      | "AW"
      | "AX"
      | "AZ"
      | "BA"
      | "BB"
      | "BD"
      | "BE"
      | "BF"
      | "BG"
      | "BH"
      | "BI"
      | "BJ"
      | "BL"
      | "BM"
      | "BN"
      | "BO"
      | "BQ"
      | "BR"
      | "BS"
      | "BT"
      | "BV"
      | "BW"
      | "BY"
      | "BZ"
      | "CA"
      | "CC"
      | "CD"
      | "CF"
      | "CG"
      | "CH"
      | "CI"
      | "CK"
      | "CL"
      | "CM"
      | "CN"
      | "CO"
      | "CR"
      | "CU"
      | "CV"
      | "CW"
      | "CX"
      | "CY"
      | "CZ"
      | "DE"
      | "DJ"
      | "DK"
      | "DM"
      | "DO"
      | "DZ"
      | "EC"
      | "EE"
      | "EG"
      | "EH"
      | "ER"
      | "ES"
      | "ET"
      | "FI"
      | "FJ"
      | "FK"
      | "FM"
      | "FO"
      | "FR"
      | "GA"
      | "GB"
      | "GD"
      | "GE"
      | "GF"
      | "GG"
      | "GH"
      | "GI"
      | "GL"
      | "GM"
      | "GN"
      | "GP"
      | "GQ"
      | "GR"
      | "GS"
      | "GT"
      | "GU"
      | "GW"
      | "GY"
      | "HK"
      | "HM"
      | "HN"
      | "HR"
      | "HT"
      | "HU"
      | "ID"
      | "IE"
      | "IL"
      | "IM"
      | "IN"
      | "IO"
      | "IQ"
      | "IR"
      | "IS"
      | "IT"
      | "JE"
      | "JM"
      | "JO"
      | "JP"
      | "KE"
      | "KG"
      | "KH"
      | "KI"
      | "KM"
      | "KN"
      | "KP"
      | "KR"
      | "KW"
      | "KY"
      | "KZ"
      | "LA"
      | "LB"
      | "LC"
      | "LI"
      | "LK"
      | "LR"
      | "LS"
      | "LT"
      | "LU"
      | "LV"
      | "LY"
      | "MA"
      | "MC"
      | "MD"
      | "ME"
      | "MF"
      | "MG"
      | "MH"
      | "MK"
      | "ML"
      | "MM"
      | "MN"
      | "MO"
      | "MP"
      | "MQ"
      | "MR"
      | "MS"
      | "MT"
      | "MU"
      | "MV"
      | "MW"
      | "MX"
      | "MY"
      | "MZ"
      | "NA"
      | "NC"
      | "NE"
      | "NF"
      | "NG"
      | "NI"
      | "NL"
      | "NO"
      | "NP"
      | "NR"
      | "NU"
      | "NZ"
      | "OM"
      | "PA"
      | "PE"
      | "PF"
      | "PG"
      | "PH"
      | "PK"
      | "PL"
      | "PM"
      | "PN"
      | "PR"
      | "PS"
      | "PT"
      | "PW"
      | "PY"
      | "QA"
      | "RE"
      | "RO"
      | "RS"
      | "RU"
      | "RW"
      | "SA"
      | "SB"
      | "SC"
      | "SD"
      | "SE"
      | "SG"
      | "SH"
      | "SI"
      | "SJ"
      | "SK"
      | "SL"
      | "SM"
      | "SN"
      | "SO"
      | "SR"
      | "SS"
      | "ST"
      | "SV"
      | "SX"
      | "SY"
      | "SZ"
      | "TB"
      | "TC"
      | "TD"
      | "TF"
      | "TG"
      | "TH"
      | "TJ"
      | "TK"
      | "TL"
      | "TM"
      | "TN"
      | "TO"
      | "TR"
      | "TT"
      | "TV"
      | "TW"
      | "TZ"
      | "UA"
      | "UG"
      | "UM"
      | "US"
      | "UY"
      | "UZ"
      | "VA"
      | "VC"
      | "VE"
      | "VG"
      | "VI"
      | "VN"
      | "VU"
      | "WF"
      | "WS"
      | "XK"
      | "YE"
      | "YT"
      | "ZA"
      | "ZM"
      | "ZW",
    ordering?: string,
    page?: number,
    search?: string,
    options?: AxiosRequestConfig
  ) {
    return SearchApiFp(this.configuration)
      .searchAreasCountriesList(country, ordering, page, search, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API endpoint for searching for businesses by name  This endpoint encapsulates two distinct use cases.   1. A nearby business code and name are supplied. In this case, we search for      a list of establishment-type places near to the business, which contain      `name`.   2. A country code and name are supplied. In this case, we search for a list      of establishment-type places within `country`, which contain `name`.  Query Parameters:      name(str): name of business to search for     nearby(str): nearby business_code to search from     country(str): country code to restrict search to
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public searchGoogleList(page?: number, options?: AxiosRequestConfig) {
    return SearchApiFp(this.configuration)
      .searchGoogleList(page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Public API endpoint for searching for tab businesses by name
   * @param {number} [page] A page number within the paginated result set.
   * @param {string} [search] A search term.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public searchPublicTabList(
    page?: number,
    search?: string,
    options?: AxiosRequestConfig
  ) {
    return SearchApiFp(this.configuration)
      .searchPublicTabList(page, search, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API endpoint for searching for businesses by name  This endpoint encapsulates two distinct use cases.   1. A nearby business code and name are supplied. In this case, we search for      a list of establishment-type places near to the business, which contain      `name`.   2. A country code and name are supplied. In this case, we search for a list      of establishment-type places within `country`, which contain `name`.  Query Parameters:      name(str): name of business to search for     nearby(str): nearby business_code to search from     country(str): country code to restrict search to
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public searchTabList(page?: number, options?: AxiosRequestConfig) {
    return SearchApiFp(this.configuration)
      .searchTabList(page, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ServicesApi - axios parameter creator
 * @export
 */
export const ServicesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} code
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    servicesDestroy: async (
      code: string,
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("servicesDestroy", "code", code);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("servicesDestroy", "id", id);
      const localVarPath = `/services/{code}/{id}/`
        .replace(`{${"code"}}`, encodeURIComponent(String(code)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} code
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    servicesList: async (
      code: string,
      ordering?: string,
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("servicesList", "code", code);
      const localVarPath = `/services/{code}/`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (ordering !== undefined) {
        localVarQueryParameter["ordering"] = ordering;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ServicesApi - functional programming interface
 * @export
 */
export const ServicesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ServicesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} code
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async servicesDestroy(
      code: string,
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.servicesDestroy(
        code,
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} code
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async servicesList(
      code: string,
      ordering?: string,
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedGeoJsonLocalServiceSerialiserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.servicesList(
        code,
        ordering,
        page,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * ServicesApi - factory interface
 * @export
 */
export const ServicesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ServicesApiFp(configuration);
  return {
    /**
     *
     * @param {string} code
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    servicesDestroy(code: string, id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .servicesDestroy(code, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} code
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    servicesList(
      code: string,
      ordering?: string,
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedGeoJsonLocalServiceSerialiserList> {
      return localVarFp
        .servicesList(code, ordering, page, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ServicesApi - object-oriented interface
 * @export
 * @class ServicesApi
 * @extends {BaseAPI}
 */
export class ServicesApi extends BaseAPI {
  /**
   *
   * @param {string} code
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServicesApi
   */
  public servicesDestroy(code: string, id: number, options?: AxiosRequestConfig) {
    return ServicesApiFp(this.configuration)
      .servicesDestroy(code, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} code
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServicesApi
   */
  public servicesList(
    code: string,
    ordering?: string,
    page?: number,
    options?: AxiosRequestConfig
  ) {
    return ServicesApiFp(this.configuration)
      .servicesList(code, ordering, page, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TeamMemberApi - axios parameter creator
 * @export
 */
export const TeamMemberApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamMemberDeleteDestroy: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("teamMemberDeleteDestroy", "id", id);
      const localVarPath = `/team-member/delete/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {PatchedTeamMemberSerialiserRequest} [patchedTeamMemberSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamMemberEditPartialUpdate: async (
      id: string,
      patchedTeamMemberSerialiserRequest?: PatchedTeamMemberSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("teamMemberEditPartialUpdate", "id", id);
      const localVarPath = `/team-member/edit/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedTeamMemberSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {TeamMemberSerialiserRequest} teamMemberSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamMemberEditUpdate: async (
      id: string,
      teamMemberSerialiserRequest: TeamMemberSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("teamMemberEditUpdate", "id", id);
      // verify required parameter 'teamMemberSerialiserRequest' is not null or undefined
      assertParamExists(
        "teamMemberEditUpdate",
        "teamMemberSerialiserRequest",
        teamMemberSerialiserRequest
      );
      const localVarPath = `/team-member/edit/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        teamMemberSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TeamMemberApi - functional programming interface
 * @export
 */
export const TeamMemberApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TeamMemberApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async teamMemberDeleteDestroy(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.teamMemberDeleteDestroy(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {PatchedTeamMemberSerialiserRequest} [patchedTeamMemberSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async teamMemberEditPartialUpdate(
      id: string,
      patchedTeamMemberSerialiserRequest?: PatchedTeamMemberSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamMemberSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.teamMemberEditPartialUpdate(
          id,
          patchedTeamMemberSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {TeamMemberSerialiserRequest} teamMemberSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async teamMemberEditUpdate(
      id: string,
      teamMemberSerialiserRequest: TeamMemberSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamMemberSerialiser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.teamMemberEditUpdate(
        id,
        teamMemberSerialiserRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * TeamMemberApi - factory interface
 * @export
 */
export const TeamMemberApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = TeamMemberApiFp(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamMemberDeleteDestroy(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .teamMemberDeleteDestroy(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {PatchedTeamMemberSerialiserRequest} [patchedTeamMemberSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamMemberEditPartialUpdate(
      id: string,
      patchedTeamMemberSerialiserRequest?: PatchedTeamMemberSerialiserRequest,
      options?: any
    ): AxiosPromise<TeamMemberSerialiser> {
      return localVarFp
        .teamMemberEditPartialUpdate(id, patchedTeamMemberSerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {TeamMemberSerialiserRequest} teamMemberSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamMemberEditUpdate(
      id: string,
      teamMemberSerialiserRequest: TeamMemberSerialiserRequest,
      options?: any
    ): AxiosPromise<TeamMemberSerialiser> {
      return localVarFp
        .teamMemberEditUpdate(id, teamMemberSerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TeamMemberApi - object-oriented interface
 * @export
 * @class TeamMemberApi
 * @extends {BaseAPI}
 */
export class TeamMemberApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamMemberApi
   */
  public teamMemberDeleteDestroy(id: string, options?: AxiosRequestConfig) {
    return TeamMemberApiFp(this.configuration)
      .teamMemberDeleteDestroy(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {PatchedTeamMemberSerialiserRequest} [patchedTeamMemberSerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamMemberApi
   */
  public teamMemberEditPartialUpdate(
    id: string,
    patchedTeamMemberSerialiserRequest?: PatchedTeamMemberSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TeamMemberApiFp(this.configuration)
      .teamMemberEditPartialUpdate(id, patchedTeamMemberSerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {TeamMemberSerialiserRequest} teamMemberSerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamMemberApi
   */
  public teamMemberEditUpdate(
    id: string,
    teamMemberSerialiserRequest: TeamMemberSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TeamMemberApiFp(this.configuration)
      .teamMemberEditUpdate(id, teamMemberSerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TeamMembersApi - axios parameter creator
 * @export
 */
export const TeamMembersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} businessCode
     * @param {TeamMemberSerialiserRequest} teamMemberSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamMembersCreate: async (
      businessCode: string,
      teamMemberSerialiserRequest: TeamMemberSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("teamMembersCreate", "businessCode", businessCode);
      // verify required parameter 'teamMemberSerialiserRequest' is not null or undefined
      assertParamExists(
        "teamMembersCreate",
        "teamMemberSerialiserRequest",
        teamMemberSerialiserRequest
      );
      const localVarPath = `/team-members/{business_code}/`.replace(
        `{${"business_code"}}`,
        encodeURIComponent(String(businessCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        teamMemberSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamMembersList: async (
      businessCode: string,
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists("teamMembersList", "businessCode", businessCode);
      const localVarPath = `/team-members/{business_code}/`.replace(
        `{${"business_code"}}`,
        encodeURIComponent(String(businessCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TeamMembersApi - functional programming interface
 * @export
 */
export const TeamMembersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TeamMembersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} businessCode
     * @param {TeamMemberSerialiserRequest} teamMemberSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async teamMembersCreate(
      businessCode: string,
      teamMemberSerialiserRequest: TeamMemberSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamMemberSerialiser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.teamMembersCreate(
        businessCode,
        teamMemberSerialiserRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async teamMembersList(
      businessCode: string,
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedTeamMemberSerialiserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.teamMembersList(
        businessCode,
        page,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * TeamMembersApi - factory interface
 * @export
 */
export const TeamMembersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = TeamMembersApiFp(configuration);
  return {
    /**
     *
     * @param {string} businessCode
     * @param {TeamMemberSerialiserRequest} teamMemberSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamMembersCreate(
      businessCode: string,
      teamMemberSerialiserRequest: TeamMemberSerialiserRequest,
      options?: any
    ): AxiosPromise<TeamMemberSerialiser> {
      return localVarFp
        .teamMembersCreate(businessCode, teamMemberSerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} businessCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamMembersList(
      businessCode: string,
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedTeamMemberSerialiserList> {
      return localVarFp
        .teamMembersList(businessCode, page, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TeamMembersApi - object-oriented interface
 * @export
 * @class TeamMembersApi
 * @extends {BaseAPI}
 */
export class TeamMembersApi extends BaseAPI {
  /**
   *
   * @param {string} businessCode
   * @param {TeamMemberSerialiserRequest} teamMemberSerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamMembersApi
   */
  public teamMembersCreate(
    businessCode: string,
    teamMemberSerialiserRequest: TeamMemberSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TeamMembersApiFp(this.configuration)
      .teamMembersCreate(businessCode, teamMemberSerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} businessCode
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamMembersApi
   */
  public teamMembersList(
    businessCode: string,
    page?: number,
    options?: AxiosRequestConfig
  ) {
    return TeamMembersApiFp(this.configuration)
      .teamMembersList(businessCode, page, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TravellerApi - axios parameter creator
 * @export
 */
export const TravellerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * For a traveller\'s following, returns a region\'s countries with following metadata.  Returns a list of countries with:     visit_count(int): number of business visits by following in country     travellers([traveller]): following who have visited country
     * @param {string} region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiAggregateRegionList: async (
      region: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'region' is not null or undefined
      assertParamExists("travellerApiAggregateRegionList", "region", region);
      const localVarPath = `/traveller/api/aggregate/region/{region}/`.replace(
        `{${"region"}}`,
        encodeURIComponent(String(region))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiChecklistRetrieve: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/traveller/api/checklist/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of Activities filtered by area and ordered by claimed listings.  Each Activity has visited (bool), visited_uuid if the current traveller has visited it.
     * @param {string} area
     * @param {string} country
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiCountryAreaActivitiesTopList: async (
      area: string,
      country: string,
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'area' is not null or undefined
      assertParamExists("travellerApiCountryAreaActivitiesTopList", "area", area);
      // verify required parameter 'country' is not null or undefined
      assertParamExists("travellerApiCountryAreaActivitiesTopList", "country", country);
      const localVarPath = `/traveller/api/country/{country}/area/{area}/activities/top/`
        .replace(`{${"area"}}`, encodeURIComponent(String(area)))
        .replace(`{${"country"}}`, encodeURIComponent(String(country)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of Area records filtered by country and ordered by claimed listings.  Each Area has visited (bool), visited_uuid if the current traveller has visited it.
     * @param {string} country
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiCountryAreasTopList: async (
      country: string,
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'country' is not null or undefined
      assertParamExists("travellerApiCountryAreasTopList", "country", country);
      const localVarPath = `/traveller/api/country/{country}/areas/top/`.replace(
        `{${"country"}}`,
        encodeURIComponent(String(country))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * GET the whole traveller network for a specific traveller
     * @param {string} travellerCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiFolloweeRelationshipsList: async (
      travellerCode: string,
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'travellerCode' is not null or undefined
      assertParamExists(
        "travellerApiFolloweeRelationshipsList",
        "travellerCode",
        travellerCode
      );
      const localVarPath =
        `/traveller/api/followee-relationships/{traveller_code}/`.replace(
          `{${"traveller_code"}}`,
          encodeURIComponent(String(travellerCode))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Display a business profile with the recommendations visible to the     current user (traveller, public).  Also allows client to pass an additional traveller code to limit the recommendations by - useful for viewing a particular traveller\'s guide.
     * @param {string} businessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiProfilePreviewRetrieve: async (
      businessCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists(
        "travellerApiProfilePreviewRetrieve",
        "businessCode",
        businessCode
      );
      const localVarPath = `/traveller/api/profile-preview/{business_code}/`.replace(
        `{${"business_code"}}`,
        encodeURIComponent(String(businessCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Display a business profile with the recommendations visible to the     current user (traveller, public).  Also allows client to pass an additional traveller code to limit the recommendations by - useful for viewing a particular traveller\'s guide.
     * @param {string} businessCode
     * @param {string} travellerCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiProfilePreviewRetrieve2: async (
      businessCode: string,
      travellerCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessCode' is not null or undefined
      assertParamExists(
        "travellerApiProfilePreviewRetrieve2",
        "businessCode",
        businessCode
      );
      // verify required parameter 'travellerCode' is not null or undefined
      assertParamExists(
        "travellerApiProfilePreviewRetrieve2",
        "travellerCode",
        travellerCode
      );
      const localVarPath =
        `/traveller/api/profile-preview/{business_code}/{traveller_code}/`
          .replace(`{${"business_code"}}`, encodeURIComponent(String(businessCode)))
          .replace(`{${"traveller_code"}}`, encodeURIComponent(String(travellerCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {FollowerRelationshipWriteSerialiserRequest} followerRelationshipWriteSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiRelationshipsCreate: async (
      followerRelationshipWriteSerialiserRequest: FollowerRelationshipWriteSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'followerRelationshipWriteSerialiserRequest' is not null or undefined
      assertParamExists(
        "travellerApiRelationshipsCreate",
        "followerRelationshipWriteSerialiserRequest",
        followerRelationshipWriteSerialiserRequest
      );
      const localVarPath = `/traveller/api/relationships/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        followerRelationshipWriteSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiRelationshipsDestroy: async (
      uuid: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiRelationshipsDestroy", "uuid", uuid);
      const localVarPath = `/traveller/api/relationships/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiRelationshipsList: async (
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/traveller/api/relationships/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} uuid
     * @param {PatchedFollowerRelationshipWriteSerialiserRequest} [patchedFollowerRelationshipWriteSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiRelationshipsPartialUpdate: async (
      uuid: string,
      patchedFollowerRelationshipWriteSerialiserRequest?: PatchedFollowerRelationshipWriteSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiRelationshipsPartialUpdate", "uuid", uuid);
      const localVarPath = `/traveller/api/relationships/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedFollowerRelationshipWriteSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiRelationshipsRetrieve: async (
      uuid: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiRelationshipsRetrieve", "uuid", uuid);
      const localVarPath = `/traveller/api/relationships/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} uuid
     * @param {FollowerRelationshipWriteSerialiserRequest} followerRelationshipWriteSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiRelationshipsUpdate: async (
      uuid: string,
      followerRelationshipWriteSerialiserRequest: FollowerRelationshipWriteSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiRelationshipsUpdate", "uuid", uuid);
      // verify required parameter 'followerRelationshipWriteSerialiserRequest' is not null or undefined
      assertParamExists(
        "travellerApiRelationshipsUpdate",
        "followerRelationshipWriteSerialiserRequest",
        followerRelationshipWriteSerialiserRequest
      );
      const localVarPath = `/traveller/api/relationships/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        followerRelationshipWriteSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiSuggestedBusinessList: async (
      page?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/traveller/api/suggested/business/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of Hashtags to suggest to the traveller.  Annotates result set with a flag that indicates whether or not a given Hashtag is already in the Traveller\'s set, and tries to ensure that selected Hashtags are returned in the first page of results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiSuggestedHashtagList: async (
      page?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/traveller/api/suggested/hashtag/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {TravellerCreateSerialiserRequest} travellerCreateSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiTravellerCreate: async (
      travellerCreateSerialiserRequest: TravellerCreateSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'travellerCreateSerialiserRequest' is not null or undefined
      assertParamExists(
        "travellerApiTravellerCreate",
        "travellerCreateSerialiserRequest",
        travellerCreateSerialiserRequest
      );
      const localVarPath = `/traveller/api/traveller/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        travellerCreateSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiTravellerDestroy: async (
      code: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("travellerApiTravellerDestroy", "code", code);
      const localVarPath = `/traveller/api/traveller/{code}/`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiTravellerList: async (
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/traveller/api/traveller/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} code
     * @param {PatchedTravellerUpdateSerialiserRequest} [patchedTravellerUpdateSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiTravellerPartialUpdate: async (
      code: string,
      patchedTravellerUpdateSerialiserRequest?: PatchedTravellerUpdateSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("travellerApiTravellerPartialUpdate", "code", code);
      const localVarPath = `/traveller/api/traveller/{code}/`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedTravellerUpdateSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiTravellerRetrieve: async (
      code: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("travellerApiTravellerRetrieve", "code", code);
      const localVarPath = `/traveller/api/traveller/{code}/`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} code
     * @param {TravellerSerialiserRequest} travellerSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiTravellerUpdate: async (
      code: string,
      travellerSerialiserRequest: TravellerSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("travellerApiTravellerUpdate", "code", code);
      // verify required parameter 'travellerSerialiserRequest' is not null or undefined
      assertParamExists(
        "travellerApiTravellerUpdate",
        "travellerSerialiserRequest",
        travellerSerialiserRequest
      );
      const localVarPath = `/traveller/api/traveller/{code}/`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        travellerSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {VisitedActivitySerialiserRequest} visitedActivitySerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedActivityCreate: async (
      visitedActivitySerialiserRequest: VisitedActivitySerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'visitedActivitySerialiserRequest' is not null or undefined
      assertParamExists(
        "travellerApiVisitedActivityCreate",
        "visitedActivitySerialiserRequest",
        visitedActivitySerialiserRequest
      );
      const localVarPath = `/traveller/api/visited/activity/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        visitedActivitySerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedActivityDestroy: async (
      uuid: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiVisitedActivityDestroy", "uuid", uuid);
      const localVarPath = `/traveller/api/visited/activity/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedActivityList: async (
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/traveller/api/visited/activity/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {string} uuid
     * @param {PatchedVisitedActivitySerialiserRequest} [patchedVisitedActivitySerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedActivityPartialUpdate: async (
      uuid: string,
      patchedVisitedActivitySerialiserRequest?: PatchedVisitedActivitySerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiVisitedActivityPartialUpdate", "uuid", uuid);
      const localVarPath = `/traveller/api/visited/activity/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedVisitedActivitySerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedActivityRetrieve: async (
      uuid: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiVisitedActivityRetrieve", "uuid", uuid);
      const localVarPath = `/traveller/api/visited/activity/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {string} uuid
     * @param {VisitedActivitySerialiserRequest} visitedActivitySerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedActivityUpdate: async (
      uuid: string,
      visitedActivitySerialiserRequest: VisitedActivitySerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiVisitedActivityUpdate", "uuid", uuid);
      // verify required parameter 'visitedActivitySerialiserRequest' is not null or undefined
      assertParamExists(
        "travellerApiVisitedActivityUpdate",
        "visitedActivitySerialiserRequest",
        visitedActivitySerialiserRequest
      );
      const localVarPath = `/traveller/api/visited/activity/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        visitedActivitySerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedArea records for all of an authenticated traveller\'s network (followees), in a given country.
     * @param {string} countryCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedAreaNetworkList: async (
      countryCode: string,
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'countryCode' is not null or undefined
      assertParamExists("travellerApiVisitedAreaNetworkList", "countryCode", countryCode);
      const localVarPath = `/traveller/api/visited/area/{country_code}/network/`.replace(
        `{${"country_code"}}`,
        encodeURIComponent(String(countryCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {VisitedBusinessWithProfileSerialiserRequest} [visitedBusinessWithProfileSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedBusinessCreate: async (
      visitedBusinessWithProfileSerialiserRequest?: VisitedBusinessWithProfileSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/traveller/api/visited/business/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        visitedBusinessWithProfileSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedBusinessDestroy: async (
      uuid: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiVisitedBusinessDestroy", "uuid", uuid);
      const localVarPath = `/traveller/api/visited/business/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedBusinessList: async (
      ordering?: string,
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/traveller/api/visited/business/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (ordering !== undefined) {
        localVarQueryParameter["ordering"] = ordering;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedBusiness records for a travellers in a given traveller\'s network, filtered by country.
     * @param {string} countryCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedBusinessNetworkList: async (
      countryCode: string,
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'countryCode' is not null or undefined
      assertParamExists(
        "travellerApiVisitedBusinessNetworkList",
        "countryCode",
        countryCode
      );
      const localVarPath =
        `/traveller/api/visited/business/network/{country_code}/`.replace(
          `{${"country_code"}}`,
          encodeURIComponent(String(countryCode))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} uuid
     * @param {PatchedVisitedBusinessWithProfileSerialiserRequest} [patchedVisitedBusinessWithProfileSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedBusinessPartialUpdate: async (
      uuid: string,
      patchedVisitedBusinessWithProfileSerialiserRequest?: PatchedVisitedBusinessWithProfileSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiVisitedBusinessPartialUpdate", "uuid", uuid);
      const localVarPath = `/traveller/api/visited/business/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedVisitedBusinessWithProfileSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedBusinessRetrieve: async (
      uuid: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiVisitedBusinessRetrieve", "uuid", uuid);
      const localVarPath = `/traveller/api/visited/business/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} uuid
     * @param {VisitedBusinessWithProfileSerialiserRequest} [visitedBusinessWithProfileSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedBusinessUpdate: async (
      uuid: string,
      visitedBusinessWithProfileSerialiserRequest?: VisitedBusinessWithProfileSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiVisitedBusinessUpdate", "uuid", uuid);
      const localVarPath = `/traveller/api/visited/business/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        visitedBusinessWithProfileSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {VisitedCountrySerialiserRequest} visitedCountrySerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedCountryCreate: async (
      visitedCountrySerialiserRequest: VisitedCountrySerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'visitedCountrySerialiserRequest' is not null or undefined
      assertParamExists(
        "travellerApiVisitedCountryCreate",
        "visitedCountrySerialiserRequest",
        visitedCountrySerialiserRequest
      );
      const localVarPath = `/traveller/api/visited/country/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        visitedCountrySerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedCountryDestroy: async (
      uuid: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiVisitedCountryDestroy", "uuid", uuid);
      const localVarPath = `/traveller/api/visited/country/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedCountryList: async (
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/traveller/api/visited/country/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {string} uuid
     * @param {PatchedVisitedCountrySerialiserRequest} [patchedVisitedCountrySerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedCountryPartialUpdate: async (
      uuid: string,
      patchedVisitedCountrySerialiserRequest?: PatchedVisitedCountrySerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiVisitedCountryPartialUpdate", "uuid", uuid);
      const localVarPath = `/traveller/api/visited/country/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedVisitedCountrySerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedCountryRetrieve: async (
      uuid: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiVisitedCountryRetrieve", "uuid", uuid);
      const localVarPath = `/traveller/api/visited/country/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {string} uuid
     * @param {VisitedCountrySerialiserRequest} visitedCountrySerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedCountryUpdate: async (
      uuid: string,
      visitedCountrySerialiserRequest: VisitedCountrySerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiVisitedCountryUpdate", "uuid", uuid);
      // verify required parameter 'visitedCountrySerialiserRequest' is not null or undefined
      assertParamExists(
        "travellerApiVisitedCountryUpdate",
        "visitedCountrySerialiserRequest",
        visitedCountrySerialiserRequest
      );
      const localVarPath = `/traveller/api/visited/country/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        visitedCountrySerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {WishlistBusinessWriteSerialiserRequest} wishlistBusinessWriteSerialiserRequest
     * @param {string} [businessCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistBusinessCreate: async (
      wishlistBusinessWriteSerialiserRequest: WishlistBusinessWriteSerialiserRequest,
      businessCountry?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'wishlistBusinessWriteSerialiserRequest' is not null or undefined
      assertParamExists(
        "travellerApiWishlistBusinessCreate",
        "wishlistBusinessWriteSerialiserRequest",
        wishlistBusinessWriteSerialiserRequest
      );
      const localVarPath = `/traveller/api/wishlist-business/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (businessCountry !== undefined) {
        localVarQueryParameter["business__country"] = businessCountry;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        wishlistBusinessWriteSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} uuid
     * @param {string} [businessCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistBusinessDestroy: async (
      uuid: string,
      businessCountry?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiWishlistBusinessDestroy", "uuid", uuid);
      const localVarPath = `/traveller/api/wishlist-business/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (businessCountry !== undefined) {
        localVarQueryParameter["business__country"] = businessCountry;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [businessCountry]
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {string} [travellerCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistBusinessList: async (
      businessCountry?: string,
      ordering?: string,
      page?: number,
      travellerCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/traveller/api/wishlist-business/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (businessCountry !== undefined) {
        localVarQueryParameter["business__country"] = businessCountry;
      }

      if (ordering !== undefined) {
        localVarQueryParameter["ordering"] = ordering;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (travellerCode !== undefined) {
        localVarQueryParameter["traveller__code"] = travellerCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} uuid
     * @param {string} [businessCountry]
     * @param {PatchedWishlistBusinessWriteSerialiserRequest} [patchedWishlistBusinessWriteSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistBusinessPartialUpdate: async (
      uuid: string,
      businessCountry?: string,
      patchedWishlistBusinessWriteSerialiserRequest?: PatchedWishlistBusinessWriteSerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiWishlistBusinessPartialUpdate", "uuid", uuid);
      const localVarPath = `/traveller/api/wishlist-business/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (businessCountry !== undefined) {
        localVarQueryParameter["business__country"] = businessCountry;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedWishlistBusinessWriteSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} uuid
     * @param {string} [businessCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistBusinessRetrieve: async (
      uuid: string,
      businessCountry?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiWishlistBusinessRetrieve", "uuid", uuid);
      const localVarPath = `/traveller/api/wishlist-business/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (businessCountry !== undefined) {
        localVarQueryParameter["business__country"] = businessCountry;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} uuid
     * @param {WishlistBusinessWriteSerialiserRequest} wishlistBusinessWriteSerialiserRequest
     * @param {string} [businessCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistBusinessUpdate: async (
      uuid: string,
      wishlistBusinessWriteSerialiserRequest: WishlistBusinessWriteSerialiserRequest,
      businessCountry?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiWishlistBusinessUpdate", "uuid", uuid);
      // verify required parameter 'wishlistBusinessWriteSerialiserRequest' is not null or undefined
      assertParamExists(
        "travellerApiWishlistBusinessUpdate",
        "wishlistBusinessWriteSerialiserRequest",
        wishlistBusinessWriteSerialiserRequest
      );
      const localVarPath = `/traveller/api/wishlist-business/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (businessCountry !== undefined) {
        localVarQueryParameter["business__country"] = businessCountry;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        wishlistBusinessWriteSerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {WishlistCountrySerialiserRequest} [wishlistCountrySerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistCountryCreate: async (
      wishlistCountrySerialiserRequest?: WishlistCountrySerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/traveller/api/wishlist-country/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        wishlistCountrySerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistCountryDestroy: async (
      uuid: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiWishlistCountryDestroy", "uuid", uuid);
      const localVarPath = `/traveller/api/wishlist-country/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistCountryList: async (
      page?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/traveller/api/wishlist-country/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} uuid
     * @param {PatchedWishlistCountrySerialiserRequest} [patchedWishlistCountrySerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistCountryPartialUpdate: async (
      uuid: string,
      patchedWishlistCountrySerialiserRequest?: PatchedWishlistCountrySerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiWishlistCountryPartialUpdate", "uuid", uuid);
      const localVarPath = `/traveller/api/wishlist-country/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedWishlistCountrySerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistCountryRetrieve: async (
      uuid: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiWishlistCountryRetrieve", "uuid", uuid);
      const localVarPath = `/traveller/api/wishlist-country/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} uuid
     * @param {WishlistCountrySerialiserRequest} [wishlistCountrySerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistCountryUpdate: async (
      uuid: string,
      wishlistCountrySerialiserRequest?: WishlistCountrySerialiserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("travellerApiWishlistCountryUpdate", "uuid", uuid);
      const localVarPath = `/traveller/api/wishlist-country/{uuid}/`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        wishlistCountrySerialiserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of Area records filtered by country.  Note: the @extend_schema decorator is used here to override the automatically- generated enum type. We want clients to be able to make requests with *no* query parameters specified; this is not possible in the case where the API is generated with an enum type.
     * @param {string} [category]
     * @param {string} [country]
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerAreasInCountryList: async (
      category?: string,
      country?: string,
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/traveller/areas-in-country/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (category !== undefined) {
        localVarQueryParameter["category"] = category;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of hardcoded \'popular\' countries in a given region.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerCountryListRetrieve: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/traveller/country-list/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TravellerApi - functional programming interface
 * @export
 */
export const TravellerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TravellerApiAxiosParamCreator(configuration);
  return {
    /**
     * For a traveller\'s following, returns a region\'s countries with following metadata.  Returns a list of countries with:     visit_count(int): number of business visits by following in country     travellers([traveller]): following who have visited country
     * @param {string} region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiAggregateRegionList(
      region: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<AggregateCountrySerialiser>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiAggregateRegionList(region, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiChecklistRetrieve(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TravellerChecklistSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiChecklistRetrieve(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of Activities filtered by area and ordered by claimed listings.  Each Activity has visited (bool), visited_uuid if the current traveller has visited it.
     * @param {string} area
     * @param {string} country
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiCountryAreaActivitiesTopList(
      area: string,
      country: string,
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedTravellerActivitySerialiserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiCountryAreaActivitiesTopList(
          area,
          country,
          page,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of Area records filtered by country and ordered by claimed listings.  Each Area has visited (bool), visited_uuid if the current traveller has visited it.
     * @param {string} country
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiCountryAreasTopList(
      country: string,
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedTravellerAreaSerialiserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiCountryAreasTopList(
          country,
          page,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * GET the whole traveller network for a specific traveller
     * @param {string} travellerCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiFolloweeRelationshipsList(
      travellerCode: string,
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedFollowerRelationshipReadSerialiserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiFolloweeRelationshipsList(
          travellerCode,
          page,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Display a business profile with the recommendations visible to the     current user (traveller, public).  Also allows client to pass an additional traveller code to limit the recommendations by - useful for viewing a particular traveller\'s guide.
     * @param {string} businessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiProfilePreviewRetrieve(
      businessCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<BusinessSpotlightSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiProfilePreviewRetrieve(
          businessCode,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Display a business profile with the recommendations visible to the     current user (traveller, public).  Also allows client to pass an additional traveller code to limit the recommendations by - useful for viewing a particular traveller\'s guide.
     * @param {string} businessCode
     * @param {string} travellerCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiProfilePreviewRetrieve2(
      businessCode: string,
      travellerCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<BusinessSpotlightSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiProfilePreviewRetrieve2(
          businessCode,
          travellerCode,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {FollowerRelationshipWriteSerialiserRequest} followerRelationshipWriteSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiRelationshipsCreate(
      followerRelationshipWriteSerialiserRequest: FollowerRelationshipWriteSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FollowerRelationshipWriteSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiRelationshipsCreate(
          followerRelationshipWriteSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiRelationshipsDestroy(
      uuid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiRelationshipsDestroy(uuid, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiRelationshipsList(
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedFollowerRelationshipReadSerialiserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiRelationshipsList(page, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} uuid
     * @param {PatchedFollowerRelationshipWriteSerialiserRequest} [patchedFollowerRelationshipWriteSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiRelationshipsPartialUpdate(
      uuid: string,
      patchedFollowerRelationshipWriteSerialiserRequest?: PatchedFollowerRelationshipWriteSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FollowerRelationshipWriteSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiRelationshipsPartialUpdate(
          uuid,
          patchedFollowerRelationshipWriteSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiRelationshipsRetrieve(
      uuid: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FollowerRelationshipReadSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiRelationshipsRetrieve(uuid, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} uuid
     * @param {FollowerRelationshipWriteSerialiserRequest} followerRelationshipWriteSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiRelationshipsUpdate(
      uuid: string,
      followerRelationshipWriteSerialiserRequest: FollowerRelationshipWriteSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FollowerRelationshipWriteSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiRelationshipsUpdate(
          uuid,
          followerRelationshipWriteSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiSuggestedBusinessList(
      page?: number,
      pageSize?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedBusinessWithHighlightsSerialiserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiSuggestedBusinessList(
          page,
          pageSize,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of Hashtags to suggest to the traveller.  Annotates result set with a flag that indicates whether or not a given Hashtag is already in the Traveller\'s set, and tries to ensure that selected Hashtags are returned in the first page of results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiSuggestedHashtagList(
      page?: number,
      pageSize?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedHashtagSerialiserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiSuggestedHashtagList(
          page,
          pageSize,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {TravellerCreateSerialiserRequest} travellerCreateSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiTravellerCreate(
      travellerCreateSerialiserRequest: TravellerCreateSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TravellerCreateSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiTravellerCreate(
          travellerCreateSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiTravellerDestroy(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiTravellerDestroy(code, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiTravellerList(
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedTravellerSerialiserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.travellerApiTravellerList(
        page,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} code
     * @param {PatchedTravellerUpdateSerialiserRequest} [patchedTravellerUpdateSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiTravellerPartialUpdate(
      code: string,
      patchedTravellerUpdateSerialiserRequest?: PatchedTravellerUpdateSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TravellerUpdateSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiTravellerPartialUpdate(
          code,
          patchedTravellerUpdateSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiTravellerRetrieve(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TravellerSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiTravellerRetrieve(code, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} code
     * @param {TravellerSerialiserRequest} travellerSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiTravellerUpdate(
      code: string,
      travellerSerialiserRequest: TravellerSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TravellerSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiTravellerUpdate(
          code,
          travellerSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {VisitedActivitySerialiserRequest} visitedActivitySerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedActivityCreate(
      visitedActivitySerialiserRequest: VisitedActivitySerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<VisitedActivitySerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedActivityCreate(
          visitedActivitySerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedActivityDestroy(
      uuid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedActivityDestroy(uuid, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedActivityList(
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedVisitedActivitySerialiserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedActivityList(page, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {string} uuid
     * @param {PatchedVisitedActivitySerialiserRequest} [patchedVisitedActivitySerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedActivityPartialUpdate(
      uuid: string,
      patchedVisitedActivitySerialiserRequest?: PatchedVisitedActivitySerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<VisitedActivitySerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedActivityPartialUpdate(
          uuid,
          patchedVisitedActivitySerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedActivityRetrieve(
      uuid: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<VisitedActivitySerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedActivityRetrieve(
          uuid,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {string} uuid
     * @param {VisitedActivitySerialiserRequest} visitedActivitySerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedActivityUpdate(
      uuid: string,
      visitedActivitySerialiserRequest: VisitedActivitySerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<VisitedActivitySerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedActivityUpdate(
          uuid,
          visitedActivitySerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedArea records for all of an authenticated traveller\'s network (followees), in a given country.
     * @param {string} countryCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedAreaNetworkList(
      countryCode: string,
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedVisitedAreaSerialiserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedAreaNetworkList(
          countryCode,
          page,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {VisitedBusinessWithProfileSerialiserRequest} [visitedBusinessWithProfileSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedBusinessCreate(
      visitedBusinessWithProfileSerialiserRequest?: VisitedBusinessWithProfileSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<VisitedBusinessWithProfileSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedBusinessCreate(
          visitedBusinessWithProfileSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedBusinessDestroy(
      uuid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedBusinessDestroy(uuid, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedBusinessList(
      ordering?: string,
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedVisitedBusinessWithProfileSerialiserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedBusinessList(
          ordering,
          page,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedBusiness records for a travellers in a given traveller\'s network, filtered by country.
     * @param {string} countryCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedBusinessNetworkList(
      countryCode: string,
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedBusinessWithHighlightsSerialiserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedBusinessNetworkList(
          countryCode,
          page,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} uuid
     * @param {PatchedVisitedBusinessWithProfileSerialiserRequest} [patchedVisitedBusinessWithProfileSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedBusinessPartialUpdate(
      uuid: string,
      patchedVisitedBusinessWithProfileSerialiserRequest?: PatchedVisitedBusinessWithProfileSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<VisitedBusinessWithProfileSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedBusinessPartialUpdate(
          uuid,
          patchedVisitedBusinessWithProfileSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedBusinessRetrieve(
      uuid: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<VisitedBusinessWithProfileSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedBusinessRetrieve(
          uuid,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} uuid
     * @param {VisitedBusinessWithProfileSerialiserRequest} [visitedBusinessWithProfileSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedBusinessUpdate(
      uuid: string,
      visitedBusinessWithProfileSerialiserRequest?: VisitedBusinessWithProfileSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<VisitedBusinessWithProfileSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedBusinessUpdate(
          uuid,
          visitedBusinessWithProfileSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {VisitedCountrySerialiserRequest} visitedCountrySerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedCountryCreate(
      visitedCountrySerialiserRequest: VisitedCountrySerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitedCountrySerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedCountryCreate(
          visitedCountrySerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedCountryDestroy(
      uuid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedCountryDestroy(uuid, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedCountryList(
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedVisitedCountrySerialiserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedCountryList(page, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {string} uuid
     * @param {PatchedVisitedCountrySerialiserRequest} [patchedVisitedCountrySerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedCountryPartialUpdate(
      uuid: string,
      patchedVisitedCountrySerialiserRequest?: PatchedVisitedCountrySerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitedCountrySerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedCountryPartialUpdate(
          uuid,
          patchedVisitedCountrySerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedCountryRetrieve(
      uuid: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitedCountrySerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedCountryRetrieve(uuid, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {string} uuid
     * @param {VisitedCountrySerialiserRequest} visitedCountrySerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiVisitedCountryUpdate(
      uuid: string,
      visitedCountrySerialiserRequest: VisitedCountrySerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitedCountrySerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiVisitedCountryUpdate(
          uuid,
          visitedCountrySerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {WishlistBusinessWriteSerialiserRequest} wishlistBusinessWriteSerialiserRequest
     * @param {string} [businessCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiWishlistBusinessCreate(
      wishlistBusinessWriteSerialiserRequest: WishlistBusinessWriteSerialiserRequest,
      businessCountry?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<WishlistBusinessWriteSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiWishlistBusinessCreate(
          wishlistBusinessWriteSerialiserRequest,
          businessCountry,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} uuid
     * @param {string} [businessCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiWishlistBusinessDestroy(
      uuid: string,
      businessCountry?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiWishlistBusinessDestroy(
          uuid,
          businessCountry,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [businessCountry]
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {string} [travellerCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiWishlistBusinessList(
      businessCountry?: string,
      ordering?: string,
      page?: number,
      travellerCode?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedWishlistBusinessReadSerialiserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiWishlistBusinessList(
          businessCountry,
          ordering,
          page,
          travellerCode,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} uuid
     * @param {string} [businessCountry]
     * @param {PatchedWishlistBusinessWriteSerialiserRequest} [patchedWishlistBusinessWriteSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiWishlistBusinessPartialUpdate(
      uuid: string,
      businessCountry?: string,
      patchedWishlistBusinessWriteSerialiserRequest?: PatchedWishlistBusinessWriteSerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<WishlistBusinessWriteSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiWishlistBusinessPartialUpdate(
          uuid,
          businessCountry,
          patchedWishlistBusinessWriteSerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} uuid
     * @param {string} [businessCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiWishlistBusinessRetrieve(
      uuid: string,
      businessCountry?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<WishlistBusinessReadSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiWishlistBusinessRetrieve(
          uuid,
          businessCountry,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} uuid
     * @param {WishlistBusinessWriteSerialiserRequest} wishlistBusinessWriteSerialiserRequest
     * @param {string} [businessCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiWishlistBusinessUpdate(
      uuid: string,
      wishlistBusinessWriteSerialiserRequest: WishlistBusinessWriteSerialiserRequest,
      businessCountry?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<WishlistBusinessWriteSerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiWishlistBusinessUpdate(
          uuid,
          wishlistBusinessWriteSerialiserRequest,
          businessCountry,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {WishlistCountrySerialiserRequest} [wishlistCountrySerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiWishlistCountryCreate(
      wishlistCountrySerialiserRequest?: WishlistCountrySerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<WishlistCountrySerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiWishlistCountryCreate(
          wishlistCountrySerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiWishlistCountryDestroy(
      uuid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiWishlistCountryDestroy(uuid, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiWishlistCountryList(
      page?: number,
      pageSize?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedWishlistCountrySerialiserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiWishlistCountryList(
          page,
          pageSize,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} uuid
     * @param {PatchedWishlistCountrySerialiserRequest} [patchedWishlistCountrySerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiWishlistCountryPartialUpdate(
      uuid: string,
      patchedWishlistCountrySerialiserRequest?: PatchedWishlistCountrySerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<WishlistCountrySerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiWishlistCountryPartialUpdate(
          uuid,
          patchedWishlistCountrySerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiWishlistCountryRetrieve(
      uuid: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<WishlistCountrySerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiWishlistCountryRetrieve(
          uuid,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} uuid
     * @param {WishlistCountrySerialiserRequest} [wishlistCountrySerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerApiWishlistCountryUpdate(
      uuid: string,
      wishlistCountrySerialiserRequest?: WishlistCountrySerialiserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<WishlistCountrySerialiser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerApiWishlistCountryUpdate(
          uuid,
          wishlistCountrySerialiserRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of Area records filtered by country.  Note: the @extend_schema decorator is used here to override the automatically- generated enum type. We want clients to be able to make requests with *no* query parameters specified; this is not possible in the case where the API is generated with an enum type.
     * @param {string} [category]
     * @param {string} [country]
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerAreasInCountryList(
      category?: string,
      country?: string,
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedAreaSerialiserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerAreasInCountryList(
          category,
          country,
          page,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns a list of hardcoded \'popular\' countries in a given region.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async travellerCountryListRetrieve(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.travellerCountryListRetrieve(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * TravellerApi - factory interface
 * @export
 */
export const TravellerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = TravellerApiFp(configuration);
  return {
    /**
     * For a traveller\'s following, returns a region\'s countries with following metadata.  Returns a list of countries with:     visit_count(int): number of business visits by following in country     travellers([traveller]): following who have visited country
     * @param {string} region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiAggregateRegionList(
      region: string,
      options?: any
    ): AxiosPromise<Array<AggregateCountrySerialiser>> {
      return localVarFp
        .travellerApiAggregateRegionList(region, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiChecklistRetrieve(
      options?: any
    ): AxiosPromise<TravellerChecklistSerialiser> {
      return localVarFp
        .travellerApiChecklistRetrieve(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of Activities filtered by area and ordered by claimed listings.  Each Activity has visited (bool), visited_uuid if the current traveller has visited it.
     * @param {string} area
     * @param {string} country
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiCountryAreaActivitiesTopList(
      area: string,
      country: string,
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedTravellerActivitySerialiserList> {
      return localVarFp
        .travellerApiCountryAreaActivitiesTopList(area, country, page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of Area records filtered by country and ordered by claimed listings.  Each Area has visited (bool), visited_uuid if the current traveller has visited it.
     * @param {string} country
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiCountryAreasTopList(
      country: string,
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedTravellerAreaSerialiserList> {
      return localVarFp
        .travellerApiCountryAreasTopList(country, page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * GET the whole traveller network for a specific traveller
     * @param {string} travellerCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiFolloweeRelationshipsList(
      travellerCode: string,
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedFollowerRelationshipReadSerialiserList> {
      return localVarFp
        .travellerApiFolloweeRelationshipsList(travellerCode, page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Display a business profile with the recommendations visible to the     current user (traveller, public).  Also allows client to pass an additional traveller code to limit the recommendations by - useful for viewing a particular traveller\'s guide.
     * @param {string} businessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiProfilePreviewRetrieve(
      businessCode: string,
      options?: any
    ): AxiosPromise<BusinessSpotlightSerialiser> {
      return localVarFp
        .travellerApiProfilePreviewRetrieve(businessCode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Display a business profile with the recommendations visible to the     current user (traveller, public).  Also allows client to pass an additional traveller code to limit the recommendations by - useful for viewing a particular traveller\'s guide.
     * @param {string} businessCode
     * @param {string} travellerCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiProfilePreviewRetrieve2(
      businessCode: string,
      travellerCode: string,
      options?: any
    ): AxiosPromise<BusinessSpotlightSerialiser> {
      return localVarFp
        .travellerApiProfilePreviewRetrieve2(businessCode, travellerCode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {FollowerRelationshipWriteSerialiserRequest} followerRelationshipWriteSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiRelationshipsCreate(
      followerRelationshipWriteSerialiserRequest: FollowerRelationshipWriteSerialiserRequest,
      options?: any
    ): AxiosPromise<FollowerRelationshipWriteSerialiser> {
      return localVarFp
        .travellerApiRelationshipsCreate(
          followerRelationshipWriteSerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiRelationshipsDestroy(uuid: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .travellerApiRelationshipsDestroy(uuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiRelationshipsList(
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedFollowerRelationshipReadSerialiserList> {
      return localVarFp
        .travellerApiRelationshipsList(page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} uuid
     * @param {PatchedFollowerRelationshipWriteSerialiserRequest} [patchedFollowerRelationshipWriteSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiRelationshipsPartialUpdate(
      uuid: string,
      patchedFollowerRelationshipWriteSerialiserRequest?: PatchedFollowerRelationshipWriteSerialiserRequest,
      options?: any
    ): AxiosPromise<FollowerRelationshipWriteSerialiser> {
      return localVarFp
        .travellerApiRelationshipsPartialUpdate(
          uuid,
          patchedFollowerRelationshipWriteSerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiRelationshipsRetrieve(
      uuid: string,
      options?: any
    ): AxiosPromise<FollowerRelationshipReadSerialiser> {
      return localVarFp
        .travellerApiRelationshipsRetrieve(uuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} uuid
     * @param {FollowerRelationshipWriteSerialiserRequest} followerRelationshipWriteSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiRelationshipsUpdate(
      uuid: string,
      followerRelationshipWriteSerialiserRequest: FollowerRelationshipWriteSerialiserRequest,
      options?: any
    ): AxiosPromise<FollowerRelationshipWriteSerialiser> {
      return localVarFp
        .travellerApiRelationshipsUpdate(
          uuid,
          followerRelationshipWriteSerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiSuggestedBusinessList(
      page?: number,
      pageSize?: number,
      options?: any
    ): AxiosPromise<PaginatedBusinessWithHighlightsSerialiserList> {
      return localVarFp
        .travellerApiSuggestedBusinessList(page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of Hashtags to suggest to the traveller.  Annotates result set with a flag that indicates whether or not a given Hashtag is already in the Traveller\'s set, and tries to ensure that selected Hashtags are returned in the first page of results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiSuggestedHashtagList(
      page?: number,
      pageSize?: number,
      options?: any
    ): AxiosPromise<PaginatedHashtagSerialiserList> {
      return localVarFp
        .travellerApiSuggestedHashtagList(page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {TravellerCreateSerialiserRequest} travellerCreateSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiTravellerCreate(
      travellerCreateSerialiserRequest: TravellerCreateSerialiserRequest,
      options?: any
    ): AxiosPromise<TravellerCreateSerialiser> {
      return localVarFp
        .travellerApiTravellerCreate(travellerCreateSerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiTravellerDestroy(code: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .travellerApiTravellerDestroy(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiTravellerList(
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedTravellerSerialiserList> {
      return localVarFp
        .travellerApiTravellerList(page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} code
     * @param {PatchedTravellerUpdateSerialiserRequest} [patchedTravellerUpdateSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiTravellerPartialUpdate(
      code: string,
      patchedTravellerUpdateSerialiserRequest?: PatchedTravellerUpdateSerialiserRequest,
      options?: any
    ): AxiosPromise<TravellerUpdateSerialiser> {
      return localVarFp
        .travellerApiTravellerPartialUpdate(
          code,
          patchedTravellerUpdateSerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiTravellerRetrieve(
      code: string,
      options?: any
    ): AxiosPromise<TravellerSerialiser> {
      return localVarFp
        .travellerApiTravellerRetrieve(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} code
     * @param {TravellerSerialiserRequest} travellerSerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiTravellerUpdate(
      code: string,
      travellerSerialiserRequest: TravellerSerialiserRequest,
      options?: any
    ): AxiosPromise<TravellerSerialiser> {
      return localVarFp
        .travellerApiTravellerUpdate(code, travellerSerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {VisitedActivitySerialiserRequest} visitedActivitySerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedActivityCreate(
      visitedActivitySerialiserRequest: VisitedActivitySerialiserRequest,
      options?: any
    ): AxiosPromise<VisitedActivitySerialiser> {
      return localVarFp
        .travellerApiVisitedActivityCreate(visitedActivitySerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedActivityDestroy(uuid: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .travellerApiVisitedActivityDestroy(uuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedActivityList(
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedVisitedActivitySerialiserList> {
      return localVarFp
        .travellerApiVisitedActivityList(page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {string} uuid
     * @param {PatchedVisitedActivitySerialiserRequest} [patchedVisitedActivitySerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedActivityPartialUpdate(
      uuid: string,
      patchedVisitedActivitySerialiserRequest?: PatchedVisitedActivitySerialiserRequest,
      options?: any
    ): AxiosPromise<VisitedActivitySerialiser> {
      return localVarFp
        .travellerApiVisitedActivityPartialUpdate(
          uuid,
          patchedVisitedActivitySerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedActivityRetrieve(
      uuid: string,
      options?: any
    ): AxiosPromise<VisitedActivitySerialiser> {
      return localVarFp
        .travellerApiVisitedActivityRetrieve(uuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedActivity records filtered by traveller code.
     * @param {string} uuid
     * @param {VisitedActivitySerialiserRequest} visitedActivitySerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedActivityUpdate(
      uuid: string,
      visitedActivitySerialiserRequest: VisitedActivitySerialiserRequest,
      options?: any
    ): AxiosPromise<VisitedActivitySerialiser> {
      return localVarFp
        .travellerApiVisitedActivityUpdate(
          uuid,
          visitedActivitySerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedArea records for all of an authenticated traveller\'s network (followees), in a given country.
     * @param {string} countryCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedAreaNetworkList(
      countryCode: string,
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedVisitedAreaSerialiserList> {
      return localVarFp
        .travellerApiVisitedAreaNetworkList(countryCode, page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {VisitedBusinessWithProfileSerialiserRequest} [visitedBusinessWithProfileSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedBusinessCreate(
      visitedBusinessWithProfileSerialiserRequest?: VisitedBusinessWithProfileSerialiserRequest,
      options?: any
    ): AxiosPromise<VisitedBusinessWithProfileSerialiser> {
      return localVarFp
        .travellerApiVisitedBusinessCreate(
          visitedBusinessWithProfileSerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedBusinessDestroy(uuid: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .travellerApiVisitedBusinessDestroy(uuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedBusinessList(
      ordering?: string,
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedVisitedBusinessWithProfileSerialiserList> {
      return localVarFp
        .travellerApiVisitedBusinessList(ordering, page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedBusiness records for a travellers in a given traveller\'s network, filtered by country.
     * @param {string} countryCode
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedBusinessNetworkList(
      countryCode: string,
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedBusinessWithHighlightsSerialiserList> {
      return localVarFp
        .travellerApiVisitedBusinessNetworkList(countryCode, page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} uuid
     * @param {PatchedVisitedBusinessWithProfileSerialiserRequest} [patchedVisitedBusinessWithProfileSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedBusinessPartialUpdate(
      uuid: string,
      patchedVisitedBusinessWithProfileSerialiserRequest?: PatchedVisitedBusinessWithProfileSerialiserRequest,
      options?: any
    ): AxiosPromise<VisitedBusinessWithProfileSerialiser> {
      return localVarFp
        .travellerApiVisitedBusinessPartialUpdate(
          uuid,
          patchedVisitedBusinessWithProfileSerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedBusinessRetrieve(
      uuid: string,
      options?: any
    ): AxiosPromise<VisitedBusinessWithProfileSerialiser> {
      return localVarFp
        .travellerApiVisitedBusinessRetrieve(uuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} uuid
     * @param {VisitedBusinessWithProfileSerialiserRequest} [visitedBusinessWithProfileSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedBusinessUpdate(
      uuid: string,
      visitedBusinessWithProfileSerialiserRequest?: VisitedBusinessWithProfileSerialiserRequest,
      options?: any
    ): AxiosPromise<VisitedBusinessWithProfileSerialiser> {
      return localVarFp
        .travellerApiVisitedBusinessUpdate(
          uuid,
          visitedBusinessWithProfileSerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {VisitedCountrySerialiserRequest} visitedCountrySerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedCountryCreate(
      visitedCountrySerialiserRequest: VisitedCountrySerialiserRequest,
      options?: any
    ): AxiosPromise<VisitedCountrySerialiser> {
      return localVarFp
        .travellerApiVisitedCountryCreate(visitedCountrySerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedCountryDestroy(uuid: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .travellerApiVisitedCountryDestroy(uuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedCountryList(
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedVisitedCountrySerialiserList> {
      return localVarFp
        .travellerApiVisitedCountryList(page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {string} uuid
     * @param {PatchedVisitedCountrySerialiserRequest} [patchedVisitedCountrySerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedCountryPartialUpdate(
      uuid: string,
      patchedVisitedCountrySerialiserRequest?: PatchedVisitedCountrySerialiserRequest,
      options?: any
    ): AxiosPromise<VisitedCountrySerialiser> {
      return localVarFp
        .travellerApiVisitedCountryPartialUpdate(
          uuid,
          patchedVisitedCountrySerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedCountryRetrieve(
      uuid: string,
      options?: any
    ): AxiosPromise<VisitedCountrySerialiser> {
      return localVarFp
        .travellerApiVisitedCountryRetrieve(uuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of VisitedCountry records.
     * @param {string} uuid
     * @param {VisitedCountrySerialiserRequest} visitedCountrySerialiserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiVisitedCountryUpdate(
      uuid: string,
      visitedCountrySerialiserRequest: VisitedCountrySerialiserRequest,
      options?: any
    ): AxiosPromise<VisitedCountrySerialiser> {
      return localVarFp
        .travellerApiVisitedCountryUpdate(uuid, visitedCountrySerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {WishlistBusinessWriteSerialiserRequest} wishlistBusinessWriteSerialiserRequest
     * @param {string} [businessCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistBusinessCreate(
      wishlistBusinessWriteSerialiserRequest: WishlistBusinessWriteSerialiserRequest,
      businessCountry?: string,
      options?: any
    ): AxiosPromise<WishlistBusinessWriteSerialiser> {
      return localVarFp
        .travellerApiWishlistBusinessCreate(
          wishlistBusinessWriteSerialiserRequest,
          businessCountry,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} uuid
     * @param {string} [businessCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistBusinessDestroy(
      uuid: string,
      businessCountry?: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .travellerApiWishlistBusinessDestroy(uuid, businessCountry, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [businessCountry]
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {string} [travellerCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistBusinessList(
      businessCountry?: string,
      ordering?: string,
      page?: number,
      travellerCode?: string,
      options?: any
    ): AxiosPromise<PaginatedWishlistBusinessReadSerialiserList> {
      return localVarFp
        .travellerApiWishlistBusinessList(
          businessCountry,
          ordering,
          page,
          travellerCode,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} uuid
     * @param {string} [businessCountry]
     * @param {PatchedWishlistBusinessWriteSerialiserRequest} [patchedWishlistBusinessWriteSerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistBusinessPartialUpdate(
      uuid: string,
      businessCountry?: string,
      patchedWishlistBusinessWriteSerialiserRequest?: PatchedWishlistBusinessWriteSerialiserRequest,
      options?: any
    ): AxiosPromise<WishlistBusinessWriteSerialiser> {
      return localVarFp
        .travellerApiWishlistBusinessPartialUpdate(
          uuid,
          businessCountry,
          patchedWishlistBusinessWriteSerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} uuid
     * @param {string} [businessCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistBusinessRetrieve(
      uuid: string,
      businessCountry?: string,
      options?: any
    ): AxiosPromise<WishlistBusinessReadSerialiser> {
      return localVarFp
        .travellerApiWishlistBusinessRetrieve(uuid, businessCountry, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} uuid
     * @param {WishlistBusinessWriteSerialiserRequest} wishlistBusinessWriteSerialiserRequest
     * @param {string} [businessCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistBusinessUpdate(
      uuid: string,
      wishlistBusinessWriteSerialiserRequest: WishlistBusinessWriteSerialiserRequest,
      businessCountry?: string,
      options?: any
    ): AxiosPromise<WishlistBusinessWriteSerialiser> {
      return localVarFp
        .travellerApiWishlistBusinessUpdate(
          uuid,
          wishlistBusinessWriteSerialiserRequest,
          businessCountry,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {WishlistCountrySerialiserRequest} [wishlistCountrySerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistCountryCreate(
      wishlistCountrySerialiserRequest?: WishlistCountrySerialiserRequest,
      options?: any
    ): AxiosPromise<WishlistCountrySerialiser> {
      return localVarFp
        .travellerApiWishlistCountryCreate(wishlistCountrySerialiserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistCountryDestroy(uuid: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .travellerApiWishlistCountryDestroy(uuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistCountryList(
      page?: number,
      pageSize?: number,
      options?: any
    ): AxiosPromise<PaginatedWishlistCountrySerialiserList> {
      return localVarFp
        .travellerApiWishlistCountryList(page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} uuid
     * @param {PatchedWishlistCountrySerialiserRequest} [patchedWishlistCountrySerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistCountryPartialUpdate(
      uuid: string,
      patchedWishlistCountrySerialiserRequest?: PatchedWishlistCountrySerialiserRequest,
      options?: any
    ): AxiosPromise<WishlistCountrySerialiser> {
      return localVarFp
        .travellerApiWishlistCountryPartialUpdate(
          uuid,
          patchedWishlistCountrySerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistCountryRetrieve(
      uuid: string,
      options?: any
    ): AxiosPromise<WishlistCountrySerialiser> {
      return localVarFp
        .travellerApiWishlistCountryRetrieve(uuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} uuid
     * @param {WishlistCountrySerialiserRequest} [wishlistCountrySerialiserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerApiWishlistCountryUpdate(
      uuid: string,
      wishlistCountrySerialiserRequest?: WishlistCountrySerialiserRequest,
      options?: any
    ): AxiosPromise<WishlistCountrySerialiser> {
      return localVarFp
        .travellerApiWishlistCountryUpdate(
          uuid,
          wishlistCountrySerialiserRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of Area records filtered by country.  Note: the @extend_schema decorator is used here to override the automatically- generated enum type. We want clients to be able to make requests with *no* query parameters specified; this is not possible in the case where the API is generated with an enum type.
     * @param {string} [category]
     * @param {string} [country]
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerAreasInCountryList(
      category?: string,
      country?: string,
      page?: number,
      options?: any
    ): AxiosPromise<PaginatedAreaSerialiserList> {
      return localVarFp
        .travellerAreasInCountryList(category, country, page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of hardcoded \'popular\' countries in a given region.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    travellerCountryListRetrieve(options?: any): AxiosPromise<void> {
      return localVarFp
        .travellerCountryListRetrieve(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TravellerApi - object-oriented interface
 * @export
 * @class TravellerApi
 * @extends {BaseAPI}
 */
export class TravellerApi extends BaseAPI {
  /**
   * For a traveller\'s following, returns a region\'s countries with following metadata.  Returns a list of countries with:     visit_count(int): number of business visits by following in country     travellers([traveller]): following who have visited country
   * @param {string} region
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiAggregateRegionList(region: string, options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiAggregateRegionList(region, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiChecklistRetrieve(options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiChecklistRetrieve(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of Activities filtered by area and ordered by claimed listings.  Each Activity has visited (bool), visited_uuid if the current traveller has visited it.
   * @param {string} area
   * @param {string} country
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiCountryAreaActivitiesTopList(
    area: string,
    country: string,
    page?: number,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiCountryAreaActivitiesTopList(area, country, page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of Area records filtered by country and ordered by claimed listings.  Each Area has visited (bool), visited_uuid if the current traveller has visited it.
   * @param {string} country
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiCountryAreasTopList(
    country: string,
    page?: number,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiCountryAreasTopList(country, page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * GET the whole traveller network for a specific traveller
   * @param {string} travellerCode
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiFolloweeRelationshipsList(
    travellerCode: string,
    page?: number,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiFolloweeRelationshipsList(travellerCode, page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Display a business profile with the recommendations visible to the     current user (traveller, public).  Also allows client to pass an additional traveller code to limit the recommendations by - useful for viewing a particular traveller\'s guide.
   * @param {string} businessCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiProfilePreviewRetrieve(
    businessCode: string,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiProfilePreviewRetrieve(businessCode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Display a business profile with the recommendations visible to the     current user (traveller, public).  Also allows client to pass an additional traveller code to limit the recommendations by - useful for viewing a particular traveller\'s guide.
   * @param {string} businessCode
   * @param {string} travellerCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiProfilePreviewRetrieve2(
    businessCode: string,
    travellerCode: string,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiProfilePreviewRetrieve2(businessCode, travellerCode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FollowerRelationshipWriteSerialiserRequest} followerRelationshipWriteSerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiRelationshipsCreate(
    followerRelationshipWriteSerialiserRequest: FollowerRelationshipWriteSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiRelationshipsCreate(
        followerRelationshipWriteSerialiserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiRelationshipsDestroy(uuid: string, options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiRelationshipsDestroy(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiRelationshipsList(page?: number, options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiRelationshipsList(page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} uuid
   * @param {PatchedFollowerRelationshipWriteSerialiserRequest} [patchedFollowerRelationshipWriteSerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiRelationshipsPartialUpdate(
    uuid: string,
    patchedFollowerRelationshipWriteSerialiserRequest?: PatchedFollowerRelationshipWriteSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiRelationshipsPartialUpdate(
        uuid,
        patchedFollowerRelationshipWriteSerialiserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiRelationshipsRetrieve(uuid: string, options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiRelationshipsRetrieve(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} uuid
   * @param {FollowerRelationshipWriteSerialiserRequest} followerRelationshipWriteSerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiRelationshipsUpdate(
    uuid: string,
    followerRelationshipWriteSerialiserRequest: FollowerRelationshipWriteSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiRelationshipsUpdate(
        uuid,
        followerRelationshipWriteSerialiserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [pageSize] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiSuggestedBusinessList(
    page?: number,
    pageSize?: number,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiSuggestedBusinessList(page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of Hashtags to suggest to the traveller.  Annotates result set with a flag that indicates whether or not a given Hashtag is already in the Traveller\'s set, and tries to ensure that selected Hashtags are returned in the first page of results.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [pageSize] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiSuggestedHashtagList(
    page?: number,
    pageSize?: number,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiSuggestedHashtagList(page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {TravellerCreateSerialiserRequest} travellerCreateSerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiTravellerCreate(
    travellerCreateSerialiserRequest: TravellerCreateSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiTravellerCreate(travellerCreateSerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiTravellerDestroy(code: string, options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiTravellerDestroy(code, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiTravellerList(page?: number, options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiTravellerList(page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} code
   * @param {PatchedTravellerUpdateSerialiserRequest} [patchedTravellerUpdateSerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiTravellerPartialUpdate(
    code: string,
    patchedTravellerUpdateSerialiserRequest?: PatchedTravellerUpdateSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiTravellerPartialUpdate(
        code,
        patchedTravellerUpdateSerialiserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiTravellerRetrieve(code: string, options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiTravellerRetrieve(code, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} code
   * @param {TravellerSerialiserRequest} travellerSerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiTravellerUpdate(
    code: string,
    travellerSerialiserRequest: TravellerSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiTravellerUpdate(code, travellerSerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedActivity records filtered by traveller code.
   * @param {VisitedActivitySerialiserRequest} visitedActivitySerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedActivityCreate(
    visitedActivitySerialiserRequest: VisitedActivitySerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedActivityCreate(visitedActivitySerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedActivity records filtered by traveller code.
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedActivityDestroy(uuid: string, options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedActivityDestroy(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedActivity records filtered by traveller code.
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedActivityList(page?: number, options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedActivityList(page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedActivity records filtered by traveller code.
   * @param {string} uuid
   * @param {PatchedVisitedActivitySerialiserRequest} [patchedVisitedActivitySerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedActivityPartialUpdate(
    uuid: string,
    patchedVisitedActivitySerialiserRequest?: PatchedVisitedActivitySerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedActivityPartialUpdate(
        uuid,
        patchedVisitedActivitySerialiserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedActivity records filtered by traveller code.
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedActivityRetrieve(uuid: string, options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedActivityRetrieve(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedActivity records filtered by traveller code.
   * @param {string} uuid
   * @param {VisitedActivitySerialiserRequest} visitedActivitySerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedActivityUpdate(
    uuid: string,
    visitedActivitySerialiserRequest: VisitedActivitySerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedActivityUpdate(uuid, visitedActivitySerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedArea records for all of an authenticated traveller\'s network (followees), in a given country.
   * @param {string} countryCode
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedAreaNetworkList(
    countryCode: string,
    page?: number,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedAreaNetworkList(countryCode, page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {VisitedBusinessWithProfileSerialiserRequest} [visitedBusinessWithProfileSerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedBusinessCreate(
    visitedBusinessWithProfileSerialiserRequest?: VisitedBusinessWithProfileSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedBusinessCreate(
        visitedBusinessWithProfileSerialiserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedBusinessDestroy(uuid: string, options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedBusinessDestroy(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedBusinessList(
    ordering?: string,
    page?: number,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedBusinessList(ordering, page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedBusiness records for a travellers in a given traveller\'s network, filtered by country.
   * @param {string} countryCode
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedBusinessNetworkList(
    countryCode: string,
    page?: number,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedBusinessNetworkList(countryCode, page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} uuid
   * @param {PatchedVisitedBusinessWithProfileSerialiserRequest} [patchedVisitedBusinessWithProfileSerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedBusinessPartialUpdate(
    uuid: string,
    patchedVisitedBusinessWithProfileSerialiserRequest?: PatchedVisitedBusinessWithProfileSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedBusinessPartialUpdate(
        uuid,
        patchedVisitedBusinessWithProfileSerialiserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedBusinessRetrieve(uuid: string, options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedBusinessRetrieve(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} uuid
   * @param {VisitedBusinessWithProfileSerialiserRequest} [visitedBusinessWithProfileSerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedBusinessUpdate(
    uuid: string,
    visitedBusinessWithProfileSerialiserRequest?: VisitedBusinessWithProfileSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedBusinessUpdate(
        uuid,
        visitedBusinessWithProfileSerialiserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedCountry records.
   * @param {VisitedCountrySerialiserRequest} visitedCountrySerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedCountryCreate(
    visitedCountrySerialiserRequest: VisitedCountrySerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedCountryCreate(visitedCountrySerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedCountry records.
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedCountryDestroy(uuid: string, options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedCountryDestroy(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedCountry records.
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedCountryList(page?: number, options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedCountryList(page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedCountry records.
   * @param {string} uuid
   * @param {PatchedVisitedCountrySerialiserRequest} [patchedVisitedCountrySerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedCountryPartialUpdate(
    uuid: string,
    patchedVisitedCountrySerialiserRequest?: PatchedVisitedCountrySerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedCountryPartialUpdate(
        uuid,
        patchedVisitedCountrySerialiserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedCountry records.
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedCountryRetrieve(uuid: string, options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedCountryRetrieve(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of VisitedCountry records.
   * @param {string} uuid
   * @param {VisitedCountrySerialiserRequest} visitedCountrySerialiserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiVisitedCountryUpdate(
    uuid: string,
    visitedCountrySerialiserRequest: VisitedCountrySerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiVisitedCountryUpdate(uuid, visitedCountrySerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {WishlistBusinessWriteSerialiserRequest} wishlistBusinessWriteSerialiserRequest
   * @param {string} [businessCountry]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiWishlistBusinessCreate(
    wishlistBusinessWriteSerialiserRequest: WishlistBusinessWriteSerialiserRequest,
    businessCountry?: string,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiWishlistBusinessCreate(
        wishlistBusinessWriteSerialiserRequest,
        businessCountry,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} uuid
   * @param {string} [businessCountry]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiWishlistBusinessDestroy(
    uuid: string,
    businessCountry?: string,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiWishlistBusinessDestroy(uuid, businessCountry, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [businessCountry]
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {number} [page] A page number within the paginated result set.
   * @param {string} [travellerCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiWishlistBusinessList(
    businessCountry?: string,
    ordering?: string,
    page?: number,
    travellerCode?: string,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiWishlistBusinessList(
        businessCountry,
        ordering,
        page,
        travellerCode,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} uuid
   * @param {string} [businessCountry]
   * @param {PatchedWishlistBusinessWriteSerialiserRequest} [patchedWishlistBusinessWriteSerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiWishlistBusinessPartialUpdate(
    uuid: string,
    businessCountry?: string,
    patchedWishlistBusinessWriteSerialiserRequest?: PatchedWishlistBusinessWriteSerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiWishlistBusinessPartialUpdate(
        uuid,
        businessCountry,
        patchedWishlistBusinessWriteSerialiserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} uuid
   * @param {string} [businessCountry]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiWishlistBusinessRetrieve(
    uuid: string,
    businessCountry?: string,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiWishlistBusinessRetrieve(uuid, businessCountry, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} uuid
   * @param {WishlistBusinessWriteSerialiserRequest} wishlistBusinessWriteSerialiserRequest
   * @param {string} [businessCountry]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiWishlistBusinessUpdate(
    uuid: string,
    wishlistBusinessWriteSerialiserRequest: WishlistBusinessWriteSerialiserRequest,
    businessCountry?: string,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiWishlistBusinessUpdate(
        uuid,
        wishlistBusinessWriteSerialiserRequest,
        businessCountry,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {WishlistCountrySerialiserRequest} [wishlistCountrySerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiWishlistCountryCreate(
    wishlistCountrySerialiserRequest?: WishlistCountrySerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiWishlistCountryCreate(wishlistCountrySerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiWishlistCountryDestroy(uuid: string, options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiWishlistCountryDestroy(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [pageSize] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiWishlistCountryList(
    page?: number,
    pageSize?: number,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiWishlistCountryList(page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} uuid
   * @param {PatchedWishlistCountrySerialiserRequest} [patchedWishlistCountrySerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiWishlistCountryPartialUpdate(
    uuid: string,
    patchedWishlistCountrySerialiserRequest?: PatchedWishlistCountrySerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiWishlistCountryPartialUpdate(
        uuid,
        patchedWishlistCountrySerialiserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiWishlistCountryRetrieve(uuid: string, options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerApiWishlistCountryRetrieve(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} uuid
   * @param {WishlistCountrySerialiserRequest} [wishlistCountrySerialiserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerApiWishlistCountryUpdate(
    uuid: string,
    wishlistCountrySerialiserRequest?: WishlistCountrySerialiserRequest,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerApiWishlistCountryUpdate(uuid, wishlistCountrySerialiserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of Area records filtered by country.  Note: the @extend_schema decorator is used here to override the automatically- generated enum type. We want clients to be able to make requests with *no* query parameters specified; this is not possible in the case where the API is generated with an enum type.
   * @param {string} [category]
   * @param {string} [country]
   * @param {number} [page] A page number within the paginated result set.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerAreasInCountryList(
    category?: string,
    country?: string,
    page?: number,
    options?: AxiosRequestConfig
  ) {
    return TravellerApiFp(this.configuration)
      .travellerAreasInCountryList(category, country, page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of hardcoded \'popular\' countries in a given region.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TravellerApi
   */
  public travellerCountryListRetrieve(options?: AxiosRequestConfig) {
    return TravellerApiFp(this.configuration)
      .travellerCountryListRetrieve(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
