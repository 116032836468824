<script lang="ts">
  export let email;
</script>

<div class="h2 text-center font-weight-bold">Check your email</div>
<hr />
<div class="text-center">
  <i class="fa fa-envelope fa-3x text-primary" />
  <div class="text-muted">
    We sent an email to you at {email}. It has a password reset link to help you reset
    your password!
  </div>
</div>
