<script>
  import { createEventDispatcher } from "svelte";
  import { getCountryCoverImageLink } from "/traveller/utils.js";
  import { COUNTRIES } from "/shared/country.js";
  /**
   * Show a (de)-colourised Country Card that can be used as a link or button.
   *
   * Supports 2 modes:
   *  1. Clicking the card dispatches a "click" event
   *     which contains the (unique) card code in
   *     event.detail.code
   *  2. When a cardLink is provided, the card becomes an anchor.
   *
   * @component CountryCard
   */

  export let code;
  export let selected = false;
  export let shrinkOnMobile = false;
  export let shrinkAlways = false;
  export let cardLink = null;
  export let newTab = false;

  let imageLink = getCountryCoverImageLink(code);
  let height = shrinkAlways ? "75px" : "175px";

  const dispatch = createEventDispatcher();
</script>

<style>
  .gray {
    filter: grayscale(0.95);
    color: #232222;
    transition: 0.5s;
    border: 0;
  }
  .colour {
    filter: none;
    transition: 0.5s;
  }
  .dark-overlay::after {
    opacity: 0.25;
  }
  /* Media queries for slim cards on mobile */
  @media only screen and (max-width: 768px) {
    .card-slim {
      height: 80px !important;
    }
    .h4 {
      font-size: 1.2rem;
    }
  }

  .card {
    height: var(--height);
  }
</style>

<a
  href={cardLink}
  target={newTab ? "_blank" : ""}
  rel={newTab ? "noopener noreferrer" : ""}>
  <div
    data-blink-quality="lightest"
    data-blink-scale-crop="450x300"
    data-blink-src={imageLink}
    style="--height: {height}"
    class="card rounded-lg bg-cover text-white dark-overlay"
    class:gray={!(selected || !imageLink)}
    class:colour={selected || !imageLink}
    class:card-slim={shrinkOnMobile}
    role="button"
    on:click={() => dispatch("click", { code: code })}>
    <slot name="before-content" />
    <div class="p-2 card-img-overlay d-flex align-items-center">
      <div class="w-100 overlay-content text-center">
        <span class="opacity-9 mb-0 text-capitalize h4 letter-spacing-2">
          {COUNTRIES[code].toUpperCase()}
        </span>
      </div>
    </div>
  </div>
</a>
