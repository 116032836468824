<script lang="ts">
  import "bootstrap/js/dist/tooltip";
  import { onMount } from "svelte";
  import { BusinessSpotlightSerialiser } from "/apiFactory/api";
  import { ActivitySubtype, DietaryType, LgbtqType, PriceRangeType } from "/shared/enums";
  import { capitalise } from "/shared/utils.js";
  import { BusinessType } from "/shared/enums";

  export let business: BusinessSpotlightSerialiser;

  let {
    name,
    short_description,
    hashtags,
    opening_time,
    closing_time,
    activity_subtype,
    number_of_rooms,
    additional_services,
    languages,
    lgbtq_type,
    dietary_category,
    cuisine,
    restaurant_style,
    price_range,
    business_type,
  } = business;

  const EMPTY_CHOICE = "nothing";
  const servicesToIcons = {
    restaurant: '<i class="fa fa-utensils mr-1 text-muted"></i>',
    "lively bar": '<i class="fa fa-beer mr-1 text-muted"></i>',
  };

  const languageCount = languages.length;

  onMount(() => {
    // @ts-ignore
    jQuery("[data-toggle='tooltip']").tooltip();
  });
</script>

<div class="row">
  <div class="col-12">
    <div class="subtitle text-primary text-sm">In 30 words</div>

    <!-- DESCRIPTION -->
    {#if short_description}
      <div class="text-base font-weight-normal mb-2 text-wrap-newline">
        {short_description}
      </div>
    {:else}
      <div class="text-base font-weight-normal mb-2 text-wrap-newline font-italic">
        {name} has not added a description yet.
      </div>
    {/if}

    <!-- HASHTAGS -->
    {#if hashtags}
      <div class="font-weight-normal text-sm text-muted d-flex flex-wrap">
        {#each hashtags as hashtag}
          <div class="mr-1">#{hashtag}</div>
        {/each}
      </div>
    {/if}
  </div>

  <!-- META-DATA -->
  <div class="col-12 pt-3 text-sm">
    {#if opening_time}
      <div>
        <!-- Drop seconds part from opening, closing times -->
        Opening times: {opening_time.slice(0, 5)}
        {#if closing_time}- {closing_time.slice(0, 5)}{/if}
      </div>
    {/if}

    {#if activity_subtype}
      <div class="mt-1">
        <div class="badge badge-pill badge-transparent bg-gray-200 px-3 py-2 mr-1">
          <span>{ActivitySubtype[activity_subtype]}</span>
        </div>
      </div>
    {/if}

    <ul class="list-inline mt-1 mb-0 ml-1 text-sm">
      {#if number_of_rooms > 0}
        <li class="list-inline-item mr-3">
          <i class="fa fa-bed mr-1 text-muted" />
          {`${number_of_rooms} room${number_of_rooms > 1 ? "s" : ""}`}
        </li>
      {/if}
      {#if additional_services}
        {#each additional_services as service}
          {#if servicesToIcons[service]}
            <li class="list-inline-item mr-3">
              {@html servicesToIcons[service]}
              {capitalise(service)}
            </li>
          {:else}
            <li class="list-inline-item mr-3">{capitalise(service)}</li>
          {/if}
        {/each}
      {/if}
    </ul>

    <!-- hide languages for eat-drink type businesses -->
    {#if languages && ![BusinessType.bar, BusinessType.cafe, BusinessType.restaurant].includes(BusinessType[business_type])}
      <ul class="list-inline mt-2 mb-0 ml-1">
        <li class="list-inline-item mr-3">
          <i class="fa fa-language mr-1 text-muted" />
          {#each languages as language, i}
            {capitalise(language)}
            {#if i + 1 < languageCount},&nbsp;{/if}
          {/each}
        </li>
      </ul>
    {/if}

    <div class="mt-2">
      {#if price_range}
        <div
          class="badge badge-pill badge-transparent bg-gray-200 px-3 py-2 mb-2
          mr-1"
          data-toggle="tooltip"
          data-placement="top"
          data-html="true"
          data-original-title="<em>Price for 2 people/night:</em><br>$ = USD
          20<br>$$ = USD 50<br>$$$ = USD 100<br>$$$$ = USD 200<br>$$$$$ = USD
          500">
          <span style="border-bottom:1px dotted">
            {PriceRangeType[price_range]}
          </span>
        </div>
      {/if}

      <!-- hide cuisine type for all stay/do businesses -->
      {#if cuisine && [BusinessType.bar, BusinessType.cafe, BusinessType.restaurant].includes(BusinessType[business_type])}
        <div
          class="badge badge-pill badge-tranpsarent bg-gray-200 px-3 py-2 mb-2
          mr-1 caps">
          {cuisine}
        </div>
      {/if}

      {#if restaurant_style}
        <div
          class="badge badge-pill badge-tranpsarent bg-gray-200 px-3 py-2 mb-2
          mr-1 caps">
          {restaurant_style}
        </div>
      {/if}

      {#if dietary_category && dietary_category != EMPTY_CHOICE}
        <div
          class="badge badge-pill badge-tranpsarent bg-gray-200 px-3 py-2 mb-2
          mr-1 caps">
          {DietaryType[dietary_category]}
        </div>
      {/if}

      {#if lgbtq_type && lgbtq_type != EMPTY_CHOICE}
        <div
          class="badge badge-pill badge-tranpsarent bg-gray-200 px-3 py-2 mb-2
          mr-1 caps">
          {LgbtqType[lgbtq_type]}
        </div>
      {/if}
    </div>
  </div>
</div>
