export const COUNTRIES = {
  AO: "Angola",
  BF: "Burkina Faso",
  BI: "Burundi",
  BJ: "Benin",
  BW: "Botswana",
  CD: "Democratic Republic of Congo",
  CF: "Central African Republic",
  CG: "Congo",
  CI: "Ivory Coast",
  CM: "Cameroon",
  CV: "Cape Verde",
  DJ: "Djibouti",
  DZ: "Algeria",
  EG: "Egypt",
  ER: "Eritrea",
  ET: "Ethiopia",
  GA: "Gabon",
  GH: "Ghana",
  GM: "The Gambia",
  GN: "Guinea",
  GQ: "Equatorial Guinea",
  GW: "Guinea-Bissau",
  KE: "Kenya",
  KM: "Comoros",
  LR: "Liberia",
  LS: "Lesotho",
  LY: "Libya",
  MA: "Morocco",
  MG: "Madagascar",
  ML: "Mali",
  MR: "Mauritania",
  MU: "Mauritius",
  MW: "Malawi",
  MZ: "Mozambique",
  NA: "Namibia",
  NE: "Niger",
  NG: "Nigeria",
  RE: "Réunion Island",
  RW: "Rwanda",
  SC: "Seychelles",
  SD: "Sudan",
  SL: "Sierra Leone",
  SN: "Senegal",
  SO: "Somalia",
  SS: "South Sudan",
  ST: "Sao Tome and Principe",
  SZ: "Eswatini",
  TD: "Chad",
  TG: "Togo",
  TN: "Tunisia",
  TZ: "Tanzania",
  UG: "Uganda",
  ZA: "South Africa",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  GF: "French Guiana",
  YT: "Mayotte",
  SH: "Saint Helena",
  EH: "Western Sahara",
  AF: "Afghanistan",
  AM: "Armenia",
  AZ: "Azerbaijan",
  BD: "Bangladesh",
  BN: "Brunei",
  BT: "Bhutan",
  CN: "China",
  GE: "Georgia",
  HK: "Hong Kong",
  ID: "Indonesia",
  IN: "India",
  JP: "Japan",
  KG: "Kyrgyzstan",
  KH: "Cambodia",
  KP: "North Korea",
  KR: "South Korea",
  KZ: "Kazakhstan",
  LA: "Laos",
  LK: "Sri Lanka",
  MM: "Myanmar",
  MN: "Mongolia",
  MV: "Maldives",
  MY: "Malaysia",
  NP: "Nepal",
  PH: "Philippines",
  PK: "Pakistan",
  SG: "Singapore",
  TB: "Tibet",
  TH: "Thailand",
  TJ: "Tajikistan",
  TL: "East Timor",
  TM: "Turkmenistan",
  TW: "Taiwan",
  UZ: "Uzbekistan",
  VN: "Vietnam",
  IO: "British Indian Ocean Territory",
  MO: "Macao",
  AD: "Andorra",
  AL: "Albania",
  AT: "Austria",
  BA: "Bosnia and Herzegovina",
  BE: "Belgium",
  BG: "Bulgaria",
  BY: "Belarus",
  CH: "Switzerland",
  CY: "Cyprus",
  CZ: "Czechia",
  DE: "Germany",
  DK: "Denmark",
  EE: "Estonia",
  ES: "Spain",
  FI: "Finland",
  FR: "France",
  GB: "United Kingdom",
  GR: "Greece",
  HR: "Croatia",
  HU: "Hungary",
  IE: "Ireland",
  IS: "Iceland",
  IT: "Italy",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  LV: "Latvia",
  MC: "Monaco",
  MD: "Moldova",
  ME: "Montenegro",
  MK: "North Macedonia",
  MT: "Malta",
  NL: "Netherlands",
  NO: "Norway",
  PL: "Poland",
  PT: "Portugal",
  RO: "Romania",
  RS: "Serbia",
  RU: "Russia",
  SE: "Sweden",
  SI: "Slovenia",
  SK: "Slovakia",
  SM: "San Marino",
  TR: "Turkey",
  UA: "Ukraine",
  VA: "Vatican City",
  XK: "Kosovo",
  AX: "Åland Islands",
  FO: "Faroe Islands",
  GI: "Gibraltar",
  GG: "Guernsey",
  IM: "Isle of Man",
  JE: "Jersey",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  BB: "Barbados",
  BO: "Bolivia",
  BR: "Brazil",
  BS: "The Bahamas",
  BZ: "Belize",
  CL: "Chile",
  CO: "Colombia",
  CR: "Costa Rica",
  CU: "Cuba",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  GD: "Grenada",
  GT: "Guatemala",
  GY: "Guyana",
  HN: "Honduras",
  HT: "Haiti",
  JM: "Jamaica",
  KN: "St Kitts and Nevis",
  LC: "St Lucia",
  NI: "Nicaragua",
  PA: "Panama",
  PE: "Peru",
  PY: "Paraguay",
  SR: "Suriname",
  SV: "El Salvador",
  TT: "Trinidad and Tobago",
  UY: "Uruguay",
  VC: "St Vincent & the Grenadines",
  VE: "Venezuela",
  FK: "Falkland Islands (Islas Malvinas)",
  AE: "United Arab Emirates",
  BH: "Bahrain",
  IL: "Israel",
  IQ: "Iraq",
  IR: "Iran",
  JO: "Jordan",
  KW: "Kuwait",
  LB: "Lebanon",
  OM: "Oman",
  PS: "Palestine",
  QA: "Qatar",
  SA: "Saudi Arabia",
  SY: "Syria",
  YE: "Yemen",
  CA: "Canada",
  US: "United States",
  MX: "Mexico",
  AI: "Anguilla",
  AW: "Aruba",
  BM: "Bermuda",
  BQ: "Bonaire, Sint Eustatius and Saba",
  KY: "Cayman Islands",
  CW: "Curaçao",
  GL: "Greenland",
  GP: "Guadeloupe",
  MQ: "Martinique",
  TC: "Turks and Caicos Islands",
  VG: "British Virgin Islands",
  VI: "U.S. Virgin Islands",
  MF: "Saint Martin",
  PM: "Saint Pierre and Miquelon",
  SX: "Sint Maarten",
  PR: "Puerto Rico",
  BL: "Saint Barthélemy",
  MS: "Montserrat",
  AU: "Australia",
  FJ: "Fiji",
  FM: "Micronesia",
  KI: "Kiribati",
  MH: "Marshall Islands",
  NR: "Nauru",
  NZ: "New Zealand",
  PG: "Papua New Guinea",
  PW: "Palau",
  SB: "Solomon Islands",
  TO: "Tonga",
  TV: "Tuvalu",
  VU: "Vanuatu",
  WS: "Samoa",
  AS: "American Samoa",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  CK: "Cook Islands",
  PF: "French Polynesia",
  GU: "Guam",
  NC: "New Caledonia",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  PN: "Pitcairn Islands",
  TK: "Tokelau",
  WF: "Wallis and Futuna",
};

export const COUNTRY_CODES_WITHOUT_IMAGES = [
  "AS",
  "AQ",
  "BV",
  "IO",
  "CX",
  "CC",
  "GF",
  "TF",
  "GU",
  "HM",
  "MO",
  "NU",
  "NF",
  "PN",
  "SH",
  "PM",
  "GS",
  "SJ",
  "TK",
  "UM",
  "WF",
  "EH",
];

export const REGIONS = {
  AF: [
    { code: "AO", name: "Angola" },
    { code: "BF", name: "Burkina Faso" },
    { code: "BI", name: "Burundi" },
    { code: "BJ", name: "Benin" },
    { code: "BW", name: "Botswana" },
    { code: "CD", name: "Democratic Republic of Congo" },
    { code: "CF", name: "Central African Republic" },
    { code: "CG", name: "Congo" },
    { code: "CI", name: "Ivory Coast" },
    { code: "CM", name: "Cameroon" },
    { code: "CV", name: "Cape Verde" },
    { code: "DJ", name: "Djibouti" },
    { code: "DZ", name: "Algeria" },
    { code: "EG", name: "Egypt" },
    { code: "ER", name: "Eritrea" },
    { code: "ET", name: "Ethiopia" },
    { code: "GA", name: "Gabon" },
    { code: "GH", name: "Ghana" },
    { code: "GM", name: "The Gambia" },
    { code: "GN", name: "Guinea" },
    { code: "GQ", name: "Equatorial Guinea" },
    { code: "GW", name: "Guinea-Bissau" },
    { code: "KE", name: "Kenya" },
    { code: "KM", name: "Comoros" },
    { code: "LR", name: "Liberia" },
    { code: "LS", name: "Lesotho" },
    { code: "LY", name: "Libya" },
    { code: "MA", name: "Morocco" },
    { code: "MG", name: "Madagascar" },
    { code: "ML", name: "Mali" },
    { code: "MR", name: "Mauritania" },
    { code: "MU", name: "Mauritius" },
    { code: "MW", name: "Malawi" },
    { code: "MZ", name: "Mozambique" },
    { code: "NA", name: "Namibia" },
    { code: "NE", name: "Niger" },
    { code: "NG", name: "Nigeria" },
    { code: "RE", name: "Réunion Island" },
    { code: "RW", name: "Rwanda" },
    { code: "SC", name: "Seychelles" },
    { code: "SD", name: "Sudan" },
    { code: "SL", name: "Sierra Leone" },
    { code: "SN", name: "Senegal" },
    { code: "SO", name: "Somalia" },
    { code: "SS", name: "South Sudan" },
    { code: "ST", name: "Sao Tome and Principe" },
    { code: "SZ", name: "Eswatini" },
    { code: "TD", name: "Chad" },
    { code: "TG", name: "Togo" },
    { code: "TN", name: "Tunisia" },
    { code: "TZ", name: "Tanzania" },
    { code: "UG", name: "Uganda" },
    { code: "ZA", name: "South Africa" },
    { code: "ZM", name: "Zambia" },
    { code: "ZW", name: "Zimbabwe" },
    { code: "YT", name: "Mayotte" },
  ],
  AS: [
    { code: "AF", name: "Afghanistan" },
    { code: "AM", name: "Armenia" },
    { code: "AZ", name: "Azerbaijan" },
    { code: "BD", name: "Bangladesh" },
    { code: "BN", name: "Brunei" },
    { code: "BT", name: "Bhutan" },
    { code: "CN", name: "China" },
    { code: "GE", name: "Georgia" },
    { code: "HK", name: "Hong Kong" },
    { code: "ID", name: "Indonesia" },
    { code: "IN", name: "India" },
    { code: "JP", name: "Japan" },
    { code: "KG", name: "Kyrgyzstan" },
    { code: "KH", name: "Cambodia" },
    { code: "KP", name: "North Korea" },
    { code: "KR", name: "South Korea" },
    { code: "KZ", name: "Kazakhstan" },
    { code: "LA", name: "Laos" },
    { code: "LK", name: "Sri Lanka" },
    { code: "MM", name: "Myanmar" },
    { code: "MN", name: "Mongolia" },
    { code: "MV", name: "Maldives" },
    { code: "MY", name: "Malaysia" },
    { code: "NP", name: "Nepal" },
    { code: "PH", name: "Philippines" },
    { code: "PK", name: "Pakistan" },
    { code: "SG", name: "Singapore" },
    { code: "TB", name: "Tibet" },
    { code: "TH", name: "Thailand" },
    { code: "TJ", name: "Tajikistan" },
    { code: "TL", name: "East Timor" },
    { code: "TM", name: "Turkmenistan" },
    { code: "TW", name: "Taiwan" },
    { code: "UZ", name: "Uzbekistan" },
    { code: "VN", name: "Vietnam" },
  ],
  EU: [
    { code: "AD", name: "Andorra" },
    { code: "AL", name: "Albania" },
    { code: "AT", name: "Austria" },
    { code: "BA", name: "Bosnia and Herzegovina" },
    { code: "BE", name: "Belgium" },
    { code: "BG", name: "Bulgaria" },
    { code: "BY", name: "Belarus" },
    { code: "CH", name: "Switzerland" },
    { code: "CY", name: "Cyprus" },
    { code: "CZ", name: "Czechia" },
    { code: "DE", name: "Germany" },
    { code: "DK", name: "Denmark" },
    { code: "EE", name: "Estonia" },
    { code: "ES", name: "Spain" },
    { code: "FI", name: "Finland" },
    { code: "FR", name: "France" },
    { code: "GB", name: "United Kingdom" },
    { code: "GR", name: "Greece" },
    { code: "HR", name: "Croatia" },
    { code: "HU", name: "Hungary" },
    { code: "IE", name: "Ireland" },
    { code: "IS", name: "Iceland" },
    { code: "IT", name: "Italy" },
    { code: "LI", name: "Liechtenstein" },
    { code: "LT", name: "Lithuania" },
    { code: "LU", name: "Luxembourg" },
    { code: "LV", name: "Latvia" },
    { code: "MC", name: "Monaco" },
    { code: "MD", name: "Moldova" },
    { code: "ME", name: "Montenegro" },
    { code: "MK", name: "North Macedonia" },
    { code: "MT", name: "Malta" },
    { code: "NL", name: "Netherlands" },
    { code: "NO", name: "Norway" },
    { code: "PL", name: "Poland" },
    { code: "PT", name: "Portugal" },
    { code: "RO", name: "Romania" },
    { code: "RS", name: "Serbia" },
    { code: "RU", name: "Russia" },
    { code: "SE", name: "Sweden" },
    { code: "SI", name: "Slovenia" },
    { code: "SK", name: "Slovakia" },
    { code: "SM", name: "San Marino" },
    { code: "TR", name: "Turkey" },
    { code: "UA", name: "Ukraine" },
    { code: "VA", name: "Vatican City" },
    { code: "XK", name: "Kosovo" },
    { code: "AX", name: "Åland Islands" },
    { code: "FO", name: "Faroe Islands" },
    { code: "GI", name: "Gibraltar" },
    { code: "GG", name: "Guernsey" },
    { code: "IM", name: "Isle of Man" },
    { code: "JE", name: "Jersey" },
  ],
  LA: [
    { code: "AG", name: "Antigua and Barbuda" },
    { code: "AI", name: "Anguilla" },
    { code: "AW", name: "Aruba" },
    { code: "AR", name: "Argentina" },
    { code: "BB", name: "Barbados" },
    { code: "BQ", name: "Bonaire, Sint Eustatius and Saba" },
    { code: "KY", name: "Cayman Islands" },
    { code: "CW", name: "Curaçao" },
    { code: "BO", name: "Bolivia" },
    { code: "BR", name: "Brazil" },
    { code: "BS", name: "The Bahamas" },
    { code: "BZ", name: "Belize" },
    { code: "CL", name: "Chile" },
    { code: "CO", name: "Colombia" },
    { code: "CR", name: "Costa Rica" },
    { code: "CU", name: "Cuba" },
    { code: "DM", name: "Dominica" },
    { code: "DO", name: "Dominican Republic" },
    { code: "EC", name: "Ecuador" },
    { code: "FK", name: "Falkland Islands (Islas Malvinas)" },
    { code: "GD", name: "Grenada" },
    { code: "GT", name: "Guatemala" },
    { code: "GY", name: "Guyana" },
    { code: "HN", name: "Honduras" },
    { code: "HT", name: "Haiti" },
    { code: "JM", name: "Jamaica" },
    { code: "KN", name: "St Kitts and Nevis" },
    { code: "LC", name: "St Lucia" },
    { code: "NI", name: "Nicaragua" },
    { code: "MX", name: "Mexico" },
    { code: "PA", name: "Panama" },
    { code: "PE", name: "Peru" },
    { code: "PY", name: "Paraguay" },
    { code: "SR", name: "Suriname" },
    { code: "SV", name: "El Salvador" },
    { code: "TT", name: "Trinidad and Tobago" },
    { code: "UY", name: "Uruguay" },
    { code: "VC", name: "St Vincent & the Grenadines" },
    { code: "VE", name: "Venezuela" },
    { code: "GP", name: "Guadeloupe" },
    { code: "MQ", name: "Martinique" },
    { code: "TC", name: "Turks and Caicos Islands" },
    { code: "VG", name: "British Virgin Islands" },
    { code: "VI", name: "U.S. Virgin Islands" },
    { code: "MF", name: "Saint Martin" },
    { code: "SX", name: "Sint Maarten" },
    { code: "PR", name: "Puerto Rico" },
    { code: "BL", name: "Saint Barthélemy" },
    { code: "MS", name: "Montserrat" },
  ],
  ME: [
    { code: "AE", name: "United Arab Emirates" },
    { code: "BH", name: "Bahrain" },
    { code: "IL", name: "Israel" },
    { code: "IQ", name: "Iraq" },
    { code: "IR", name: "Iran" },
    { code: "JO", name: "Jordan" },
    { code: "KW", name: "Kuwait" },
    { code: "LB", name: "Lebanon" },
    { code: "OM", name: "Oman" },
    { code: "PS", name: "Palestine" },
    { code: "QA", name: "Qatar" },
    { code: "SA", name: "Saudi Arabia" },
    { code: "SY", name: "Syria" },
    { code: "YE", name: "Yemen" },
  ],
  NA: [
    { code: "CA", name: "Canada" },
    { code: "US", name: "United States" },
    { code: "BM", name: "Bermuda" },
    { code: "GL", name: "Greenland" },
  ],
  OC: [
    { code: "AU", name: "Australia" },
    { code: "FJ", name: "Fiji" },
    { code: "FM", name: "Micronesia" },
    { code: "KI", name: "Kiribati" },
    { code: "MH", name: "Marshall Islands" },
    { code: "NR", name: "Nauru" },
    { code: "NZ", name: "New Zealand" },
    { code: "PG", name: "Papua New Guinea" },
    { code: "PW", name: "Palau" },
    { code: "SB", name: "Solomon Islands" },
    { code: "TO", name: "Tonga" },
    { code: "TV", name: "Tuvalu" },
    { code: "VU", name: "Vanuatu" },
    { code: "WS", name: "Samoa" },
    { code: "CK", name: "Cook Islands" },
    { code: "PF", name: "French Polynesia" },
    { code: "NC", name: "New Caledonia" },
    { code: "MP", name: "Northern Mariana Islands" },
  ],
};
