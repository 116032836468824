<script context="module" lang="ts">
  // svelte-ignore unused-export-let
  export enum Size {
    REGULAR = "Regular",
    LARGE = "Large",
  }
</script>

<script lang="ts">
  import { TravellerCoreSerialiser } from "/apiFactory";
  import { getTravellerInitials } from "/traveller/utils";

  export let traveller: TravellerCoreSerialiser;
  export let size: Size = Size.REGULAR;

  /**
   * Displays a  traveller selfie.
   *
   * Each traveller must have the following attributes:
   *  * selfie_avatar_link
   *
   * An avatar size can optionally be specified. Size
   * must be a member of the Size enum.
   *
   * @component Avatar
   * @example
   * <Avatar traveller={traveller} />
   */

  const initials = getTravellerInitials(traveller);
</script>

{#if traveller.selfie_avatar_link}
  <img
    alt="Traveller avatar"
    data-blink-uuid={traveller.selfie_avatar_uuid}
    data-blink-scale-mode="scale-crop"
    data-blink-quality="smart"
    class="avatar avatar-traveller bg-light"
    class:avatar-lg={size === Size.LARGE} />
{:else}
  <div
    class="avatar avatar-lg avatar-anonymous bg-light d-flex align-items-center justify-content-center shrink text-uppercase text-white h2"
    class:avatar-lg={size === Size.LARGE}>
    {initials}
  </div>
{/if}
