<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import generateCoverImage from "../../shared/generateCoverImage.js";
  import { BusinessSerialiser as Business } from "/apiFactory/api";
  import BusinessTypeBadge from "./BusinessTypeBadge.svelte";

  export let business: Business;
  export let wishlist_uuid;

  const dispatch = createEventDispatcher();

  let height;
</script>

<div
  id="featuredBusinessCard"
  class="card rounded-lg w-100 border-0 text-white gradient-overlay hover-animate
  embed-responsive embed-responsive-1by1"
  role="button"
  bind:clientHeight={height}
  on:click={() =>
    dispatch("click", { business: business, wishlist_uuid: wishlist_uuid })}>
  {#if business.cover_image_link}
    <img
      data-blink-uuid={business.cover_image_uuid}
      data-blink-quality="better"
      class="card-img embed-responsive-item"
      style="object-fit: cover"
      alt={business.name} />
  {:else}
    <div class="embed-responsive-item">
      <!-- dynamically generate a perfectly sized square svg (only need height) -->
      {@html generateCoverImage(
        business.country.code,
        business.business_code,
        height,
        height
      )}
    </div>
  {/if}

  <div class="card-img-overlay d-flex align-items-end p-1 flex-column">
    <div class="w-100 overlay-content text-right mb-auto">
      <div class="d-flex flex-row-reverse justify-content-between">
        <div class="m-2">
          <BusinessTypeBadge {business} />
        </div>
        <!-- TODO enable this when we can check that the current user is a team member
            (i.e. entitled to the staff_discount) -->
        <!-- {#if business.staff_discount}
        <span
          class="badge bg-orange px-2 py-2 mx-2 my-2 rounded-lg text-sm text-dark">
          {business.staff_discount}% off
        </span>
        {/if} -->
      </div>
    </div>

    <div class="w-100 overlay-content text-left">
      <div
        class="mx-2 mb-2 mt-1 align-bottom d-flex flex-column
        justify-content-between">
        <div class="text-small text-white text-truncate">{business.name}</div>
        <div class="d-flex flex-row justify-content-between">
          <div class="m-0 font-weight-light text-sm">
            {business.area?.name || business.city}, {business.country.name}
          </div>
          {#if wishlist_uuid}
            <div class="align-self-end text-right">
              <i class="fa fa-bookmark fa-lg" />
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>
