<script lang="ts">
  import ResultSet from "../grayhound/ResultSet.svelte";
  import ResultItem from "../grayhound/ResultItem.svelte";
  import ResultList from "../grayhound/ResultList.svelte";
  import { Hound, relevancy } from "../grayhound/grayhound";
  import { ActivitySubtype } from "/shared/enums";
  import { COUNTRIES } from "/shared/country";
  import { activityCountryView, activityView } from "/guidebook/shared/urls";
  export let query: string;

  let search: Hound = {
    name: "Activities",
    search: false,
    local: (words) => {
      let primary = "activity";

      let indexes = {
        activity: Object.entries(ActivitySubtype),
        country: Object.entries(COUNTRIES),
      };
      return relevancy(words, indexes, primary);
    },
  };
</script>

<style>
  .text-reset {
    text-decoration: none;
  }
</style>

<ResultSet hound={search} {query} let:results on:complete>
  <ResultList {results} let:items>
    {#each items as result (result.key)}
      {#if result.country}
        <ResultItem>
          <a
            class="text-reset"
            href={activityCountryView(result.activity.key, result.country.key)}>
            <div>{result.activity.value} in {result.country.value}</div>
          </a>
        </ResultItem>
      {:else}
        <ResultItem>
          <a class="text-reset" href={activityView(result.activity.key)}>
            <div>{result.activity.value}</div>
          </a>
        </ResultItem>
      {/if}
    {/each}
  </ResultList>
</ResultSet>
