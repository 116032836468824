import { NetworkError, HTTPError } from "../shared/errors.js";

const HTTP_204_NO_CONTENT = 204;

/*
  Returns a promise containing the json data for a response,
  or an error payload containing the error type, response, and any json errors (param).
*/
export function getJsonOrThrow(response) {
  if (response.status == HTTP_204_NO_CONTENT && response.ok) {
    return Promise.resolve();
  }
  let json = response.json();
  if (response.ok) {
    return json;
  } else {
    throw json.then((data) => Promise.reject(new HTTPError(response, data)));
  }
}

export let fetchError = (error) => Promise.reject(new NetworkError(error));
