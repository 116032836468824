<script lang="ts">
  import DirectButton from "/business/enquiry/DirectButton.svelte";

  export let business;
  export let recommenders;
</script>

<u>
  <DirectButton
    {business}
    {recommenders}
    btnClasses={"text-muted"}
    content={'Save discount voucher to use later <i class="fa fa-sm fa-arrow-right" />'} />
</u>
