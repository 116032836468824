const baseUrl = "/traveller";

export const ACTIVITY_IMAGES_BASE_URL =
  "https://directory-activity-images.s3.amazonaws.com/";

export const getActivityImageURL = (slug) => {
  return `${ACTIVITY_IMAGES_BASE_URL}${slug}.jpeg`;
};

const ALLOWED_HOSTS = ["theguide", "preview", "listing"];

export const makeAbsoluteURL = (host, route) => {
  if (!ALLOWED_HOSTS.includes(host)) return route;

  // defined globally by base template
  if (window.DEBUG) {
    return `http://${host}.tab.local${route}`;
  }
  return `https://${host}.tab.travel${route}`;
};

export const countryView = (travellerCode, countryCode) =>
  `${baseUrl}/${travellerCode}/${countryCode}/`;
export const regionView = (regionCode) => `${baseUrl}/add-countries/${regionCode}`;

export const networkCountryView = (countryCode) => `${baseUrl}/network/${countryCode}/`;

export const theirGuideView = (travellerCode) => `${baseUrl}/my-guide/${travellerCode}/`;
export const theirCountryView = (travellerCode, countryCode) =>
  `${theirGuideView(travellerCode)}${countryCode}`;

// curried version of theirCountryView to allow partial calling
export const theirCountryViewCurry = (travellerCode) => (countryCode) =>
  theirCountryView(travellerCode, countryCode);
// curried version of countryView to allow partial calling
export const countryViewCurry = (travellerCode) => (countryCode) =>
  countryView(travellerCode, countryCode);

export const signupView = () => `${baseUrl}/signup/`;
export const signupReferralView = () => `${baseUrl}/invite/`;
export const onboardingView = () => `${baseUrl}/add-countries/`;
export const businessReferralView = (businessCode) =>
  `${baseUrl}/recommend/${businessCode.toLowerCase()}`;

export const editWishlistView = () => `${baseUrl}/wishlist/update/`;
export const wishlistView = () => `${baseUrl}/wishlist/`;

export const vouchersView = () => `${baseUrl}/vouchers/`;

export const couponView = (couponCode) => `${baseUrl}/coupon/${couponCode}/`;

export const hashtagsView = () => `${baseUrl}/follow-hashtags/update/`;
export const suggestedHashtagsView = () => `${baseUrl}/follow-hashtags/`;
export const wishlistCountryView = () => `${baseUrl}/wishlist-country/`;

export const travellerTermsView = () => `/traveller-terms/`;
export const privacyPolicyView = () => `/privacy-policy/`;
export const accountView = () => `${baseUrl}/update/`;

export const regionOnboardingView = () => `${baseUrl}/add-countries/`;

export const addRecommendationsView = () => `${baseUrl}/add-recommendations/`;
export const addFirstRecommendationView = () =>
  `${baseUrl}/add-your-first-recommendation/`;
export const signupRecommendationView = (businessCode) =>
  `${baseUrl}/recommend/${businessCode}`;

export const dashboardView = () => `${baseUrl}/`;
export const recommendationsView = (travellerCode) => `${baseUrl}/${travellerCode}/`;
export const loginView = () => `${baseUrl}/login/`;
export const logoutView = () => {
  return `/accounts/logout/`;
};
export const discoverView = () => `${baseUrl}/for-you/`;
export const getStartedChecklist = () => `${baseUrl}/get-started/checklist/`;

export const followingView = () => `${baseUrl}/following/`;
export const followersView = () => `${baseUrl}/following/#followers`;
