<script>
  import "daterangepicker";
  import "../../css/datepicker.css";
  import { onMount, createEventDispatcher } from "svelte";
  import { notification } from "../shared/utils.js";
  import { bookItem } from "./api.js";
  import { currentItemType, notifyBookingSuccess } from "./stores.js";
  import HCaptcha from "/shared/HCaptcha.svelte";

  export let id;
  export let title;
  export let businessCode;

  const dispatch = createEventDispatcher();
  let start_date;
  let end_date;
  let hcaptchaToken;
  let captcha;

  /*
   * Event handlers
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    // don't submit if captcha not good
    if (!hcaptchaToken) {
      notification.error("Please fill out captcha before submitting!");
      return;
    }

    // construct form and append to_date, from_date keys
    var form = new FormData(document.getElementById("bookingForm"));

    if (start_date) form.append("to_date", start_date);
    if (end_date) form.append("from_date", end_date);
    form.append("token", hcaptchaToken);

    // call the API
    try {
      await bookItem($currentItemType, businessCode, form);
    } catch (error) {
      // handle potential captcha errors
      if (error.response?.data?.token) {
        captcha.reset();
        notification.error("Captcha verification failed. Please try again.");
        return;
      }
      // otherwise, warn the user and exit
      notification.reportError(error);
      return;
    }

    // tell store we are done
    notifyBookingSuccess();

    // notify the end user
    notification.success("Thank you. Your booking enquiry has been sent.");

    // close the modal that contains this form
    dispatch("closeForm");
  };

  onMount(() => {
    // attach a daterangepicker to the 'dates' form field,
    // component must be mounted on the DOM first.
    var dates = jQuery("input[name='daterange']");
    dates.daterangepicker({
      autoUpdateInput: true,
      locale: {
        cancelLabel: "Clear", // dates are optional
      },
    });

    // clear default values
    dates.val("");
    dates.on("apply.daterangepicker", function (ev, picker) {
      start_date = picker.startDate.format("YYYY-MM-DD");
      end_date = picker.endDate.format("YYYY-MM-DD");
    });

    // clear values on 'cancel'
    dates.on("cancel.daterangepicker", function (ev, picker) {
      dates.val("");
      start_date = null;
      end_date = null;
    });

    // Scroll form into view
    let form = document.getElementById("bookingForm");
    if (form) {
      form.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  });

  /* hCaptcha controls */
  const handleHCaptchaSuccess = (ev) => {
    hcaptchaToken = ev.detail.token;
  };
</script>

<style>
  #button-group {
    text-align: right;
    padding-bottom: 1rem;
  }
</style>

<form on:submit={handleSubmit} id="bookingForm">
  <input type="hidden" name="item_type" value={$currentItemType} />
  <!-- we pass the businessCode here, which is used to do a SlugRelatedField lookup
    on the backend. -->
  <input type="hidden" name="business_profile" value={businessCode} />
  <input type="hidden" name="bookable_name" value={title} />
  <input
    type="hidden"
    name={$currentItemType == "rooms" ? "room" : "activity"}
    value={id} />
  <div class="form-group">
    <label for="guest_name" class="form-label mb-0">Name*</label>
    <input name="guest_name" class="form-control" required />
  </div>
  <div class="form-group">
    <label for="guest_email" class="form-label mb-0">Email*</label>
    <input name="guest_email" type="email" class="form-control" required />
  </div>
  <div id="dates" class="form-group">
    <label for="dates" class="form-label">Dates of Your Trip</label>
    <div>
      <input type="text" class="form-control" name="daterange" />
    </div>
  </div>
  <div class="form-group">
    <label for="guest_message" class="form-label mb-0">Message</label>
    <textarea
      class="form-control"
      id="recommendation-text"
      aria-describedby="bookingMessage"
      rows="2"
      name="guest_message"
      placeholder="Enter a message..." />
  </div>

  <div class="text-center mb-2">
    <HCaptcha bind:this={captcha} on:success={handleHCaptchaSuccess} />
  </div>

  <div id="button-group">
    <button
      type="button"
      class="btn btn-outline-muted"
      on:click={() => dispatch("closeForm")}>
      Cancel
    </button>
    <button type="submit" class="btn btn-primary px-5">Send</button>
  </div>
</form>
