<script lang="ts">
  /**
   * Renders a Pure Swiper based on a list of items and a template component.
   *
   * Each item is bound to a component and placed into a SwiperSlide as part of a Swiper.
   *
   * Defaults include navigation + with no pagination.
   *
   * @component PureSwiper
   * @example
   * <PureSwiper component={BusinessCard} items={businesses} />
   */
  import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
  import { Swiper, SwiperSlide } from "swiper/svelte";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let items = [];
  export let component;
  export let showNavigationDesktop = true;
  export let swiperProps = {
    navigation: {
      nextEl: ".button-next",
      prevEl: ".button-prev",
    },
    pagination: false,
    slidesPerView: 1.3,
    loop: false, // swiper generates DOM-only dupes to loop, don't do this
    spaceBetween: 20,
    breakpoints: {
      576: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 3,
      },
      960: {
        slidesPerView: 4,
      },
    },
  };
  // install Swiper components
  SwiperCore.use([Navigation, Pagination, A11y]);

  // store swiper instance for triggering updates
  let swiper = null;

  const getSwiper = (e) => {
    const [_swiper] = e.detail;
    // set the swiper instance
    setTimeout(() => {
      swiper = _swiper;
    });
  };

  // react to changes to items and swiper, data may be async
  $: {
    items;
    // swiper may not have initialised yet
    if (swiper) {
      setTimeout(() => {
        swiper.update();
      });
    }
  }
</script>

<style global lang="scss">
  // Import Swiper styles
  @import "swiper/swiper.scss";
  @import "swiper/components/navigation/navigation.scss";
  @import "swiper/components/pagination/pagination.scss";

  .pure-swiper-container {
    // pull content above down inline with arrows
    margin-top: -2.25rem;
    position: relative;
    z-index: 0;
  }

  .button-next::after {
    // remove default icon
    display: none;
  }
  .button-prev::after {
    // remove default icon
    display: none;
  }

  .text-dark {
    color: #929292;
  }

  .pure-swiper-button-disabled {
    // hide when no more to scroll
    opacity: 0 !important;
  }
</style>

<Swiper containerModifierClass={"pure-swiper-"} {...swiperProps} on:swiper={getSwiper}>
  <!-- NAVIGATION -->
  <span slot="container-start">
    <!-- Hide buttons on mobile (redundant) and on desktop where not needed -->
    <div
      class:d-md-flex={items.length > 4 && showNavigationDesktop}
      class="ml-auto mb-2 d-none">
      <div class="button-prev ml-auto">
        <span class="px-3">
          <i class="fa fal-chevron-left text-dark" />
        </span>
      </div>
      <div class="button-next">
        <span class="pl-3 pr-1">
          <i class="fa fal-chevron-right text-dark" />
        </span>
      </div>
    </div>
    <!-- Keep button padding if hidden for title alignment -->
    <div class:d-md-flex={items.length <= 4} class="mb-4 d-none" />
  </span>

  <!-- SLIDES -->
  {#each items as item}
    <SwiperSlide>
      <div class="my-2">
        <svelte:component
          this={component}
          {...item}
          on:click={() => {
            dispatch("click", { ...item });
          }} />
      </div>
    </SwiperSlide>
  {/each}
</Swiper>
