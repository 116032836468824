<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { loginView as businessLoginView } from "/business/shared/urls";
  import { makeAbsoluteURL } from "/traveller/shared/urls";
  const dispatch = createEventDispatcher();

  let submitted = false;
  let email = "";

  function checkEmail() {
    submitted = true;
    dispatch("submit", email);
    setInterval(animateLoadingButton, 300);
  }

  const MAX_DOTS = 4;
  let loadingText = `.${"&nbsp;".repeat(MAX_DOTS)}`;
  let ticker = 0;

  async function animateLoadingButton() {
    const ticks = ticker++ % MAX_DOTS;
    loadingText = `.${".".repeat(ticks)}${"&nbsp;".repeat(MAX_DOTS - ticks)}`;
  }
</script>

<div class="h2 text-center font-weight-bold">Welcome to the Guide</div>
<div class="h5 text-center text-muted">Log in or Sign up</div>
<hr />

<form class="text-center mt-4" on:submit|preventDefault={checkEmail}>
  <input
    type="email"
    class="form-control my-4"
    id="emailInput"
    placeholder="Email"
    bind:value={email}
    required />

  <div class="mt-2">
    {#if submitted}
      <button class="w-100 btn btn-primary" disabled>
        {@html loadingText}
      </button>
    {:else}
      <button class="w-100 btn btn-primary" type="submit">Continue</button>
    {/if}
  </div>
</form>
<hr class="w-50" />
<div class="w-100 text-center">
  <a href={makeAbsoluteURL("listing", businessLoginView())} class="text-muted mx-auto">
    Are you a business?
    <br />
    Log in to your listing here
  </a>
</div>
