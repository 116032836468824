<script>
  /*
   * This component exists to wrap other components in a white card
   * "cap" element, of the style seen in business profiles.
   */
</script>

<div class="pb-2 px-md-4">
  <div class="container">
    <div class="row">
      <div class="col-10 offset-1 px-0">
        <div class="card border-0 shadow bg-white">
          <div class="card-body">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
