<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { Modal } from "sveltestrap";
  import Select from "/credit/components/CouponRecommenderSelect.svelte";

  export let recommenders;
  export let isOpen;
  export let toggle;

  const dispatch = createEventDispatcher();

  let selectedRecommender;
  // make transition snappier
  const transitionOptions = { duration: 150 };

  // inform parent of selectedRecommender
  // parent is responsible for toggling modal
  const handleClick = () => {
    dispatch("select", {
      selectedRecommender: selectedRecommender,
    });
  };
</script>

<Modal {isOpen} {toggle} {transitionOptions}>
  <div class="row">
    <div class="col-12">
      <div class="text-center mb-2 mt-3">I want the bonus credit to go to:</div>
      <div class="col-9 mx-auto">
        <Select bind:selected={selectedRecommender} {recommenders} />
      </div>
      <div class="text-center">
        <button class="btn btn-primary mb-3 mt-1" on:click={handleClick}>
          Next
          <i class="fa fa-arrow-right" />
        </button>
      </div>
    </div>
  </div>
</Modal>
