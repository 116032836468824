<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { errors, hasError } from "../stores";
  import ErrorMessage from "/shared/ErrorMessage.svelte";

  const dispatch = createEventDispatcher();
  let submitted = false;

  $: {
    if ($hasError) {
      submitted = false;
    }
  }

  let password = "";

  function login() {
    // clear errors
    $errors = {};

    submitted = true;
    animateLoadingButton();
    dispatch("submit", password);
  }

  const MAX_DOTS = 4;
  let loadingText = `.${"&nbsp;".repeat(MAX_DOTS)}`;
  let ticker = 0;

  async function animateLoadingButton() {
    if (!submitted) return;
    const ticks = ticker++ % MAX_DOTS;
    loadingText = `.${".".repeat(ticks)}${"&nbsp;".repeat(MAX_DOTS - ticks)}`;
    setTimeout(animateLoadingButton, 300);
  }
</script>

<div class="h2 text-center font-weight-bold">Welcome back</div>
<hr />

<div class="text-center mt-4">
  <form class="text-center" on:submit|preventDefault={login}>
    <input
      type="password"
      on:change={() => (submitted = false)}
      class:is-invalid={"passwordInput" in $errors}
      class="form-control"
      id="passwordInput"
      placeholder="Password"
      bind:value={password}
      required />
    <ErrorMessage name="passwordInput" />
    {#if submitted}
      <button class="w-100 btn btn-primary my-2" disabled>
        {@html loadingText}
      </button>
    {:else}
      <button class="w-100 btn btn-primary my-2" type="submit">Login</button>
    {/if}
  </form>
  <button class="btn text-muted" on:click={() => dispatch("requestReset")}>
    <u>Forget Password?</u>
  </button>
</div>
