import trianglify from "trianglify";
import colours from "./countryColours.js";

export default function generateCoverImage(
  countryCode,
  seed = null,
  width = 255,
  height = 255
) {
  // cannot generate images with 0 height or 0 width!
  if (height === 0 || width === 0) return "";

  const palette = colours.find((elem) => elem.code == countryCode).flag_colours;
  const image = trianglify({
    xColors: palette,
    yColors: "match",
    colorFunction: trianglify.colorFunctions.sparkle(0.07),
    cellSize: 24,
    variance: 0.33,
    seed: seed,
    width: width,
    height: height,
  }).toSVGTree();

  return image.toString();
}
