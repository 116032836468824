import { BUSINESS_TYPES } from "./types";
const baseUrl = "/explore";

export const homeView = () => `${baseUrl}/`;
export const activityView = (activityCode) => `${baseUrl}/activities/${activityCode}/`;
export const allActivitiesView = () => `${baseUrl}/activities/`;
export const activityCountryView = (activityCode, countryCode) =>
  `${baseUrl}/${countryCode}/activities/${activityCode}/`;

export const countryView = (countryCode) => `${baseUrl}/${countryCode}`;
export const allCountriesView = () => `${baseUrl}/countries/`;
export const areaView = (countryCode, areaSlug) =>
  `${baseUrl}/${countryCode}/${areaSlug}`;
export const areaListingStay = (countryCode, areaSlug) =>
  `${baseUrl}/${countryCode}/${areaSlug}/stay`;
export const areaListingActivities = (countryCode, areaSlug, activitySubtype) => {
  let params = new URLSearchParams({
    activity_subtype: activitySubtype ? activitySubtype : "",
  });
  return `${baseUrl}/${countryCode}/${areaSlug}/activities/?${params}`;
};
export const areaListingEatDrink = (countryCode, areaSlug) =>
  `${baseUrl}/${countryCode}/${areaSlug}/eat-drink`;

export const areaListingView = (countryCode, areaSlug, business_type) => {
  if (business_type == BUSINESS_TYPES.ACCOMMODATION) {
    return areaListingStay(countryCode, areaSlug);
  } else if (business_type == BUSINESS_TYPES.ACTIVITY) {
    return areaListingActivities(countryCode, areaSlug);
  } else {
    return areaListingEatDrink(countryCode, areaSlug);
  }
};
const ACTIVITIES_ANCHOR = "#popularActivitiesHeading";
const REGIONS_ANCHOR = "#regionsHeading";

// dynamic links based on where the user is, either scroll or change page
export const homeViewRegions = (location) =>
  location.endsWith(homeView()) ? REGIONS_ANCHOR : homeView() + REGIONS_ANCHOR;
export const homeViewActivities = (location) =>
  location.endsWith(homeView()) ? ACTIVITIES_ANCHOR : homeView() + ACTIVITIES_ANCHOR;
