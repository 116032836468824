<script lang="ts">
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();
  const MAX_DOTS = 4;

  let submitted = false;
  export let email: string = "";

  function requestReset() {
    submitted = true;
    dispatch("submit", email);
    setInterval(animateLoadingButton, 300);
  }

  let loadingText = `.${"&nbsp;".repeat(MAX_DOTS)}`;
  let ticker = 0;

  async function animateLoadingButton() {
    const ticks = ticker++ % MAX_DOTS;
    loadingText = `.${".".repeat(ticks)}${"&nbsp;".repeat(MAX_DOTS - ticks)}`;
  }
</script>

<div class="h2 text-center font-weight-bold">Forgotten your password?</div>
<hr />

<form class="text-center mt-4" on:submit|preventDefault={requestReset}>
  <div class="small mb-2">
    Enter the email address associated with your account, and we’ll email you a link to
    reset your password.
  </div>
  <input
    type="email"
    class="form-control"
    id="emailInput"
    placeholder="Email"
    bind:value={email} />
  {#if submitted}
    <button class="w-100 btn btn-primary my-2 px-5" disabled>
      {@html loadingText}
    </button>
  {:else}
    <button class="w-100 btn btn-primary my-2 px-5" type="submit">
      Send reset link
    </button>
  {/if}
</form>
