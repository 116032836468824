<script lang="ts">
  import api from "../api";
  import { currentTraveller } from "../stores";
  import { notification } from "/shared/utils";

  export let wishlistUuid = undefined;
  export let businessCode = null;

  const updateWishlist = async () => {
    let res;
    if (wishlistUuid) {
      try {
        res = await api.travellerApiWishlistBusinessDestroy(wishlistUuid);
      } catch (error) {
        notification.reportError(error);
        // bail early as uuid cannot be updated
        return;
      }
    } else {
      try {
        res = await api.travellerApiWishlistBusinessCreate({
          business: businessCode,
        });
        notification.success("Saved!");
      } catch (error) {
        notification.reportError(error);
        // bail early as uuid cannot be updated
        return;
      }
    }
    // update component props
    // if uuid was already set, blank it. Otherwise set it.
    wishlistUuid = wishlistUuid ? "" : res.data?.uuid;
  };
</script>

<style lang="css">
  .text-resizable {
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    .text-resizable {
      font-size: 0.875rem;
    }
  }
</style>

<!-- Only logged-in users can add businesses to wishlists -->
{#if $currentTraveller.code}
  <div on:click={updateWishlist}>
    {#if wishlistUuid}
      <div class="text-center ml-auto mt-2">
        <a
          id="addToWishlist"
          class="btn btn-outline px-2 text-resizable text-white"
          target="_blank"
          rel="noopener noreferrer">
          <i class="fas fa-bookmark mr-1 d-none d-md-inline" />
          Saved
        </a>
      </div>
    {:else}
      <div class="text-center ml-auto mt-2">
        <a
          id="addToWishlist"
          class="btn btn-outline-light px-2 text-resizable"
          target="_blank"
          rel="noopener noreferrer">
          <i class="far far-bookmark mr-1 d-none d-md-inline" />
          Add to Wishlist
        </a>
      </div>
    {/if}
  </div>
{/if}
