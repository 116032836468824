<script lang="ts">
  import Select from "svelte-select";
  import { COUNTRIES } from "/shared/country.js";
  import { createEventDispatcher } from "svelte";

  export let inputMaxWidth = 100; // %
  export let placeholder = "Type to search all countries";
  export let selectedCountry: string;
  export let hasError: boolean = false;

  // init selector with an optional preselected country
  let selectedValue = selectedCountry
    ? {
        value: selectedCountry,
        name: COUNTRIES[selectedCountry],
      }
    : undefined;

  // reactively update selectedCountry code when user selects
  $: selectedCountry = selectedValue?.value;

  // sort countries by name and map to selector label:value pairs
  let countries = Object.entries(COUNTRIES)
    .sort((a, b) => {
      if (a[1] > b[1]) {
        return 1;
      }
      if (a[1] < b[1]) {
        return -1;
      }
      return 0;
    })
    .map((country) => ({ value: country[0], label: country[1] }));

  const dispatch = createEventDispatcher();
</script>

<style>
  :global(input) {
    margin: 0;
  }
  /* 
    svelte-select sets style with CSS variables.

    Border and placeholder grey is the theme's gray-600.
    Background grey is the theme's gray-900.
  */
  .select {
    --borderFocusColor: #71d270;
    --borderRadius: 0.2rem;
    --background: var(--background-color);
    --inputColor: black;
    --itemColor: black;
    --itemIsActiveBG: #71d270;
    --itemIsActiveColor: black;
    --itemIsActiveBackground: #71d270;
    --itemHoverBG: #e9ecef;
    --clearSelectTop: 5px;
    --indicatorTop: 5px;
    --inputFontSize: 16px; /* Make this explicit to avoid zooming on iOS devices */
    color: var(--color);
  }

  @media only screen and (min-width: 769px) {
    .select {
      max-width: var(--input-max-width);
    }
  }
</style>

<div
  class="select"
  style="--input-max-width: {inputMaxWidth}%; --background-color: white;
  --color: #212529">
  <Select
    items={countries}
    {placeholder}
    showChevron={true}
    bind:value={selectedValue}
    on:select={(ev) => dispatch("select", ev.detail.value)}
    {hasError} />
</div>
