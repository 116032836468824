<script>
  export let businessName;
</script>

<div class="row">
  <div class="col-12">
    <div class="subtitle text-primary text-sm">Recommendations</div>
    <div
      class="text-base font-weight-normal mb-2 text-wrap-newline text-muted font-italic">
      Travellers have not added recommendations for {businessName} yet.
    </div>
  </div>
</div>
