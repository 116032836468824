<script>
  import RecommendationsSection from "/traveller/modals/Recommendations.svelte";
  import EmptyStateRecommendations from "./emptyStateRecommendations.svelte";
  import { currentTraveller } from "/traveller/stores";
  import { business, recommendations, recommenders } from "../stores";
  import BookDirectDiscount from "/traveller/components/BookDirectDiscount.svelte";
  import SaveCouponButton from "/traveller/components/SaveCouponButton.svelte";
  import { onDestroy } from "svelte";

  export let businessCode;
  export let businessName;

  const MAX_RECOMMENDATIONS = 5;
  recommendations.fetch(businessCode, $currentTraveller.code);

  onDestroy(() => {
    // make new API call if component created again (i.e. when mounted inside a modal).
    $recommendations.loaded = false;
  });
</script>

{#if !$business.error && $recommendations.loaded}
  <!-- There may be something that we want to display -->
  {#if $currentTraveller.code && $recommenders.length}
    <!-- There are recommendations to display for this traveller -->
    <div class="card border-0 shadow mb-5">
      <div class="card-body">
        <RecommendationsSection
          highlights={$recommendations.recommendations.slice(0, MAX_RECOMMENDATIONS)} />
        {#if $business.discount}
          <BookDirectDiscount business={$business} />
          <br />
          <div class="mt-3">
            <SaveCouponButton business={$business} recommenders={$recommenders} />
          </div>
        {/if}
      </div>
    </div>
  {:else if $currentTraveller.code}
    <!-- This traveller has no relevant recommendations -->
    <div class="card border-0 shadow p-3 mb-5">
      <EmptyStateRecommendations {businessName} />
    </div>
  {/if}
{/if}
