import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { DEFAULT_MESSAGE, NetworkError, reportError } from "./errors.js";
import { BusinessType } from "./enums";

import Cookies from "js-cookie";

export const csrftoken = Cookies.get("csrftoken");

/*
 * Reports error to sentry and shows customer an appropriate message
 *
 * @param {Error} error - error to report.
 */
Notyf.prototype.reportError = function (error) {
  // inform users of all errors except network errors
  if (!(error instanceof NetworkError))
    this.error(error.customer_message || DEFAULT_MESSAGE);

  reportError(error);
};

export const notification = new Notyf({
  duration: 4000,
  position: {
    x: "center",
    y: "top",
  },
});

export let confirmDelete = (object_name, on_confirm, on_cancel) => {
  if (confirm(`Are you sure you want to delete this ${object_name}?`)) {
    return on_confirm();
  }
  if (on_cancel) {
    return on_cancel();
  }
};

export const showField = (selector, show) => {
  show ? $(selector).show() : $(selector).hide();
};

/*
 * conditionally clear the field denoted by selector
 *
 * @param {String} selector - a CSS selector (id or class name)
 * @param {Boolean} clear - if true, clear the field; otherwise leave as-is
 */
export const clearField = (selector, clear) => {
  clear ? $(selector).val("") : $(selector).val();
};

/*
 * Returns a truncated string. The truncated part is
 * indicated with '...'.
 * A regex is used in order to split along spaces, newline
 * characters, and carriage returns ('\r').
 *
 * @param {string} text - string to truncate
 * @param {number} truncateLength - number of words at which to truncate text
 */
export const truncate = (text, truncateLength) => {
  if (text.split(" ").length < truncateLength) {
    return text;
  }
  return (
    text
      .split(/[\s,]+/)
      .slice(0, truncateLength)
      .join(" ") + "..."
  );
};

export const capitalise = (s) => s.trim().replace(/^\w/, (c) => c.toUpperCase());

export const getSearchResultIcon = (searchResult) => {
  switch (BusinessType[searchResult.business_type]) {
    case BusinessType.accommodation:
      return '<i class="far far-bed searchbox-suggestion-icon"></i>';
    case BusinessType.activity:
      return '<i class="far far-hiking searchbox-suggestion-icon"></i>';
    case BusinessType.bar:
      return '<i class="far far-glass-martini-alt searchbox-suggestion-icon"></i>';
    case BusinessType.restaurant:
      return '<i class="far far-utensils searchbox-suggestion-icon"></i>';
    case BusinessType.cafe:
      return '<i class="far far-utensils searchbox-suggestion-icon"></i>';
    case BusinessType.sight:
      return '<i class="far far-camera searchbox-suggestion-icon"></i>';
  }
};

export const addTypeaheadListStyleRule = () => {
  const ruleDomNodeId = "typeaheadMenuLeftPositionRule";
  let styleRule = $(`#${ruleDomNodeId}`);
  if (!styleRule.length) {
    // there is an issue with the typeahead load event, so a hacky approach is
    // instead to use the typeahead input becoming active to set the position of the suggestion menu
    $(".typeahead").bind("typeahead:active", function (ev, suggestion) {
      // create node
      const element = document.createElement("style");
      element.id = ruleDomNodeId;

      // suggestion list should be centered horizontally w.r.t. input group but is positioned absolutely
      // inline with it. it should be moved left half of the difference in width between it
      // and the input group
      const inputGroupWith = $(".input-group").width();
      const menuWidth = $(".tt-menu").width();

      const difference = menuWidth - inputGroupWith;
      const menuLeftPosition = -(difference / 2);

      element.innerHTML = `.tt-menu {left: ${menuLeftPosition}px !important;}`;
      const header = document.getElementsByTagName("HEAD")[0];
      header.appendChild(element);
    });
  }
};

/* Returns a businesses location formatted as "locality, country" */
export const formatLocation = (business) => {
  const locality = business.area?.name || business.city;
  const country = business.country.name;
  return locality ? `${locality}, ${country}` : country;
};
