<script lang="ts">
  /*
   * Button redirects to website or via coupon creation flow
   *
   * This directs unauthed users directly to a business' website and
   * travellers via a create coupon flow if available (defaulting to website).
   *
   * If a traveller has a recommender they can create a coupon via the recommender
   * modal, then are directed to the coupon page to follow redemption instructions.
   *
   */
  import SelectRecommender from "/traveller/modals/SelectRecommender.svelte";
  import { notification } from "/shared/utils";
  import api from "/credit/api";
  import { currentTraveller } from "/traveller/stores";
  import { couponView } from "/traveller/shared/urls";
  import { Recommender } from "/business/types";

  export let business;
  export let recommenders: Array<Recommender>;
  export let content = "Book Now";
  export let btnClasses = "btn btn-primary";

  let isOpen = false;

  const toggle = () => {
    isOpen = !isOpen;
  };

  const createCouponAndRedirect = async (recommenderCode) => {
    let res;
    try {
      res = await api.creditApiCouponCreate({
        traveller: $currentTraveller.code,
        recommender: recommenderCode,
        business: business.business_code,
      });
    } catch (error) {
      notification.reportError(error);
      return;
    }
    // redirect to traveller coupons page
    // travellers make a booking from here
    window.location.href = couponView(res.data?.code);
  };

  // launch modal, ensure recommender selection
  const handleClick = () => {
    if (!$currentTraveller.code) {
      return;
    }

    if (recommenders.length == 1) {
      createCouponAndRedirect(recommenders[0].code);
    } else {
      toggle();
    }
  };
</script>

<a
  class={btnClasses}
  role="button"
  on:click={handleClick}
  href={$currentTraveller.code ? null : business.book_now_url}>
  {@html content}
</a>

<SelectRecommender
  {isOpen}
  {recommenders}
  {toggle}
  on:select={(ev) => createCouponAndRedirect(ev.detail.selectedRecommender.value.code)} />
