import { derived, readable, writable } from "svelte/store";
import travellerApi from "/traveller/api";
import businessApi from "./api";
import { Recommender } from "./types";

declare global {
  interface Window {
    businessCode?: string;
    currentBusiness?: { business_code: string };
  }
}

export const business = readable({ loaded: false, error: false }, (set) => {
  if (window.businessCode) {
    businessApi
      .apiBusinessRetrieve(window.businessCode)
      .then((response) => set({ ...response.data, loaded: true, error: false }))
      .catch((error) => set({ loaded: true, error: true }));
  }
});

export const currentBusiness = readable(
  {
    business_code: "",
  },
  function getCurrentBusiness(set) {
    if (window.currentBusiness) set(window.currentBusiness);
  }
);

function recommendationsStore() {
  const { subscribe, set, update } = writable({
    loaded: false,
    error: false,
    businessCode: "",
    travellerCode: "",
    recommendations: [],
  });

  return {
    subscribe,
    set,
    update,
    fetch: async (businessCode, travellerCode) => {
      try {
        let response = await travellerApi.travellerApiProfilePreviewRetrieve(
          businessCode
        );
        set({
          loaded: true,
          error: false,
          businessCode: businessCode,
          travellerCode: travellerCode,
          recommendations: response.data.visits,
        });
      } catch (error) {
        set({
          loaded: true,
          error: true,
          businessCode: "",
          travellerCode: "",
          recommendations: [],
        });
      }
    },
  };
}

export const recommendations = recommendationsStore();
export const recommenders = derived(
  recommendations,
  ($recommendations, set) => {
    let travellers: Recommender[] = [];
    // reshape data for Select component
    $recommendations.recommendations.forEach((highlight) => {
      // only show other travellers as suggestions
      if ($recommendations.travellerCode != highlight.traveller_code) {
        travellers.push({
          code: highlight.traveller_code,
          selfie_avatar_uuid: highlight.traveller_selfie_uuid,
          name: highlight.traveller_name,
        });
      }
    });
    set(travellers);
  },
  []
);
