<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { authFlow } from "../stores";
  import { AuthFlows } from "../constants";

  const dispatch = createEventDispatcher();

  export let name;
  export let initials;
  export let email;

  let submitted = false;
  let [user, domain] = email.split("@");
  let len = user.length / 3;
  let obfuscatedEmail =
    user.slice(0, len) + "*".repeat(len) + user.slice(-len) + "@" + domain;

  const MAX_DOTS = 4;
  let loadingText = `.${"&nbsp;".repeat(MAX_DOTS)}`;
  let ticker = 0;

  async function animateLoadingButton() {
    const ticks = ticker++ % MAX_DOTS;
    loadingText = `.${".".repeat(ticks)}${"&nbsp;".repeat(MAX_DOTS - ticks)}`;
  }

  function requestLink() {
    submitted = true;
    setInterval(animateLoadingButton, 300);
    dispatch("requestLink", email);
  }
</script>

<div class="h2 text-center font-weight-bold">
  Welcome back{name ? ", " + name : ""}
</div>
<hr />

<div class="mt-4">
  {#if initials}
    <div
      class="ml-auto mr-auto mb-2 avatar avatar-lg avatar-anonymous d-flex
      justify-content-center align-items-center font-weight-bold text-white
      text-uppercase text-xl">
      {initials}
    </div>
  {/if}

  <div class="text-center">
    <div class="mb-4 text-muted">
      <i class="fa far-envelope" />
      {obfuscatedEmail}
    </div>

    <div class="my-2">
      {#if submitted}
        <button class="w-100 btn btn-primary my-2" disabled>
          {@html loadingText}
        </button>
      {:else}
        <button class="w-100 btn btn-primary my-2" on:click={requestLink}>
          Email me a link to log in instantly
        </button>
      {/if}

      {#if $authFlow === AuthFlows.PASSWORD}
        <button
          class="w-100 btn btn-outline-primary my-2"
          on:click={() => dispatch("enterPassword")}>
          Enter Password
        </button>
      {/if}
    </div>
  </div>
</div>
