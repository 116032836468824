<script lang="ts">
  export let link: string;
  export let icon: string;
  export let label: string;
  export let active = false;
</script>

<div class="text-center">
  <a class="btn px-3" href={link}>
    <i
      class="fa fa-lg {icon}"
      class:text-primary={active}
      class:text-gray-500={!active} />
    <div
      class:text-gray-900={active}
      class:text-gray-500={!active}
      class="font-weight-bold text-xs">
      {label}
    </div>
  </a>
</div>
