import * as Sentry from "@sentry/browser";

const DEFAULT_MESSAGE =
  "Looks like something went wrong. We track these errors automatically, but if the problem persists feel free to contact us. In the meantime, try again.";

const IGNORABLE_ERROR_CODES = [
  400, // Client error
];

class NetworkError extends Error {
  constructor(error) {
    super(error.message);
    this.error = error;
    this.customer_message =
      "A network error has a occured, please check your connection and try again.";
  }
}

class HTTPError extends Error {
  constructor(response, data) {
    super(`Error (${response.status}) - ${response.statusText}`);
    this.response = response;
    this.data = data;
    this.status = response.status;
    if (!data) {
      response
        .json()
        .then((jsonError) => (this.data = jsonError))
        .catch((err) => (this.data = `Could not parse json error response: ${err}`));
    }
    this.detail = data.detail || JSON.stringify(data);
    this.message = `${this.message}: ${this.detail}`;
    switch (response.status) {
      case 400:
        this.customer_message =
          "There is an error in the form, please correct this and try again.";
        break;
      case 401:
      case 403:
        this.customer_message = "Must have permissions to perform this action.";
        break;
      case 404:
        this.customer_message =
          "The request failed, please contact support if the error persists.";
        break;
      case 429:
        this.customer_message = "Too many requests, please try again in a few minutes.";
        break;
      case 500:
      case 502:
      case 503:
      default:
        this.customer_message = DEFAULT_MESSAGE;
        break;
    }
  }
}

/* Report to sentry if available (prod/stag only)
 *
 */
function reportError(error) {
  if (window.useSentry) {
    // we only care about a subset of HTTP errors here
    // network errors are reported separately by the backend
    if (error instanceof HTTPError && !IGNORABLE_ERROR_CODES.includes(error.status)) {
      Sentry.captureException(error, {
        extra: error.data,
      });
    }
  } else {
    // rethrow all errors in dev for debugging
    throw error;
  }
}
export { NetworkError, HTTPError, DEFAULT_MESSAGE, reportError };
