<script lang="ts">
  import { search, Hound } from "./grayhound";
  import ResultList from "./ResultList.svelte";
  import { slide } from "svelte/transition";
  import { createEventDispatcher } from "svelte";

  export let hound: Hound;
  export let query: string;

  const dispatch = createEventDispatcher();

  // get/update remote values if needed
  // TODO: would be nice to cache these in a store
  $: if (hound.prefetch) {
    fetch(hound.prefetch)
      .then((response) => response.json())
      .then((data) => (hound.local = data));
  }

  let results = [];
  $: {
    search(query, hound).then((searchResults) => {
      results = searchResults;
      dispatch("complete", { found: searchResults.length > 0, query: query });
    });
  }
</script>

{#if results.length || hound.suggestions}
  <div class="py-2" transition:slide>
    {#if hound.name}
      <div class="text-md font-weight-bold pb-3">{hound.name}</div>
    {/if}
    <slot {results} {hound}>
      <ResultList
        {results}
        suggestions={hound.suggestions}
        render={hound.render}
        on:select />
    </slot>
  </div>
{/if}
