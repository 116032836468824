<script lang="ts">
  export let business;
</script>

<span class="font-weight-bold">
  Save {business.discount}% because people you follow recommend {business.name}!
</span>
<br />
{#if business.bonus_credit}
  They also get a ${business.bonus_credit} USD bonus credit from the business for helping you
  book direct.
{/if}
