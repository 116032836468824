import { COUNTRY_CODES_WITHOUT_IMAGES } from "../shared/country.js";
import { TravellerCoreSerialiser } from "/apiFactory/api";

export const NO_AREA = "NO_AREA";

export function getCountryCoverImageLink(countryCode) {
  if (COUNTRY_CODES_WITHOUT_IMAGES.includes(countryCode)) {
    return "/static/images/tab-worldmap.jpg";
  }
  var url = `https://directory-country-images-v4.s3.amazonaws.com/${countryCode.toUpperCase()}_image.jpeg`;
  return url;
}

export function getFlagImageLink(countryCode) {
  var url = `/static/images/flag/${countryCode.toLowerCase()}.png`;
  return url;
}

export function getSpotlightCoverImageLink(countryCode) {
  var url = `https://directory-traveller-spotlight-cover-images.s3.amazonaws.com/${countryCode.toLowerCase()}.png`;
  return url;
}

// relationships are asymmetric and we always want to display the other
// party to the relationship rather than current user
export function getTravellerToDisplayForFollowingPage(relationship, travellerCode) {
  if (relationship.to_traveller.code == travellerCode) {
    return relationship.from_traveller;
  }
  if (relationship.from_traveller.code == travellerCode) {
    return relationship.to_traveller;
  }
}

/**
 * Return a traveller's initials.
 *
 * @param {TravellerCoreSerialiser} traveller
 */
export function getTravellerInitials(traveller) {
  // bail early if nothing to split
  if (!traveller.name.trim()) return "";

  const split = traveller.name.trim().split(" ");
  if (split.length == 1) return split[0][0].toUpperCase();
  else return split[0][0].toUpperCase() + split[1][0].toUpperCase();
}

/**
 * Returns date in format 'Dec 2020'
 *
 */
export function formatDate(date) {
  return new Date(date.replace(/ /g, "T")).toLocaleDateString(undefined, {
    year: "numeric",
    month: "short",
  });
}
