<form action="#" id="search" class="form-inline" on:click>
  <div class="input-label-absolute input-label-absolute-left ml-lg-2 ml-xl-3">
    <label for="search_search" class="label-absolute">
      <i class="fa fa-search text-primary" />
      <span class="sr-only">What are you looking for?</span>
    </label>
    <input
      id="search_search"
      placeholder="Search"
      aria-label="Search"
      class="form-control form-control-sm border-0 shadow-0 bg-gray-200" />
    <button type="reset" class="btn btn-reset btn-sm">
      <i class="fa-times fas" />
    </button>
  </div>
</form>
