<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import ErrorMessage from "/shared/ErrorMessage.svelte";
  import { travellerTermsView, privacyPolicyView } from "/traveller/shared/urls.js";

  import CountrySelect from "/traveller/components/CountrySelect.svelte";
  import { hasError, errors } from "../stores";

  export let email: string;
  let form = {
    first_name: "",
    last_name: "",
    home_country: undefined,
    email: email,
  };

  $: form.email = email;

  const dispatch = createEventDispatcher();
  let submitted = false;
  $: {
    if ($hasError) {
      submitted = false;
    }
  }

  const MAX_DOTS = 4;
  let loadingText = `.${"&nbsp;".repeat(MAX_DOTS)}`;
  let ticker = 0;

  async function animateLoadingButton() {
    if (!submitted) return;
    const ticks = ticker++ % MAX_DOTS;
    loadingText = `.${".".repeat(ticks)}${"&nbsp;".repeat(MAX_DOTS - ticks)}`;
    setTimeout(animateLoadingButton, 300);
  }

  function signup() {
    $errors = {};
    submitted = true;
    animateLoadingButton();
    dispatch("submit", form);
  }
</script>

<div class="h2 text-center font-weight-bold">Finish signing up</div>
<hr />

<form class="mt-4 text-left text-dark" on:submit|preventDefault={signup}>
  <div class="form-group">
    <label for="firstName">First Name</label>
    <input
      type="text"
      class="form-control"
      id="firstName"
      required
      bind:value={form.first_name} />
  </div>

  <div class="form-group">
    <label for="lastName">Last Name</label>
    <input
      type="text"
      class="form-control"
      id="lastName"
      required
      bind:value={form.last_name} />
  </div>

  <div class="form-group">
    <label for="country">Home Country</label>
    <CountrySelect
      bind:selectedCountry={form.home_country}
      hasError={$errors["home_country"]} />
  </div>

  <div class="form-group">
    <label for="emailInput">Email</label>
    <input type="email" class="form-control" id="email" required bind:value={email} />
    <ErrorMessage name="email" />
  </div>
  <!-- prettier-ignore -->
  <small id="submitHelp" class="form-text text-muted">
    By clicking the button below, I agree to the
    <a href={travellerTermsView()} target="_blank" rel="noopener noreferrer">
      Terms & Conditions
    </a>
    and
    <a href={privacyPolicyView()} target="_blank" rel="noopener noreferrer">
      Privacy Policy</a>, and confirm I am at least 18 years of age.
  </small>

  {#if submitted}
    <button class="btn btn-primary mt-4 w-100" disabled>
      {@html loadingText}
    </button>
  {:else}
    <button class="btn btn-primary mt-4 w-100" type="submit">Continue</button>
  {/if}
</form>
