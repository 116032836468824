<script lang="ts">
  import SearchButtonModal from "/shared/components/search/SearchButtonModal.svelte";
  import {
    activityView,
    countryView,
    areaView,
    homeView,
    allActivitiesView,
    allCountriesView,
  } from "/guidebook/shared/urls";
  import { currentTraveller } from "/traveller/stores";
  import { COUNTRIES } from "/shared/country";
  import { ActivitySubtype } from "/shared/enums";
  import {
    accountView,
    loginView,
    signupView,
    logoutView,
    dashboardView,
    discoverView,
    wishlistView,
    vouchersView,
  } from "/traveller/shared/urls";

  export let contentWhite = false;
  export let transparent = false;
  export let marketingUrl = null;
  export let creatorsUrl = null;
  export let showSearch = true;
  export let showMegaMenu = true;

  const lightMode = contentWhite || transparent;

  const POPULAR_COUNTRIES = ["MX", "ZA", "AR", "TH", "ID", "PE", "CO", "NP"];
  const ACTIVITIES = [
    "surfing",
    "safari",
    "cooking_classes",
    "cycling",
    "winetours",
    "hiking",
    "paddle_boarding",
    "yoga",
  ];
  const AREAS = [
    { name: "Ubud", slug: "ubud", country: { code: "ID", name: "Indonesia" } },
    {
      name: "Chiang Mai",
      slug: "chiang-mai",
      country: { code: "TH", name: "Thailand" },
    },
    { name: "Tulum", slug: "tulum", country: { code: "MX", name: "Mexico" } },
    { name: "Paris", slug: "paris", country: { code: "FR", name: "France" } },
    { name: "Boston", slug: "boston-ma", country: { code: "US", name: "USA" } },
    {
      name: "Cape Town",
      slug: "cape-town",
      country: { code: "ZA", name: "South Africa" },
    },
    {
      name: "Edinburgh",
      slug: "edinburgh",
      country: { code: "GB", name: "UK" },
    },
    {
      name: "Hoi An",
      slug: "hoi-an",
      country: { code: "VN", name: "Vietnam" },
    },
  ];

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = logoutView();
  };
</script>

<style lang="scss">
  // Import theme colours
  @use "sass/user/colours";

  .navbar-logo {
    height: 30px;
    opacity: 1;
  }

  /* down arrow isn't showing correctly? override with fa icon */
  .navbar .dropdown-toggle::after {
    content: "" !important;
  }
  @media only screen and (max-width: 992px) {
    .navbar-expand-lg.bg-transparent.navbar-light .navbar-collapse {
      background: colours.$gray-100;
    }

    .navbar-expand-lg.bg-transparent.navbar-dark .navbar-collapse {
      background: colours.$gray-700;
    }
  }

  // Position above other content in the navbar (cover-image)
  .light-mode {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 50;
  }
  .dropdown-menu {
    z-index: 1021;
  }
</style>

<nav
  class:navbar-light={!lightMode}
  class:navbar-dark={lightMode}
  class:light-mode={lightMode}
  class:bg-white={lightMode}
  class:text-primary={!lightMode}
  class:shadow-sm={!transparent}
  class="navbar navbar-expand-lg bg-transparent">
  <div class="container-fluid">
    <div class="d-flex flex-row align-items-center">
      <div>
        <a href={homeView()} class="navbar-brand py-3" style="line-height: 0;">
          <img
            class="navbar-logo"
            src="/static/images/directory-logo-crop-420.png"
            alt="The Guide logo"
            style="height:30px; opacity:1;" />
        </a>
      </div>
      {#if showSearch}
        <div class="d-none d-sm-flex">
          <SearchButtonModal />
        </div>
      {/if}
    </div>

    <button
      type="button"
      data-toggle="collapse"
      data-target="#navbarCollapse"
      aria-controls="navbarCollapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
      class="d-none d-md-block d-lg-none navbar-toggler navbar-toggler-right
      border-light">
      <i class="fa fa-bars" />
    </button>

    <div id="navbarCollapse" class="collapse navbar-collapse">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item mr-3">
          <div class="d-flex d-sm-none">
            <SearchButtonModal />
          </div>
        </li>

        {#if showMegaMenu}
          <!-- Megamenu-->
          <li class="nav-item dropdown position-static mr-3">
            <!-- svelte-ignore a11y-invalid-attribute -->
            <a
              href="#"
              class="nav-link dropdown-toggle"
              class:text-primary={!lightMode}
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              Explore The Guide
              <i class="fa fa-angle-down ml-1" />
            </a>

            <div
              class="dropdown-menu megamenu py-lg-0"
              style="transform: translateY(30px);">
              <div class="row">
                <div class="col-lg-9">
                  <div class="row p-3 pr-lg-0 pl-lg-5 pt-lg-5">
                    <div class="col-lg-4">
                      <!-- Megamenu list-->
                      <h6 class="text-uppercase">Popular Countries</h6>
                      <ul class="megamenu-list list-unstyled">
                        {#each POPULAR_COUNTRIES as country}
                          <li class="megamenu-list-item">
                            <a href={countryView(country)} class="megamenu-list-link">
                              {COUNTRIES[country]}
                            </a>
                          </li>
                        {/each}
                      </ul>
                      <ul class="megamenu-list list-unstyled">
                        <li class="megamenu-list-item">
                          <a
                            href={allCountriesView()}
                            class="megamenu-list-link text-primary">
                            See all countries
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-lg-4">
                      <!-- Megamenu list-->
                      <h6 class="text-uppercase">Explore Activities</h6>
                      <ul class="megamenu-list list-unstyled">
                        {#each ACTIVITIES as activity}
                          <li class="megamenu-list-item">
                            <a href={activityView(activity)} class="megamenu-list-link">
                              {ActivitySubtype[activity]}
                            </a>
                          </li>
                        {/each}
                      </ul>
                      <ul class="megamenu-list list-unstyled">
                        <li class="megamenu-list-item">
                          <a
                            href={allActivitiesView()}
                            class="megamenu-list-link text-primary">
                            See more activities
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-lg-4">
                      <!-- Megamenu list-->
                      <h6 class="text-uppercase">Trending Destinations</h6>
                      <ul class="megamenu-list list-unstyled">
                        {#each AREAS as area}
                          <li class="megamenu-list-item">
                            <a
                              href={areaView(area.country.code, area.slug)}
                              class="megamenu-list-link">
                              {`${area.name}, ${area.country.name}`}
                            </a>
                          </li>
                        {/each}
                      </ul>
                    </div>
                  </div>
                  <div class="row megamenu-services d-none d-lg-flex pl-lg-5">
                    <div class="col-xl-4 col-lg-6 d-flex">
                      <div class="megamenu-services-item">
                        <svg
                          class="svg-icon megamenu-services-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 64 64">
                          <title>Destination Map</title>
                          <desc> A line styled icon from Orion Icon Library. </desc>
                          <path
                            d="M43.1 17l9.9 9.9M43 27l9.9-9.9m-19.5 7.6a10 10 0
                            0 1 6.8-2.7M29.7 35.2a10 10 0 0 0 .5-2.9V32a10 10 0
                            0 1 .9-4.3M21.2 42a10 10 0 0 0 6.6-3.5M9.8 42h7.6M2
                            42h4"
                            stroke-miterlimit="10"
                            stroke="#101010"
                            fill="none"
                            data-name="layer2"
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            style="stroke:var(--layer1, #101010)" />
                          <path
                            d="M62 10v50l-22-6-16 6-22-6V4l22 6 16-6 22 6z"
                            stroke-miterlimit="10"
                            stroke="#101010"
                            fill="none"
                            data-name="layer1"
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            style="stroke:var(--layer1, #101010)" />
                        </svg>
                        <div>
                          <h6 class="text-uppercase mb-1">Be Inspired</h6>
                          <p class="mb-0 text-muted text-sm">Personalised just for you</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 d-flex">
                      <div class="megamenu-services-item">
                        <svg
                          class="svg-icon megamenu-services-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 64 64">
                          <title>Money Box</title>
                          <desc> A line styled icon from Orion Icon Library. </desc>
                          <path
                            data-name="layer1"
                            d="M28.8 19.5a21.1 21.1 0 0 0-7.1 2.2c-7.9 4.2-9.2
                            11-9.2 16.3s2.3 13.1 10.8 20h7.3v-4l8.9.5a20.1 20.1
                            0 0 0 2.3 3.5h6.7v-6a47.9 47.9 0 0 0 6-5c2.9.3 5.9-2
                            7-8.7 0-1-.5-1.3-1-1.3a6.8 6.8 0 0
                            1-3-1c-.4-.5-1.6-4.7-3.8-8a11.7 11.7 0 0 1
                            4.1-5.2c-2.8-1.4-5.7-2.4-11.4-1.8-1.5-.3-4-.9-6.4-1.3"
                            fill="none"
                            stroke="#101010"
                            stroke-miterlimit="10"
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            style="stroke:var(--layer2, #101010)" />
                          <circle
                            data-name="layer2"
                            cx="34.5"
                            cy="14"
                            r="8"
                            fill="none"
                            stroke="#101010"
                            stroke-miterlimit="10"
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            style="stroke:var(--layer2, #101010)" />
                          <path
                            data-name="layer1"
                            d="M12.5 38c-2.9-.3-10-1.4-10 2s1.9 3.4 3.8 2.6
                            4.3-5.5-3.8-8.6m25.7-8.9a20.1 20.1 0 0 1 12.7-.1"
                            fill="none"
                            stroke="#101010"
                            stroke-miterlimit="10"
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            style="stroke:var(--layer2, #101010)" />
                          <circle
                            data-name="layer1"
                            cx="48.5"
                            cy="34"
                            r="1"
                            fill="none"
                            stroke="#101010"
                            stroke-miterlimit="10"
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            style="stroke:var(--layer2, #101010)" />
                        </svg>
                        <div>
                          <h6 class="text-uppercase mb-1">Book Direct</h6>
                          <p class="mb-0 text-muted text-sm">Travel responsibly</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 d-flex">
                      <div class="megamenu-services-item">
                        <svg
                          class="svg-icon megamenu-services-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 64 64">
                          <title>Customer Support</title>
                          <desc> A line styled icon from Orion Icon Library. </desc>
                          <path
                            data-name="layer2"
                            d="M24 40.5V44c0 2 .6 5-10.4 7A14.6 14.6 0 0 0 2
                            62h59a14.3 14.3 0 0 0-11.4-11C39 49 40 46 40 44v-5"
                            fill="none"
                            stroke="#101010"
                            stroke-miterlimit="10"
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            style="stroke:var(--layer1, #101010)" />
                          <path
                            data-name="layer1"
                            d="M46.4 33H51a3.3 3.3 0 0 0 3-3v-7a3.3 3.3 0 0
                            0-3-3h-5m-28 0h-6a3.3 3.3 0 0 0-3 3v7a3.3 3.3 0 0 0
                            3 3h5.2"
                            fill="none"
                            stroke="#101010"
                            stroke-miterlimit="10"
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            style="stroke:var(--layer1, #101010)" />
                          <path
                            data-name="layer2"
                            d="M46 18C26 18 26 4 26 4s1 12-8 15m18
                            43l9.6-12m-27.7 0L28 62M17.9 50a10.8 10.8 0 0
                            1-4.9-4c4-4 5-20 5-20m28 0s0 16 4 20a10.7 10.7 0 0
                            1-4.7 4"
                            fill="none"
                            stroke="#101010"
                            stroke-miterlimit="10"
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            style="stroke:var(--layer1, #101010)" />
                          <path
                            data-name="layer2"
                            d="M26 42c-4.7-2.9-8-8.8-8-16V16C18 5.6 25.4 2 32
                            2s14 3.6 14 14v16c0 4-4 7-6 7"
                            fill="none"
                            stroke="#101010"
                            stroke-miterlimit="10"
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            style="stroke:var(--layer1, #101010)" />
                          <path
                            data-name="layer1"
                            d="M40 38.9a2.9 2.9 0 0 0-2.9-2.9h-2.2a2.9 2.9 0 0
                            0-2.9 2.9 3 3 0 0 0 2.9 3.1H37a3 3 0 0 0 3-3.1z"
                            fill="none"
                            stroke="#101010"
                            stroke-miterlimit="10"
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            style="stroke:var(--layer1, #101010)" />
                        </svg>
                        <div>
                          <h6 class="text-uppercase mb-1">Share Suggestions</h6>
                          <p class="mb-0 text-muted text-sm">
                            Follow friends &amp; local experts
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 d-none d-lg-block">
                  <img
                    data-blink-uuid="06740c21-b117-4077-8295-7394e73b13e0"
                    alt="People cycling through rice fields "
                    class="bg-image" />
                </div>
              </div>
            </div>
          </li>
        {:else}
          <li class="nav-item mr-3">
            <a href={homeView()} class="nav-link" class:text-primary={!lightMode}>
              Explore The Guide
            </a>
          </li>
        {/if}

        <!-- /Megamenu end-->

        {#if $currentTraveller.code}
          <li class="nav-item mr-3">
            <a href={discoverView()} class="nav-link" class:text-primary={!lightMode}>
              For You
            </a>
          </li>

          <li class="nav-item mr-3">
            <a href={dashboardView()} class="nav-link" class:text-primary={!lightMode}>
              My Recommendations
            </a>
          </li>

          <li class="nav-item mr-3 dropdown">
            <!-- svelte-ignore a11y-invalid-attribute -->
            <a
              id="accountDropDown"
              href="#"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              class="nav-link dropdown-toggle"
              class:text-primary={!lightMode}>
              My Account
              <i class="fa fa-angle-down ml-1" />
            </a>
            <div
              aria-labelledby="docsDropdownMenuLink"
              class="dropdown-menu dropdown-menu-right"
              style="transform: translateY(30px);">
              <a href={wishlistView()} class="dropdown-item">
                <i class="fa fa-bookmark text-primary mr-2 fa-fw" />
                My Wishlist
              </a>
              <a href={vouchersView()} class="dropdown-item">
                <i class="fa fa-ticket-alt text-primary mr-2 fa-fw" />
                Saved Vouchers
              </a>
              <!--               <a href={dashboardView()} class="dropdown-item">
                Invite a Friend
              </a> -->
              <a href={accountView()} class="dropdown-item">
                <i class="fa fa-sliders-v text-primary mr-2 fa-fw" />
                Profile & Settings
              </a>
              <span
                on:click={() => {
                  logout();
                }}
                class="dropdown-item">
                <i class="fa fa-sign-out-alt text-primary mr-2 fa-fw" />
                Log Out
              </span>
            </div>
          </li>
        {:else}
          <li class="nav-item mr-3">
            <a href={marketingUrl} class="nav-link" class:text-primary={!lightMode}>
              For Business
            </a>
          </li>

          <li class="nav-item mr-3">
            <a href={creatorsUrl} class="nav-link" class:text-primary={!lightMode}>
              For Creators
            </a>
          </li>
          <li class="nav-item mr-3">
            <a href={loginView()} class="nav-link">Log in</a>
          </li>
          <li class="nav-item">
            <a href={signupView()} class="btn btn-primary">Sign up for free</a>
          </li>
        {/if}
      </ul>
    </div>
  </div>
</nav>
