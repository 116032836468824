<script>
  import { getContext } from "svelte";
  import { $enum as enumUtil } from "ts-enum-util";
  import { ActivitySubtype } from "/shared/enums";
  import { activityView, activityCountryView } from "../shared/urls";

  /**
   * Activity Card
   *
   * Show an activity's name and image, forwards click events.
   * @component activityCard
   */

  export let activity;

  let link;
  let activityCode = enumUtil(ActivitySubtype)
    .getKeyOrThrow(activity.name)
    .replace("_", "-");

  const country = getContext("country") || {};

  if (country.code) {
    link = activityCountryView(activityCode, country.code);
  } else {
    link = activityView(activityCode);
  }
</script>

<style>
  .card {
    height: 350px;
  }
</style>

<a href={link}>
  <div
    data-blink-quality="lightest"
    data-blink-src={activity.imageLink}
    class="card border-0 bg-cover text-white dark-overlay rounded-lg
    hover-animate"
    role="button"
    on:click>
    <div class="card-img-overlay d-flex align-items-center">
      <div class="w-100 overlay-content text-center">
        <span class="opacity-9 mb-0 text-white text-capitalize h4 letter-spacing-2">
          {activity.name.toUpperCase()}
        </span>
      </div>
    </div>
  </div>
</a>
