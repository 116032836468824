import { CreditApi } from "/apiFactory/api";
import { Configuration } from "/apiFactory/configuration";
// @ts-ignore
import { csrftoken } from "/shared/utils.js";

const url = new URL(window.location.href);
const config = new Configuration({
  basePath: url.origin,
  baseOptions: {
    headers: {
      "X-CSRFToken": csrftoken,
    },
  },
});

const api = new CreditApi(config);

export default api;
