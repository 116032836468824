<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { fade } from "svelte/transition";
  import Card from "./Card.svelte";

  export let activityProducts = [];
  export let size = 8;

  const dispatch = createEventDispatcher();
</script>

<div class="row">
  {#each activityProducts.slice(0, size) as activityProduct, i (i)}
    <div class="col-md-3" transition:fade>
      <Card
        product={activityProduct.product}
        on:click={() => dispatch("click", { ...activityProduct })} />
    </div>
  {/each}
</div>
