<script lang="ts">
  /*
   * This component represents some of the header element found on
   * business profile pages. It is intended to be used in modal
   * elements.
   *
   * The "badges" slot allows consumers of this component to display
   * extra business profile badges.
   */

  import { getSpotlightCoverImageLink } from "../utils.js";
  import { BusinessType } from "../../shared/enums";
  import { BusinessSpotlightSerialiser } from "/apiFactory/api";
  import { getFlagImageLink } from "../utils";
  import { countryView, areaView } from "/guidebook/shared/urls.js";
  import { makeAbsoluteURL } from "/traveller/shared/urls.js";
  import ViewListingButton from "./ViewListingButton.svelte";
  import AddToWishlistButton from "./AddToWishlistButton.svelte";

  export let business: BusinessSpotlightSerialiser;
  export let wishlist_uuid = undefined;

  let { name, business_type, country, area, city, cover_image_uuid } = business;

  // only set defaultCoverImage if coverImageUUID is undefined
  const defaultCoverImage = cover_image_uuid
    ? ""
    : getSpotlightCoverImageLink(country.code);
</script>

<div
  class="pb-5 d-flex align-items-end dark-overlay bg-cover rounded-lg-top"
  data-blink-uuid={cover_image_uuid}
  data-blink-src={defaultCoverImage}
  data-blink-quality="lightest">
  <div class="container overlay-content py-5 px-4 px-md-4">
    <div class="row">
      <div class="col-10 offset-1">
        <div
          class="d-flex justify-content-between align-items-start flex-column
          text-white mb-4 mb-lg-0">
          <div class="d-flex justify-content-between w-100">
            <div class="mb-2 d-flex align-items-end">
              {#if business_type}
                <div
                  class="badge badge-light px-2 py-2 rounded-lg text-xs font-weight-bolder">
                  {BusinessType[business_type].toUpperCase()}
                </div>
              {/if}
              {#if business.is_shell}
                <div
                  class="badge badge-pill px-2 py-2 rounded-lg text-xs ml-2"
                  style="background: #FAA251">
                  UNCLAIMED
                </div>
              {/if}
            </div>
            <div
              class="d-flex flex-column align-items-end align-items-md-stretch
              flex-md-row justify-content-end">
              {#if !business.is_shell}
                <div class="mb-4">
                  <ViewListingButton url={business.profile_url} />
                  <AddToWishlistButton
                    wishlistUuid={wishlist_uuid}
                    businessCode={business.business_code} />
                </div>
              {/if}
            </div>
          </div>
          <div class="h1 text-shadow">{name}</div>
          <div>
            <a
              href={makeAbsoluteURL("theguide", countryView(business.country.code))}
              class="text-reset">
              <img
                src={getFlagImageLink(country.code)}
                alt="{country.name} flag"
                style="height:22px; padding-bottom: 2px; padding-right: 5px;
                padding-left: 2px;" />
              {country.name}
            </a>
            {#if area || city}
              <i class="fa-angle-right fas mr-2 ml-2" />
            {/if}
            {#if area}
              <a
                href={makeAbsoluteURL("theguide", areaView(country.code, area.slug))}
                class="text-reset">
                {area.name}
              </a>
            {:else}{city}{/if}
          </div>
          <slot name="badges" />
        </div>
      </div>
    </div>
  </div>
</div>
