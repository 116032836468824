import { STOP_WORDS } from "./constants";

/*
 * Defines how the search engine should search for queries within some data
 *
 * Specify an array of data(datums) in local, a local function, a prefetch URL to fetch an array of data or
 * a remote URL to query against.
 */
export interface Hound {
  name?: string;
  local?: Array<any> | ((any) => Array<any>);
  search?: boolean;
  suggestions?: Array<any>;
  prefetch?: string;
  remote?: (string) => string;
  transform?: (any) => string; // the data from a remote request
  identify?: (any) => string; // the unique ID of a datum
  render?: (any) => string; // get the human readable representation of a datum
  // internal
  controller?: any;
  results?: Array<any>;
  lastQuery?: string;
}

async function queryRemote(query: string, hound: Hound) {
  // check for and abort some requests in-flight
  // we want to load something as a user types but not all requests
  if (hound.controller && Math.random() < 0.5) {
    hound.controller.abort();
  }

  // reset abort controller for new request
  hound.controller = new AbortController();

  try {
    let response = await fetch(hound.remote(query), {
      signal: hound.controller.signal,
    });
    let values = await response.json();

    if (hound.transform) {
      values = hound.transform(values);
    }
    return values;
  } catch (error) {
    return [];
  }
}

/* Return a list of ASCII normalised lower-case tokens. */
function tokenize(query: string): Array<string> {
  // normalize splits a diacritic ligament from the character, which we then remove
  let tokens = query
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .split(" ");
  return tokens.filter((w) => !STOP_WORDS[w]);
}

export interface Match {
  score: number;
  key: string;
  value: string;
}

export function relevancy(
  queryTokens: Array<string>,
  indexes: { [key: string]: Array<Array<string>> },
  primaryKey: string
): Array<{ [key: string]: Match }> {
  let matches: {
    [key: string]: { [key: string]: Match };
  } = {};

  for (let index in indexes) {
    matches[index] = {};

    // search word by word, more words == greater relevancy
    for (let queryToken of queryTokens) {
      // search for each queryToken in every index
      for (let [key, value] of indexes[index]) {
        for (let token of tokenize(value)) {
          // search within each token for queryToken
          if (token.includes(queryToken)) {
            let result = matches[index]?.[key] || { score: 0, key, value };
            // basic match == 1
            result.score += 1;

            // starts with == 2
            if (token.startsWith(queryToken)) result.score += 1;

            // exact match == 3
            if (token === queryToken) result.score += 1;

            matches[index][key] = result;
          }
        }
      }
    }
  }

  let matchList = {};
  // convert dict of matches to lists sorted by relevancy
  for (let match in matches) {
    matchList[match] = Object.values(matches[match]).sort(
      (a, b) => b.score - a.score
    );
  }

  let results = [];
  const primaryMatches = matchList[primaryKey];
  const secondaryMatchKeys = Object.keys(matchList).filter(
    (key) => key !== primaryKey
  );
  const onlyPrimaryResults = secondaryMatchKeys.length !== 1;

  // produce final result set, combining each result from primary and secondary sources
  for (let result of primaryMatches) {
    if (onlyPrimaryResults) {
      results.push({ key: result.key, [primaryKey]: result });
    } else {
      // otherwise, combine with secondary results
      for (let secondaryKey of secondaryMatchKeys) {
        // combine if the secondary key has matches
        if (matchList[secondaryKey].length) {
          for (let secondaryResult of matchList[secondaryKey]) {
            results.push({
              key: result.key + secondaryResult.key,
              [primaryKey]: result,
              [secondaryKey]: secondaryResult,
            });
          }
        } else {
          // no secondary match, just push primaryKey result
          results.push({ key: result.key, [primaryKey]: result });
        }
      }
    }
  }

  return results;
}

export async function search(query: string, hound: Hound) {
  let results = [];

  // cleanup query to improve matching/cache-hits
  query = query.trim().toLowerCase();

  if (!query) {
    return [];
  }
  // use cache if same query
  if (query === hound.lastQuery) {
    return hound.results;
  }

  // keep track of query for caching
  hound.lastQuery = query;

  let queryTokens = tokenize(query);

  if (hound.remote) {
    results = await queryRemote(query, hound);
  } else if (typeof hound.local === "function") {
    results = hound.local(queryTokens);
  } else {
    results = hound.local || [];
  }

  if (hound.search !== undefined && !hound.search) {
    hound.results = results;
    return results;
  }

  let identify = (value) => value.toString();
  if (hound.identify) {
    identify = hound.identify;
  }

  // get results and cache
  hound.results = results.filter((value: string) =>
    identify(value).toLowerCase().includes(query)
  );

  return hound.results;
}
