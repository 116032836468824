<script lang="ts">
  import { setContext } from "svelte";
  import { fly } from "svelte/transition";
  import {
    State,
    Transition,
    FLY_DURATION,
    FLY_IN_DELAY,
    LOGIN_PANELS,
  } from "./constants";
  import { suggestedHashtagsView } from "/traveller/shared/urls";
  import { errors } from "./stores";

  // needed for ErrorMessage form helpers
  setContext("errors", errors);

  export let email: string;
  export let name: string;
  export let initials: string;
  export let nextURL: string;

  export let checkEmail;
  export let createAccount;
  export let requestLink;
  export let requestPasswordReset;
  export let login;
  export let initialState = null;
  export let panels = LOGIN_PANELS;

  let xIn: Transition, xOut: Transition;
  // reactive transitions (on xIn/xOut)
  $: transitionIn = { x: xIn, duration: FLY_DURATION, delay: FLY_IN_DELAY };
  $: transitionOut = { x: xOut, duration: FLY_DURATION };

  // state machine for moving between panels
  let history: State[];
  $: history = []; // weird bug occurs if history is not set using reactivity
  let state = initialState || State.LOGIN;

  function changeState(next: State) {
    // don't add repeated states to stack
    if (next == state) {
      return;
    }
    // push current state and move to next
    history.push(state);
    state = next;
  }

  async function goTo(fn, next: State, fail?: State) {
    // update transition before state change
    let result = await fn();
    [xIn, xOut] = [Transition.RIGHT, Transition.LEFT];
    if (result) {
      changeState(next);
    } else if (fail) {
      changeState(fail);
    }
  }

  async function goBack() {
    if (history.length) {
      [xIn, xOut] = [Transition.LEFT, Transition.RIGHT];
      state = history.pop();
    }
  }

  // Final state redirect
  $: {
    if (state === State.LOGGED_IN) {
      window.location.href = nextURL;
    } else if (state == State.SIGNUP_SUBMITTED) {
      window.location.href = nextURL || suggestedHashtagsView();
    }
  }
</script>

<style>
  .top-left {
    /* Custom class as we don't place the button in the header */
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem 1rem;
    z-index: 1004;
  }
  .overflow-hidden {
    width: 200%;
  }
</style>

<!-- BACK BUTTON -->
{#if history.length > 0}
  <button class="btn top-left" on:click={goBack}>
    <span aria-hidden="true">
      <i class="fab fa-arrow-left" />
    </span>
  </button>
{/if}
<!-- /BACK BUTTON -->
<div class="px-4 overflow-hidden row">
  <!-- PANELS -->
  {#if state === State.LOGIN}
    <div class="w-100 col-6" in:fly|local={transitionIn} out:fly|local={transitionOut}>
      <svelte:component
        this={panels[State.LOGIN]}
        on:submit={(ev) =>
          goTo(() => checkEmail(ev.detail), State.REQUEST_LINK, State.SIGNUP)} />
    </div>
  {:else if state === State.SIGNUP}
    <div class="w-100 col-6" in:fly|local={transitionIn} out:fly|local={transitionOut}>
      <svelte:component
        this={panels[State.SIGNUP]}
        {email}
        on:submit={(ev) =>
          goTo(() => createAccount(ev.detail), State.SIGNUP_SUBMITTED)} />
    </div>
  {:else if state === State.REQUEST_LINK}
    <div class="w-100 col-6" in:fly|local={transitionIn} out:fly|local={transitionOut}>
      <svelte:component
        this={panels[State.REQUEST_LINK]}
        {email}
        {name}
        {initials}
        on:requestLink={(ev) =>
          goTo(() => requestLink(ev.detail, nextURL, false), State.REQUEST_SENT)}
        on:enterPassword={() => goTo(() => true, State.ENTER_PASSWORD)} />
    </div>
  {:else if state === State.REQUEST_SENT}
    <div class="w-100 col-6" in:fly|local={transitionIn} out:fly|local={transitionOut}>
      <svelte:component
        this={panels[State.REQUEST_SENT]}
        {email}
        on:requestLink={(ev) =>
          goTo(() => requestLink(ev.detail, nextURL, true), State.REQUEST_SENT)} />
    </div>
  {:else if state === State.ENTER_PASSWORD}
    <div class="w-100 col-6" in:fly|local={transitionIn} out:fly|local={transitionOut}>
      <svelte:component
        this={panels[State.ENTER_PASSWORD]}
        on:submit={(ev) => goTo(() => login(ev.detail), State.LOGGED_IN)}
        on:requestReset={() => goTo(() => true, State.REQUEST_RESET)} />
    </div>
  {:else if state === State.REQUEST_RESET}
    <div class="w-100 col-6" in:fly|local={transitionIn} out:fly|local={transitionOut}>
      <svelte:component
        this={panels[State.REQUEST_RESET]}
        {email}
        on:submit={(ev) =>
          goTo(() => requestPasswordReset(ev.detail), State.RESET_REQUEST_SENT)} />
    </div>
  {:else if state === State.RESET_REQUEST_SENT}
    <div class="w-100 col-6" in:fly|local={transitionIn} out:fly|local={transitionOut}>
      <svelte:component this={panels[State.RESET_REQUEST_SENT]} {email} />
    </div>
  {:else if [State.LOGGED_IN, State.SIGNUP_SUBMITTED].includes(state)}
    <div
      in:fly={transitionIn}
      out:fly={transitionOut}
      class="w-100 col-12 mt-4 text-center">
      <i class="fa far-check-circle fa-3x text-primary" />
    </div>
  {/if}
</div>
