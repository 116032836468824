<script lang="ts">
  export let url;
</script>

<style lang="css">
  .text-resizable {
    font-size: 1rem;
  }

  /* Small devices (landscape phones, 544px and up) */
  @media (max-width: 768px) {
    .text-resizable {
      font-size: 0.875rem;
    }
  }
</style>

<div class="text-center ml-auto">
  <a
    id="viewFullProfile"
    href={url}
    class="btn btn-primary px-2 text-resizable"
    target="_blank"
    rel="noopener noreferrer">
    View Full Listing
    <i class="fas fa-external-link-alt ml-1 d-none d-md-inline" />
  </a>
</div>
