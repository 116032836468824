<script lang="ts">
  import { fade } from "svelte/transition";
  import BusinessCard from "./BusinessCard.svelte";

  export let businesses = [];
  export let size = 6;
</script>

<div class="row">
  {#each businesses.slice(0, size) as business, i (i)}
    <div class="col-md-4" transition:fade>
      <BusinessCard {business} on:click />
    </div>
  {/each}
</div>
