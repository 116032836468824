<script>
  /**
   * Country Card
   *
   * Show a Country's name and image, accepts a cardLink.
   * @component CountryCard
   */

  import CountryCard from "/shared/components/CountryCard.svelte";

  export let code;
  export let cardLink = null;
  export let shrinkOnMobile = false;
  export let newTab = false;
</script>

<CountryCard {code} {shrinkOnMobile} {cardLink} selected={true} {newTab} on:click />
