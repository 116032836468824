<script lang="ts">
  import SwiperCore, { Autoplay, A11y, EffectFade, SwiperOptions } from "swiper";
  import { Swiper, SwiperSlide } from "swiper/svelte";
  import { BusinessSerialiser as Business } from "/apiFactory/api";
  import SearchBar from "/shared/components/search/SearchBar.svelte";
  import { dashboardView } from "/traveller/shared/urls";
  import { allActivitiesView, allCountriesView } from "/guidebook/shared/urls.js";

  export let heroItems: Array<{ business: Business; image_uuid: string }>;

  let swiperProps: SwiperOptions = {
    allowTouchMove: false,
    pagination: false,
    slidesPerView: 1,
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    speed: 2500,
    autoplay: true,
  };

  // install Swiper components
  SwiperCore.use([A11y, Autoplay, EffectFade]);

  // store swiper instance for triggering updates
  let swiper = null;

  const getSwiper = (e) => {
    const [_swiper] = e.detail;
    // set the swiper instance
    setTimeout(() => {
      swiper = _swiper;
    });
  };

  // react to changes to businesses and swiper, data may be async
  $: {
    heroItems;
    // swiper may not have initialised yet
    if (swiper) {
      setTimeout(() => {
        swiper.update();
      });
    }
  }
</script>

<style global lang="scss">
  // Import Swiper styles
  @import "swiper/swiper.scss";
  @import "swiper/components/effect-fade/effect-fade.scss";
  .top {
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
  }

  .img-container {
    min-height: 100vh;
  }
  @media only screen and (max-height: 800px) {
    .img-container {
      min-height: 850px;
    }
  }
</style>

<!-- DYNAMIC BACKGROUND IMAGES -->
<Swiper {...swiperProps} on:swiper={getSwiper}>
  <!-- SLIDES -->
  {#each heroItems as item}
    <SwiperSlide>
      <div data-blink-uuid={item.image_uuid} class="dark-overlay bg-cover">
        <div class="img-container container h-100">
          <!-- BUSINESS NAME -->
          <div
            class="overlay-content d-none d-lg-block"
            style="position: absolute; bottom: 20px; left: 36px;">
            <p class="text-white opacity-8">
              <span class="mr-2 opacity-8">
                Featured:
                <br />
              </span>
              {item.business.name} ({item.business.area?.name
                ? item.business.area?.name + ","
                : ""}
              {item.business.country.name})
            </p>
          </div>
          <!-- /BUSINESS NAME -->
        </div>
      </div>
    </SwiperSlide>
  {/each}
  <!-- /SLIDES -->
</Swiper>

<!-- STATIC OVERLAY CONTENT -->
<!-- This content needs to be above so that the searchbox does not move with each slide. -->
<div
  class="container top position-absolute d-flex h-100 text-white overlay-content
  align-items-md-center"
  style="min-height: 100vh;">
  <div class="w-100 py-6">
    <div class="mb-7 pb-4">
      <SearchBar />
    </div>

    <!-- HEADER -->
    <div class="row mb-5">
      <div class="col-12 text-center">
        <h2 style="line-height: 1" class="display-3 font-weight-bold mb-3">
          Explore better. Book direct.
        </h2>
        <p
          class="lead opacity-8 ml-auto mr-auto"
          style="font-weight:400; max-width: 800px;">
          See suggestions from friends &amp; local experts. Book direct instead of using
          traditional booking sites — you’ll get the best price, and help local businesses
          avoid unnecessary fees.
        </p>
      </div>
    </div>
    <!-- /HEADER -->

    <!-- PAGE LINKS -->
    <div class="row mt-3 mt-md-5 justify-content-md-center">
      <div class="mx-5 mb-2">
        <a
          href={allCountriesView()}
          class="media text-reset text-decoration-none hover-animate">
          <div class="icon-rounded bg-white">
            <i class="fa fa-globe-stand text-primary text-lg" />
          </div>
          <div class="media-body">
            <h5 class="mt-3 ml-2">
              Explore by Country
              <i class="fa fa-long-arrow-alt-right ml-2" />
            </h5>
          </div>
        </a>
      </div>

      <div class="mx-5 mb-2">
        <a
          class="media text-reset text-decoration-none hover-animate"
          href={allActivitiesView()}>
          <div class="icon-rounded bg-white">
            <i class="fa fa-hiking text-primary text-lg" />
          </div>
          <div class="media-body">
            <h5 class="mt-3 ml-2">
              Explore by Activity
              <i class="fa fa-long-arrow-alt-right ml-2" />
            </h5>
          </div>
        </a>
      </div>

      <div class="mx-5 mb-2">
        <a
          href={dashboardView()}
          class="media text-reset text-decoration-none hover-animate">
          <div class="icon-rounded bg-white">
            <i class="fa fa-users text-primary text-lg" />
          </div>
          <div class="media-body">
            <h5 class="mt-3 ml-2 mb-0">
              Personalised for You
              <i class="fa fa-long-arrow-alt-right ml-2" />
            </h5>
          </div>
        </a>
      </div>
      <!-- /PAGE LINKS -->
    </div>
  </div>
</div>
