import { SvelteComponent } from "svelte";
import { reportError } from "./errors";

/**
 * Init a svelte component in the target denoted by the elementID.
 *
 * Automatically passes in data-* attributes as camelCased props.
 *
 * @param {string} elementID An elementID, e.g., 'my-element'
 * @param {SvelteComponent} component Any Svelte component
 * @param {Array<any>} props Props to pass to component, merged with any specified data attrs.
 * @returns bool Whether or not the target was found and init'ed
 */
export function initComponentById(elementId, component, props) {
  elementId = elementId && elementId[0] == "#" ? elementId : `#${elementId}`;
  let target = $(elementId);

  if (!target.length) {
    return false;
  }

  try {
    new component({
      target: target[0],
      props: { ...target.data(), ...props },
    });
  } catch (error) {
    reportError(error);
    return false;
  }
  return true;
}

/**
 * Init a svelte component in the targets denoted by the className.
 *
 * Automatically passes in data-* attributes as camelCased props.
 *
 * @param {string} elementID A className, e.g., 'my-classy-elements'
 * @param {SvelteComponent} component Any Svelte component
 * @param {Array<any>} props If an array, maps one-to-one with each target, else, applies
 *   the same props to all targets.
 * @returns int The number of targets init'ed
 */
export function initComponentsByClassName(className, component, props) {
  className = className && className[0] == "." ? className : `.${className}`;

  let targets = $(className);
  let matchProps = props != null && props.length === targets.length;
  let created = 0;
  targets.each((i, target) => {
    try {
      new component({
        target: target,
        props: { ...$(target).data(), ...(matchProps ? props[i] : props) },
      });
      created++;
    } catch (error) {
      reportError(error);
    }
  });
  return created;
}

export async function initPage(component, app = "traveller") {
  let page = await import(`/routes/${app}/__page.svelte`);
  // support dynamic component imports i.e. await import("xyz.svelte");
  if (component.__esModule) component = component.default;

  return initComponentById("page", page.default, { component: component });
}

export async function initFooter(component) {
  // support dynamic component imports i.e. await import("xyz.svelte");
  if (component.__esModule) component = component.default;
  return initComponentById("footer", component);
}
