<script lang="ts">
  import { MetaTags } from "svelte-meta-tags";

  export let title: string = "Explore better & book direct with The Guide";
  export let description: string =
    "Get personalised travel recommendations from The Guide and book direct. You'll get the best price, and help local businesses avoid unnecessary fees.";
  export let images: Array<{ url: string; width: number; height: number; alt: string }> =
    [];

  const FACEBOOK_APP_ID = "931739037246748";
  const CANONICAL_URL = "https://www.theguide.tab.travel/explore";
</script>

<MetaTags
  {title}
  {description}
  canonical={CANONICAL_URL}
  openGraph={{
    url: CANONICAL_URL,
    title: title,
    description: description,
    images: images,
    site_name: "The Guide",
  }}
  twitter={{
    cardType: "summary",
    site: "@usetheguide",
  }}
  facebook={{
    appId: FACEBOOK_APP_ID,
  }}
  robotsProps={{
    noarchive: true,
    nosnippet: false,
    maxImagePreview: "standard",
    maxVideoPreview: 10,
    notranslate: false,
    noimageindex: false,
  }} />
