<script>
  import { getCurrencySymbol } from "/shared/currency.js";
  export let product;
</script>

<div
  on:click
  style="cursor: pointer;"
  class="card h-100 border-0 rounded-lg hover-animate">
  <div class="embed-responsive embed-responsive-4by3">
    <img
      alt={product.title}
      data-blink-uuid={product.image_link_uuid}
      class="card-img-top embed-responsive-item rounded-lg" />
  </div>
  <div class="card-body px-0 pt-2">
    <!-- TITLE -->
    <div class="font-weight-bold mb-0 mt-0 text-truncate-2 h6">
      {product.title}
    </div>

    <!-- META-DATA  -->
    <!-- Use flexbox to place content in a row -->
    <div class="d-flex">
      <!-- align to bottom edge using mt-auto -->
      <div class="mt-auto text-sm text-muted">
        {#if product.duration}
          <div class="d-block">
            <i class="fa fa-xs fal-clock mr-1" />
            {product.duration_human}
          </div>
        {/if}

        {#if product.maximum_capacity}
          <div class="d-block">
            <i class="fa fa-xs fal-users mr-1" />
            Up to {product.maximum_capacity} people
          </div>
        {/if}
      </div>

      {#if product.minimum_price}
        <!-- move to right using ml-auto -->
        <div class="ml-auto text-muted">
          <div class="d-block m-0 font-weight-bold text-sm text-right">From</div>
          <div class="d-block text-md font-weight-bolder">
            <span class="text-sm">
              {getCurrencySymbol(product.minimum_price_currency)}
            </span>
            {Math.trunc(product.minimum_price)}
          </div>
        </div>
      {/if}
    </div>
    <!-- /META-DATA -->
  </div>
</div>
