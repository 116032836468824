<script lang="ts" context="module">
  // svelte-ignore unused-export-let
  export interface Activity {
    name: string;
    imageLink: string;
    activityCode?: string;
  }
</script>

<script lang="ts">
  import { setContext } from "svelte";
  import ActivityCard from "./ActivityCard.svelte";
  import PureSwiper from "./PureSwiper.svelte";

  export let activities: Array<Activity>;
  export let country = null;

  if (country) {
    setContext("country", {
      code: country,
    });
  }

  // create list of named objects for prop spreading
  // @ts-ignore
  $: activities = activities.map((a) => ({ activity: a }));
</script>

<PureSwiper items={activities} component={ActivityCard} on:click />
