<script>
  import BusinessCard from "./BusinessCard.svelte";
  import PureSwiper from "./PureSwiper.svelte";
  export let businesses = [];

  let swiperProps = {
    slidesPerView: 1.3,
    spaceBetween: 10,
    navigation: {
      nextEl: ".button-next",
      prevEl: ".button-prev",
    },
    breakpoints: {
      576: {
        slidesPerView: 2,
      },
      960: {
        slidesPerView: 3,
      },
    },
  };

  // create list of named objects for prop spreading
  $: mappedBusinesses = businesses.map((b) => ({ business: b }));
</script>

<PureSwiper {swiperProps} items={mappedBusinesses} component={BusinessCard} on:click />
