<script lang="ts">
  import Recommendation from "./Recommendation.svelte";
  import { VisitedBusinessSerialiser as Highlight } from "/apiFactory/api";

  export let highlights: Array<Highlight> = [];
</script>

<div class="row">
  <div class="col-12">
    <div class="subtitle text-primary text-sm mb-5">Recommendations</div>
    {#each highlights as highlight, i (highlight.created)}
      <Recommendation {...highlight} />
      {#if i !== highlights.length - 1}
        <hr class="pb-2 mt-n2" />
      {/if}
    {/each}
  </div>
</div>
