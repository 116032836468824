<script lang="ts">
  import { Modal, ModalBody } from "sveltestrap";
  import SpotlightHeader from "./SpotlightHeader.svelte";
  import ThirtyWordsSection from "./ThirtyWords.svelte";
  import ViewListingButton from "./ViewListingButton.svelte";
  import Cap from "./cap.svelte";
  import { Recommender } from "/business/types";
  import { currentTraveller } from "../stores";
  import {
    VisitedBusinessSerialiser as Highlight,
    BusinessSpotlightSerialiser,
  } from "/apiFactory/api";
  import TravellerRecommendations from "/business/profile/TravellerRecommendations.svelte";

  export let open: boolean = false;
  export let toggle = undefined;
  export let business: BusinessSpotlightSerialiser;
  export let highlights: Array<Highlight> = [];
  export let wishlist_uuid = undefined;

  let recommenders: Array<Recommender> = [];
  // retrieve recommenders from highlights
  $: {
    recommenders = []; // reset list each time updated
    highlights.forEach((highlight) => {
      if ($currentTraveller.code != highlight.traveller.code) {
        recommenders.push({
          code: highlight.traveller.code,
          selfie_avatar_uuid: highlight.traveller.selfie_avatar_uuid,
          name: highlight.traveller.name,
        });
      }
    });
  }

  /*
   * The *spotlight* component composes a number of elements
   * together to show a "preview" of a business's profile page
   * in a Bootstrap modal.
   *
   * The `business` prop must conform to the interface defined
   * in `business.ts`.
   */

  // make open/close snappier
  const transitionOptions = { duration: 150 };
</script>

<style lang="css">
  .top-right {
    /* Custom class as we don't place the button in the header */
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem 1rem;
    z-index: 1004;
  }
  :global(.modal-content) {
    border: 0;
  }
</style>

<Modal isOpen={open} {toggle} size="lg" {transitionOptions}>
  <ModalBody class="p-0 mb-4">
    <button type="button" on:click={toggle} class="close top-right" aria-label="Close">
      <span aria-hidden="true">
        <i class="far far-times text-white" />
      </span>
    </button>
    <slot name="header">
      <SpotlightHeader
        {business}
        wishlist_uuid={wishlist_uuid || business.wishlist_uuid} />
    </slot>
    <slot name="cap">
      <!-- Overlay '30 words' section onto SpotlightHeader component -->
      <div class="mt-n5">
        <Cap>
          <ThirtyWordsSection {business} />
        </Cap>
      </div>
    </slot>

    <!-- TRAVELLER WITH RECOMMENDATIONS-->
    <slot name="body">
      <div class="pb-2 px-md-4">
        <div class="container">
          <div class="row">
            <div class="col-10 offset-1 px-0">
              <TravellerRecommendations
                businessCode={business.business_code}
                businessName={business.name} />
            </div>
          </div>
        </div>
      </div>
    </slot>
    <!-- /TRAVELLER -->

    <slot name="footer">
      {#if !business.is_shell}
        <div class="my-4">
          <ViewListingButton url={business.profile_url} />
        </div>
      {/if}
    </slot>
  </ModalBody>
</Modal>

<slot name="extra-modal" />
