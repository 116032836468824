<script>
  import { Modal, ModalBody, ModalHeader } from "sveltestrap";
  import { ActivitySubtype } from "/shared/enums.ts";
  import BookItemForm from "/inventory/BookItemForm.svelte";
  import { getCurrencySymbol } from "/shared/currency.js";

  export let activity;
  export let business = {
    name: "",
    profile_url: "",
  };
  export let isOpen = false;
  export let toggle = null;
  let showForm = false;
</script>

<style lang="css">
  .text-black {
    color: #000000 !important;
  }
  pre {
    white-space: pre-wrap;
  }
  .embed-responsive-3by2::before {
    padding-top: 66.66%;
    display: block;
    content: "";
  }
  :global(.detail-modal .modal-lg) {
    max-width: 700px;
  }
  :global(.detail-modal .modal-body) {
    padding-top: 0;
  }
</style>

<!--
  @component
  Display a business activity's details in a modal with CTA button.

  The CTA redirects if the activity has a booking url, otherwise shows the BookItemForm.

  - Dispatches:
    - toggle, when the modal is closed - either by a user or completion
-->
<div class="detail-modal">
  <Modal
    class="modal-dialog-centered"
    contentClassName="rounded-lg"
    size="lg"
    {isOpen}
    {toggle}>
    <ModalHeader>{activity.title}</ModalHeader>

    <ModalBody>
      <div class="mx-lg-5 mx-1">
        {#if activity.image_gallery}
          <!-- create a responsive insta-like gallery -->
          <div class="embed-responsive embed-responsive-3by2">
            <iframe
              title="itemImageGallery"
              src={`${activity.image_gallery}gallery/-/nav/false/-/fit/cover/-/loop/true/-/allowfullscreen/false/-/thumbwidth/100/`}
              width="100%"
              height="250"
              allowfullscreen="true" />
          </div>
        {:else if activity.image_link}
          <div class="embed-responsive embed-responsive-3by2">
            <img
              class="embed-responsive-item"
              data-blink-uuid={activity.image_link_uuid}
              data-blink-scale-mode="scale-crop"
              alt={activity.title} />
          </div>
        {/if}

        <div class="mt-2">
          <!-- ACTIVITY INFO -->
          <span
            style="background-color: #b4b4b6;"
            class="badge badge-pill badge-dark-light text-white font-weight-bold
            float-left">
            {ActivitySubtype[activity.activity_type]}
          </span>

          <div class="font-weight-bold text-sm d-flex">
            <!-- Allow duration/capacity content to fill and wrap on smaller screens -->
            <div
              class="d-flex px-1 flex-wrap flex-grow-1 justify-content-center
              align-content-start">
              {#if activity.duration}
                <div class="mx-2 d-lg-none d-inline text-muted">
                  <i class="fa fa-xs fa-clock mr-1" />
                  {activity.duration_human}
                </div>
                <div class="mx-2 d-none d-lg-inline text-muted">
                  <i class="fa fa-xs fa-clock mr-1" />
                  Duration: {activity.duration_human}
                </div>
              {/if}

              {#if activity.maximum_capacity}
                <div class="mx-2 d-lg-none d-inline text-muted">
                  <i class="fa fa-xs fa-users mr-1" />
                  1-{activity.maximum_capacity}
                </div>
                <div class="mx-2 d-none d-lg-inline text-muted">
                  <i class="fa fa-xs fa-users mr-1" />
                  Max: {activity.maximum_capacity} people
                </div>
              {/if}
            </div>

            <!-- Move price to the far right (ml-auto) -->
            {#if activity.minimum_price}
              <div class="d-block ml-auto text-black">
                <div class="m-0 font-weight-bold text-sm">From</div>
                <div class="m-0 h4 float-right font-weight-bolder d-flex">
                  <span>
                    {getCurrencySymbol(activity.minimum_price_currency)}
                  </span>
                  <span>{Math.trunc(activity.minimum_price)}</span>
                </div>
              </div>
            {/if}
          </div>

          <!-- BUSINESS NAME -->
          {#if business.name}
            <div class="text-md font-weight-bold text-black mt-sm-n3 mb-1">
              by {business.name}
            </div>
          {/if}

          <!-- ACTIVITY DESCRIPTION -->
          {#if activity.description}
            <!-- use pre to render line breaks, but also wrap using css (see style) -->
            <pre class="font-weight-normal h6 text-muted">
              {activity.description}
            </pre>
          {/if}
        </div>

        <!-- BOOKING FORM -->
        {#if showForm}
          <div class="mt-4">
            <BookItemForm
              id={activity.id}
              title={activity.title}
              onOkay={() => (isOpen = !isOpen)}
              onCancel={() => (showForm = !showForm)} />
          </div>
        {/if}

        <!-- CTA -->
        <div class="text-center mt-4">
          {#if activity.book_now_url}
            <a
              class="btn btn-primary px-5"
              href={activity.book_now_url}
              target="_blank"
              rel="noopener noreferrer">
              Book Direct
            </a>
          {:else if !showForm}
            <button
              class="btn btn-primary px-5"
              on:click={() => {
                showForm = true;
              }}>
              Book Direct
            </button>
          {/if}

          <!-- SEE MORE FROM BUSINESS -->
          <div class="mt-2 text-black">
            <a class="text-sm font-weight-bold text-black" href={business.profile_url}>
              <u>See more from {business.name}</u>
            </a>
          </div>
        </div>
      </div>
    </ModalBody>
  </Modal>
</div>
